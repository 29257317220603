import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import { getAwardHistory } from "../../store/actions/points.actions";

import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import TableComponent from "../../components/tableComponent";

const AwardHistory = ({ match }) => {
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);

  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const { isLoadingPoints, rewards, countRewards } = useSelector(
    state => state.points
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getAwardHistory({
        page: currentPage,
        limit: rowsPerPage,
        id: match.params.id
      })
    );
  }, [dispatch, currentPage, rowsPerPage]);

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Award Name | AR",
      key: "awardNameAr"
    },
    {
      name: "Award Name | EN",
      key: "awardName"
    },
    {
      name: "Award Image",
      field: "imagePath",
      key: "imageIcon"
    },
    {
      name: "Award Points",
      key: "numberOfPoints"
    },
    {
      name: "Date",
      field: "saveDate",
      key: "date"
    }
  ];

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Awards History</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {isLoadingPoints ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-lg"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <TableComponent
                        tableHead={tableHead}
                        tableBody={rewards}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Pagination
                  totalCount={countRewards}
                  rows={rowsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setPageQuery}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardHistory;

import { API_URL } from "../constants";
import axiosInstance from "./interceptor";

export const foodTruckApi = {
  getFoodTruckMenu() {
    return axiosInstance.get(`${API_URL}/food-truck/truck-menu`);
  },

  updateFoodTruckMenu(data) {
    return axiosInstance.patch(`${API_URL}/food-truck/truck-menu`, {
      ...data
    });
  },

  getFoodTruckBooked(params) {
    const { page, limit } = params;
    return axiosInstance.get(`${API_URL}/food-truck/booked-truck`, {
      params: {
        page,
        limit
      }
    });
  }
};

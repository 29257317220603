import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createScheduleNotification } from "store/actions/notification.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./styles.scss";

export default function ModalBirthdayNotifications({ modal, toggle }) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: "",
      text: "",
      date: "",
      status: "",
      repeatTimes: 1,
      type: 2,
      trigger: "Trigger",
      additionalData: ""
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Required"),
      text: Yup.string().required("Required"),
      status: Yup.number().required("Required"),
      date: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(
        createScheduleNotification({
          ...values,
          repeatTimes: values.repeatTimes.toString(),
          date: `${new Date(values.date).getMonth() + 1}`
        })
      );
      formik.resetForm();
      toggle();
    }
  });

  const getError = name =>
    formik.errors[name] && formik.touched[name] ? (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{formik.errors[name]}</li>
      </ul>
    ) : null;

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Birthday Notification
      </ModalHeader>
      <form onSubmit={formik.handleSubmit} className="modal-body p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="control-label mb-1">Title</div>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              placeholder="Title"
              value={formik.values.title}
              onClick={() => {
                formik.setFieldTouched("title", true);
              }}
              onChange={formik.handleChange}
            />
            {getError("title")}
          </div>
          <div className="col-md-12">
            <div className="control-label mb-1">Message</div>
            <textarea
              type="text"
              className="form-control"
              id="text"
              name="text"
              placeholder="Message"
              value={formik.values.text}
              onClick={() => {
                formik.setFieldTouched("text", true);
              }}
              onChange={formik.handleChange}
            />
            {getError("text")}
          </div>

          <div className="col-md-12 month-picker">
            <div className="control-label mb-1">Date</div>

            <DatePicker
              id="date"
              placeholderText={
                formik.values.date
                  ? moment(formik.values.date).format("MMMM")
                  : "Date"
              }
              onChange={e => {
                formik.setFieldValue("date", e);
              }}
              onClick={() => {
                formik.setFieldError("date", true);
              }}
              className="w-100 py-1 px-2 rounded border"
              dateFormat="MMMM"
              showMonthYearPicker
            />
            {getError("date")}
          </div>

          <div className="col-md-12">
            <div className="control-label mb-1">Status</div>
            <select
              className="custom-select form-control"
              name="status"
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              <option value="">--</option>
              <option value="1">Active</option>
              <option value="2">Not Active</option>
            </select>
            {getError("status")}
          </div>
          <div className="col-md-12">
            <div className="control-label mb-1">Repeated times</div>
            <input
              type="number"
              min="1"
              className="form-control"
              id="repeatTimes"
              name="repeatTimes"
              placeholder="Repeated times"
              value={formik.values.repeatTimes}
              onClick={() => {
                formik.setFieldTouched("repeatTimes", true);
              }}
              onChange={formik.handleChange}
            />
            {getError("repeatTimes")}
          </div>
          <div className="col-md-12">
            <div className="control-label mb-1">Promo Code</div>
            <input
              type="text"
              className="form-control"
              id="additionalData"
              name="additionalData"
              placeholder="Promo Code"
              value={formik.values.additionalData}
              onClick={() => {
                formik.setFieldTouched("additionalData", true);
              }}
              onChange={formik.handleChange}
            />
            {getError("additionalData")}
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              formik.resetForm();
              toggle();
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setAdvertisement } from "../../../store/actions/pagesContent.action";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import Dropify from "../../dropify/dropify";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../../store/actions/products.actions";

export default function ModalAdvertisement({ modal, toggle }) {
  const dispatch = useDispatch();
  const { products, isLoadingProducts } = useSelector(state => state.products);
  const formik = useFormik({
    initialValues: {
      selectedFile: "",
      product: ""
    },
    validationSchema: Yup.object().shape({
      selectedFile: Yup.mixed().required("Required")
    }),
    onSubmit: values => {
      const formData = new FormData();
      formData.append("image", values.selectedFile);
      const data = { formData };
      if (values.product) {
        data.product = products[values.product];
      }
      dispatch(setAdvertisement(data));
      formik.resetForm();
      toggle();
    }
  });
  useEffect(() => {
    dispatch(getProducts({ pagination: { limit: 100, page: 1 } }));
  }, [dispatch]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Advertisement
      </ModalHeader>
      <form onSubmit={formik.handleSubmit} className="modal-body p-4">
        {isLoadingProducts ? (
          <div className="d-flex justify-content-center  w-100">
            <div className="spinner-border avatar-lg" role="status"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="control-label mb-1" htmlFor="field-2">
                Product
              </div>
              <select
                className="custom-select form-control"
                id="product"
                name="product"
                onChange={formik.handleChange}
                value={formik.values.product}
              >
                <option value="">--</option>
                {products.map((item, index) => (
                  <option key={item.id} value={index}>
                    {item.name.en}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-12">
              <div htmlFor="field-2" className="control-label mb-1">
                Image{" "}
              </div>
              <Dropify
                product={products[formik.values.product] || {}}
                height="25vh"
                // action={setAdvertisement}
                modal={modal}
                formik={formik}
              />
            </div>
          </div>
        )}
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              formik.resetForm();
              toggle();
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

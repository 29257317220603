import { call, put, takeLatest } from "redux-saga/effects";
import { notificationApi } from "../../services/notification.service";
import { notificationActionsTypes } from "../actions/notification.actions";

function* sendAllNotifications(action) {
  try {
    yield call(notificationApi.sendAllNotifications, action.payload);
    yield put({
      type: notificationActionsTypes.SEND_ALL_NOTIFICATIONS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: notificationActionsTypes.SEND_ALL_NOTIFICATIONS_FAILURE
    });
  }
}

function* sendSpecificNotifications(action) {
  try {
    yield call(notificationApi.sendSpecificNotifications, action.payload);
    yield put({
      type: notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS_FAILURE
    });
  }
}

function* createScheduleNotification(action) {
  try {
    const { data } = yield call(
      notificationApi.createScheduleNotification,
      action.payload
    );
    yield put({
      type: notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION_FAILURE
    });
  }
}

function* getScheduleNotification(action) {
  try {
    const { data } = yield call(
      notificationApi.getScheduleNotifications,
      action.payload
    );
    yield put({
      type: notificationActionsTypes.GET_SCHEDULE_NOTIFICATION_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: notificationActionsTypes.GET_SCHEDULE_NOTIFICATION_FAILURE
    });
  }
}

function* editScheduleNotificationStatus(action) {
  try {
    yield call(
      notificationApi.editScheduleNotificationStatus,
      action.payload.id,
      action.payload.data
    );
    yield put({
      type: notificationActionsTypes.EDIT_SCHEDULE_NOTIFICATION_STATUS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: notificationActionsTypes.EDIT_SCHEDULE_NOTIFICATION_STATUS_FAILURE
    });
  }
}

function* notificationsSaga() {
  yield takeLatest(
    [notificationActionsTypes.SEND_ALL_NOTIFICATIONS],
    sendAllNotifications
  );
  yield takeLatest(
    [notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS],
    sendSpecificNotifications
  );
  yield takeLatest(
    [notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION],
    createScheduleNotification
  );
  yield takeLatest(
    [notificationActionsTypes.GET_SCHEDULE_NOTIFICATION],
    getScheduleNotification
  );
  yield takeLatest(
    [notificationActionsTypes.EDIT_SCHEDULE_NOTIFICATION_STATUS],
    editScheduleNotificationStatus
  );
}

export default notificationsSaga;

import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { assignPoint } from "store/actions/points.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import { simpleDateFormat } from "utils/helpers";

export default function ModalPointHistory({ modal, toggle, id }) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      numberOfPoints: "",
      expiration: ""
    },
    validationSchema: Yup.object().shape({
      numberOfPoints: Yup.string().required("Required"),
      expiration: Yup.string().required("Required")
    }),
    onSubmit: values => {
      const expiration = simpleDateFormat(values.expiration);
      dispatch(
        assignPoint({
          numberOfPoints: values.numberOfPoints,
          expiration,
          id
        })
      );
      formik.resetForm();
      toggle();
    }
  });

  const getError = name =>
    formik.errors[name] && formik.touched[name] ? (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{formik.errors[name]}</li>
      </ul>
    ) : null;

  const changeDate = e => {
    formik.setValues({
      ...formik.values,
      expiration: e
    });
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        Assign Points
      </ModalHeader>
      <form className="modal-body p-4" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="numberOfPoints" className="control-label">
                Number of Points
              </label>
              <input
                type="number"
                className="form-control"
                name="numberOfPoints"
                placeholder="Number of Points"
                value={formik.values.numberOfPoints}
                onChange={formik.handleChange}
                onClick={() => {
                  formik.setFieldTouched("numberOfPoints", true);
                }}
              />
              {getError("numberOfPoints")}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="expiration" className="control-label">
                Points Expiration:
              </label>
              <DatePicker
                id="expiration"
                selected={formik.values.expiration}
                onChange={changeDate}
                showTimeSelect
                className="datepicker"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
              {getError("expiration")}
            </div>
          </div>
        </div>

        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button color="success" type="submit">
            Save Changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

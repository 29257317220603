import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import {
  getPoints,
  updatePoint,
  resetError
} from "../../store/actions/points.actions";
import { getProducts } from "../../store/actions/products.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import LoadingButton from "../../components/loadingButton/loadingButton";
import PointsList from "./pointList";
import _ from "lodash";

const UpdatePoint = ({ history, match }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const products = useSelector(state => state.products.products);
  const { showMessage, points } = useSelector(state => state.points);
  const isLoading = useSelector(state => state.points.isLoadingPoints);
  const isLoadingBtn = useSelector(state => state.points.isLoadingPointsBtn);

  const addNewRow = e => {
    if (Number(formik.pointType === 1)) {
      formik.setFieldValue("pointCondition", [
        ...formik.values.pointCondition,
        {
          numberOfOrders: "",
          numberOfPoints: ""
        }
      ]);
    } else {
      formik.setFieldValue("pointCondition", [
        ...formik.values.pointCondition,
        {
          numberOfProducts: "",
          numberOfPoints: "",
          productId: ""
        }
      ]);
    }
  };

  const clickOnDelete = record => {
    formik.setFieldValue(
      "pointCondition",
      formik.values.pointCondition.filter(r => r !== record)
    );
  };

  const closeSurvey = () => {
    history.push("/points");
  };

  const formik = useFormik({
    initialValues: {
      pointId: points.id,
      pointType: match.params.pointType,
      pointCondition: points.pointConditions
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (Number(formik.values.pointType) === 1) {
        dispatch(
          updatePoint({
            pointId: values.pointId,
            pointCondition: values.pointCondition
          })
        );
      } else {
        dispatch(
          updatePoint({
            pointId: values.pointId,
            pointCondition: values.pointCondition
          })
        );
      }
    }
  });

  useEffect(() => {
    dispatch(
      getPoints({
        type: match.params.pointType,
        pagination: {
          page: 1,
          limit: 100
        }
      })
    );
  }, [match.params.pointType]);

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(resetError());
        history.push("/points");
      }, 3000);
    }
  }, [showMessage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Update Point</h4>
                </div>
              </div>
            </div>
            {showMessage && <div className="alert alert-success">Saved</div>}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2"></div>
                    <div className="col-md-12">
                      <div className="modal-content">
                        {isLoading || _.isEmpty(points) ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border avatar-lg"
                              role="status"
                            ></div>
                          </div>
                        ) : (
                          <div className="modal-body p-4">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="pointType">
                                      Point Type: &nbsp; &nbsp;{" "}
                                    </label>

                                    <select
                                      id="pointType"
                                      name="pointType"
                                      className="form-control"
                                      onChange={formik.handleChange}
                                      value={formik.values.pointType}
                                      disabled
                                    >
                                      <option value={1}>Order</option>
                                      <option value={2}>Product</option>
                                    </select>
                                    {formik.errors.pointType &&
                                    formik.touched.pointType ? (
                                      <ul className="parsley-errors-list filled">
                                        <li className="parsley-required">
                                          {formik.errors.pointType}
                                        </li>
                                      </ul>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <table className="table">
                                <thead>
                                  <tr className="d-flex">
                                    <th className="col-md-5 pl-0 text-left">
                                      {Number(formik.values.pointType) === 1
                                        ? "Number of Orders"
                                        : "Number of Products"}
                                    </th>
                                    <th className="col-md-5 pl-0 text-left">
                                      Number of Points
                                    </th>
                                    <th className="col-md-2 pl-0"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.pointCondition && (
                                    <PointsList
                                      add={addNewRow}
                                      delete={clickOnDelete}
                                      taskList={formik.values.pointCondition}
                                      formik={formik}
                                      products={products}
                                    />
                                  )}
                                </tbody>
                              </table>

                              <div className="modal-footer mt-3">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-dismiss="modal"
                                  onClick={closeSurvey}
                                >
                                  Close
                                </button>
                                <LoadingButton
                                  isLoadingBtn={isLoadingBtn}
                                  btnContent="Save"
                                />
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePoint;

export const authorizationActionsTypes = {
  SET_STATUS_MENU: "SET_STATUS_MENU",
  SET_STATUS_MENU_FALSE: "SET_STATUS_MENU_FALSE",

  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  // TODO: where is logout failure
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  FOODICS_AUTH: "FOODICS_AUTH",
  FOODICS_AUTH_SUCCESS: "FOODICS_AUTH_SUCCESS",

  GET_SUPERVISORS: "GET_SUPERVISORS",
  GET_SUPERVISORS_SUCCESS: "GET_SUPERVISORS_SUCCESS",
  GET_SUPERVISORS_FAILURE: "GET_SUPERVISORS_FAILURE",

  SET_SUPERVISORS: "SET_SUPERVISORS",
  SET_SUPERVISORS_SUCCESS: "SET_SUPERVISORS_SUCCESS",
  SET_SUPERVISORS_FAILURE: "SET_SUPERVISORS_FAILURE",

  UPDATE_SUPERVISORS: "UPDATE_SUPERVISORS",
  UPDATE_SUPERVISORS_SUCCESS: "UPDATE_SUPERVISORS_SUCCESS",
  UPDATE_SUPERVISORS_FAILURE: "UPDATE_SUPERVISORS_FAILURE",

  DELETE_SUPERVISORS: "DELETE_SUPERVISORS",
  DELETE_SUPERVISORS_SUCCESS: "DELETE_SUPERVISORS_SUCCESS",
  DELETE_SUPERVISORS_FAILURE: "DELETE_SUPERVISORS_FAILURE",

  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE"
};

export const login = data => {
  return {
    type: authorizationActionsTypes.LOGIN,
    payload: data
  };
};

export const foodicsAuth = () => {
  return {
    type: authorizationActionsTypes.FOODICS_AUTH
  };
};

export const changeStatusMenu = () => {
  return {
    type: authorizationActionsTypes.SET_STATUS_MENU
  };
};

export const changeStatusMenuFalse = () => {
  return {
    type: authorizationActionsTypes.SET_STATUS_MENU_FALSE
  };
};

export const logout = () => {
  return {
    type: authorizationActionsTypes.LOGOUT
  };
};

export const getSupervisors = data => {
  return {
    type: authorizationActionsTypes.GET_SUPERVISORS,
    payload: data
  };
};

export const setSupervisors = data => {
  return {
    type: authorizationActionsTypes.SET_SUPERVISORS,
    payload: data
  };
};

export const updateSupervisors = data => {
  return {
    type: authorizationActionsTypes.UPDATE_SUPERVISORS,
    payload: data
  };
};

export const deleteSupervisors = id => {
  return {
    type: authorizationActionsTypes.DELETE_SUPERVISORS,
    payload: id
  };
};

export const getUser = () => {
  return {
    type: authorizationActionsTypes.GET_USER
  };
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";
import usePrevious from "../../helpers/usePrevious";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { getCustomers } from "../../store/actions/customers.actions";
import { sendSpecificNotification } from "../../store/actions/notification.actions";

import { DebounceInput } from "react-debounce-input";
import Pagination from "../../components/pagination/pagination";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

const SpecificNotifications = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const formik = useFormik({
    initialValues: {
      message: "",
      customers: new Set()
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      message: Yup.string().required("Required")
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(sendSpecificNotification(values));
      resetForm();
      formik.setValues({ message: "", customers: new Set() });
    }
  });

  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);

  const [search, setSearch] = useState(searchQuery || "");
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setSearch(searchQuery || "");
  });

  const { customers, countCustomers, isLoadingCustomer } = useSelector(
    state => state.customers
  );
  const { loadingNotifications, success } = useSelector(
    state => state.notification
  );

  const previousSearchValue = usePrevious(search);
  const previousCurrentPageValue = usePrevious(currentPage);

  useEffect(() => {
    if (
      previousSearchValue !== undefined &&
      search !== undefined &&
      previousSearchValue !== search
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }

    dispatch(
      getCustomers({
        pagination: { page: currentPage, limit: rowsPerPage },
        search
      })
    );
  }, [dispatch, currentPage, rowsPerPage, search]);

  const tableHead = [
    {
      name: "Name",
      key: "firstName",
      lastName: "lastName"
    },
    {
      name: "Mobile Number",
      key: "phone"
    },
    {
      name: "Check",
      key: "checkCustomer",
      formik: formik,
      onClick: ({ target: { value } }) => {
        const customers = formik.values.customers;
        if (customers.has(value)) {
          customers.delete(value);
        } else {
          customers.add(value);
        }
        formik.setFieldValue("customers", customers);
      }
    }
  ];

  useEffect(() => {
    success &&
      toast.success("Sent", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [success]);

  const getError = name =>
    formik.errors[name] && formik.touched[name] ? (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{formik.errors[name]}</li>
      </ul>
    ) : null;

  const loadingIndicator = () => (
    <div className="d-flex justify-content-center">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  );

  const getContent = () => (
    <div className="row">
      <div className="col-sm-12">
        <div className="card-box">
          <div className="row">
            <div className="col-md-12">
              <div className="form-inline">
                <div className="form-group mb-2">
                  <DebounceInput
                    type="search"
                    minLength={1}
                    className="form-control"
                    debounceTimeout={800}
                    value={searchInputValue}
                    onChange={e => {
                      setSearchInputValue(e.target.value);
                      setSearchQuery(e.target.value);
                    }}
                    placeholder="Search by phone..."
                  />
                </div>
              </div>
              <div className="form w-100 d-flex justify-content-between align-items-end">
                <div className="form-group mb-2">
                  <textarea
                    className="p-2 form-control"
                    style={{ width: "500px", height: "100px" }}
                    name="message"
                    id="message"
                    placeholder="Message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    onClick={() => {
                      formik.setFieldTouched("message", true);
                    }}
                  ></textarea>
                  {getError("message")}
                </div>
                <div className="text-md-right">
                  <button
                    type="button"
                    className="btn btn-success waves-effect waves-light mb-2 mr-2"
                    onClick={formik.handleSubmit}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <TableComponent tableHead={tableHead} tableBody={customers} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Pagination
                totalCount={countCustomers}
                rows={rowsPerPage}
                currentPage={currentPage}
                setCurrentPage={setPageQuery}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Specific Notifications</h4>
                </div>
              </div>
            </div>
            {isLoadingCustomer || loadingNotifications
              ? loadingIndicator()
              : getContent()}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecificNotifications;

import React, { Fragment } from "react";

const AnswerList = props => {
  const Choices = ["", "", "", "", ""];

  return props.taskList.map((val, idx) => (
    <Fragment key={val + idx}>
      <tr className="d-flex" key={val.index}>
        <td className="col-md-4 pl-0 pr-0">
          <input
            type="text"
            className="form-control"
            id={`questions[${idx}].questionTitle`}
            name={`questions[${idx}].questionTitle`}
            value={props.formik.values.questions[idx].questionTitle}
            onChange={props.formik.handleChange}
            placeholder="Question"
            onClick={() => {
              props.formik.setFieldTouched(
                `questions[${idx}].questionTitle`,
                true
              );
            }}
            required
          />
        </td>

        <td className="col-md-4 pr-0 pl-0">
          <select
            id={`questions[${idx}].questionType`}
            name={`questions[${idx}].questionType`}
            onChange={props.formik.handleChange}
            value={props.formik.values.questions[idx].questionType}
            className="form-control"
          >
            <option value={1}>Rate</option>
            <option value={2}>Text</option>
            <option value={3}>Multiple Choices</option>
          </select>
        </td>
        <td className="col-md-3">
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              Add Question
            </button>
          ) : (
            <button
              className="btn btn-danger text-center"
              type="button"
              onClick={() => props.delete(val)}
            >
              X
            </button>
          )}
        </td>
      </tr>
      {Number(props.formik.values.questions[idx].questionType) === 3 && (
        <tr className="row">
          {props.formik.values.questions[idx].choices
            ? props.formik.values.questions[idx].choices.map(
                (choice, index) => (
                  <td key={`choice + ${index}`} className="col-md-6 text-left">
                    <div className="form-group">
                      <label
                        htmlFor={`props.formik.questions[${idx}].choices[${index}]`}
                        className="control-label"
                      >
                        {`Choice#${index + 1}`}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`questions[${idx}].choices[${index}]`}
                        name={`questions[${idx}].choices[${index}]`}
                        onChange={props.formik.handleChange}
                        value={choice}
                        placeholder={`Choice#${index + 1}`}
                      />
                    </div>
                  </td>
                )
              )
            : (props.formik.values.questions[idx].choices = Choices) &&
              props.formik.values.questions[idx].choices.map(
                (choice, index) => (
                  <td key={`choice + ${index}`} className="col-md-6 text-left">
                    <div className="form-group">
                      <label
                        htmlFor={`props.formik.questions[${idx}].choices[${index}]`}
                        className="control-label"
                      >
                        {`Choice#${index + 1}`}
                      </label>
                      <input
                        required={index === 0 ? true : false}
                        type="text"
                        className="form-control"
                        id={`questions[${idx}].choices[${index}]`}
                        name={`questions[${idx}].choices[${index}]`}
                        onChange={props.formik.handleChange}
                        value={choice}
                        placeholder={`Choice#${index + 1}`}
                      />
                    </div>
                  </td>
                )
              )}
        </tr>
      )}
    </Fragment>
  ));
};

export default AnswerList;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useQueryParam, NumberParam } from "use-query-params";
import { getFeedbackQuestions } from "../../store/actions/feedback.actions";
import _ from "lodash";
import TableComponent from "../../components/tableComponent";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModalEditQuestion from "../../components/modal/modalEditFeedbackQuestion/modalEditFeedbackQuestion";
import Pagination from "../../components/pagination/pagination";

const feedbackQuestion = ({ history, match }) => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState({});

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  const [feedbackQuestion, setFeedbackQuestion] = useState(false);
  const editFeedbackQuestion = () => setFeedbackQuestion(!feedbackQuestion);

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const feedbackQuestions = useSelector(
    state => state.feedback.feedbackQuestions
  );
  const countFeedbackQuestions = useSelector(
    state => state.feedback.countCustomerFeedback
  );
  const isLoading = useSelector(
    state => state.feedback.isLoadingFeedbackQuestions
  );

  const tableHead = [
    {
      name: "Filter Type",
      key: "feedbackType",
      value: feedbackQuestions
    },
    {
      name: "Question Type",
      key: "questionType"
    },
    {
      name: "Question",
      key: "questionTitle"
    },
    {
      name: "View Choices",
      key: "viewChoices",
      onClick: body => {
        setQuestion(body);
        editFeedbackQuestion();
      }
    }
  ];

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getFeedbackQuestions({
        id: match.params.id,
        pagination: {
          page: currentPage || 1,
          limit: rowsPerPage || 10
        }
      })
    );
  }, [match.params.id]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Feedback Questions</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="text-md-right">
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light mb-2 mr-2 mt-2"
                    onClick={() => {
                      history.push(`/updateSurvey/${match.params.id}`);
                    }}
                  >
                    <span style={{ color: "#fff" }}>Update Survey</span>
                  </button>
                </div>
                <div className="card-box paddingNone">
                  <div className="MultipleChoices selected">
                    {isLoading || _.isEmpty(feedbackQuestions[0]) ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-lg"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={feedbackQuestions[0].questions}
                        />
                        <Pagination
                          totalCount={countFeedbackQuestions}
                          rows={rowsPerPage}
                          currentPage={currentPage}
                          setCurrentPage={setPageQuery}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {question && (
        <ModalEditQuestion
          modal={feedbackQuestion}
          toggle={editFeedbackQuestion}
          feedbackQuestion={question}
        />
      )}
    </div>
  );
};

export default feedbackQuestion;

import React from "react";

const FilterPoints = ({ setFilter, value }) => (
  <div className="row mb-2">
    <div className="col-md-12">
      <div className="col-12 text-sm-center form-inline">
        <label htmlFor="Filter By">Filter By: &nbsp; &nbsp; </label>
        <div className="form-group mr-2">
          <select
            id="filterPoints"
            className="custom-select custom-select-sm"
            onChange={e => setFilter(e.target.value)}
            value={value}
          >
            <option value={1}>Order</option>
            <option value={2}>Product</option>
          </select>
        </div>
      </div>
    </div>
  </div>
);

export default FilterPoints;

import { call, put, takeLatest } from "redux-saga/effects";
import { pagesContentApi } from "../../services/pagesContent.service";
import { pagesContentsActionsTypes } from "../actions/pagesContent.action";

function* getContactUs() {
  try {
    const result = yield call(pagesContentApi.getContactUs);

    yield put({
      type: pagesContentsActionsTypes.GET_CONTACT_US_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.GET_CONTACT_US_FAILURE
    });
  }
}

function* setContactUs(action) {
  try {
    const result = yield call(pagesContentApi.setContactUs, action.payload);

    yield put({
      type: pagesContentsActionsTypes.SET_CONTACT_US_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.SET_CONTACT_US_FAILURE
    });
  }
}

function* getAboutUs(action) {
  try {
    const result = yield call(pagesContentApi.getAboutUs, action.payload);

    yield put({
      type: pagesContentsActionsTypes.GET_ABOUT_US_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.GET_ABOUT_US_FAILURE
    });
  }
}

function* setAboutUs(action) {
  try {
    const result = yield call(pagesContentApi.setAboutUs, action.payload);

    yield put({
      type: pagesContentsActionsTypes.SET_ABOUT_US_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.SET_ABOUT_US_FAILURE
    });
  }
}

function* getPrivacy(action) {
  try {
    const result = yield call(pagesContentApi.getPrivacy, action.payload);

    yield put({
      type: pagesContentsActionsTypes.GET_PRIVACY_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.GET_PRIVACY_FAILURE
    });
  }
}

function* setPrivacy(action) {
  try {
    const result = yield call(pagesContentApi.setPrivacy, action.payload);

    yield put({
      type: pagesContentsActionsTypes.SET_PRIVACY_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.SET_PRIVACY_FAILURE
    });
  }
}

function* getAdvertisement() {
  try {
    const result = yield call(pagesContentApi.getAdvertisement);
    yield put({
      type: pagesContentsActionsTypes.GET_ADVERTISEMENT_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.GET_ADVERTISEMENT_FAILURE
    });
  }
}

function* setAdvertisement({ payload }) {
  try {
    const { data } = yield call(pagesContentApi.setAdvertisement, payload);
    if (payload.product) {
      data.product = payload.product;
    }
    yield put({
      type: pagesContentsActionsTypes.SET_ADVERTISEMENT_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.SET_ADVERTISEMENT_FAILURE
    });
  }
}

function* deleteAdvertisement(action) {
  try {
    const result = yield call(
      pagesContentApi.deleteAdvertisement,
      action.payload
    );

    yield put({
      type: pagesContentsActionsTypes.DELETE_ADVERTISEMENT_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: pagesContentsActionsTypes.DELETE_ADVERTISEMENT_FAILURE
    });
  }
}

function* pagesContentSaga() {
  yield takeLatest([pagesContentsActionsTypes.GET_CONTACT_US], getContactUs);
  yield takeLatest([pagesContentsActionsTypes.SET_CONTACT_US], setContactUs);
  yield takeLatest([pagesContentsActionsTypes.GET_ABOUT_US], getAboutUs);
  yield takeLatest([pagesContentsActionsTypes.SET_ABOUT_US], setAboutUs);
  yield takeLatest([pagesContentsActionsTypes.GET_PRIVACY], getPrivacy);
  yield takeLatest([pagesContentsActionsTypes.SET_PRIVACY], setPrivacy);
  yield takeLatest(
    [pagesContentsActionsTypes.GET_ADVERTISEMENT],
    getAdvertisement
  );
  yield takeLatest(
    [pagesContentsActionsTypes.SET_ADVERTISEMENT],
    setAdvertisement
  );
  yield takeLatest(
    [pagesContentsActionsTypes.DELETE_ADVERTISEMENT],
    deleteAdvertisement
  );
}

export default pagesContentSaga;

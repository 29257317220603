export const productsActionsTypes = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",

  GET_MODIFIERS: "GET_MODIFIERS",
  GET_MODIFIERS_SUCCESS: "GET_MODIFIERS_SUCCESS",
  GET_MODIFIERS_FAILURE: "GET_MODIFIERS_FAILURE",

  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

  SET_DOUBLE_MODIFIERS: "SET_DOUBLE_MODIFIERS",
  SET_DOUBLE_MODIFIERS_SUCCESS: "SET_DOUBLE_MODIFIERS_SUCCESS",
  SET_DOUBLE_MODIFIERS_FAILURE: "SET_DOUBLE_MODIFIERS_FAILURE",

  ADD_PRODUCT: "ADD_PRODUCT",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",

  UPDATE_PRODUCT_IMAGE: "UPDATE_PRODUCT_IMAGE",
  UPDATE_PRODUCT_IMAGE_SUCCESS: "UPDATE_PRODUCT_IMAGE_SUCCESS",

  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",

  UPDATE_MODIFIER_PRODUCT: "UPDATE_MODIFIER_PRODUCT",
  UPDATE_MODIFIER_PRODUCT_SUCCESS: "UPDATE_MODIFIER_PRODUCT_SUCCESS",

  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_SELECTED_CATEGORY_SUCCESS: "SET_SELECTED_CATEGORY_SUCCESS",
  SET_SELECTED_CATEGORY_FAILURE: "SET_SELECTED_CATEGORY_FAILURE",

  UPDATE_MODIFIER_IMAGE: "UPDATE_MODIFIER_IMAGE",
  UPDATE_MODIFIER_IMAGE_SUCCESS: "UPDATE_MODIFIER_IMAGE_SUCCESS",
  UPDATE_MODIFIER_IMAGE_FAILURE: "UPDATE_MODIFIER_IMAGE_FAILURE",

  UPDATE_PRODUCTS_ORDER: "UPDATE_PRODUCTS_ORDER",
  UPDATE_PRODUCTS_ORDER_SUCCESS: "UPDATE_PRODUCTS_ORDER_SUCCESS",
  UPDATE_PRODUCTS_ORDER_FAILURE: "UPDATE_PRODUCTS_ORDER_FAILURE",

  TOGGLE_SELECTION: "TOGGLE_SELECTION",
  TOGGLE_SELECTION_SUCCESS: "TOGGLE_SELECTION_SUCCESS",
  TOGGLE_SELECTION_FAILURE: "TOGGLE_SELECTION_FAILURE"
};

export const toggleSelections = data => {
  return {
    type: productsActionsTypes.TOGGLE_SELECTION,
    payload: data
  };
};

export const updateProductsOrder = data => {
  return {
    type: productsActionsTypes.UPDATE_PRODUCTS_ORDER,
    payload: data
  };
};

export const getProducts = data => {
  return {
    type: productsActionsTypes.GET_PRODUCTS,
    payload: data
  };
};

export const getProduct = data => {
  return {
    type: productsActionsTypes.GET_PRODUCT,
    payload: data
  };
};

export const getModifiers = data => {
  return {
    type: productsActionsTypes.GET_MODIFIERS,
    payload: data
  };
};

export const setDoubledModifiers = data => {
  return {
    type: productsActionsTypes.SET_DOUBLE_MODIFIERS,
    payload: data
  };
};

export const addProduct = data => {
  return {
    type: productsActionsTypes.ADD_PRODUCT,
    payload: data
  };
};

export const updateProductImage = (formData, productId) => {
  return {
    type: productsActionsTypes.UPDATE_PRODUCT_IMAGE,
    payload: { productId, formData }
  };
};

export const updateProduct = data => {
  return {
    type: productsActionsTypes.UPDATE_PRODUCT,
    payload: data
  };
};

export const updateModifierProduct = data => {
  return {
    type: productsActionsTypes.UPDATE_MODIFIER_PRODUCT,
    payload: data
  };
};

export const updateModifierImage = (id, formData) => {
  return {
    type: productsActionsTypes.UPDATE_MODIFIER_IMAGE,
    payload: { formData, id }
  };
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import classNames from "classnames";
import { getCoupons } from "../../store/actions/discounts.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import TableComponent from "../../components/tableComponent";

const Customers = () => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const tableHead = [
    {
      name: "Name | En",
      key: "nameEn"
    },
    {
      name: "Name | Ar",
      key: "nameAr"
    },
    { name: "Value", key: "amount" },
    { name: "Valid From", field: "validFrom", key: "date" },
    { name: "Valid To", field: "validTo", key: "date" },
    { name: "Code", key: "code" },
    {
      name: "Discount Type",
      key: "isPercentage"
    },
    { name: "Active", key: "isActive" },
    { name: "Edit", key: "editWithRedirect", path: "coupons", param: "id" }
  ];

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [type, setType] = useState("");
  const [rowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const coupons = useSelector(state => state.discounts.coupons);
  const countCoupons = useSelector(state => state.discounts.couponCount);
  const isLoading = useSelector(state => state.discounts.isLoadingCoupons);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getCoupons({
        pagination: { page: currentPage, limit: rowsPerPage },
        data: { isActive: type }
      })
    );
  }, [currentPage, rowsPerPage, type]);

  const handleChange = ({ target: { value } }) => setType(value);

  const filter = () => (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="text-sm-center form-inline">
            <label htmlFor="example-select">Type : &nbsp; &nbsp; </label>
            <div className="form-group mr-2">
              <select
                id="demo-foo-filter-status"
                className="custom-select custom-select-sm"
                name="fileType"
                onChange={handleChange}
                value={type}
              >
                <option value="">--</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Coupons</h4>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                {filter()}
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2"></div>

                      <TableComponent
                        tableHead={tableHead}
                        tableBody={coupons}
                      />

                      <Pagination
                        totalCount={countCoupons}
                        rows={rowsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setPageQuery}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login, getUser } from "../../store/actions/authorization.actions";
import { Redirect } from "react-router";
import { StorageService } from "../../services/storage.service";
import logo from "../../assets/images/logo-sm.png";
import "./index.scss";

const Login = ({ history }) => {
  const dispatch = useDispatch();

  let token = useSelector(state => state.authorization.token);
  const { authError, isLoadingAuth } = useSelector(
    state => state.authorization
  );
  let userData = useSelector(state => state.authorization.userData);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .required("Email field is empty"),
      password: Yup.string().required("Password field is empty")
    }),
    onSubmit: values => {
      dispatch(login(values));
    }
  });

  useEffect(() => {
    if (token) {
      StorageService.setToken(token);
      dispatch(getUser());
      // history.push("reports");
    }
  });

  if (userData) {
    const {
      branchesList,
      menu,
      orders,
      feedback,
      contentPages,
      customers,
      foodTruck,
      discountsAndCoupons
    } = userData;

    if (branchesList) {
      history.push("branches");
    } else if (menu) {
      history.push("categories");
    } else if (orders) {
      history.push("orders");
    } else if (foodTruck) {
      history.push("food-truck");
    } else if (contentPages) {
      history.push("contentPages");
    } else if (customers) {
      history.push("customers");
    } else if (discountsAndCoupons) {
      history.push("discounts");
    } else if (feedback) {
      history.push("feedbackSurveys");
    } else {
      history.push("/");
    }
  }
  // if (StorageService.getToken()) {
  //   return <Redirect to="/reports" />;
  // }

  return (
    <div
      className="authentication-bg authentication-bg-pattern enlarged"
      cz-shortcut-listen="true"
    >
      <div className="account-pages mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card bg-pattern">
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <span>
                      <span>
                        <img src={logo} alt="" height="120" />
                      </span>
                    </span>
                    <p className="text-muted mb-4 mt-3">
                      Enter your email address and password to access admin
                      panel.
                    </p>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="email">Username</label>
                      <input
                        className={
                          "form-control " +
                          (formik.errors.username && "parsley-error")
                        }
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        onClick={() => {
                          formik.setFieldTouched("email", true);
                        }}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <ul className="parsley-errors-list filled">
                          <li className="parsley-required">
                            {formik.errors.email}
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="password">Password</label>
                      <input
                        className={
                          "form-control " +
                          (formik.errors.password && "parsley-error")
                        }
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        onChange={formik.handleChange}
                        onClick={() => {
                          formik.setFieldTouched("password", true);
                        }}
                        autoComplete="off"
                      />
                      {formik.errors.password && formik.touched.password && (
                        <ul className="parsley-errors-list filled">
                          <li className="parsley-required">
                            {formik.errors.password}
                          </li>
                        </ul>
                      )}
                    </div>
                    {authError && authError.message && (
                      <div className="alert alert-danger" role="alert">
                        {authError.message}
                      </div>
                    )}
                    {isLoadingAuth ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-md"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <div className="form-group mb-0 text-center">
                        <button
                          className="btn btn-login btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer footer-alt">
        © 2024 Burgerizzr All Rights Reserved.
      </footer>
    </div>
  );
};

export default Login;

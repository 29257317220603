import { call, put, takeLatest } from "redux-saga/effects";
import { categoriesApi } from "../../services/categories.service";
import { categoriesActionsTypes } from "../actions/categories.actions";
import * as CategoriesActions from "../actions/categories.actions";

function* getCategories(action) {
  try {
    const result = yield call(
      categoriesApi.getCategories,
      action.payload.pagination,
      action.payload.search
    );

    yield put({
      type: categoriesActionsTypes.GET_CATEGORIES_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: categoriesActionsTypes.GET_CATEGORIES_FAILURE
    });
  }
}

function* updateCategoryImage(action) {
  try {
    const result = yield call(
      categoriesApi.updateCategoryImage,
      action.payload
    );

    yield put({
      type: categoriesActionsTypes.UPDATE_CATEGORY_IMAGE_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: categoriesActionsTypes.UPDATE_CATEGORY_IMAGE_FAILURE
    });
  }
}

function* updateCategory(action) {
  try {
    const result = yield call(
      categoriesApi.updateCategory,
      action.payload.id,
      action.payload.name,
      action.payload.selected
    );
    yield put({
      type: categoriesActionsTypes.UPDATE_CATEGORY_SUCCESS,
      payload: action.payload || result.data
    });
  } catch (error) {
    yield put({
      type: categoriesActionsTypes.UPDATE_CATEGORY_FAILURE
    });
  }
}

function* synchronizeMenu() {
  try {
    yield call(categoriesApi.synchronizeMenu);

    yield put({
      type: categoriesActionsTypes.SYNCHRONIZE_MENU_SUCCESS
    });
    yield put(CategoriesActions.getCategories());
  } catch (error) {
    yield put({
      type: categoriesActionsTypes.SYNCHRONIZE_MENU_FAILURE
    });
  }
}

function* updateCategoriesOrder(action) {
  try {
    yield call(categoriesApi.updateCategoriesOrder, action.payload);
    yield put({
      type: categoriesActionsTypes.UPDATE_CATEGORIES_ORDER_SUCCESS
    });
  } catch (error) {
    yield put({
      type: categoriesActionsTypes.UPDATE_CATEGORIES_ORDER_FAILURE
    });
  }
}

function* categoriesSaga() {
  yield takeLatest([categoriesActionsTypes.GET_CATEGORIES], getCategories);
  yield takeLatest(
    [categoriesActionsTypes.UPDATE_CATEGORY_IMAGE],
    updateCategoryImage
  );
  yield takeLatest([categoriesActionsTypes.UPDATE_CATEGORY], updateCategory);
  yield takeLatest([categoriesActionsTypes.SYNCHRONIZE_MENU], synchronizeMenu);
  yield takeLatest(
    [categoriesActionsTypes.UPDATE_CATEGORIES_ORDER],
    updateCategoriesOrder
  );
}

export default categoriesSaga;

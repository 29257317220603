import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalDelete from "../../modal/modalDelete/modalDelete";
import TableComponent from "../../tableComponent";
import ModalAdvertisement from "../../../components/modal/modalAdvertisement/modalAdvertisement";
import { getAdvertisement } from "../../../store/actions/pagesContent.action";

const Advertisement = () => {
  const dispatch = useDispatch();
  const { advertisementData, loadingAd } = useSelector(
    state => state.pagesContent
  );

  const tableHead = [
    {
      name: "Image",
      field: "imagePath",
      key: "imageIcon"
    },
    {
      name: "Product Name",
      key: "ProductName"
    },
    {
      name: "Product Hid",
      key: "productHid"
    },
    {
      name: "Delete",
      key: "delete",
      return: "id",
      onClick: id => {
        toggle();
        setAdvertisementId(id);
      }
    }
  ];

  const [advertisement, setAdvertisement] = useState(false);
  const addNewAdvertisement = () => setAdvertisement(!advertisement);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [advertisementId, setAdvertisementId] = useState("");

  useEffect(() => {
    dispatch(getAdvertisement());
  }, []);

  return (
    <>
      {loadingAd ? (
        <div className="d-flex justify-content-center  w-100">
          <div className="spinner-border avatar-lg" role="status"></div>
        </div>
      ) : (
        <div className="col-md-12">
          <div className="text-md-right">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light mb-2 mr-2"
              onClick={addNewAdvertisement}
            >
              <i className="mdi mdi-plus mr-1"></i> Add
            </button>
          </div>
          <TableComponent tableHead={tableHead} tableBody={advertisementData} />
          <ModalAdvertisement
            modal={advertisement}
            toggle={addNewAdvertisement}
          />
          <ModalDelete
            modal={modal}
            toggle={toggle}
            id={advertisementId}
            name="Advertisement"
          />
        </div>
      )}
    </>
  );
};

export default Advertisement;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePrevious from "../../helpers/usePrevious";
import { getCategories } from "../../store/actions/categories.actions";
import { getProducts } from "../../store/actions/products.actions";
import Pagination from "../pagination/pagination";

const ProductTable = ({ formik }) => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.categories);
  const products = useSelector(state => state.products.products);
  const countBranch = useSelector(state => state.products.countProduct);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [filteredCategory, setFilteredCategory] = useState("");
  const previousFilteredValue = usePrevious(filteredCategory);
  const previousCurrentPageValue = usePrevious(currentPage);

  useEffect(() => {
    dispatch(
      getCategories({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      previousFilteredValue !== undefined &&
      filteredCategory !== undefined &&
      previousFilteredValue !== filteredCategory
    ) {
      if (previousCurrentPageValue !== 1) {
        return setCurrentPage(1);
      }
    }
    dispatch(
      getProducts({
        pagination: {
          page: currentPage,
          limit: rowsPerPage
        },
        filteredCategory
      })
    );
  }, [dispatch, filteredCategory, currentPage, rowsPerPage]);

  const selectedArr =
    formik.values.products && formik.values.products.map(item => item.id);

  useEffect(() => {
    selectedArr &&
      selectedArr.map(arr => formik.values.selectedProducts.add(arr));
  }, [products]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="selectCategory" className="control-label">
            Select Category
          </label>
          <select
            className="form-control"
            id="selectCategory"
            onChange={e => {
              setFilteredCategory(e.target.value);
            }}
          >
            <option key="0" value="">
              All
            </option>
            {categories.map(category => (
              <option value={category.id} key={category.id}>
                {category.name.en}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <div className="form-group mb-3">
            <label htmlFor="example-select">Product:</label>
            <div className="table-responsive">
              <table className="table table-bordered toggle-circle mb-0">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Category</th>
                    <th>Product Name</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map(product => (
                    <tr key={product.id}>
                      <td>
                        {React.createElement("input", {
                          type: "checkbox",
                          defaultChecked:
                            selectedArr && selectedArr.includes(product.id),
                          onClick: () => {
                            if (
                              formik.values.selectedProducts.has(product.id)
                            ) {
                              formik.values.selectedProducts.delete(product.id);
                            } else {
                              formik.values.selectedProducts.add(product.id);
                            }
                          }
                        })}
                      </td>
                      <td>
                        {product.categories && product.categories[0].name.en}
                      </td>
                      <td>{product.name.en}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalCount={countBranch}
              rows={rowsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTable;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePrevious from "../../helpers/usePrevious";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { DebounceInput } from "react-debounce-input";
import {
  getCategories,
  updateCategoriesOrder
} from "../../store/actions/categories.actions";
import Pagination from "../../components/pagination/pagination";
import ModalCategory from "../../components/modal/modalCategory/modalCategory";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import avatar from "../../assets/images/small/defaultCategory.png";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import classNames from "classnames";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CategoriesArrangement = () => {
  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);

  const [items, setItems] = useState([]);

  const [search, setSearch] = useState(searchQuery || "");
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(100);
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setSearch(searchQuery || "");
  });

  const categories = useSelector(state => state.categories.categories);
  const isLoadingCategories = useSelector(state => state.categories.isLoading);
  const countCategory = useSelector(state => state.categories.countCategory);

  const [category, setCategory] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();

  const previousSearchValue = usePrevious(search);
  const previousCurrentPageValue = usePrevious(currentPage);

  useEffect(() => {
    setItems(categories.sort((a, b) => a.order - b.order));
  }, [categories]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      previousSearchValue !== undefined &&
      search !== undefined &&
      previousSearchValue !== search
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }

    dispatch(
      getCategories({
        pagination: {
          page: "",
          limit: ""
        },
        search
      })
    );
  }, [dispatch, currentPage, rowsPerPage, search]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const item = reorder(items, result.source.index, result.destination.index);
    setItems(item);
    dispatch(
      updateCategoriesOrder(
        item.map((i, index) => ({ id: i.id, order: index }))
      )
    );
  };

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Categories Arrangement</h4>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="form-inline">
                        <div className="form-group mb-2">
                          <label htmlFor="inputPassword2" className="sr-only">
                            Search
                          </label>
                          <DebounceInput
                            type="search"
                            minLength={1}
                            className="form-control"
                            debounceTimeout={800}
                            value={searchInputValue}
                            onChange={e => {
                              setSearchInputValue(e.target.value);
                              setSearchQuery(e.target.value);
                            }}
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoadingCategories ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border avatar-lg"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <table className="table table-centered table-borderless table-hover mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th>Red Icon</th>
                              <th>Grey Icon</th>
                              <th>Name English</th>
                              <th>Name Arabic</th>
                            </tr>
                          </thead>

                          <Droppable droppableId="droppable">
                            {provided => {
                              return (
                                <tbody ref={provided.innerRef}>
                                  {items.map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {provided => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          id={item.id}
                                          {...provided.dragHandleProps}
                                        >
                                          <td className="table-user">
                                            <img
                                              alt="table-user"
                                              className="mr-2 rounded-circle"
                                              src={item.redIconPath || avatar}
                                            />
                                          </td>
                                          <td className="table-user">
                                            <img
                                              alt="table-user"
                                              className="mr-2 rounded-circle"
                                              src={item.greyIconPath || avatar}
                                            />
                                          </td>
                                          <td>{item.name.en}</td>
                                          <td>{item.name.ar}</td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              );
                            }}
                          </Droppable>
                        </table>
                      </DragDropContext>
                    </div>
                  )}
                  <Pagination
                    totalCount={countCategory}
                    rows={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setPageQuery}
                  />
                  {category && (
                    <ModalCategory
                      category={category}
                      modal={modal}
                      onClose={() => {
                        toggle();
                        setCategory(undefined);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategoriesArrangement;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSupervisors } from "../../../store/actions/authorization.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

const PrivilegesData = [
  {
    name: "List All Branches",
    key: "branchesList"
  },
  {
    name: "Edit All Branches",
    key: "branchesEdit"
  },
  {
    name: "Edit Bulk Branches",
    key: "bulkBranchesEdit"
  },
  {
    name: "Sync All Branches",
    key: "branchesSync"
  },
  {
    name: "View Order",
    key: "order"
  },
  {
    name: "View All Orders",
    key: "orders"
  },
  {
    name: "Manage Order Refund",
    key: "manageOrderRefund"
  },
  {
    name: "Manage Menu and modifier",
    key: "menu"
  },
  {
    name: "Manage Sync Menus",
    key: "menuSync"
  },
  {
    name: "Manage Discount and Coupons",
    key: "discountsAndCoupons"
  },
  {
    name: "View Customers",
    key: "customers"
  },
  {
    name: "Manage Customers Wallets",
    key: "manageWallets"
  },
  {
    name: "Manage Customer Points",
    key: "managePoints"
  },
  {
    name: "Manage Feedback",
    key: "feedback"
  },
  {
    name: "Mange page’s contents",
    key: "contentPages"
  },
  {
    name: "Manage Food Truck",
    key: "foodTruck"
  }
];

const Privileges = {
  branchesList: "2", // 2== the id for all branches
  singleBranch: "",
  branchesEdit: "2", // 2== the id for all branches
  branchesSync: false,
  singleBranchEdit: "",
  // singleBranchSync: "",
  menu: false,
  menuSync: false,
  order: false,
  orders: false,
  discountsAndCoupons: false,
  customers: false,
  feedback: false,
  contentPages: false,
  foodTruck: false,
  manageOrderRefund: false,
  manageWallets: false,
  bulkBranchesEdit: false,
  managePoints: false
};

export default function ModalAddSupervisors({ extraData, modal, toggle }) {
  const dispatch = useDispatch();

  // There's a complication in the branches privilege, so this state property will control whether
  // the user will have the privilege or not, and the formic property will contain the details
  const [hasListBranchesPrivilege, setHasListBranchesPrivilege] = useState(
    false
  );
  const [hasEditBranchesPrivilege, setHasEditBranchesPrivilege] = useState(
    false
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      privileges: Privileges
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    }),
    onSubmit: values => {
      if (
        values.privileges.branchesList === "1" ||
        values.privileges.branchesList == false
      ) {
        // branch has not been selected
        alert("please select branch to be assigned...");
        return;
      }
      if (
        values.privileges.branchesEdit === "1" ||
        values.privileges.branchesEdit == false
      ) {
        // branch has not been selected
        alert("please select branch edit to be assigned...");
        return;
      }

      dispatch(
        setSupervisors({
          ...values,
          privileges: {
            ...values.privileges,
            branchesList: hasListBranchesPrivilege,
            singleBranch:
              values.privileges.branchesList === "2"
                ? ""
                : values.privileges.branchesList,
            branchesEdit: hasEditBranchesPrivilege,
            singleBranchEdit:
              values.privileges.branchesEdit === "2"
                ? ""
                : values.privileges.branchesEdit
          }
        })
      );
      formik.resetForm();
      toggle();
    }
  });

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Supervisor
      </ModalHeader>
      <form
        onSubmit={formik.handleSubmit}
        className="modal-body pb-1 pr-4 pl-4  "
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={formik.handleChange}
                placeholder="Name"
                onClick={() => {
                  formik.setFieldTouched("name", true);
                }}
              />
              {formik.errors.name && formik.touched.name ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.name}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="email" className="control-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                onChange={formik.handleChange}
                placeholder="Email"
                onClick={() => {
                  formik.setFieldTouched("email", true);
                }}
              />
              {formik.errors.email && formik.touched.email ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.email}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="control-label">Privileges</label>

              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th colSpan={2}>Privilege</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PrivilegesData.map(privilege => (
                      <tr key={privilege.key}>
                        {privilege.key == "branchesList" ? (
                          <>
                            <td style={{ textAlign: "center" }}>
                              <div className="checkbox checkbox-dark mb-3">
                                <input
                                  type="checkbox"
                                  id={"listBranchesPrivilege"}
                                  name={"listBranchesPrivilege"}
                                  onChange={e => {
                                    console.log(
                                      "e.target.checked",
                                      e.target.checked
                                    );
                                    setHasListBranchesPrivilege(
                                      e.target.checked
                                    );
                                  }}
                                  checked={hasListBranchesPrivilege}
                                  value={hasListBranchesPrivilege}
                                />
                                <label
                                  htmlFor={"listBranchesPrivilege"}
                                ></label>
                              </div>
                            </td>
                            <td>List Branches</td>
                            <td>
                              <select
                                disabled={!hasListBranchesPrivilege}
                                id={`privileges[${privilege.key}]`}
                                name={`privileges[${privilege.key}]`}
                                onChange={formik.handleChange}
                              >
                                {extraData.map(item => (
                                  <option
                                    value={item.id}
                                    defaultValue="All"
                                    key={item.id}
                                  >
                                    {item.name.en}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </>
                        ) : privilege.key == "branchesEdit" ? (
                          <>
                            <td style={{ textAlign: "center" }}>
                              <div className="checkbox checkbox-dark mb-3">
                                <input
                                  type="checkbox"
                                  id={"editBranchesPrivilege"}
                                  name={"editBranchesPrivilege"}
                                  onChange={e =>
                                    setHasEditBranchesPrivilege(
                                      e.target.checked
                                    )
                                  }
                                  checked={hasEditBranchesPrivilege}
                                  value={hasEditBranchesPrivilege}
                                />
                                <label
                                  htmlFor={"editBranchesPrivilege"}
                                ></label>
                              </div>
                            </td>
                            <td>Edit branches</td>
                            <td>
                              <select
                                disabled={!hasEditBranchesPrivilege}
                                id={`privileges[${privilege.key}]`}
                                name={`privileges[${privilege.key}]`}
                                onChange={formik.handleChange}
                              >
                                {extraData.map(item => (
                                  <option
                                    value={item.id}
                                    defaultValue="All"
                                    key={item.id}
                                  >
                                    {item.name.en}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <div className="checkbox checkbox-dark mb-3">
                                <input
                                  type="checkbox"
                                  id={`privileges[${privilege.key}]`}
                                  name={`privileges[${privilege.key}]`}
                                  onChange={formik.handleChange}
                                  checked={
                                    formik.values.privileges[privilege.key]
                                  }
                                  value={privilege.key}
                                />
                                <label
                                  htmlFor={`privileges[${privilege.key}]`}
                                ></label>
                              </div>
                            </td>
                            <td colSpan={2}>{privilege.name}</td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getProducts } from "../../../store/actions/products.actions";
import {
  setRelatedUpsell,
  resetError
} from "../../../store/actions/upsell.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

export default function ModalRelatedProduct({ modal, toggle }) {
  const dispatch = useDispatch();
  const products = useSelector(state => state.products.products);

  const formik = useFormik({
    initialValues: {
      productUpsellId: "",
      productRelatedUpsellId: ""
    },
    validationSchema: Yup.object().shape({
      productUpsellId: Yup.string().required("Required"),
      productRelatedUpsellId: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(setRelatedUpsell(values));
      formik.resetForm();
      toggle();
      setTimeout(() => {
        dispatch(resetError());
      }, 500);
    }
  });

  useEffect(() => {
    dispatch(getProducts({ pagination: { limit: "", page: "" } }));
  }, [dispatch]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Related Product
      </ModalHeader>
      <form onSubmit={formik.handleSubmit} className="modal-body p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="productUpsellId" className="control-label">
                Select Product
              </label>
              <select
                className="form-control"
                id="productUpsellId"
                name="productUpsellId"
                onChange={formik.handleChange}
                value={formik.values.productUpsellId}
              >
                <option>Choose Product</option>

                {products.map(
                  productItem =>
                    productItem.selected && (
                      <option key={productItem.id} value={productItem.id}>
                        {productItem.name.en}
                      </option>
                    )
                )}
              </select>
              {formik.errors.productUpsellId &&
              formik.touched.productUpsellId ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.productUpsellId}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="productRelatedUpsellId" className="control-label">
                Select RelatedProduct
              </label>
              <select
                className="form-control"
                id="productRelatedUpsellId"
                name="productRelatedUpsellId"
                onChange={formik.handleChange}
                value={formik.values.productRelatedUpsellId}
              >
                <option>Choose Related Product</option>
                {products.map(
                  productRelatedUpsellId =>
                    productRelatedUpsellId.selected && (
                      <option
                        key={productRelatedUpsellId.id}
                        value={productRelatedUpsellId.id}
                      >
                        {productRelatedUpsellId.name.en}
                      </option>
                    )
                )}
              </select>
              {formik.errors.productRelatedUpsellId &&
              formik.touched.productRelatedUpsellId ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.productRelatedUpsellId}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

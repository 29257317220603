import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getFeedbackQuestions,
  updateFeedbackQuestions
} from "../../store/actions/feedback.actions";
import { getCountries, getCities } from "../../store/actions/branches.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ToggleButton from "../../components/toggleButton/toggleButton";
import LoadingButton from "../../components/loadingButton/loadingButton";
import AnswerList from "./answerList";
import "react-toastify/dist/ReactToastify.css";

const UpdateSurvey = ({ history, match }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const feedbackQuestions = useSelector(
    state => state.feedback.feedbackQuestions
  );
  const isLoading = useSelector(
    state => state.feedback.isLoadingFeedbackQuestions
  );
  const isLoadingBtn = useSelector(
    state => state.feedback.isLoadingFeedbackQuestionsBtn
  );

  const { cities, countriesList } = useSelector(state => state.branches);

  const [countryId, setCountryId] = useState(
    "c58462c6-7db2-4360-a9fc-0ddd8ecd2ffe"
  );

  const addNewRow = () => {
    formik.setFieldValue("questions", [
      ...formik.values.questions,
      {
        questionTitle: "",
        questionType: 1,
        choices: ["", "", "", "", ""]
      }
    ]);
  };

  const clickOnDelete = record => {
    formik.setFieldValue(
      "questions",
      formik.values.questions.filter(r => r !== record)
    );
  };

  const closeSurvey = () => {
    history.push(`/feedbackQuestions/${match.params.id}`);
  };

  const formik = useFormik({
    initialValues: {
      id: feedbackQuestions[0] && feedbackQuestions[0].id,
      feedbackType: feedbackQuestions[0] && feedbackQuestions[0].feedbackType,
      isActive: feedbackQuestions[0] && feedbackQuestions[0].isActive,
      questions: feedbackQuestions[0] && feedbackQuestions[0].questions,
      specialTypeFilters:
        feedbackQuestions[0] && feedbackQuestions[0].specialTypeFilters
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      feedbackType: Yup.string().required("Required"),
      questions: Yup.array().of(
        Yup.object().shape({
          questionTitle: Yup.string().required("Required")
        })
      )
    }),
    onSubmit: values => {
      dispatch(
        updateFeedbackQuestions({ ...values, ...values.specialTypeFilters })
      );
      setTimeout(() => {
        !isLoadingBtn && closeSurvey();
      }, 800);
    }
  });

  const handleToggle = () => {
    formik.values.isActive
      ? formik.setFieldValue("isActive", false)
      : formik.setFieldValue("isActive", true);
  };

  useEffect(() => {
    dispatch(
      getFeedbackQuestions({
        pagination: {
          page: "",
          limit: ""
        },
        id: match.params.id
      })
    );
  }, [match.params.id]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCities(countryId));
  }, [dispatch, countryId]);

  const specificFields = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.gender">
            Gender: &nbsp; &nbsp;
          </label>

          <select
            required
            id="specialTypeFilters.gender"
            name="specialTypeFilters.gender"
            className="form-control"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.gender) ||
              ""
            }
          >
            <option value={""}>--</option>
            <option value={"male"}>Male</option>
            <option value={"female"}>Female</option>
          </select>
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.minAge">
            Minimum Age: &nbsp; &nbsp;
          </label>
          <input
            required
            type="number"
            min="1"
            className="form-control"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.minAge) ||
              ""
            }
            id="specialTypeFilters.minAge"
            name="specialTypeFilters.minAge"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.maxAge">
            Maximum Age: &nbsp; &nbsp;
          </label>
          <input
            required
            type="number"
            min="1"
            className="form-control"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.maxAge) ||
              ""
            }
            id="specialTypeFilters.maxAge"
            name="specialTypeFilters.maxAge"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.minNumberOfOrders">
            Minimum Orders Number: &nbsp; &nbsp;
          </label>
          <input
            required
            type="number"
            min="1"
            className="form-control"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.minNumberOfOrders) ||
              ""
            }
            id="specialTypeFilters.minNumberOfOrders"
            name="specialTypeFilters.minNumberOfOrders"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.maxNumberOfOrders">
            Maximum Orders Number: &nbsp; &nbsp;
          </label>
          <input
            required
            type="number"
            min="1"
            className="form-control"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.maxNumberOfOrders) ||
              ""
            }
            id="specialTypeFilters.maxNumberOfOrders"
            name="specialTypeFilters.maxNumberOfOrders"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="country">Country: &nbsp; &nbsp; </label>
          <select
            required
            id="country"
            name="country"
            className="form-control"
            onChange={e => setCountryId(e.target.value)}
            value={countryId || ""}
          >
            <option value={""}>--</option>
            {countriesList &&
              countriesList.map(country => (
                <option key={country.id} value={country.id}>
                  {country.name.en}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.city">City: &nbsp; &nbsp; </label>
          <select
            required
            id="specialTypeFilters.city"
            name="specialTypeFilters.city"
            className="form-control"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.city) ||
              ""
            }
          >
            <option value={""}>--</option>
            {cities &&
              cities.map(city => (
                <option key={city.id} value={city.id}>
                  {city.name.en}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="specialTypeFilters.phoneNumber">
            Phone Number: &nbsp; &nbsp;
          </label>
          <input
            required
            type="text"
            className="form-control"
            placeholder="+966555555555"
            onChange={formik.handleChange}
            value={
              (formik.values.specialTypeFilters &&
                formik.values.specialTypeFilters.phoneNumber) ||
              ""
            }
            id="specialTypeFilters.phoneNumber"
            name="specialTypeFilters.phoneNumber"
          />
        </div>
      </div>
    </div>
  );
  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Update Survey</h4>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2"></div>
                      <div className="col-md-12">
                        <div className="modal-content">
                          <div className="modal-body p-4">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="feedbackType">
                                      Feedback Type: &nbsp; &nbsp;{" "}
                                    </label>

                                    <select
                                      id="feedbackType"
                                      name="feedbackType"
                                      className="form-control"
                                      onChange={formik.handleChange}
                                      value={formik.values.feedbackType || ""}
                                      disabled
                                    >
                                      <option value={1}>General</option>
                                      <option value={2}>Order</option>
                                      <option value={3}>Specific</option>
                                    </select>
                                    {formik.errors.feedbackType &&
                                    formik.touched.feedbackType ? (
                                      <ul className="parsley-errors-list filled">
                                        <li className="parsley-required">
                                          {formik.errors.feedbackType}
                                        </li>
                                      </ul>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mb-1">
                                <div className="toggleButton">
                                  <label
                                    htmlFor="isActive"
                                    className="control-label"
                                  >
                                    Activated:
                                  </label>
                                  <div onClick={handleToggle}>
                                    <ToggleButton
                                      value={formik.values.isActive || false}
                                    />
                                  </div>
                                </div>
                              </div>

                              {+formik.values.feedbackType === 3 &&
                                specificFields()}

                              <table className="table">
                                <thead>
                                  <tr className="d-flex">
                                    <th className="col-md-5 pl-0 text-left">
                                      Question
                                    </th>
                                    <th className="col-md-5 pl-0 text-left">
                                      Question Type
                                    </th>
                                    <th className="col-md-2 pl-0"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.questions && (
                                    <AnswerList
                                      add={addNewRow}
                                      delete={clickOnDelete}
                                      taskList={formik.values.questions}
                                      formik={formik}
                                    />
                                  )}
                                </tbody>
                              </table>
                              <div className="modal-footer mt-3">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  onClick={closeSurvey}
                                >
                                  Close
                                </button>
                                <LoadingButton
                                  isLoadingBtn={isLoadingBtn}
                                  btnContent="Save"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSurvey;

import React from "react";
import { Route } from "react-router-dom";
import auth from "./auth";
import ForbiddenPage from "../../pages/forbiddenPage";

const ProtectedRoute = ({ component: Component, ...protectedRouteProps }) => {
  return (
    <Route
      {...protectedRouteProps}
      render={props =>
        auth.checkAuth(protectedRouteProps) ? (
          <Component {...props} />
        ) : (
          <ForbiddenPage />
        )
      }
    />
  );
};

export default ProtectedRoute;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import { useFormik } from "formik";
import * as Yup from "yup";
import { objectToFormData } from "object-to-formdata";
import { getCategories } from "../../store/actions/categories.actions";
import { getProducts } from "../../store/actions/products.actions";
import {
  setOffer,
  getOffers,
  resetError
} from "../../store/actions/points.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import ModalOffer from "../../components/modal/modalOffer/modalOffer";
import ModalDelete from "../../components/modal/modalDelete/modalDelete";
import TableComponent from "../../components/tableComponent";
import { ToastContainer, toast } from "react-toastify";

const Offers = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [offerId, setOfferId] = useState("");
  const [offer, setOffers] = useState(false);
  const addNewOffer = () => setOffers(!offer);

  const offers = useSelector(state => state.points.offers);
  const countOffers = useSelector(state => state.points.countOffers);
  const isLoading = useSelector(state => state.points.isLoadingOffers);
  const offerError = useSelector(state => state.points.offerError);

  const categories = useSelector(state => state.categories.categories);
  const products = useSelector(state => state.products.products);

  const tableHead = [
    {
      name: "Name",
      key: "offerName"
    },
    {
      name: "Name|AR",
      key: "nameAr"
    },
    {
      name: "Product Name",
      key: "offerProduct"
    },
    {
      name: "Price",
      key: "offerPrice"
    },
    // {
    //   name: "Expiration Date",
    //   field: "offerDate",
    //   key: "date"
    // },
    {
      name: "Points",
      key: "relatedPoints"
    },
    {
      name: "Available For Delivery",
      key: "availableForDelivery"
    },
    {
      name: "Image",
      field: "imagePath",
      key: "imageIcon"
    },
    {
      name: "Date From",
      key: "date",
      field: "dateFrom"
    },
    {
      name: "Date To",
      key: "date",
      field: "dateTo"
    },
    {
      name: "Time From",
      key: "timeFrom"
    },
    {
      name: "Time To",
      key: "timeTo"
    },
    {
      name: "Delete",
      key: "delete",
      return: "productId",
      onClick: id => {
        toggle();
        setOfferId(id);
      }
    }
  ];

  const formik = useFormik({
    initialValues: {
      category: "",
      product: "",
      nameAr: "",
      offerName: "",
      // expirationDate: "",
      relatedPoints: "",
      availableForDelivery: false,
      selectedFile: "",
      dateFrom: "",
      dateTo: "",
      timeFrom: "",
      timeTo: ""
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Required"),
      product: Yup.string().required("Required"),
      offerName: Yup.string().required("Required"),
      nameAr: Yup.string().required("Required"),
      // expirationDate: Yup.string().required("Required"),
      relatedPoints: Yup.number().required("Required"),
      selectedFile: Yup.mixed().required("Required"),
      dateFrom: Yup.string().required("Required"),
      dateTo: Yup.string().required("Required"),
      timeFrom: Yup.string().required("Required"),
      timeTo: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(
        setOffer(
          objectToFormData({
            productId: values.product,
            name: values.offerName,
            nameAr: values.nameAr,
            date: values.expirationDate,
            relatedPoints: values.relatedPoints,
            availableForDelivery: values.availableForDelivery,
            image: values.selectedFile,
            dateFrom: new Date(values.dateFrom).toISOString(),
            dateTo: new Date(values.dateTo).toISOString(),
            timeFrom: `${new Date(values.timeFrom).getHours()}:${
              new Date(values.timeFrom).getMinutes() === 0
                ? new Date(values.timeFrom).getMinutes() + "0"
                : new Date(values.timeFrom).getMinutes()
            }`,
            timeTo: `${new Date(values.timeTo).getHours()}:${
              new Date(values.timeTo).getMinutes() === 0
                ? new Date(values.timeTo).getMinutes() + "0"
                : new Date(values.timeTo).getMinutes()
            }`
          })
        )
      );
      addNewOffer();
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetError());
      }, 500);
    }
  });

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getOffers({
        page: currentPage || 1,
        limit: rowsPerPage || 10
      })
    );
  }, [dispatch, currentPage, rowsPerPage]);

  useEffect(() => {
    dispatch(
      getCategories({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: {
          page: "",
          limit: ""
        },
        filteredCategory: formik.values.category
      })
    );
  }, [dispatch, formik.values.category]);

  useEffect(() => {
    if (offerError) {
      toast.error(offerError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }, [offerError]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Offers</h4>
              </div>
            </div>
            <div className="col-12">
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border avatar-lg" role="status"></div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="text-md-right">
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                            onClick={addNewOffer}
                          >
                            <i className="mdi mdi-plus mr-1"></i> New Offer
                          </button>
                        </div>

                        <TableComponent
                          tableHead={tableHead}
                          tableBody={offers}
                        />
                      </div>
                    </div>
                    <Pagination
                      totalCount={countOffers}
                      rows={rowsPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setPageQuery}
                    />
                    <ModalOffer
                      modal={offer}
                      toggle={addNewOffer}
                      categories={categories}
                      products={products}
                      formik={formik}
                    />
                    <ModalDelete
                      modal={modal}
                      toggle={toggle}
                      id={offerId}
                      name="Offer"
                    />
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnVisibilityChange
                      draggable
                      pauseOnHover
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;

import { pagesContentsActionsTypes } from "../actions/pagesContent.action";

const initialState = {
  contactUs: [],
  aboutUs: [],
  aboutUsImage: "",
  privacy: [],
  loadingPrivacy: false,
  isloadingPrivacyBtn: false,
  loadingAboutUs: false,
  isloadingAboutUsBtn: false,
  loadingContactUs: false,
  isloadingContactUsBtn: false,
  advertisementData: [],
  loadingAd: false
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case pagesContentsActionsTypes.GET_CONTACT_US: {
      return {
        ...state,
        contactUs: action.payload,
        loadingContactUs: true
      };
    }
    case pagesContentsActionsTypes.GET_CONTACT_US_SUCCESS: {
      return {
        ...state,
        contactUs: action.payload,
        loadingContactUs: false
      };
    }

    case pagesContentsActionsTypes.SET_CONTACT_US: {
      return {
        ...state,
        isloadingContactUsBtn: true
      };
    }
    case pagesContentsActionsTypes.SET_CONTACT_US_SUCCESS: {
      return {
        ...state,
        isloadingContactUsBtn: false
      };
    }

    case pagesContentsActionsTypes.GET_ABOUT_US: {
      return {
        ...state,
        aboutUs: action.payload,
        loadingAboutUs: true
      };
    }

    case pagesContentsActionsTypes.GET_ABOUT_US_SUCCESS: {
      return {
        ...state,
        aboutUs: action.payload.en || action.payload.ar,
        aboutUsImage: action.payload.aboutUsImage,
        loadingAboutUs: false
      };
    }

    case pagesContentsActionsTypes.SET_ABOUT_US: {
      return {
        ...state,
        isloadingAboutUsBtn: true
      };
    }

    case pagesContentsActionsTypes.SET_ABOUT_US_SUCCESS: {
      return {
        ...state,
        isloadingAboutUsBtn: false
      };
    }

    case pagesContentsActionsTypes.GET_PRIVACY: {
      return {
        ...state,
        loadingPrivacy: true
      };
    }

    case pagesContentsActionsTypes.GET_PRIVACY_SUCCESS: {
      return {
        ...state,
        privacy: action.payload.en || action.payload.ar,
        loadingPrivacy: false
      };
    }

    case pagesContentsActionsTypes.SET_PRIVACY: {
      return {
        ...state,
        isloadingPrivacyBtn: true
      };
    }

    case pagesContentsActionsTypes.SET_PRIVACY_SUCCESS: {
      return {
        ...state,
        isloadingPrivacyBtn: false
      };
    }

    case pagesContentsActionsTypes.GET_ADVERTISEMENT:
      return {
        ...state,
        loadingAd: true
      };

    case pagesContentsActionsTypes.GET_ADVERTISEMENT_SUCCESS: {
      return {
        ...state,
        advertisementData: action.payload.advertisements,
        loadingAd: false
      };
    }

    case pagesContentsActionsTypes.GET_ADVERTISEMENT_FAILURE: {
      return {
        ...state,
        loadingAd: false
      };
    }

    case pagesContentsActionsTypes.SET_ADVERTISEMENT_SUCCESS: {
      const newAdvertisementData = [...state.advertisementData, action.payload];
      return {
        ...state,
        advertisementData: newAdvertisementData
      };
    }

    case pagesContentsActionsTypes.DELETE_ADVERTISEMENT: {
      const deletedArr = state.advertisementData.filter(
        advertisement => advertisement.id !== action.payload
      );
      return {
        ...state,
        advertisementData: deletedArr
      };
    }

    default:
      return state;
  }
}

export const Links = [
  {
    name: "Reports",
    icon: "fe-file",
    fieldName: "feedback",
    url: "/reports"
  },
  {
    name: "Branches",
    url: "/branches",
    icon: "fe-map-pin",
    fieldName: "branchesList"
  },
  {
    name: "Menu",
    icon: "fe-align-justify",
    fieldName: "menu",

    children: [
      {
        name: "Categories",
        url: "/categories",
        icon: "fe-list"
      },
      {
        name: "Products",
        url: "/products",
        icon: "fe-shopping-cart"
      },
      {
        name: "Modifiers",
        url: "/modifiers",
        icon: "fe-check-square"
      },
      {
        name: "Menu Arrangement",
        icon: "fe-grid",
        children: [
          {
            name: "Products",
            url: "/arrangement/products",
            icon: "#"
          },
          {
            name: "Categories",
            url: "/arrangement/categories",
            icon: "#"
          }
        ]
      }
    ]
  },
  {
    name: "Notifications",
    icon: "fe-bell",
    fieldName: "notif",
    children: [
      {
        name: "General Notifications",
        icon: "#",
        fieldName: "general",
        children: [
          {
            name: "General",
            url: "/notifications/general",
            icon: "#"
          },
          {
            name: "Specific",
            url: "/notifications/specific",
            icon: "#"
          }
        ]
      },
      {
        name: "Offer Notifications",
        url: "/notifications/offer",
        icon: "#"
      },
      // {
      //   name: "Schedule Notifications",
      //   url: "/notifications/schedule",
      //   icon: "#"
      // },
      {
        name: "Birthday Notifications",
        url: "/notifications/birthday",
        icon: "#"
      }
    ]
  },
  {
    name: "Order Managment",
    icon: "fe-box",
    fieldName: "orders",

    children: [
      {
        name: "Order Status",
        url: "/orderStatus",
        icon: "fe-check-circle"
      },
      {
        name: "Order Cancel Time",
        url: "/orderCancelTime",
        icon: "fe-minus-circle"
      },
      {
        name: "Delivery Fees",
        url: "/deliveryFees",
        icon: "fe-truck"
      },
      {
        name: "Upsell Message",
        url: "#",
        icon: "fe-layout",
        children: [
          {
            name: "Fixed Product",
            url: "/fixedProductUpsell",
            icon: "#"
          },
          {
            name: "Related Product",
            url: "/relatedProductUpsell",
            icon: "#"
          }
        ]
      }
    ]
  },
  {
    name: "Promotions",
    icon: "fe-tag",
    fieldName: "discountsAndCoupons",

    children: [
      {
        name: "Discounts",
        url: "/discounts",
        icon: "fe-percent"
      },
      {
        name: "Coupons",
        url: "/coupons",
        icon: "fe-gift"
      },
      {
        name: "Points",
        icon: "fe-star",
        children: [
          {
            name: "Points",
            url: "/points"
          },
          {
            name: "Points report",
            url: "/points-report"
          }
        ]
      },
      {
        name: "Awards",
        url: "/rewards",
        icon: "fe-award"
      },
      {
        name: "Offers",
        url: "/offers",
        icon: "fe-tag"
      }
    ]
  },
  {
    name: "Feedback",
    icon: "fe-message-square",
    fieldName: "feedback",
    url: "/feedbackSurveys"
  },
  {
    name: "Customers",
    icon: "fe-users",
    url: "/customers",
    fieldName: "customers"
  },

  {
    name: "Pages Content",
    icon: "fe-file-text",
    url: "/contentPages",
    fieldName: "contentPages"
  },
  {
    name: "Supervisors Privileges",
    icon: "fe-user-check",
    url: "/supervisors",
    fieldName: "supervisors"
  },
  {
    name: "Foodtruck",
    icon: "fe-truck",
    fieldName: "foodTruck",
    children: [
      {
        name: "Foodtruck Menu",
        url: "/food-truck"
      },
      {
        name: "Booked Foodtrucks",
        url: "/food-truck/booked"
      }
    ]
  },
  {
    name: "Advertisement",
    url: "/advertisement",
    icon: "fe-volume-1",
    fieldName: "supervisors"
  },
  {
    name: "Orders",
    url: "/orders",
    icon: "fe-shopping-bag",
    fieldName: "orders"
  },
  {
    name: "Transactions",
    url: "/transactions",
    icon: "fe-credit-card",
    fieldName: "transactions"
  }
];

import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { Links } from "./constants";
import { changeStatusMenuFalse } from "../../store/actions/authorization.actions";
import { StorageService } from "../../services/storage.service";
import SideBarDropDown from "./sideBarDropDown";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./sideBar.scss";

const Sidebar = ({ match }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(StorageService.getUser());
  const links = Links.filter(
    el =>
      user &&
      (user.role === "supervisor"
        ? user.privileges[el.fieldName] !== false &&
          user.privileges[el.fieldName] !== undefined
        : Links)
  );

  return (
    <>
      <div className="left-side-menu">
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            {links.map(link => {
              if (link.children) {
                return (
                  <SideBarDropDown key={link.name} link={link} match={match} />
                );
              } else {
                return (
                  <li key={link.name} id="menu-item">
                    <NavLink
                      to={link.url}
                      onClick={() => dispatch(changeStatusMenuFalse())}
                      className="has-dropdown"
                      activeClassName="activeLink"
                    >
                      <i className={link.icon}></i>
                      <span>{link.name} </span>
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default withRouter(Sidebar);

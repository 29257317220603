import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ series, xaxis, height = 350, endingShape }) => {
  const state = {
    series: series.map((item, index) => ({
      name: index + 1,
      data: item.map(i => i.toFixed(2))
    })),
    options: {
      chart: {
        type: "bar",
        height
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: xaxis
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: val => {
            return "$ " + val;
          }
        }
      },
      legend: {
        showForSingleSeries: false
      }
    }
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
    </div>
  );
};
export default ApexChart;

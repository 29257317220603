import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import { useFormik } from "formik";
import * as Yup from "yup";
import { objectToFormData } from "object-to-formdata";
import { getCategories } from "../../store/actions/categories.actions";
import { getProducts } from "../../store/actions/products.actions";
import {
  getRewards,
  setReward,
  updateReward
} from "../../store/actions/points.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import ModalReward from "../../components/modal/modalReward/modalReward";
import TableComponent from "../../components/tableComponent";
import ModalDelete from "../../components/modal/modalDelete/modalDelete";
import ModalEditReward from "../../components/modal/modalEditReward";

const Rewards = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleEdit = () => setEditReward(!editReward);
  const [rewardId, setRewardId] = useState("");
  const [reward, setRewards] = useState(false);
  const [editReward, setEditReward] = useState(false);
  const [currentReward, setCurrentReward] = useState({});
  const addNewReward = () => setRewards(!reward);

  const {
    rewards,
    error,
    countRewards,
    isLoadingRewards: isLoading
  } = useSelector(state => state.points);
  const { categories } = useSelector(state => state.categories);
  const { products } = useSelector(state => state.products);

  const [tableBody, setTableBody] = useState(rewards || []);

  const tableHead = [
    {
      name: "Number of Points",
      key: "numberOfPoints"
    },
    {
      name: "Award Name",
      key: "awardName"
    },
    {
      name: "Name|AR",
      key: "awardNameAr"
    },
    {
      name: "Award Image",
      field: "imagePath",
      key: "imageIcon"
    },
    {
      name: "Edit",
      key: "editWithCallback",
      onClick: data => {
        toggleEdit();
        setCurrentReward(data);
      }
    },
    {
      name: "Active",
      key: "show",
      onClick: data => {
        setTableBody(
          tableBody.map(i => (i.id === data.id ? { ...i, show: !i.show } : i))
        );
        dispatch(updateReward(data.id, { show: !data.show }));
      }
    },
    {
      name: "Delete",
      key: "delete",
      return: "id",
      onClick: id => {
        toggle();
        setRewardId(id);
      }
    }
  ];

  const formik = useFormik({
    initialValues: {
      category: "",
      product: "",
      numberOfPoints: "",
      awardName: "",
      awardNameAr: "",
      selectedFile: ""
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Required"),
      product: Yup.string().required("Required"),
      numberOfPoints: Yup.number().required("Required"),
      awardName: Yup.string().required("Required"),
      selectedFile: Yup.mixed().required("Required")
    }),
    onSubmit: values => {
      dispatch(
        setReward(
          objectToFormData({
            numberOfPoints: values.numberOfPoints,
            awardName: values.awardName,
            awardNameAr: values.awardNameAr,
            image: values.selectedFile,
            productId: values.product
          })
        )
      );
      addNewReward();
      formik.resetForm();
    }
  });

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getRewards({
        page: currentPage || 1,
        limit: rowsPerPage || 10
      })
    );
  }, [dispatch, currentPage, rowsPerPage]);

  useEffect(() => {
    dispatch(
      getCategories({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: {
          page: "",
          limit: ""
        },
        filteredCategory: formik.values.category
      })
    );
  }, [dispatch, formik.values.category]);

  useEffect(() => {
    setTableBody(rewards);
  }, [rewards]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Awards</h4>
              </div>
            </div>
            <div className="col-12">
              {error && <div className="alert alert-danger mb-2">{error}</div>}

              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border avatar-lg" role="status"></div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="text-md-right">
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                            onClick={addNewReward}
                          >
                            <i className="mdi mdi-plus mr-1"></i> Add New
                          </button>
                        </div>
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={tableBody}
                        />
                      </div>
                    </div>
                    <Pagination
                      totalCount={countRewards}
                      rows={rowsPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setPageQuery}
                    />
                    <ModalReward
                      modal={reward}
                      toggle={addNewReward}
                      categories={categories}
                      products={products}
                      formik={formik}
                    />
                    <ModalEditReward
                      modal={editReward}
                      toggle={toggleEdit}
                      award={currentReward}
                    />
                    <ModalDelete
                      modal={modal}
                      toggle={toggle}
                      id={rewardId}
                      name="Reward"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;

import { all } from "redux-saga/effects";
import categoriesSaga from "./categories.saga";
import authorizationSaga from "./authorization.saga";
import productsSaga from "./products.saga";
import branchesSaga from "./branches.saga";
import customersSaga from "./customers.saga";
import ordersSaga from "./orders.saga";
import pagesContent from "./pagesContent.saga";
import discounts from "./discounts.saga";
import upellSaga from "./upsell.saga";
import feedbackSaga from "./feedback.saga";
import pointsSaga from "./points.saga";
import foodTruckSaga from "./foodTruck.saga";
import notificationsSaga from "./notification.saga";
import transactionsSaga from "./transactions.saga";

export default function* rootSaga() {
  yield all([
    categoriesSaga(),
    productsSaga(),
    branchesSaga(),
    customersSaga(),
    ordersSaga(),
    pagesContent(),
    discounts(),
    upellSaga(),
    feedbackSaga(),
    pointsSaga(),
    foodTruckSaga(),
    authorizationSaga(),
    notificationsSaga(),
    transactionsSaga(),
  ]);
}

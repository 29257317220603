import { pointsActionsTypes } from "../actions/points.actions";

const initialState = {
  rewards: [],
  countRewards: null,
  isLoadingRewards: false,
  offers: [],
  countOffers: null,
  isLoadingOffers: false,
  offerError: "",
  points: [],
  pointHistory: [],
  countPoints: null,
  isLoadingPoints: false,
  isLoadingPointsBtn: false,
  isLoadingPointHistory: false,
  pointError: "",
  pointsReport: {},
  userList: [],
  showMessage: false,
  error: ""
};

export default function points(state = initialState, action) {
  switch (action.type) {
    case pointsActionsTypes.GET_REWARDS: {
      return {
        ...state,
        isLoadingRewards: true
      };
    }

    case pointsActionsTypes.GET_REWARDS_SUCCESS: {
      return {
        ...state,
        rewards: action.payload.awards,
        countRewards: action.payload.meta.totalCount,
        isLoadingRewards: false
      };
    }

    case pointsActionsTypes.GET_REWARDS_FAILURE: {
      return {
        ...state,
        isLoadingRewards: false
      };
    }

    case pointsActionsTypes.SET_REWARD_SUCCESS: {
      const newReward = [...state.rewards, action.payload];
      return {
        ...state,
        rewards: newReward
      };
    }

    case pointsActionsTypes.DELETE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.filter(item => item.id !== action.payload),
        error: ""
      };

    case pointsActionsTypes.DELETE_REWARD_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case pointsActionsTypes.GET_OFFERS: {
      return {
        ...state,
        isLoadingOffers: true
      };
    }

    case pointsActionsTypes.GET_OFFERS_SUCCESS: {
      return {
        ...state,
        offers: action.payload.offers,
        countOffers: action.payload.meta.totalCount,
        isLoadingOffers: false
      };
    }

    case pointsActionsTypes.GET_OFFERS_FAILURE: {
      return {
        ...state,
        isLoadingOffers: false
      };
    }

    case pointsActionsTypes.SET_OFFER_SUCCESS: {
      const newOffer = [...state.offers, action.payload];
      return {
        ...state,
        offers: newOffer
      };
    }

    case pointsActionsTypes.SET_OFFER_FAILURE: {
      return {
        ...state,
        offerError: action.payload
      };
    }

    case pointsActionsTypes.DELETE_OFFER: {
      const deletedArr = state.offers.filter(
        offer => offer.productId !== action.payload
      );
      return {
        ...state,
        offers: deletedArr
      };
    }

    case pointsActionsTypes.GET_POINTS: {
      return {
        ...state,
        isLoadingPoints: true,
        isLoadingPointsBtn: true
      };
    }

    case pointsActionsTypes.GET_POINTS_SUCCESS: {
      return {
        ...state,
        points: action.payload.points[0] || [],
        countPoints: action.payload.meta.totalCount,
        isLoadingPoints: false,
        isLoadingPointsBtn: false
      };
    }

    case pointsActionsTypes.GET_POINTS_FAILURE: {
      return {
        ...state,
        isLoadingReisLoadingPointswards: false,
        isLoadingPointsBtn: false
      };
    }

    case pointsActionsTypes.SET_POINT: {
      return {
        ...state,
        isLoadingPointsBtn: true
      };
    }

    case pointsActionsTypes.SET_POINT_SUCCESS: {
      const newPoint = [...state.points, action.payload];
      return {
        ...state,
        points: newPoint,
        isLoadingPointsBtn: false
      };
    }

    case pointsActionsTypes.SET_POINT_FAILURE: {
      return {
        ...state,
        pointError: action.payload,
        isLoadingPointsBtn: false
      };
    }

    case pointsActionsTypes.UPDATE_POINT_SUCCESS: {
      const pointConditions = state.points.pointConditions.map(point => {
        if (point.id === action.payload[0].id) {
          point = action.payload[0];
        }
        return point;
      });
      state.points.pointConditions = pointConditions;
      return {
        ...state,
        points: state.points,
        showMessage: true
      };
    }

    case pointsActionsTypes.GET_POINT_HISTORY: {
      return {
        ...state,
        isLoadingPointHistory: true
      };
    }

    case pointsActionsTypes.GET_POINT_HISTORY_SUCCESS: {
      return {
        ...state,
        pointHistory: action.payload,
        isLoadingPointHistory: false
      };
    }

    case pointsActionsTypes.GET_POINT_HISTORY_FAILURE: {
      return {
        ...state,
        isLoadingPointHistory: false
      };
    }

    case pointsActionsTypes.RESET_ERROR: {
      return {
        ...state,
        pointError: null,
        offerError: null,
        showMessage: false
      };
    }

    case pointsActionsTypes.ASSIGN_POINT_SUCCESS: {
      // const pointConditions = state.points.pointConditions.map(point => {
      //   if (point.id === action.payload[0].id) {
      //     point = action.payload[0];
      //   }
      //   return point;
      // });
      state.pointHistory.pointsHistory = [
        ...state.pointHistory.pointsHistory,
        action.payload
      ];
      return {
        ...state,
        pointHistory: state.pointHistory
      };
    }

    case pointsActionsTypes.GET_POINTS_RIYAL_SUCCESS:
      return {
        ...state,
        pointsRiyal: action.payload.pointsRiyalValue
      };

    case pointsActionsTypes.GET_POINTS_RIYAL_FAILURE:
      return {
        ...state,
        pointError: action.payload
      };

    case pointsActionsTypes.UPDATE_POINTS_RIYAL_FAILURE:
      return {
        ...state,
        pointError: action.payload
      };

    case pointsActionsTypes.UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.map(i =>
          i.id === action.payload.id
            ? {
                ...i,
                ...action.payload.data
              }
            : i
        )
      };

    case pointsActionsTypes.GET_POINTS_REPORT:
      return {
        ...state,
        isLoadingPoints: true
      };

    case pointsActionsTypes.GET_POINTS_REPORT_SUCCESS:
      if (action.payload) {
        const pointsReport = Object.entries(action.payload);
        return {
          ...state,
          pointsReport: Object.fromEntries(pointsReport.slice(0, 4)),
          userList: pointsReport.slice(4)[0][1] || [],
          isLoadingPoints: false
        };
      } else {
        return { ...state, isLoadingPoints: false };
      }

    case pointsActionsTypes.GET_POINTS_REPORT_FAILURE:
      return {
        ...state,
        isLoadingPoints: false
      };

    case pointsActionsTypes.GET_AWARD_HISTORY:
      return {
        ...state,
        isLoadingPoints: true
      };

    case pointsActionsTypes.GET_AWARD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingPoints: false,
        rewards: action.payload && action.payload.awards,
        countRewards: action.payload.meta && action.payload.meta.totalCount
      };

    case pointsActionsTypes.GET_AWARD_HISTORY_FAILURE:
      return {
        ...state,
        isLoadingPoints: false
      };

    default:
      return state;
  }
}

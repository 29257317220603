import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { changeStatusMenuFalse } from "../../store/actions/authorization.actions";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./sideBar.scss";
import ThirdLevel from "./thirdLevel";

const DropDownChild = ({ child, match }) => {
  const dispatch = useDispatch();
  const openStatus = useSelector(state => state.authorization.menuStatus);
  const [upsellStatus, setUpsellStatus] = useState(false);

  useEffect(() => {
    if (!openStatus) {
      setUpsellStatus(false);
    }
  }, [openStatus]);

  return (
    <div key={child.name}>
      {child.children ? (
        <span id={`link${child.name}`} className="childItem">
          <a
            id="link"
            className={classNames(
              child.children.some(({ url }) => match.url === url) && "active"
            )}
            onClick={() => {
              setUpsellStatus(!upsellStatus);
            }}
            href="# "
          >
            <i className={child.icon}></i>
            <span>{child.name}</span>
            <span
              className={classNames(
                openStatus && (upsellStatus ? "arrow-rotate" : "menu-arrow")
              )}
            ></span>
          </a>
          <span
            className={classNames(
              "nav-second-level",
              upsellStatus ? "open" : "hide"
            )}
          >
            {child.children.map((childItem, index) =>
              !childItem.children ? (
                <li key={child.name + index} id="menu-item">
                  <NavLink
                    to={childItem.url || "#"}
                    onClick={() => dispatch(changeStatusMenuFalse())}
                    className="has-dropdown"
                    activeClassName="activeLink"
                  >
                    <i className={childItem.icon}></i>
                    <span>{childItem.name}</span>
                  </NavLink>
                </li>
              ) : (
                <ThirdLevel
                  key={childItem.name}
                  link={childItem}
                  match={match}
                />
              )
            )}
          </span>
        </span>
      ) : (
        <li key={child.name}>
          <NavLink
            to={child.url || "#"}
            onClick={() => dispatch(changeStatusMenuFalse())}
            className="has-dropdown"
            activeClassName="activeLink"
          >
            <i className={child.icon}></i>
            <span>{child.name}</span>
          </NavLink>
        </li>
      )}
    </div>
  );
};

export default DropDownChild;

import { ordersActionsTypes } from "../actions/orders.actions";

const initialState = {
  fixedFee: 0,
  minimumFreeDeliveryOrderPrice: 0,
  loadingDeliveryFee: false,
  isloadingDeliveryFeeBtn: false,
  loadingCancelTime: false,
  isloadingCancelTimeBtn: false,
  loadingOrderStatus: false,
  loadingOrderStatusBtn: false,
  value: "00:00:00",
  orderStatus: [],
  orders: [],
  loadingOrders: false,
  deliveryState: false,
  dashboardStatus: [],
  mobileStatus: [],
  orderError: null,
  specificOrder: {},
  receiptExpirationTime: "",
  orderRefunds: [],
  loadingOrderRefunds: false,
  createOrderRefundError: "",
  countOrders: 0
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case ordersActionsTypes.GET_ORDERS: {
      return {
        ...state,
        loadingOrders: true,
        orderError: null
      };
    }

    case ordersActionsTypes.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: action.payload,
        loadingOrders: false,
        orderError: null
      };
    }

    case ordersActionsTypes.GET_ORDERS_FAILURE: {
      return {
        ...state,
        orderError: action.payload,
        loadingOrders: false
      };
    }

    case ordersActionsTypes.GET_SPECIFIC_ORDER_SUCCESS: {
      return {
        ...state,
        specificOrder: action.payload
      };
    }

    case ordersActionsTypes.GET_SPECIFIC_ORDER_FAILURE: {
      return {
        ...state
      };
    }

    case ordersActionsTypes.GET_DASHBOARD_STATUS: {
      return {
        ...state,
        loadingOrderStatus: true
      };
    }

    case ordersActionsTypes.GET_DASHBOARD_STATUS_SUCCESS: {
      return {
        ...state,
        loadingOrderStatus: false,
        dashboardStatus: action.payload
      };
    }

    case ordersActionsTypes.GET_MOBILE_STATUS_SUCCESS: {
      return {
        ...state,
        mobileStatus: action.payload
      };
    }

    case ordersActionsTypes.SET_ORDER_STATUS: {
      return {
        ...state,
        loadingOrderStatusBtn: true
      };
    }

    case ordersActionsTypes.SET_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        loadingOrderStatusBtn: false
      };
    }

    case ordersActionsTypes.GET_CANCEL_TIME: {
      return {
        ...state,
        loadingCancelTime: true
      };
    }

    case ordersActionsTypes.GET_CANCEL_TIME_SUCCESS: {
      return {
        ...state,
        value: action.payload.value,
        loadingCancelTime: false
      };
    }

    case ordersActionsTypes.SET_CANCEL_TIME: {
      return {
        ...state,
        isloadingCancelTimeBtn: true
      };
    }

    case ordersActionsTypes.SET_CANCEL_TIME_SUCCESS: {
      return {
        ...state,
        isloadingCancelTimeBtn: false
      };
    }

    case ordersActionsTypes.GET_DELIVERY_FEE: {
      return {
        ...state,
        loadingDeliveryFee: true
      };
    }

    case ordersActionsTypes.GET_DELIVERY_FEE_SUCCESS: {
      return {
        ...state,
        fixedFee: action.payload.fixedFee,
        minimumFreeDeliveryOrderPrice:
          action.payload.minimumFreeDeliveryOrderPrice,
        deliveryState: action.payload.deliveryFee,
        minimumAmountForDelivery: action.payload.minimumAmountForDelivery,
        loadingDeliveryFee: false
      };
    }

    case ordersActionsTypes.SET_DELIVERY_FEE: {
      return {
        ...state,
        isloadingDeliveryFeeBtn: true
      };
    }

    case ordersActionsTypes.SET_DELIVERY_FEE_SUCCESS: {
      return {
        ...state,
        isloadingDeliveryFeeBtn: false
      };
    }

    case ordersActionsTypes.GET_ALL_ORDERS:
      return {
        ...state,
        loadingOrders: true
      };

    case ordersActionsTypes.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loadingOrders: false,
        orders: action.payload.orders,
        countOrders: action.payload.meta.count
      };

    case ordersActionsTypes.GET_RECEIPT_EXPIRATION:
      return {
        ...state,
        loadingOrders: true
      };

    case ordersActionsTypes.GET_RECEIPT_EXPIRATION_SUCCESS:
      return {
        ...state,
        loadingOrders: false,
        receiptExpirationTime: action.payload.receiptExpirationTime
      };

    case ordersActionsTypes.GET_RECEIPT_EXPIRATION_FAILURE:
      return {
        ...state,
        loadingOrders: false
      };

    case ordersActionsTypes.SET_RECEIPT_EXPIRATION:
      return {
        ...state,
        loadingOrders: true
      };

    case ordersActionsTypes.SET_RECEIPT_EXPIRATION_SUCCESS:
      return {
        ...state,
        loadingOrders: false
      };

    case ordersActionsTypes.SET_RECEIPT_EXPIRATION_FAILURE:
      return {
        ...state,
        loadingOrders: false
      };

    case ordersActionsTypes.ADD_ORDER:
      return {
        ...state,
        orders: [{ ...action.payload, newOrder: true }, ...state.orders]
      };

    case ordersActionsTypes.GET_ORDER_REFUNDS:
      return {
        ...state,
        loadingOrderRefunds: false
      };

    case ordersActionsTypes.GET_ORDER_REFUNDS_SUCCESS:
      return {
        ...state,
        loadingOrderRefunds: false,
        orderRefunds: action.payload
      };

    case ordersActionsTypes.GET_ORDER_REFUNDS_FAILURE:
      return {
        ...state,
        loadingOrderRefunds: false
      };

    case ordersActionsTypes.SET_ORDER_REFUND:
      return {
        ...state,
        loadingOrderRefunds: true
      };

    case ordersActionsTypes.SET_ORDER_REFUND_SUCCESS:
      return {
        ...state,
        loadingOrderRefunds: false
      };

    case ordersActionsTypes.SET_ORDER_REFUND_FAILURE:
      return {
        ...state,
        loadingOrderRefunds: false,
        createOrderRefundError: action.payload
      };
    default:
      return state;
  }
}

import { call, put, takeLatest, select } from "redux-saga/effects";
import { feedbackApi } from "../../services/feedback.service";
import { feedbackActionsTypes } from "../actions/feedback.actions";

const deletedFeedbackQuestion = state => ({
  id: state.feedback.deletedFeedbackQuestion
});

function* getCustomerFeedback(action) {
  try {
    const result = yield call(feedbackApi.getCustomerFeedback, action.payload);
    yield put({
      type: feedbackActionsTypes.GET_CUSTOMER_FEEDBACK_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: feedbackActionsTypes.GET_CUSTOMER_FEEDBACK_FAILURE
    });
  }
}

function* getFeedbackQuestions(action) {
  try {
    const result = yield call(
      feedbackApi.getFeedbackQuestions,
      action.payload.feedbackType,
      action.payload.pagination,
      action.payload.id
    );
    yield put({
      type: feedbackActionsTypes.GET_FEEDBACK_QUESTIONS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: feedbackActionsTypes.GET_FEEDBACK_QUESTIONS_FAILURE
    });
  }
}

function* setFeedbackQuestions(action) {
  try {
    const result = yield call(feedbackApi.setFeedbackQuestions, action.payload);
    yield put({
      type: feedbackActionsTypes.SET_FEEDBACK_QUESTIONS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: feedbackActionsTypes.SET_FEEDBACK_QUESTIONS_FAILURE
    });
  }
}

function* updateFeedbackQuestions(action) {
  try {
    const result = yield call(
      feedbackApi.updateFeedbackQuestions,
      action.payload
    );
    yield put({
      type: feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS_FAILURE
    });
  }
}

function* deleteFeedbackQuestion(action) {
  const data = yield select(deletedFeedbackQuestion);

  try {
    yield call(feedbackApi.deleteFeedbackQuestion, action.payload);

    yield put({
      type: feedbackActionsTypes.DELETE_FEEDBACK_QUESTIONS_SUCCESS,
      payload: data.id
    });
  } catch (error) {
    yield put({
      type: feedbackActionsTypes.DELETE_FEEDBACK_QUESTIONS_FAILURE
    });
  }
}

function* getReports(action) {
  try {
    const result = yield call(feedbackApi.getReports, action.payload);
    yield put({
      type: feedbackActionsTypes.GET_REPORTS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: feedbackActionsTypes.GET_REPORTS_FAILURE
    });
  }
}

function* feedbackSaga() {
  yield takeLatest(
    [feedbackActionsTypes.GET_CUSTOMER_FEEDBACK],
    getCustomerFeedback
  );
  yield takeLatest(
    [feedbackActionsTypes.GET_FEEDBACK_QUESTIONS],
    getFeedbackQuestions
  );
  yield takeLatest(
    [feedbackActionsTypes.SET_FEEDBACK_QUESTIONS],
    setFeedbackQuestions
  );
  yield takeLatest(
    [feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS],
    updateFeedbackQuestions
  );
  yield takeLatest(
    [feedbackActionsTypes.DELETE_FEEDBACK_QUESTIONS],
    deleteFeedbackQuestion
  );
  yield takeLatest([feedbackActionsTypes.GET_REPORTS], getReports);
}

export default feedbackSaga;

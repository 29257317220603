import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import Location from "../../location/location";

export default function modalBookedFoodTruck({ toggle, modal, location }) {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Customer Location</ModalHeader>
      <div className="modal-body p-4">
        <div className="map">
          <Location location={location} />
        </div>
        <ModalFooter className="mt-3">
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              toggle();
            }}
            style={{ position: "relative" }}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

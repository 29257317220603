import { API_URL } from "../constants";
import axiosInstance from "../services/interceptor";
import axios from "axios";

export const authorizationsApi = {
  login(data) {
    return axios.post(`${API_URL}/admin/sign-in`, data);
  },

  getSupervisors(pagination) {
    return axiosInstance.get(`${API_URL}/admin/all-supervisors`, {
      params: pagination
    });
  },

  setSupervisors(supervisorData) {
    return axiosInstance.post(`${API_URL}/admin/supervisor`, supervisorData);
  },

  updateSupervisors(supervisorData) {
    return axiosInstance.patch(`${API_URL}/admin/supervisor`, supervisorData);
  },

  deleteSupervisors(supervisorId) {
    return axiosInstance.delete(`${API_URL}/admin/supervisor/${supervisorId}`);
  },

  getUser() {
    return axiosInstance.get(`${API_URL}/admin/privileges`);
  }
};

import React from "react";
import classNames from "classnames";
import "./toggleButton.scss";

const ToggleButton = ({ value = false, size }) => {
  return (
    <div>
      <div
        className={classNames("toggle", size && "toggle-small", {
          "toggle-red": !value
        })}
      >
        <div
          className={classNames(
            "toggle-button",
            size && "toggle-button-small",
            size && value && "vip-small",
            { vip: value }
          )}
        ></div>
      </div>
    </div>
  );
};

export default ToggleButton;

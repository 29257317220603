import {
  branchesActionsTypes,
  updateBranch
} from "../actions/branches.actions";

const initialState = {
  countBranch: 0,
  countriesList: [],
  countries: [],
  cities: [],
  countryId: null,
  cityId: null,
  availableForDelivery: null,
  showInMobileApp: null,
  branchId: null,
  loadingBranches: false,
  branches: [],
  isSynchronizedBranches: false,
  bulkBranchesUpdateLoading: false,
  bulkBranchesUpdateErr: "",
  isBulkBranchesUpdateSucceeded: false
};

export default function countries(state = initialState, action) {
  switch (action.type) {
    case branchesActionsTypes.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cityId: null,
        cities: action.payload.data
      };
    }
    case branchesActionsTypes.GET_COUNTRIES: {
      return {
        ...state,
        loadingBranches: true
      };
    }
    case branchesActionsTypes.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countriesList: action.payload.data.countries,
        loadingBranches: false
      };
    }

    case branchesActionsTypes.GET_COUNTRIES_FAILURE: {
      return {
        ...state,
        loadingBranches: false
      };
    }

    case branchesActionsTypes.GET_COUNTRIES_LIST: {
      return {
        ...state,
        countryId: action.payload.countryId,
        cityId: action.payload.cityId,
        availableForDelivery: action.payload.availableForDelivery,
        showInMobileApp: action.payload.showInMobileApp
      };
    }

    case branchesActionsTypes.GET_COUNTRIES_LIST_SUCCESS: {
      return {
        ...state,
        countBranch: action.payload.data.meta.count,
        countries: action.payload.data.countries
      };
    }

    case branchesActionsTypes.GET_BRANCHES_SUCCESS: {
      return {
        ...state,
        branches: action.payload.data.branches,
        countBranch: action.payload.data.meta.totalCount,
        isBulkBranchesUpdateSucceeded: false
      };
    }

    case branchesActionsTypes.SYNCHRONIZE_BRANCHES: {
      return {
        ...state,
        isSynchronizedBranches: true,
        isBulkBranchesUpdateSucceeded: false
      };
    }
    case branchesActionsTypes.SYNCHRONIZE_BRANCHES_SUCCESS: {
      return {
        ...state,
        isSynchronizedBranches: false
      };
    }

    case branchesActionsTypes.SYNCHRONIZE_BRANCHES_FAILURE: {
      return {
        ...state,
        isSynchronizedBranches: false
      };
    }
    case branchesActionsTypes.UPDATE_BRANCH_SUCCESS: {
      const modifiedBranch = action.payload;

      // Find the index of the branch to update
      const branchIndex = state.branches.findIndex(
        branch => branch.id === modifiedBranch.id
      );

      // Check if branch exists
      if (branchIndex !== -1) {
        // Create a copy of the branches array to avoid mutation
        const updatedBranches = [...state.branches];

        // Update the branch at the found index
        updatedBranches[branchIndex] = {
          ...updatedBranches[branchIndex],
          ...modifiedBranch
        };

        return {
          ...state,
          branches: updatedBranches
        };
      } else {
        return state;
      }
    }

    case branchesActionsTypes.BULK_BRANCHES_UPDATE: {
      return {
        ...state,
        bulkBranchesUpdateLoading: true,
        bulkBranchesUpdateErr: ""
      };
    }

    case branchesActionsTypes.BULK_BRANCHES_UPDATE_SUCCESS: {
      return {
        ...state,
        bulkBranchesUpdateLoading: false,
        isBulkBranchesUpdateSucceeded: true
      };
    }
    case branchesActionsTypes.BULK_BRANCHES_UPDATE_FAILURE: {
      return {
        ...state,
        bulkBranchesUpdateLoading: false,
        bulkBranchesUpdateErr: action.payload
      };
    }

    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "../../helpers/usePrevious";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { DebounceInput } from "react-debounce-input";
import { getProducts } from "../../store/actions/products.actions";
import { getCategories } from "../../store/actions/categories.actions";
import classNames from "classnames";
import Product from "../../components/product/product";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";

const Products = () => {
  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [categoryQuery, setCategoryQuery] = useQueryParam(
    "category",
    StringParam
  );

  const [search, setSearch] = useState(searchQuery || "");
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || "");
  const [filteredCategory, setFilteredCategory] = useState(categoryQuery || "");
  const [
    filteredCategorySelectValue,
    setfilteredCategorySelectValue
  ] = useState(categoryQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(9);

  const dispatch = useDispatch();
  const products = useSelector(state => state.products.products);
  const categories = useSelector(state => state.categories.categories || []);
  const countProduct = useSelector(state => state.products.countProduct);
  const isLoadingProducts = useSelector(
    state => state.products.isLoadingProducts
  );

  const previousSearchValue = usePrevious(search);
  const previousCurrentPageValue = usePrevious(currentPage);
  const previousFilteredCategoryValue = usePrevious(filteredCategory);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setSearch(searchQuery || "");
  });
  useEffect(() => {
    setFilteredCategory(categoryQuery || "");
  });

  useEffect(() => {
    if (
      previousSearchValue !== undefined &&
      search !== undefined &&
      previousSearchValue !== search
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }
    if (
      previousFilteredCategoryValue !== undefined &&
      filteredCategory !== undefined &&
      previousFilteredCategoryValue !== filteredCategory
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }
    dispatch(
      getProducts({
        pagination: { page: currentPage, limit: rowsPerPage },
        search,
        filteredCategory
      })
    );
  }, [dispatch, currentPage, rowsPerPage, search, filteredCategory]);

  useEffect(() => {
    dispatch(getCategories({ pagination: { limit: "", page: "" } }));
  }, []);

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Products</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-inline">
                        <div className="form-group">
                          <label htmlFor="inputPassword2" className="sr-only">
                            Search
                          </label>
                          <DebounceInput
                            type="search"
                            minLength={1}
                            className="form-control"
                            debounceTimeout={800}
                            value={searchInputValue}
                            id="inputPassword2"
                            onChange={e => {
                              setSearchInputValue(e.target.value);
                              setSearchQuery(e.target.value);
                            }}
                            placeholder="Search..."
                          />
                        </div>
                        <div className="form-group mx-sm-3">
                          <label htmlFor="status-select" className="mr-2">
                            Category
                          </label>
                          <select
                            className="custom-select"
                            id="status-select"
                            value={filteredCategorySelectValue}
                            onChange={e => {
                              setfilteredCategorySelectValue(e.target.value);
                              setCategoryQuery(e.target.value);
                            }}
                          >
                            <option value="">All </option>
                            {categories.map(category => (
                              <option key={category.id} value={category.id}>
                                {category.name.en}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isLoadingProducts ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : products.length ? (
              <div className="row">
                {products.map(product => (
                  <Product key={product.id} product={product} />
                ))}
              </div>
            ) : (
              <div className="notFound">Not Found</div>
            )}
            <div className="row">
              <div className="col-12">
                <Pagination
                  totalCount={countProduct}
                  rows={rowsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setPageQuery}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;

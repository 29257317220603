import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { getFoodTruckMenu } from "../../store/actions/foodTruck.actions";
import { getProducts } from "../../store/actions/products.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";

const FoodTruck = ({ history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const menuData = useSelector(state => state.foodTruck.menuData);
  const products = useSelector(state => state.products.products);
  const isLoading = useSelector(state => state.foodTruck.isLoadingMenu);

  const tableHeadMenu = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Number of Persons",
      key: "persons"
    },
    {
      name: "Price",
      key: "price"
    }
  ];

  const tableHeadProducts = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Product Id",
      key: "id"
    },
    {
      name: "Product Name",
      key: "productName",
      value: products
    }
  ];

  useEffect(() => {
    dispatch(getFoodTruckMenu());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: {
          page: "",
          limit: ""
        }
      })
    );
  }, [dispatch]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Food Truck</h4>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-box">
                      <div className="text-md-right">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                          onClick={() => {
                            history.push("food-truck/manage");
                          }}
                        >
                          <i className="mr-1"></i> Update Food Truck
                        </button>
                      </div>
                      <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                        Menu
                      </h5>
                      <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <TableComponent
                          tableHead={tableHeadMenu}
                          tableBody={menuData.quantityPrice}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-box">
                      <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                        Products
                      </h5>
                      <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <TableComponent
                          tableHead={tableHeadProducts}
                          tableBody={menuData.availableProducts}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-box">
                      <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                        Additional Quantity
                      </h5>
                      <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <div className="form-group mb-2 w-100">
                          <label htmlFor="product-meta-1">
                            Number of Persons
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="numberofPersons"
                            name="numberofPersons"
                            placeholder="Number of Persons"
                            value={
                              menuData.additionalQuantityPrice &&
                              menuData.additionalQuantityPrice.persons
                            }
                            disabled
                            readOnly
                          />
                          {/* {form.errors.productNameEn &&
                        form.touched.productNameEn ? (
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">
                              {form.errors.productNameEn}
                            </li>
                          </ul>
                        ) : null} */}
                        </div>

                        <div className="form-group w-100">
                          <label htmlFor="product-meta-1">Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="price"
                            name="price"
                            placeholder="Price"
                            value={
                              menuData.additionalQuantityPrice &&
                              menuData.additionalQuantityPrice.price
                            }
                            disabled
                            readOnly
                          />
                          {/* {form.errors.productNameEn &&
                        form.touched.productNameEn ? (
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">
                              {form.errors.productNameEn}
                            </li>
                          </ul>
                        ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodTruck;

import React, { useEffect, useState } from "react";
import AsyncCities from "./asyncCities/asyncCities";
import AsyncCountries from "./asyncCountries/asyncCountries";
import AsyncAvailableForDelivery from "./asyncAvailableForDelivery/asyncAvailableForDelivery";
import { useDispatch } from "react-redux";
import {
  getCountries,
  getCities,
  getCountriesList
} from "../../store/actions/branches.actions";
import "./filter.scss";
import usePrevious from "../../helpers/usePrevious";
import AsyncShowInMobileApp from "./asyncShowInMobile/asyncShowInMobile";

const Filter = ({
  action,
  setCurrentPage,
  currentPage,
  rowsPerPage,
  isAvailable,
  showInMobile
}) => {
  const dispatch = useDispatch();

  const [countryId, setCountryId] = useState(null); // used to be the country id to search branches with "55cf4280-b74d-41c2-a69f-7371d5bce800"
  const [cityId, setCityId] = useState("");
  const [availableForDelivery, setAvailableForDelivery] = useState(null);
  const [showInMobileApp, setShowInMobileApp] = useState(null);

  const previousCountryValue = usePrevious(countryId);
  const previousCityValue = usePrevious(cityId);
  const previousCurrentPageValue = usePrevious(currentPage);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCities(countryId));
    (isAvailable || showInMobile) &&
      dispatch(
        getCountriesList({
          cityId,
          countryId,
          availableForDelivery,
          showInMobileApp
        })
      );
    // showInMobile &&
    //   dispatch(getCountriesList({ cityId, countryId, showInMobileApp }));
  }, [dispatch, countryId, cityId, availableForDelivery, showInMobileApp]);

  if (!isAvailable) {
    useEffect(() => {
      if (
        previousCityValue !== undefined &&
        countryId !== undefined &&
        cityId !== undefined &&
        (previousCountryValue !== countryId || previousCityValue !== cityId)
      ) {
        if (previousCurrentPageValue !== 1) {
          return setCurrentPage(1);
        }
      }
      dispatch(
        action({
          cityId,
          countryId,
          pagination: { page: currentPage, limit: rowsPerPage }
        })
      );
    }, [dispatch, cityId, countryId, currentPage, rowsPerPage]);
  }

  return (
    <div className="d-flex">
      {/* <AsyncCountries countryId={countryId} setCountryId={setCountryId} /> */}
      {/* <AsyncCities
        countryId={countryId}
        cityId={cityId}
        setCityId={setCityId}
      /> */}
      {isAvailable && (
        <AsyncAvailableForDelivery
          availableForDelivery={availableForDelivery}
          setAvailableForDelivery={setAvailableForDelivery}
        />
      )}
      {showInMobile && (
        <AsyncShowInMobileApp
          showInMobileApp={showInMobileApp}
          setShowInMobileApp={setShowInMobileApp}
        />
      )}
    </div>
  );
};

export default Filter;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import { sendAllNotifications } from "../../store/actions/notification.actions";

import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

const GeneralNotifications = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const formik = useFormik({
    initialValues: {
      message: "",
      customers: false,
      guests: false
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      message: Yup.string().required("Required")
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(sendAllNotifications(values));
      resetForm();
    }
  });

  const { loadingNotifications, success } = useSelector(
    state => state.notification
  );

  useEffect(() => {
    success &&
      toast.success("Sent", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [success]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">General Notifications</h4>
                </div>
              </div>
            </div>
            {loadingNotifications ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form w-100 d-flex justify-content-between align-items-end">
                          <div className="form-group mb-2">
                            <textarea
                              className="p-2 form-control"
                              style={{ width: "500px", height: "100px" }}
                              name="message"
                              id="message"
                              placeholder="Message"
                              value={formik.values.message}
                              onChange={formik.handleChange}
                              onClick={() => {
                                formik.setFieldTouched("message", true);
                              }}
                            ></textarea>
                            {formik.errors.message && formik.touched.message ? (
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">
                                  {formik.errors.message}
                                </li>
                              </ul>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label htmlFor="customers" className="mr-2">
                              Customers
                            </label>
                            <input
                              type="checkbox"
                              name="customers"
                              id="customers"
                              checked={formik.values.customers}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="guests" className="mr-2">
                              Guests
                            </label>
                            <input
                              type="checkbox"
                              name="guests"
                              id="guests"
                              checked={formik.values.guests}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <div className="text-md-right">
                            <button
                              type="button"
                              className="btn btn-success waves-effect waves-light mb-2 mr-2"
                              onClick={formik.handleSubmit}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralNotifications;

import axiosInstance from "../services/interceptor";

export const productsApi = {
  getProducts(params, search, filteredCategory) {
    const { page, limit } = params;
    return axiosInstance.get("/products", {
      params: {
        page,
        limit,
        search,
        categoryId: filteredCategory
      }
    });
  },

  getProduct(productId) {
    return axiosInstance.get(`/products/${productId}`);
  },

  getModifiers(params) {
    const { page, limit } = params;
    return axiosInstance.get("/modifiers", {
      params: {
        page,
        limit
      }
    });
  },

  updateProduct(id, data) {
    return axiosInstance.patch(`/products/${id}`, data);
  },
  updateModifierProduct(productId, modifierIds, selected) {
    return axiosInstance.patch(`/modifiers/${modifierIds}/selection-product`, {
      productId,
      selected
    });
  },

  addProduct(productId, relatedProduct) {
    return axiosInstance.put(
      `/products/${productId}/related-product-id`,
      relatedProduct
    );
  },

  updateProductImage(data) {
    return axiosInstance.put(
      `/products/${data.productId}/image`,
      data.formData
    );
  },

  setDoubledModifiers(modifierId, isDouble) {
    return axiosInstance.patch(`/modifiers/${modifierId}/is-double`, {
      isDouble
    });
  },

  updateModifierImage(id, data) {
    return axiosInstance.put(`/modifier-options/${id}/image`, data);
  },

  updateProductsOrder(products) {
    return axiosInstance.patch("/products/order", {
      products
    });
  },

  toggleSelection({ id, selected }) {
    return axiosInstance.patch(`/products/${id}/selection`, {
      selected
    });
  }
};

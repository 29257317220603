import { API_URL } from "../constants";
import axiosInstance from "../services/interceptor";

export const categoriesApi = {
  getCategories(params, search) {
    const { page, limit } = params;

    return axiosInstance.get(`${API_URL}/categories`, {
      params: {
        page,
        limit,
        search
      }
    });
  },

  selectCategories(categoryId, selectedCategory) {
    return axiosInstance.patch(
      `${API_URL}/categories/${categoryId}/selection`,
      {
        selected: selectedCategory
      }
    );
  },

  updateCategory(id, name, selected) {
    return axiosInstance.patch(`${API_URL}/categories/${id}`, {
      name,
      selected
    });
  },

  updateModifiers(modifierId, selectedModifier) {
    return axiosInstance.patch(`${API_URL}/modifiers/${modifierId}/selection`, {
      selected: selectedModifier
    });
  },

  updateCategoryImage(data) {
    return axiosInstance.put(
      `${API_URL}/categories/${data.categoryId}/image`,
      data.formdata
    );
  },

  synchronizeMenu() {
    return axiosInstance.post(`${API_URL}/foodics/sync-manage-menu`);
  },

  updateCategoriesOrder(categories) {
    return axiosInstance.patch(`${API_URL}/categories/order`, { categories });
  }
};

import { categoriesActionsTypes } from "../actions/categories.actions";

const initialState = {
  categories: [],
  isLoading: false,
  countCategory: 0,
  isSynchronizedMenu: false
};

export default function categories(state = initialState, action) {
  switch (action.type) {
    case categoriesActionsTypes.GET_CATEGORIES: {
      return {
        ...state,
        isLoading: true
      };
    }

    case categoriesActionsTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        countCategory: action.payload.meta.count,
        categories: action.payload.categories,
        isLoading: false
      };
    }

    case categoriesActionsTypes.GET_CATEGORIES_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case categoriesActionsTypes.SYNCHRONIZE_MENU: {
      return {
        ...state,
        isSynchronizedMenu: true
      };
    }

    case categoriesActionsTypes.SYNCHRONIZE_MENU_SUCCESS: {
      return {
        ...state,
        isSynchronizedMenu: false
      };
    }

    case categoriesActionsTypes.SYNCHRONIZE_MENU_FAILURE: {
      return {
        ...state,
        isSynchronizedMenu: false
      };
    }

    case categoriesActionsTypes.UPDATE_CATEGORY_IMAGE_SUCCESS: {
      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.id === action.payload.id) {
            category.greyIconPath = action.payload.greyIconPath;
            category.redIconPath = action.payload.redIconPath;
          }
          return category;
        })
      };
    }

    case categoriesActionsTypes.UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.id === action.payload.id) {
            category.name = action.payload.name;
            category.selected = action.payload.selected;
          }
          return category;
        })
      };
    }

    case categoriesActionsTypes.UPDATE_CATEGORIES_ORDER:
      return {
        ...state,
        isLoading: true
      };

    case categoriesActionsTypes.UPDATE_CATEGORIES_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case categoriesActionsTypes.UPDATE_CATEGORIES_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAboutUs,
  setAboutUs
} from "../../../store/actions/pagesContent.action";
import { useFormik } from "formik";
import classNames from "classnames";
import LoadingButton from "../../../components/loadingButton/loadingButton";
import AboutUsDropify from "../../aboutUsDropify/aboutUsDropify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AboutUs = ({ lang }) => {
  const dispatch = useDispatch();
  const about = useSelector(state => state.pagesContent.aboutUs || []);
  const aboutUsImage = useSelector(
    state => state.pagesContent.aboutUsImage || ""
  );
  const loadingAboutUs = useSelector(
    state => state.pagesContent.loadingAboutUs
  );
  const isLoadingBtn = useSelector(
    state => state.pagesContent.isloadingAboutUsBtn
  );

  const [modal, setModal] = useState(true);
  const [image, setImage] = useState(aboutUsImage);

  const formik = useFormik({
    initialValues: about,
    enableReinitialize: true,
    onSubmit: values => {
      setModal(false);
      dispatch(
        setAboutUs({
          ...values,
          image,
          lang
        })
      );
    }
  });

  useEffect(() => {
    dispatch(getAboutUs(lang));
  }, [lang]);

  useEffect(() => {
    setImage(aboutUsImage);
  }, [aboutUsImage]);

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return loadingAboutUs ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  ) : (
    <div className="col-md-12">
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-content">
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div
                    className={classNames(
                      "d-flex",
                      lang === "ar" && "justify-content-end"
                    )}
                  >
                    <label htmlFor="aboutUs" className="control-label">
                      {lang === "en"
                        ? "About Burgerizze Image"
                        : "عن برقرايزر - صورة"}
                    </label>
                  </div>

                  <AboutUsDropify
                    aboutUsImage={aboutUsImage}
                    height="25vh"
                    action={setImage}
                    modal={modal}
                    formik={formik}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="aboutUs"
                    className={classNames(
                      "control-label",
                      lang === "ar" && "float-right"
                    )}
                  >
                    {lang === "en" ? "About Us" : " عن برقرارزر"}
                  </label>
                  <textarea
                    rows="5"
                    id="aboutUs"
                    className={classNames(
                      "form-control",
                      lang === "ar" && "text-right"
                    )}
                    placeholder={lang === "en" ? "About Us" : "عن برقرارزر"}
                    onChange={formik.handleChange}
                    value={formik.values.aboutUs || ""}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="vision"
                    className={classNames(
                      "control-label",
                      lang === "ar" && "float-right"
                    )}
                  >
                    {lang === "en" ? "Vision" : "الرؤية"}
                  </label>
                  <textarea
                    rows="5"
                    id="vision"
                    className={classNames(
                      "form-control",
                      lang === "ar" && "text-right"
                    )}
                    placeholder={lang === "en" ? "Vision" : "الرؤية"}
                    onChange={formik.handleChange}
                    value={formik.values.vision || ""}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="mission"
                    className={classNames(
                      "control-label",
                      lang === "ar" && "float-right"
                    )}
                  >
                    {lang === "en" ? "Mission" : "الرسالة"}
                  </label>
                  <textarea
                    rows="5"
                    id="mission"
                    className={classNames(
                      "form-control",
                      lang === "ar" && "text-right"
                    )}
                    placeholder={lang === "en" ? "Mission" : "الرسالة"}
                    onChange={formik.handleChange}
                    value={formik.values.mission || ""}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <LoadingButton isLoadingBtn={isLoadingBtn} btnContent="Save" />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </form>
    </div>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { getFixedUpsell } from "../../store/actions/upsell.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModalFixedProduct from "../../components/modal/modalFixedProduct/modaFixedProduct";
import TableComponent from "../../components/tableComponent";
import ModalDelete from "../../components/modal/modalDelete/modalDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FixedProductUpsell = () => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const dispatch = useDispatch();
  const fixedUpsells = useSelector(state => state.upsell.fixedUpsells);
  const loadingFixedUpsell = useSelector(
    state => state.upsell.loadingFixedUpsell
  );

  const errorMessage = useSelector(state => state.upsell.fixedMSG53);

  const tableHead = [
    {
      name: "Product",
      key: "product",
      value: "products"
    },
    {
      name: "Date",
      key: "dateRange",
      dateFrom: "dateFrom",
      dateTo: "dateTo"
    },
    {
      name: "Time",
      key: "timeRange",
      timeFrom: "timeFrom",
      timeTo: "timeTo"
    },
    {
      name: "Delete",
      key: "delete",
      return: "id",
      onClick: id => {
        toggle();
        setUpsellId(id);
      }
    }
  ];

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [upsellId, setUpsellId] = useState("");

  const [fixedProduct, setFixedProduct] = useState(false);
  const addNewFixedProduct = () => setFixedProduct(!fixedProduct);

  useEffect(() => {
    dispatch(getFixedUpsell());
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage === "MSG53") {
      toast.error("Upsell is already exists", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else if (errorMessage) {
      toast.error("You can't add more than 4 products", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }, [errorMessage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Fixed Products Upsell</h4>
                </div>
              </div>
            </div>
            {loadingFixedUpsell ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="card-box">
                <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="text-md-right">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                        onClick={addNewFixedProduct}
                      >
                        <i className="mdi mdi-plus mr-1"></i> Add
                      </button>
                    </div>
                    <TableComponent
                      tableHead={tableHead}
                      tableBody={fixedUpsells}
                    />
                  </div>
                </div>
              </div>
            )}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <ModalDelete
              modal={modal}
              toggle={toggle}
              id={upsellId}
              name="FixedUpsell"
            />
          </div>
          <ModalFixedProduct modal={fixedProduct} toggle={addNewFixedProduct} />
        </div>
      </div>
    </div>
  );
};

export default FixedProductUpsell;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPrivacy,
  setPrivacy
} from "../../../store/actions/pagesContent.action";
import { useFormik } from "formik";
import classNames from "classnames";
import LoadingButton from "../../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PrivacyAndPolicy = ({ lang }) => {
  const dispatch = useDispatch();
  const privacy = useSelector(state => state.pagesContent.privacy || []);
  const loadingPrivacy = useSelector(
    state => state.pagesContent.loadingPrivacy
  );
  const isLoadingBtn = useSelector(
    state => state.pagesContent.isloadingPrivacyBtn
  );

  const formik = useFormik({
    initialValues: privacy,
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(setPrivacy({ privacyAndPolicy: values.privacyAndPolicy, lang }));
    }
  });

  useEffect(() => {
    dispatch(getPrivacy(lang));
  }, [lang]);

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return loadingPrivacy ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  ) : (
    <div className="col-md-12">
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-content">
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-md-12">
                <label
                  htmlFor="privacyAndPolicy"
                  className={classNames(
                    "control-label",
                    lang === "ar" && "float-right"
                  )}
                >
                  {lang === "en"
                    ? "Privacy and Policy:"
                    : "الأحكام وسياسة الخصوصية"}
                </label>
                <textarea
                  rows="8"
                  className={classNames(
                    "form-control",
                    lang === "ar" && "text-right"
                  )}
                  id="privacyAndPolicy"
                  placeholder={
                    lang === "en"
                      ? "Privacy and Policy:"
                      : "الأحكام وسياسة الخصوصية"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.privacyAndPolicy || ""}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <LoadingButton isLoadingBtn={isLoadingBtn} btnContent="Save" />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </form>
    </div>
  );
};

export default PrivacyAndPolicy;

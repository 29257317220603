import { API_URL } from "../constants";
import axiosInstance from "../services/interceptor";

export const customersApi = {
  getCustomers(params, search) {
    const { page, limit } = params;

    return axiosInstance.get(`${API_URL}/customers`, {
      params: {
        page,
        limit,
        search
      }
    });
  },
  synchronizeBlacklist() {
    return axiosInstance.post("/foodics/sync-blocked-customers");
  },
  addWalletCredit(data) {
    return axiosInstance.post(`${API_URL}/wallet/add-credit`, data);
  }
};

export const pagesContentsActionsTypes = {
  GET_CONTACT_US: "GET_CONTACT_US",
  GET_CONTACT_US_SUCCESS: "GET_CONTACT_US_SUCCESS",
  GET_CONTACT_US_FAILURE: "GET_CONTACT_US_FAILURE",

  SET_CONTACT_US: "SET_CONTACT_US",
  SET_CONTACT_US_SUCCESS: "SET_CONTACT_US_SUCCESS",
  SET_CONTACT_US_FAILURE: "SET_CONTACT_US_FAILURE",

  GET_ABOUT_US: "GET_ABOUT_US",
  GET_ABOUT_US_SUCCESS: "GET_ABOUT_US_SUCCESS",
  GET_ABOUT_US_FAILURE: "GET_ABOUT_US_FAILURE",

  SET_ABOUT_US: "SET_ABOUT_US",
  SET_ABOUT_US_SUCCESS: "SET_ABOUT_US_SUCCESS",
  SET_ABOUT_US_FAILURE: "SET_ABOUT_US_FAILURE",

  GET_PRIVACY: "GET_PRIVACY",
  GET_PRIVACY_SUCCESS: "GET_PRIVACY_SUCCESS",
  GET_PRIVACY_FAILURE: "GET_PRIVACY_FAILURE",

  SET_PRIVACY: "SET_PRIVACY",
  SET_PRIVACY_SUCCESS: "SET_PRIVACY_SUCCESS",
  SET_PRIVACY_FAILURE: "SET_PRIVACY_FAILURE",

  GET_ADVERTISEMENT: "GET_ADVERTISEMENT",
  GET_ADVERTISEMENT_SUCCESS: "GET_ADVERTISEMENT_SUCCESS",
  GET_ADVERTISEMENT_FAILURE: "GET_ADVERTISEMENT_FAILURE",

  SET_ADVERTISEMENT: "SET_ADVERTISEMENT",
  SET_ADVERTISEMENT_SUCCESS: "SET_ADVERTISEMENT_SUCCESS",
  SET_ADVERTISEMENT_FAILURE: "SET_ADVERTISEMENT_FAILURE",

  DELETE_ADVERTISEMENT: "DELETE_ADVERTISEMENT",
  DELETE_ADVERTISEMENT_SUCCESS: "DELETE_ADVERTISEMENT_SUCCESS",
  DELETE_ADVERTISEMENT_FAILURE: "DELETE_ADVERTISEMENT_FAILURE"
};

export const getContactUs = () => {
  return {
    type: pagesContentsActionsTypes.GET_CONTACT_US
  };
};

export const setContactUs = data => {
  return {
    type: pagesContentsActionsTypes.SET_CONTACT_US,
    payload: data
  };
};

export const getAboutUs = data => {
  return {
    type: pagesContentsActionsTypes.GET_ABOUT_US,
    payload: data
  };
};

export const setAboutUs = data => {
  return {
    type: pagesContentsActionsTypes.SET_ABOUT_US,
    payload: data
  };
};

export const getPrivacy = data => {
  return {
    type: pagesContentsActionsTypes.GET_PRIVACY,
    payload: data
  };
};

export const setPrivacy = data => {
  return {
    type: pagesContentsActionsTypes.SET_PRIVACY,
    payload: data
  };
};

export const getAdvertisement = () => {
  return {
    type: pagesContentsActionsTypes.GET_ADVERTISEMENT
  };
};

export const setAdvertisement = data => {
  return {
    type: pagesContentsActionsTypes.SET_ADVERTISEMENT,
    payload: data
  };
};

export const deleteAdvertisement = data => {
  return {
    type: pagesContentsActionsTypes.DELETE_ADVERTISEMENT,
    payload: data
  };
};

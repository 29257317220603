import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getContactUs,
  setContactUs
} from "../../../store/actions/pagesContent.action";
import { useFormik } from "formik";
import _ from "lodash";
import LoadingButton from "../../../components/loadingButton/loadingButton";
import "./contactUs.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const dispatch = useDispatch();
  const contactUs = useSelector(state => state.pagesContent.contactUs || []);
  const loadingContactUs = useSelector(
    state => state.pagesContent.loadingContactUs
  );
  const isLoadingBtn = useSelector(
    state => state.pagesContent.isloadingContactUsBtn
  );

  const contactUsObj = _.chain(contactUs)
    .keyBy("key")
    .mapValues("value")
    .value();

  const formik = useFormik({
    initialValues: contactUsObj,
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(setContactUs(values));
    }
  });

  useEffect(() => {
    dispatch(getContactUs());
  }, []);

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return loadingContactUs ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <div className="col-md-12">
        <div className="modal-content">
          <div className="modal-body p-4">
            {contactUs.map(contact => (
              <div className="row" key={contact.id}>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor={contact.key} className="control-label">
                      {contact.key}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={contact.key}
                      name={contact.key}
                      placeholder={contact.key}
                      onChange={formik.handleChange}
                      value={formik.values[contact.key] || ""}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <LoadingButton isLoadingBtn={isLoadingBtn} btnContent="Save" />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </form>
  );
};

export default ContactUs;

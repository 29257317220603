import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, Polygon } from "google-maps-react";
import { GOOGLE_MAP_API_KEY } from "../../constants";

const Location = ({ location, google, width, height, coordinates }) => {
  if (!location) {
    return null;
  }
  const [polygon, setPolygon] = useState([]);

  const mapStyles = {
    width: "90%",
    height: "250px",
    padding: "60px 20px"
  };

  useEffect(() => {
    const coords = [];
    if (coordinates && coordinates.length) {
      coordinates[0].forEach(i => coords.push({ lng: i[0], lat: i[1] }));
      setPolygon(coords);
    }
  }, [coordinates]);

  return (
    <div className="location">
      <div className="location__content">
        <Map
          google={google}
          zoom={8}
          style={mapStyles}
          center={location}
          initialCenter={location}
        >
          {polygon.length && (
            <Polygon
              paths={polygon}
              key={1}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35
              }}
            />
          )}
          <Marker position={location} />
        </Map>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(Location);

import axiosInstance from "./interceptor";

export const notificationApi = {
  sendAllNotifications(data) {
    return axiosInstance.post("/notifications/send-all", data);
  },

  sendSpecificNotifications(data) {
    return axiosInstance.post("/notifications/send-specific", data);
  },

  createScheduleNotification(data) {
    return axiosInstance.post("/notifications/schedule-notification", data);
  },

  getScheduleNotifications(params) {
    return axiosInstance.get("/notifications/scheduled-notifications", {
      params
    });
  },

  editScheduleNotificationStatus(id, data) {
    return axiosInstance.patch(
      `/notifications/${id}/scheduled-notification`,
      data
    );
  }
};

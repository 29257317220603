import React from "react";

const AsyncAvailableForDelivery = ({
  availableForDelivery,
  setAvailableForDelivery
}) => {
  return (
    <div className="form-group">
      <label className="mr-1" htmlFor="Available For Delivery">
        Available For Delivery
      </label>
      <select
        value={availableForDelivery || ""}
        onChange={e => setAvailableForDelivery(e.target.value)}
        className="custom-select"
      >
        <option value={null}>All</option>
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </select>
    </div>
  );
};

export default AsyncAvailableForDelivery;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import "./index.scss";
import { useFormik } from "formik";
import TableComponent from "../../components/tableComponent";
import { tableHead1 } from "./constants";
import {
  getTransactions,
  getTransactionsExport,
} from "../../store/actions/transactions.actions";

const Transactions = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector((state) => state.authorization.menuStatus)
  );

  const transactions = useSelector((state) => state.transactions.transactions);
  const isLoadingTransactions = useSelector(
    (state) => state.transactions.isLoadingTransactions
  );

  const [tableBody, setTableBody] = useState([]);
  const [tableHead, setTableHead] = useState([]);

  const formik = useFormik({
    initialValues: {
      dateTo: "",
      dateFrom: "",
      fileType: "excel",
    },
  });

  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  useEffect(() => {
    setTableHead(tableHead1);
  }, [tableHead1]);

  const handleClick = () => {
    dispatch(getTransactionsExport({ fileType: formik.values.fileType }));
  };

  useEffect(() => {
    setTableBody(transactions);
  }, [transactions]);

  const loader = () => (
    <div className="d-flex justify-content-center mt-5">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  );

  const header = () => (
    <div className="row">
      <div className="col-sm-12">
        <div className="card-box paddingNone">
          <div className="row mb-2">
            <div className="col-md-12 d-flex align-items-center flex-wrap">
              <div className="text-sm-center form-inline">
                <label htmlFor="example-select">
                  File Type : &nbsp; &nbsp;{" "}
                </label>
                <div className="form-group mr-2">
                  <select
                    id="demo-foo-filter-status"
                    className="custom-select custom-select-sm"
                    name="fileType"
                    onChange={formik.handleChange}
                    value={formik.values.fileType}
                  >
                    <option value={"excel"}>Excel</option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light  custom-select-sm"
                  onClick={handleClick}
                >
                  <i className="mr-1">
                    <span style={{ color: "#fff" }}>Save File</span>
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const table = () => (
    <div className="row card-box">
      <>
        <div className="d-flex mb-2">
          {String(transactions) ? (
            <div className="mx-2">
              Number of Transactions: {transactions.length}
            </div>
          ) : null}
        </div>

        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Top transactions</h4>
          </div>
        </div>
      </>
      <TableComponent tableHead={tableHead} tableBody={tableBody} />
    </div>
  );

  return (
    <div id="wrapper" className="reports">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          {isLoadingTransactions ? (
            loader()
          ) : (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title">Transactions</h4>
                  </div>
                </div>
              </div>

              {header()}
              {table()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;

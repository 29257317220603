import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePrevious from "../../helpers/usePrevious";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { DebounceInput } from "react-debounce-input";
import {
  getCustomers,
  synchronizeBlacklist
} from "../../store/actions/customers.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import TableComponent from "../../components/tableComponent";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { StorageService } from "../../services/storage.service";

const Customers = () => {
  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const { role, privileges } = JSON.parse(StorageService.getUser()) || {};
  const [search, setSearch] = useState(searchQuery || "");
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setSearch(searchQuery || "");
  });
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const { isLoadingCustomer, isSynchronized } = useSelector(
    state => state.customers
  );

  const dispatch = useDispatch();
  const customers = useSelector(state => state.customers.customers);
  const countCustomers = useSelector(state => state.customers.countCustomers);

  const previousSearchValue = usePrevious(search);
  const previousCurrentPageValue = usePrevious(currentPage);

  useEffect(() => {
    if (
      previousSearchValue !== undefined &&
      search !== undefined &&
      previousSearchValue !== search
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }

    dispatch(
      getCustomers({
        pagination: { page: currentPage, limit: rowsPerPage },
        search
      })
    );
  }, [dispatch, currentPage, rowsPerPage, search]);

  const tableHead = [
    {
      name: "Blacklist",
      key: "blacklist"
    },
    {
      name: "Name",
      key: "firstName",
      lastName: "lastName"
    },
    {
      name: "Mobile Number",
      key: "phone"
    },
    {
      name: "Email",
      key: "email"
    },
    {
      name: "Order History",
      key: "details",
      path: "customers",
      param: "foodicsId"
    },
    {
      name: "Points History",
      key: "details",
      path: "pointHistory",
      param: "id"
    },
    {
      name: "Customer Details",
      key: "details",
      path: "customer",
      param: "id"
    },
    {
      name: "Awards History",
      key: "details",
      path: "awardHistory",
      param: "id"
    },
    {
      name: "Transactions Details",
      key: "details",
      path: "customerTransactions",
      param: "id"
    }
  ];
  if (role === "admin" || privileges.manageWallets) {
    tableHead.push({
      name: "Add credit",
      key: "addCredit",
      path: "customerAddCredit",
      param: "id"
    });
  }

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Customers</h4>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="card-box d-flex">
                <h5 className="mr-3">
                  Do you want to synchronize the customer blacklist with
                  Foodics?
                </h5>
                <LoadingButton
                  isLoadingBtn={!isSynchronized}
                  btnContent="Synchronize"
                  onClick={() => dispatch(synchronizeBlacklist())}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="form-inline">
                          <div className="form-group mb-2">
                            <label htmlFor="inputPassword2" className="sr-only">
                              Search
                            </label>
                            <DebounceInput
                              type="search"
                              minLength={1}
                              className="form-control"
                              debounceTimeout={800}
                              value={searchInputValue}
                              onChange={e => {
                                setSearchInputValue(e.target.value);
                                setSearchQuery(e.target.value);
                              }}
                              placeholder="Search by phone..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoadingCustomer ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-lg"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <TableComponent
                        tableHead={tableHead}
                        tableBody={customers}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Pagination
                  totalCount={countCustomers}
                  rows={rowsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setPageQuery}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;

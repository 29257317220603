import React, { Fragment } from "react";
const PointList = props => {
  return props.taskList.map((val, idx) => (
    <Fragment key={val + idx}>
      <tr className="d-flex">
        {Number(props.formik.values.pointType) === 1 ? (
          <td className="col-md-4 pl-0 pr-0 border-0">
            <input
              type="number"
              className="form-control"
              id={`pointCondition[${idx}].numberOfOrders`}
              name={`pointCondition[${idx}].numberOfOrders`}
              value={props.formik.values.pointCondition[idx].numberOfOrders}
              onChange={props.formik.handleChange}
              placeholder={"Number of Orders"}
              onClick={() => {
                props.formik.setFieldTouched(
                  `pointCondition[${idx}].numberOfOrders`,
                  true
                );
              }}
              required
            />
          </td>
        ) : (
          <td className="col-md-4 pl-0 pr-0 border-0">
            <input
              type="number"
              className="form-control"
              id={`pointCondition[${idx}].numberOfProducts`}
              name={`pointCondition[${idx}].numberOfProducts`}
              value={props.formik.values.pointCondition[idx].numberOfProducts}
              onChange={props.formik.handleChange}
              placeholder={"Number of Products"}
              onClick={() => {
                props.formik.setFieldTouched(
                  `pointCondition[${idx}].numberOfProducts`,
                  true
                );
              }}
              required
            />
          </td>
        )}

        <td className="col-md-4 pr-0 pl-0 border-0">
          <input
            type="number"
            className="form-control"
            id={`pointCondition[${idx}].numberOfPoints`}
            name={`pointCondition[${idx}].numberOfPoints`}
            value={props.formik.values.pointCondition[idx].numberOfPoints}
            onChange={props.formik.handleChange}
            placeholder="Number of Points"
            onClick={() => {
              props.formik.setFieldTouched(
                `pointCondition[${idx}].numberOfPoints`,
                true
              );
            }}
            required
          />
        </td>
        <td className="col-md-3 border-0">
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              Add Condition
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-danger text-center"
              onClick={() => props.delete(val)}
            >
              X
            </button>
          )}
        </td>
      </tr>
      {Number(props.formik.values.pointType) === 2 && (
        <tr className="row">
          <td className="col-md-12 text-left">
            <div className="form-group">
              <label
                htmlFor={`pointCondition[${idx}].productId`}
                className="control-label"
              >
                Select Product
              </label>
              <select
                className="custom-select custom-select-sm"
                value={props.formik.values.pointCondition[idx].productId}
                id={`pointCondition[${idx}].productId`}
                name={`pointCondition[${idx}].productId`}
                onChange={props.formik.handleChange}
                required
              >
                <option value="">--</option>
                {props.products.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.name.en}
                  </option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  ));
};
export default PointList;

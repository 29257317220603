import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import classNames from "classnames";
import { getModifiers } from "../../store/actions/products.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModifierPage from "../../components/modifierPage";
import Pagination from "../../components/pagination/pagination";

const Modifiers = () => {
  const dispatch = useDispatch();
  const modifiers = useSelector(state => state.products.modifiers);
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const isLoadingModifiers = useSelector(
    state => state.products.isLoadingModifiers
  );
  const countModifiers = useSelector(state => state.products.countModifiers);

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getModifiers({
        pagination: { page: currentPage || 1, limit: rowsPerPage || 10 }
      })
    );
  }, [dispatch, currentPage, rowsPerPage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Modifiers</h4>
                </div>
              </div>
            </div>

            {isLoadingModifiers ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : modifiers.length ? (
              <div className="row">
                <div className="col-xl-12">
                  {modifiers &&
                    modifiers.map((modifier, index) => (
                      <ModifierPage
                        key={modifier.id}
                        modifier={modifier}
                        index={index}
                      />
                    ))}
                  <Pagination
                    totalCount={countModifiers}
                    rows={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setPageQuery}
                  />
                </div>
              </div>
            ) : (
              <div className="notFound">Not Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modifiers;

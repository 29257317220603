import React, { useEffect, useState } from "react";
import classNames from "classnames";

const Pagination = ({
  currentPage = 1,
  totalCount,
  rows = 10,
  setCurrentPage
}) => {
  const firstPage = 1;
  const lastPage = Math.ceil(totalCount / rows);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const pageNumbers = [];
    let from = 1;
    let to = Math.ceil(totalCount / rows);
    if (to > 7) {
      if (currentPage < 4) {
        to = 7;
      } else if (to - currentPage <= 3) {
        to = Math.ceil(totalCount / rows);
        from = Math.ceil(totalCount / rows) - 6;
      } else if (currentPage >= 4) {
        from = currentPage - 3;
        to = currentPage + 3;
      }
    }
    for (let i = from; i <= to; i++) {
      pageNumbers.push(i);
    }
    setPageNumbers(pageNumbers);
  }, [currentPage, rows, setCurrentPage, totalCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  if (pageNumbers.length > 1) {
    return (
      <ul className="pagination pagination-rounded justify-content-end my-2">
        <li className="page-item">
          <span
            className="page-link"
            onClick={() =>
              currentPage !== firstPage && setCurrentPage(currentPage - 1)
            }
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
            <span className="sr-only">Previous</span>
          </span>
        </li>

        {pageNumbers.map((singlePage, index) => (
          <li
            key={index}
            className={classNames(
              "page-item",
              currentPage === singlePage && "active"
            )}
          >
            <span
              className="page-link"
              onClick={() => setCurrentPage(singlePage)}
            >
              {singlePage}
            </span>
          </li>
        ))}

        <li className="page-item">
          <span
            className="page-link"
            onClick={() =>
              currentPage !== lastPage && setCurrentPage(currentPage + 1)
            }
            aria-label="Next"
          >
            <span aria-hidden="true">»</span>
            <span className="sr-only">Next</span>
          </span>
        </li>
      </ul>
    );
  } else {
    return null;
  }
};

export default Pagination;

export const pointsActionsTypes = {
  GET_REWARDS: "GET_REWARDS",
  GET_REWARDS_SUCCESS: "GET_REWARDS_SUCCESS",
  GET_REWARDS_FAILURE: "GET_REWARDS_FAILURE",

  SET_REWARD: "SET_REWARD",
  SET_REWARD_SUCCESS: "SET_REWARD_SUCCESS",
  SET_REWARD_FAILURE: "SET_REWARD_FAILURE",

  UPDATE_REWARD: "UPDATE_REWARD",
  UPDATE_REWARD_SUCCESS: "UPDATE_REWARD_SUCCESS",
  UPDATE_REWARD_FAILURE: "UPDATE_REWARD_FAILURE",

  DELETE_REWARD: "DELETE_REWARD",
  DELETE_REWARD_SUCCESS: "DELETE_REWARD_SUCCESS",
  DELETE_REWARD_FAILURE: "DELETE_REWARD_FAILURE",

  GET_OFFERS: "GET_GET_OFFERSREWARDS",
  GET_OFFERS_SUCCESS: "GET_OFFERS_SUCCESS",
  GET_OFFERS_FAILURE: "GET_OFFERS_FAILURE",

  SET_OFFER: "SET_OFFER",
  SET_OFFER_SUCCESS: "SET_OFFER_SUCCESS",
  SET_OFFER_FAILURE: "SET_OFFER_FAILURE",

  DELETE_OFFER: "DELETE_OFFER",
  DELETE_OFFER_SUCCESS: "DELETE_OFFER_SUCCESS",
  DELETE_OFFER_FAILURE: "DELETE_OFFER_FAILURE",

  GET_POINTS: "GET_POINTS",
  GET_POINTS_SUCCESS: "GET_POINTS_SUCCESS",
  GET_POINTS_FAILURE: "GET_POINTS_FAILURE",

  SET_POINT: "SET_POINT",
  SET_POINT_SUCCESS: "SET_POINT_SUCCESS",
  SET_POINT_FAILURE: "SET_POINT_FAILURE",

  UPDATE_POINT: "UPDATE_POINT",
  UPDATE_POINT_SUCCESS: "UPDATE_POINT_SUCCESS",
  UPDATE_POINT_FAILURE: "UPDATE_POINT_FAILURE",

  GET_POINT_HISTORY: "GET_POINT_HISTORY",
  GET_POINT_HISTORY_SUCCESS: "GET_POINT_HISTORY_SUCCESS",
  GET_POINT_HISTORY_FAILURE: "GET_POINT_HISTORY_FAILURE",

  ASSIGN_POINT: "ASSIGN_POINT",
  ASSIGN_POINT_SUCCESS: "ASSIGN_POINT_SUCCESS",
  ASSIGN_POINT_FAILURE: "ASSIGN_POINT_FAILURE",

  RESET_ERROR: "RESET_ERROR",

  GET_POINTS_RIYAL: "GET_POINTS_RIYAL",
  GET_POINTS_RIYAL_SUCCESS: "GET_POINTS_RIYAL_SUCCESS",
  GET_POINTS_RIYAL_FAILURE: "GET_POINTS_RIYAL_FAILURE",

  UPDATE_POINTS_RIYAL: "UPDATE_POINTS_RIYAL",
  UPDATE_POINTS_RIYAL_SUCCESS: "UPDATE_POINTS_RIYAL_SUCCESS",
  UPDATE_POINTS_RIYAL_FAILURE: "UPDATE_POINTS_RIYAL_FAILURE",

  GET_POINTS_REPORT: "GET_POINTS_REPORT",
  GET_POINTS_REPORT_SUCCESS: "GET_POINTS_REPORT_SUCCESS",
  GET_POINTS_REPORT_FAILURE: "GET_POINTS_REPORT_FAILURE",

  GET_AWARD_HISTORY: "GET_AWARD_HISTORY",
  GET_AWARD_HISTORY_SUCCESS: "GET_AWARD_HISTORY_SUCCESS",
  GET_AWARD_HISTORY_FAILURE: "GET_AWARD_HISTORY_FAILURE"
};

export const getPointsRiyal = () => {
  return {
    type: pointsActionsTypes.GET_POINTS_RIYAL
  };
};

export const updatePointsRiyal = data => {
  return {
    type: pointsActionsTypes.UPDATE_POINTS_RIYAL,
    payload: data
  };
};

export const getRewards = data => {
  return {
    type: pointsActionsTypes.GET_REWARDS,
    payload: data
  };
};

export const setReward = data => {
  return {
    type: pointsActionsTypes.SET_REWARD,
    payload: data
  };
};

export const updateReward = (id, data) => {
  return {
    type: pointsActionsTypes.UPDATE_REWARD,
    payload: { id, data }
  };
};

export const deleteReward = data => {
  return {
    type: pointsActionsTypes.DELETE_REWARD,
    payload: data
  };
};

export const getOffers = data => {
  return {
    type: pointsActionsTypes.GET_OFFERS,
    payload: data
  };
};

export const setOffer = data => {
  return {
    type: pointsActionsTypes.SET_OFFER,
    payload: data
  };
};

export const deleteOffer = data => {
  return {
    type: pointsActionsTypes.DELETE_OFFER,
    payload: data
  };
};

export const getPoints = data => {
  return {
    type: pointsActionsTypes.GET_POINTS,
    payload: data
  };
};

export const setPoint = data => {
  return {
    type: pointsActionsTypes.SET_POINT,
    payload: data
  };
};

export const updatePoint = data => {
  return {
    type: pointsActionsTypes.UPDATE_POINT,
    payload: data
  };
};

export const getPointHistory = data => {
  return {
    type: pointsActionsTypes.GET_POINT_HISTORY,
    payload: data
  };
};

export const assignPoint = data => {
  return {
    type: pointsActionsTypes.ASSIGN_POINT,
    payload: data
  };
};

export const resetError = () => {
  return {
    type: pointsActionsTypes.RESET_ERROR
  };
};

export const getPointsReport = data => {
  return {
    type: pointsActionsTypes.GET_POINTS_REPORT,
    payload: data
  };
};

export const getAwardHistory = data => {
  return {
    type: pointsActionsTypes.GET_AWARD_HISTORY,
    payload: data
  };
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import { getTransactions } from "../../store/actions/transactions.actions";

const Transactions = () => {
  const { id } = useParams();
  const transactions =
    useSelector((state) => state.transactions.transactions) || [];
  const [walletData, setWalletData] = useState({});
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector((state) => state.authorization.menuStatus)
  );

  useEffect(() => {
    dispatch(getTransactions());
  }, [getTransactions]);

  useEffect(() => {
    const index = transactions.findIndex((i) => i.id === id);
    if (index > -1) setWalletData(transactions[index]);
  }, [transactions]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Transactions Customer Details</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">First Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.firstName
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Last Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.lastName
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Spend:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet && walletData.wallet.spend
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Earned:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet && walletData.wallet.earned
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Created at:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={moment
                              .utc(
                                walletData.wallet &&
                                  walletData.wallet.customer.createdAt
                              )
                              .local()
                              .format("dddd, MMMM D, YYYY")}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Birthdate:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={moment
                              .utc(
                                walletData.wallet &&
                                  walletData.wallet.customer.birthdate
                              )
                              .local()
                              .format("dddd, MMMM D, YYYY")}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Blacklist:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              !!walletData.wallet.customer.blacklist
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Gender:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.gender
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Phone:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.phone
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Email:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.email
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Notes:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.notes
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Foodics Id:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.foodicsId
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Spent points:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.spentPoints
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Orders:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              walletData.wallet &&
                              walletData.wallet.customer.orders
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;

import { API_URL } from "../constants";
import axiosInstance from "./interceptor";

export const upsellApi = {
  getFixedUpsell() {
    return axiosInstance.get(`${API_URL}/upsell/fixed`);
  },

  setFixedUpsell(fixedUpsell) {
    const { dateTo, dateFrom, timeFrom, timeTo, product } = fixedUpsell;
    return axiosInstance.post(`${API_URL}/upsell/fixed`, {
      dateTo,
      dateFrom,
      timeFrom,
      timeTo,
      product
    });
  },

  deleteFixedUpsell(id) {
    return axiosInstance.delete(`${API_URL}/upsell/fixed/${id}`);
  },

  getRelatedUpsell() {
    return axiosInstance.get(`${API_URL}/upsell/related`);
  },

  setRelatedUpsell(relatedUpsell) {
    const { productUpsellId, productRelatedUpsellId } = relatedUpsell;

    return axiosInstance.post(`${API_URL}/upsell/related`, {
      productUpsellId,
      productRelatedUpsellId
    });
  },

  deleteRelatedUpsell(id) {
    return axiosInstance.delete(`${API_URL}/upsell/related/${id}`);
  }
};

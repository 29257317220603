import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  getSupervisors,
  updateSupervisors
} from "../../../store/actions/authorization.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

const PrivilegesData = [
  {
    name: "List All Branches",
    key: "branchesList"
  },
  {
    name: "Edit All Branches",
    key: "branchesEdit"
  },
  {
    name: "Edit Bulk Branches",
    key: "bulkBranchesEdit"
  },
  {
    name: "Sync All Branches",
    key: "branchesSync"
  },
  {
    name: "View Order",
    key: "order"
  },
  {
    name: "View All Orders",
    key: "orders"
  },
  {
    name: "Manage Order Refund",
    key: "manageOrderRefund"
  },
  {
    name: "Manage Menu and modifier",
    key: "menu"
  },
  {
    name: "Manage Sync Menus",
    key: "menuSync"
  },
  {
    name: "Manage Discount and Coupons",
    key: "discountsAndCoupons"
  },
  {
    name: "View Customers",
    key: "customers"
  },
  {
    name: "Manage Customers Wallets",
    key: "manageWallets"
  },
  {
    name: "Manage Customer Points",
    key: "managePoints"
  },
  {
    name: "Manage Feedback",
    key: "feedback"
  },
  {
    name: "Mange page’s contents",
    key: "contentPages"
  },
  {
    name: "Manage Food Truck",
    key: "foodTruck"
  }
];

export default function ModalSupervisors({
  extraData,
  modal,
  onClose,
  supervisor
}) {
  const dispatch = useDispatch();
  // There's a complication in the branches privilege, so this state property will control whether
  // the user will have the privilege or not, and the formic property will contain the details
  const [hasListBranchesPrivilege, setHasListBranchesPrivilege] = useState(
    supervisor.privileges.branchesList
  );
  const [hasEditBranchesPrivilege, setHasEditBranchesPrivilege] = useState(
    supervisor.privileges.branchesEdit
  );

  const formik = useFormik({
    initialValues: {
      privileges: supervisor.privileges,
      id: supervisor.id
    },
    onSubmit: values => {
      const {
        branchesList,
        singleBranch,
        branchesEdit,
        singleBranchEdit
      } = values.privileges;
      // The passed values here are ids, where id == 2 is when all branches is selected.
      // for the single branch the latest id value change is held in the  (branchesList,branchesEdit,branchesSync ) that's why we check to see it's an id (string) otherwise
      // the value is held from the database and hasn't changed recently in the form

      dispatch(
        updateSupervisors({
          ...values,
          privileges: {
            ...values.privileges,
            branchesList: hasListBranchesPrivilege,
            singleBranch:
              branchesList === "2" || !hasListBranchesPrivilege
                ? ""
                : typeof branchesList === "string"
                ? branchesList
                : singleBranch,
            branchesEdit: hasEditBranchesPrivilege,
            singleBranchEdit:
              branchesEdit === "2" || !hasEditBranchesPrivilege
                ? ""
                : typeof branchesEdit === "string"
                ? branchesEdit
                : singleBranchEdit
          }
        })
      );
      formik.resetForm();
      onClose();
      setTimeout(() => {
        dispatch(getSupervisors({ page: 1, limit: 10 }));
      }, 2000);
    }
  });

  const getDefaultBranchListValue = () => {
    // get the  single branch name and return it, otherwise it's not a single branch
    const singleBranchId = supervisor.privileges.singleBranch;

    if (supervisor.privileges.singleBranch) {
      const branchIndex = extraData.findIndex(
        branch => branch.id === singleBranchId
      );

      if (branchIndex > -1) {
        return extraData[branchIndex].name.en;
      }
    }
    return "All";
  };

  const getDefaultBranchEditValue = () => {
    // get the  single branch name and return it, otherwise it's not a single branch
    const singleBranchId = supervisor.privileges.singleBranchEdit;

    if (supervisor.privileges.singleBranchEdit) {
      const branchIndex = extraData.findIndex(
        branch => branch.id === singleBranchId
      );
      if (branchIndex > -1) {
        return extraData[branchIndex].name.en;
      }
    }
    return "All";
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        onClose();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          onClose();
          formik.resetForm();
        }}
      >
        Edit Privileges
      </ModalHeader>
      <form
        onSubmit={formik.handleSubmit}
        className="modal-body pb-1 pr-4 pl-4  "
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="control-label">Privileges</label>
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th colSpan={2}>Privilege</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PrivilegesData.map(privilege => (
                      <tr key={privilege.key}>
                        {privilege.key == "branchesList" ? (
                          <>
                            <td>
                              <div className="checkbox checkbox-dark mb-2">
                                <input
                                  type="checkbox"
                                  id={"listBranchesPrivilege"}
                                  name={"listBranchesPrivilege"}
                                  onChange={e =>
                                    setHasListBranchesPrivilege(
                                      e.target.checked
                                    )
                                  }
                                  checked={hasListBranchesPrivilege}
                                  value={hasListBranchesPrivilege}
                                />
                                <label
                                  htmlFor={"listBranchesPrivilege"}
                                ></label>
                              </div>
                            </td>
                            <td>List Branches</td>
                            <td>
                              <select
                                id={`privileges[${privilege.key}]`}
                                name={`privileges[${privilege.key}]`}
                                onChange={formik.handleChange}
                                disabled={
                                  !(
                                    formik.values.privileges &&
                                    formik.values.privileges[privilege.key]
                                  ) && !hasListBranchesPrivilege
                                }
                              >
                                {extraData.map(item => (
                                  <option
                                    value={item.id}
                                    defaultValue={getDefaultBranchListValue()}
                                    selected={
                                      item.name.en ==
                                      getDefaultBranchListValue()
                                    }
                                    key={item.id}
                                  >
                                    {item.name.en}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </>
                        ) : privilege.key == "branchesEdit" ? (
                          <>
                            <td style={{ textAlign: "center" }}>
                              <div className="checkbox checkbox-dark mb-3">
                                <input
                                  type="checkbox"
                                  id={"editBranchesPrivilege"}
                                  name={"editBranchesPrivilege"}
                                  onChange={e => {
                                    setHasEditBranchesPrivilege(
                                      e.target.checked
                                    );
                                  }}
                                  checked={hasEditBranchesPrivilege}
                                  value={hasEditBranchesPrivilege}
                                />
                                <label
                                  htmlFor={"editBranchesPrivilege"}
                                ></label>
                              </div>
                            </td>
                            <td>Edit branches</td>
                            <td>
                              <select
                                id={`privileges[${privilege.key}]`}
                                name={`privileges[${privilege.key}]`}
                                onChange={formik.handleChange}
                                disabled={
                                  !(
                                    formik.values.privileges &&
                                    formik.values.privileges[privilege.key]
                                  ) && !hasEditBranchesPrivilege
                                }
                              >
                                {extraData.map(item => (
                                  <option
                                    value={item.id}
                                    defaultValue={getDefaultBranchEditValue()}
                                    selected={
                                      item.name.en ==
                                      getDefaultBranchEditValue()
                                    }
                                    key={item.id}
                                  >
                                    {item.name.en}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <div className="checkbox checkbox-dark mb-2">
                                <input
                                  type="checkbox"
                                  id={`privileges[${privilege.key}]`}
                                  name={`privileges[${privilege.key}]`}
                                  onChange={formik.handleChange}
                                  checked={
                                    formik.values.privileges &&
                                    formik.values.privileges[privilege.key]
                                  }
                                  value={privilege.key}
                                />
                                <label
                                  htmlFor={`privileges[${privilege.key}]`}
                                ></label>
                              </div>
                            </td>
                            <td colSpan={2}>{privilege.name}</td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              onClose();
              formik.resetForm();
            }}
          >
            Close
          </Button>{" "}
          <Button type="submit" color="success">
            Save Changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import { call, put, takeLatest } from "redux-saga/effects";
import { customersApi } from "../../services/customers.service";
import { customersActionsTypes } from "../actions/customers.actions";

function* getCustomers(action) {
  try {
    const result = yield call(
      customersApi.getCustomers,
      action.payload.pagination,
      action.payload.search
    );
    yield put({
      type: customersActionsTypes.GET_CUSTOMERS_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: customersActionsTypes.GET_CUSTOMERS_FAILURE
    });
  }
}

function* synchronizeBlacklist() {
  try {
    yield call(customersApi.synchronizeBlacklist);
    yield put({
      type: customersActionsTypes.SYNCHRONIZE_BLACKLIST_SUCCESS
    });
  } catch (error) {
    yield put({
      type: customersActionsTypes.SYNCHRONIZE_BLACKLIST_FAILURE
    });
  }
}

function* addWalletCredit(action) {
  try {
    const result = yield call(customersApi.addWalletCredit, action.payload);
    yield put({
      type: customersActionsTypes.ADD_WALLET_CREDIT_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    console.log("Error:  ", error);
    yield put({
      type: customersActionsTypes.ADD_WALLET_CREDIT_FAILURE,
      payload: error.data.error || error.data.message
    });
  }
}

function* customersSaga() {
  yield takeLatest([customersActionsTypes.GET_CUSTOMERS], getCustomers);
  yield takeLatest([customersActionsTypes.ADD_WALLET_CREDIT], addWalletCredit);
  yield takeLatest(
    [customersActionsTypes.SYNCHRONIZE_BLACKLIST],
    synchronizeBlacklist
  );
}

export default customersSaga;

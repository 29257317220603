import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import {
  getCountriesList,
  synchronizeBranches,
  getBranches
} from "../../store/actions/branches.actions";
import BranchPage from "../../components/branch/branch";
import Filter from "../../components/filter/filter";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import LoadingButton from "../../components/loadingButton/loadingButton";
import "./index.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { StorageService } from "../../services/storage.service";

const BranchPages = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const user = JSON.parse(StorageService.getUser()) || {};

  const allBranches = useSelector(state => state.branches.branches || []);
  const countries = useSelector(state => state.branches.countries || []);
  const noContent =
    countries[0] &&
    countries[0].cities &&
    !countries[0].cities.some(city => city.branches.length);
  const loadingBranches = useSelector(state => state.branches.loadingBranches);
  const isLoadingBtn = useSelector(
    state => state.branches.isSynchronizedBranches
  );

  useEffect(() => {
    // TODO: to see if this request has an effect, otherwise delete it, I discovered that it's being used
    dispatch(
      getBranches({
        pagination: { page: "", limit: "" }
      })
    );
  }, [dispatch]);

  const { role, privileges } = user;

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Branches</h4>
                </div>
              </div>
              <div className="col-12">
                <div className="card-box d-flex">
                  {/* TODO: Very bad and not readable code, will be refactored after Updating to new react version */}
                  {(role === "admin" ||
                    (role === "supervisor" &&
                      privileges &&
                      privileges.branchesSync)) && (
                    <>
                      <h5 className="mr-3">
                        Do you want to synchronize Branches with Foodics ?
                      </h5>
                      <LoadingButton
                        isLoadingBtn={isLoadingBtn}
                        btnContent="Synchronize"
                        onClick={() => dispatch(synchronizeBranches())}
                      />
                    </>
                  )}
                  {/* TODO: Very bad and not readable code, will be refactored after Updating to new react version */}
                  {!isLoadingBtn &&
                    allBranches.length > 0 &&
                    (role === "admin" ||
                      (role === "supervisor" &&
                        privileges &&
                        privileges.bulkBranchesEdit)) && (
                      <div className="ml-auto ">
                        <Link
                          className="bg-warning p-1 m-1 font-8 "
                          to={`/branches/bulk/status`}
                          branches={allBranches}
                        >
                          Bulk Status Edit {"  "}
                          <i
                            style={{ cursor: "pointer" }}
                            className="font-12 cursor text-muted  mdi mdi-pencil"
                          ></i>
                        </Link>
                        <Link
                          className="bg-warning p-1 m-1 "
                          to={`/branches/bulk/working-hrs`}
                          branches={allBranches}
                        >
                          Bulk Working HRs Edit {"  "}
                          <i
                            style={{ cursor: "pointer" }}
                            className="font-12 cursor text-muted  mdi mdi-pencil"
                          ></i>
                        </Link>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-12">
                <div className="card-box d-flex justify-content-center">
                  <Filter
                    action={getCountriesList}
                    isAvailable={true}
                    showInMobile={true}
                  />
                </div>
              </div>
            </div>

            {loadingBranches ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : countries.length ? (
              <div className="row">
                {countries &&
                  countries.map(
                    country =>
                      country.cities.length > 0 &&
                      country.cities.some(city => city.branches.length > 0) &&
                      country.cities.map(
                        city =>
                          city.branches.length > 0 &&
                          city.branches.map(branch => (
                            <BranchPage
                              user={user}
                              key={branch.id}
                              allBranches={allBranches}
                              branch={branch}
                              city={city}
                            />
                          ))
                      )
                  )}
              </div>
            ) : (
              <div className="notFound">Not Found</div>
            )}
            {!loadingBranches && noContent && (
              <div className="notFound">Not Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchPages;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import FilterContent from "../../components/contentPage/filterContent/filterContent";
import ContactUs from "../../components/contentPage/contactUs/contactUs";
import AboutUs from "../../components/contentPage/aboutUs/aboutUs";
import PrivacyAndPolicy from "../../components/contentPage/privacyAndPolicy/privacyAndPolicy";
import Advertisement from "../../components/contentPage/advertisement/advertisement";

const pagesContent = () => {
  const [filter, setFilter] = useState("contactUs");
  const [lang, setLang] = useState("en");
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Pages Content</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  {
                    <FilterContent
                      setFilter={setFilter}
                      setLang={setLang}
                      filter={filter}
                    />
                  }

                  {filter === "contactUs" && <ContactUs />}
                  {filter === "aboutUs" && <AboutUs lang={lang} />}
                  {filter === "privacyAndPolicy" && (
                    <PrivacyAndPolicy lang={lang} />
                  )}
                  {filter === "advertisement" && <Advertisement />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pagesContent;

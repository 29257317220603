import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { StorageService } from "../../services/storage.service";
import { logout } from "../../store/actions/authorization.actions";

const Logout = () => {
  const dispatch = useDispatch();
  if (StorageService.getToken()) {
    localStorage.clear();
    dispatch(logout());
  }
  return <Redirect to="/login" />;
};

export default Logout;

import { call, put, takeLatest, select } from "redux-saga/effects";
import { authorizationsApi } from "../../services/authorization.service";
import { StorageService } from "../../services/storage.service";
import { authorizationActionsTypes } from "../actions/authorization.actions";

const deletedSupervisor = state => ({
  id: state.authorization.deletedSupervisor
});

function* login(action) {
  try {
    const result = yield call(authorizationsApi.login, action.payload);
    yield put({
      type: authorizationActionsTypes.LOGIN_SUCCESS,
      payload: result.data
    });
  } catch ({ response }) {
    yield put({
      type: authorizationActionsTypes.LOGIN_FAILURE,
      payload: response.data
    });
  }
}

function* getSupervisors(action) {
  try {
    const result = yield call(authorizationsApi.getSupervisors, action.payload);
    yield put({
      type: authorizationActionsTypes.GET_SUPERVISORS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: authorizationActionsTypes.GET_SUPERVISORS_FAILURE
    });
  }
}

function* setSupervisors(action) {
  try {
    const result = yield call(authorizationsApi.setSupervisors, action.payload);
    yield put({
      type: authorizationActionsTypes.SET_SUPERVISORS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: authorizationActionsTypes.SET_SUPERVISORS_FAILURE,
      payload: error.data.message
    });
  }
}

function* updateSupervisors(action) {
  try {
    const result = yield call(
      authorizationsApi.updateSupervisors,
      action.payload
    );
    yield put({
      type: authorizationActionsTypes.UPDATE_SUPERVISORS_SUCCESS,
      payload: action.payload || result.data
    });
  } catch (error) {
    yield put({
      type: authorizationActionsTypes.UPDATE_SUPERVISORS_FAILURE
    });
  }
}

function* deleteSupervisors(action) {
  const data = yield select(deletedSupervisor);
  try {
    yield call(authorizationsApi.deleteSupervisors, action.payload);
    yield put({
      type: authorizationActionsTypes.DELETE_SUPERVISORS_SUCCESS,
      payload: data.id
    });
  } catch (error) {
    yield put({
      type: authorizationActionsTypes.DELETE_SUPERVISORS_FAILURE
    });
  }
}

function* getUser() {
  try {
    const result = yield call(authorizationsApi.getUser);
    StorageService.setUser(result.data.data);
    yield put({
      type: authorizationActionsTypes.GET_USER_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: authorizationActionsTypes.GET_USER_FAILURE
    });
  }
}

function* authorizationSaga() {
  yield takeLatest([authorizationActionsTypes.LOGIN], login);
  yield takeLatest([authorizationActionsTypes.GET_SUPERVISORS], getSupervisors);
  yield takeLatest([authorizationActionsTypes.SET_SUPERVISORS], setSupervisors);
  yield takeLatest(
    [authorizationActionsTypes.UPDATE_SUPERVISORS],
    updateSupervisors
  );
  yield takeLatest(
    [authorizationActionsTypes.DELETE_SUPERVISORS],
    deleteSupervisors
  );
  yield takeLatest([authorizationActionsTypes.GET_USER], getUser);
}

export default authorizationSaga;

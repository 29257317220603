import axiosInstance from "../services/interceptor";

export const discountsApi = {
  getDiscounts(params) {
    const { page, limit } = params;
    return axiosInstance.post(`/discounts?page=${page}&limit=${limit}`);
  },

  getDiscount(id) {
    return axiosInstance.get(`/discounts/${id}`);
  },

  updateDiscount(discount) {
    return axiosInstance.put(`/discounts/${discount.id}`, discount);
  },

  getCoupons(params, data) {
    return axiosInstance.post("/discounts/coupons", { ...data, ...params });
  },

  getCoupon(id) {
    return axiosInstance.get(`/discounts/coupon/${id}`);
  },

  updateCoupon(coupon) {
    return axiosInstance.put(`/discounts/coupon/${coupon.id}`, coupon);
  }
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import {
  getPoints,
  updatePoint,
  getPointsRiyal,
  updatePointsRiyal
} from "../../store/actions/points.actions";
import { getProducts } from "../../store/actions/products.actions";
import { useQueryParam, NumberParam } from "use-query-params";
import { useFormik } from "formik";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import FilterPoints from "../../components/filterPoints";
import Pagination from "../../components/pagination/pagination";
import ModalPoint from "../../components/modal/modalPoint";
import TableComponent from "../../components/tableComponent";
import "./index.scss";
import _ from "lodash";

const Points = ({ history }) => {
  const dispatch = useDispatch();
  const [pointType, setPointType] = useState(1);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(2);
  const [point, setPoint] = useState();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const { points, pointsRiyal } = useSelector(state => state.points);
  const pointConditions = useSelector(
    state => state.points.points.pointConditions
  );

  const countPoints = useSelector(state => state.points.countPoints);
  const products = useSelector(state => state.products.products);
  const isLoading = useSelector(state => state.points.isLoadingPoints);

  const formik = useFormik({
    initialValues: {
      expiration: (points.expiration && points.expiration.split(":")) || [
        "00",
        "00",
        "00",
        "00",
        "00"
      ],
      str: "",
      editable: false,
      year: "00",
      month: "00",
      day: "00",
      hour: "00",
      minute: "00",
      pointsRiyal,
      pointsEditable: false
    },
    enableReinitialize: true
  });

  const tableHead = [
    Number(points.pointType) === 1
      ? {
          name: "Number of Orders",
          key: "numberOfOrders"
        }
      : {
          name: "Number of Products",
          key: "numberOfProducts"
        },
    {
      name: "Number of Points",
      key: "numberOfPoints"
    },

    {
      name: "Edit",
      key: "editWithCallback",
      onClick: body => {
        setPoint(body);
        toggle();
      }
    }
  ];

  if (+points.pointType === 2) {
    tableHead.unshift({
      name: "Name",
      key: "pointName",
      value: points.pointType,
      products
    });
  }

  const handleDuration = (value, id) => {
    let minuteValue = document.getElementById(id).value;
    if (minuteValue.length < 2) {
      document.getElementById(id).value = "0" + minuteValue;
      formik.setFieldValue(id, "0" + value);
    } else {
      formik.setFieldValue(id, value);
    }
  };

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: { limit: 100, page: 1 },
        search: "",
        filteredCategory: ""
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getPoints({
        type: pointType,
        pagination: {
          page: currentPage || 1,
          limit: rowsPerPage || 10
        }
      })
    );
    dispatch(getPointsRiyal());
  }, [pointType, currentPage, rowsPerPage]);

  useEffect(() => {
    formik.setFieldValue("year", formik.values.expiration[0]);
    formik.setFieldValue("month", formik.values.expiration[1]);
    formik.setFieldValue("day", formik.values.expiration[2]);
    formik.setFieldValue("hour", formik.values.expiration[3]);
    formik.setFieldValue("day", formik.values.expiration[4]);
  }, [formik.values.expiration]);

  useEffect(() => {
    formik.setFieldValue(
      "str",
      ...[
        formik.values.year +
          ":" +
          formik.values.month +
          ":" +
          formik.values.day +
          ":" +
          formik.values.hour +
          ":" +
          formik.values.minute
      ]
    );
  }, [
    formik.values.year,
    formik.values.month,
    formik.values.day,
    formik.values.hour,
    formik.values.minute
  ]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Points</h4>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-box">
                    <div className="text-md-right">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                        onClick={() => {
                          if (_.isEmpty(points)) {
                            history.push("createPoint");
                          } else {
                            history.push(`updatePoint/${pointType}`);
                          }
                        }}
                      >
                        <i className="mdi mdi-plus mr-1"></i>{" "}
                        {_.isEmpty(points) ? "New" : "Update"}
                      </button>
                    </div>
                    <div className="d-flex">
                      <FilterPoints
                        setFilter={setPointType}
                        value={pointType}
                      />
                      <div className="row mb-2">
                        <div className="col-md-12">
                          <div className="col-12 text-sm-center form-inline">
                            <label
                              htmlFor="expiration"
                              className="control-label mr-2"
                            >
                              Point Duration:
                            </label>

                            <div className="form-group mr-2">
                              <form id="duration" className="duration">
                                <input
                                  id="year"
                                  type="number"
                                  min="0"
                                  max="99"
                                  placeholder="00"
                                  className={classNames(
                                    "form-control custom-select-sm",
                                    !formik.values.editable && "border-0"
                                  )}
                                  value={formik.values.year || ""}
                                  onChange={e =>
                                    handleDuration(e.target.value, "year")
                                  }
                                  disabled={!formik.values.editable}
                                />{" "}
                                : &nbsp;
                                <input
                                  id="month"
                                  type="number"
                                  min="0"
                                  max="99"
                                  placeholder="00"
                                  className={classNames(
                                    "form-control custom-select-sm",
                                    !formik.values.editable && "border-0"
                                  )}
                                  value={formik.values.month || ""}
                                  onChange={e =>
                                    handleDuration(e.target.value, "month")
                                  }
                                  disabled={!formik.values.editable}
                                />
                                : &nbsp;
                                <input
                                  id="day"
                                  type="number"
                                  min="0"
                                  max="99"
                                  placeholder="00"
                                  className={classNames(
                                    "form-control custom-select-sm",
                                    !formik.values.editable && "border-0"
                                  )}
                                  value={formik.values.day || ""}
                                  onChange={e =>
                                    handleDuration(e.target.value, "day")
                                  }
                                  disabled={!formik.values.editable}
                                />
                                : &nbsp;
                                <input
                                  id="hour"
                                  type="number"
                                  min="0"
                                  max="99"
                                  placeholder="00"
                                  className={classNames(
                                    "form-control custom-select-sm",
                                    !formik.values.editable && "border-0"
                                  )}
                                  value={formik.values.hour || ""}
                                  onChange={e =>
                                    handleDuration(e.target.value, "hour")
                                  }
                                  disabled={!formik.values.editable}
                                />
                                : &nbsp;
                                <input
                                  id="minute"
                                  type="number"
                                  min="0"
                                  max="99"
                                  placeholder="00"
                                  className={classNames(
                                    "form-control custom-select-sm",
                                    !formik.values.editable && "border-0"
                                  )}
                                  value={formik.values.minute || ""}
                                  onChange={e =>
                                    handleDuration(e.target.value, "minute")
                                  }
                                  disabled={!formik.values.editable}
                                />
                              </form>

                              {!formik.values.editable ? (
                                <i
                                  className="mdi mdi-pencil action-icon"
                                  onClick={() => {
                                    formik.setFieldValue("editable", true);
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="mdi mdi-check-circle action-icon"
                                  onClick={() => {
                                    formik.setFieldValue("editable", false);

                                    dispatch(
                                      updatePoint({
                                        expiration:
                                          formik.values.str || "00:00:00:00:00",
                                        pointId: points.id
                                      })
                                    );
                                  }}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12">
                          <div className="col-12 text-sm-center form-inline">
                            <label
                              htmlFor="expiration"
                              className="control-label mr-2"
                            >
                              1 Riyal =
                            </label>

                            <div className="form-group mr-2">
                              <form
                                id="pointsRiyal"
                                className="pointsRiyal w-25"
                              >
                                <input
                                  id="pointsRiyal"
                                  type="number"
                                  name="pointsRiyal"
                                  className={classNames(
                                    "form-control custom-select-sm w-100",
                                    !formik.values.pointsEditable && "border-0"
                                  )}
                                  value={formik.values.pointsRiyal || ""}
                                  onChange={formik.handleChange}
                                  disabled={!formik.values.pointsEditable}
                                />
                              </form>

                              {!formik.values.pointsEditable ? (
                                <i
                                  className="mdi mdi-pencil action-icon"
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "pointsEditable",
                                      true
                                    );
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="mdi mdi-check-circle action-icon"
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "pointsEditable",
                                      false
                                    );

                                    dispatch(
                                      updatePointsRiyal({
                                        points: formik.values.pointsRiyal
                                      })
                                    );
                                  }}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="MultipleChoices selected">
                      <div className="col-md-12">
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={pointConditions || points.pointConditions}
                        />
                        <Pagination
                          totalCount={countPoints}
                          rows={rowsPerPage}
                          currentPage={currentPage}
                          setCurrentPage={setPageQuery}
                        />
                      </div>
                    </div>
                    {point && (
                      <ModalPoint
                        products={products}
                        point={point}
                        pointType={points.pointType}
                        modal={modal}
                        onClose={() => {
                          toggle();
                          setPoint(undefined);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Points;

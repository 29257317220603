import { feedbackActionsTypes } from "../actions/feedback.actions";

const initialState = {
  feedbackQuestions: [],
  isLoadingFeedbackQuestions: false,
  isLoadingFeedbackQuestionsBtn: false,
  customerFeedback: [],
  isLoadingCustomerFeedback: false,
  countFeedbackQuestions: null,
  countCustomerFeedback: null,
  deletedFeedbackQuestion: "",
  reports: {},
  isLoadingReports: false
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case feedbackActionsTypes.GET_FEEDBACK_QUESTIONS: {
      return {
        ...state,
        isLoadingFeedbackQuestions: true
      };
    }

    case feedbackActionsTypes.GET_FEEDBACK_QUESTIONS_SUCCESS: {
      return {
        ...state,
        feedbackQuestions: action.payload.surveys,
        isLoadingFeedbackQuestions: false,
        countFeedbackQuestions: action.payload.meta.totalCount
      };
    }

    case feedbackActionsTypes.GET_FEEDBACK_QUESTIONS_FAILURE: {
      return {
        ...state,
        isLoadingFeedbackQuestions: false
      };
    }

    case feedbackActionsTypes.SET_FEEDBACK_QUESTIONS: {
      return {
        ...state,
        isLoadingFeedbackQuestionsBtn: true
      };
    }

    case feedbackActionsTypes.SET_FEEDBACK_QUESTIONS_SUCCESS: {
      return {
        ...state,
        isLoadingFeedbackQuestionsBtn: false
      };
    }

    case feedbackActionsTypes.SET_FEEDBACK_QUESTIONS_FAILURE: {
      return {
        ...state,
        isLoadingFeedbackQuestionsBtn: false
      };
    }

    case feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS: {
      return {
        ...state,
        isLoadingFeedbackQuestionsBtn: true
      };
    }

    case feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS_SUCCESS: {
      return {
        ...state,
        isLoadingFeedbackQuestionsBtn: false
      };
    }

    case feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS_FAILURE: {
      return {
        ...state,
        isLoadingFeedbackQuestionsBtn: false
      };
    }

    case feedbackActionsTypes.DELETE_FEEDBACK_QUESTIONS: {
      return {
        ...state,

        deletedFeedbackQuestion: action.payload
      };
    }

    case feedbackActionsTypes.DELETE_FEEDBACK_QUESTIONS_SUCCESS: {
      const deletedArr = state.feedbackQuestions.filter(
        feedaback => feedaback.id !== action.payload
      );
      return {
        ...state,
        feedbackQuestions: deletedArr
      };
    }

    case feedbackActionsTypes.GET_CUSTOMER_FEEDBACK: {
      return {
        ...state,
        isLoadingCustomerFeedback: true
      };
    }

    case feedbackActionsTypes.GET_CUSTOMER_FEEDBACK_SUCCESS: {
      return {
        ...state,
        customerFeedback: action.payload,
        isLoadingCustomerFeedback: false,
        countCustomerFeedback: action.payload.meta.totalCount
      };
    }

    case feedbackActionsTypes.GET_CUSTOMER_FEEDBACK_FAILURE: {
      return {
        ...state,
        isLoadingCustomerFeedback: false
      };
    }

    case feedbackActionsTypes.GET_REPORTS: {
      return {
        ...state,
        isLoadingReports: true
      };
    }

    case feedbackActionsTypes.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        isLoadingReports: false,
        reports: action.payload
      };
    }

    case feedbackActionsTypes.GET_REPORTS_FAILURE: {
      return {
        ...state,
        isLoadingReports: false
      };
    }

    default:
      return state;
  }
}

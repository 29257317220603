import React from "react";
import classNames from "classnames";

const FilterPoints = ({ setFilter, filter, setLang }) => (
  <div className="row mb-2">
    <div className="col-md-12">
      <div className={classNames("col-12 text-sm-center form-inline")}>
        <label htmlFor="example-select">Filter By: &nbsp; &nbsp; </label>
        <div className="form-group mr-2">
          <select
            id="demo-foo-filter-status"
            className="custom-select custom-select-sm"
            onChange={e => setFilter(e.target.value)}
          >
            <option value="contactUs">Contact Us</option>
            <option value="aboutUs">About Us</option>
            <option value="privacyAndPolicy">Privacy and Policy</option>
            {/* <option value="advertisement">Advertisement</option> */}
          </select>
          {filter !== "contactUs" && filter !== "advertisement" && (
            <select
              id="demo-foo-filter-status"
              className="custom-select custom-select-sm"
              onChange={e => setLang(e.target.value)}
            >
              <option value="en">English</option>
              <option value="ar">عربى</option>
            </select>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default FilterPoints;

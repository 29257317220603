import { call, put, takeLatest } from "redux-saga/effects";
import { ordersApi } from "../../services/orders.service";
import { ordersActionsTypes } from "../actions/orders.actions";

function* getOrders(action) {
  try {
    const result = yield call(ordersApi.getOrders, action.payload);
    yield put({
      type: ordersActionsTypes.GET_ORDERS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_ORDERS_FAILURE,
      payload: error.data.message
    });
  }
}

function* getSpecificOrder(action) {
  try {
    const result = yield call(ordersApi.getSpecificOrder, action.payload);
    yield put({
      type: ordersActionsTypes.GET_SPECIFIC_ORDER_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_SPECIFIC_ORDER_FAILURE
    });
  }
}

function* getDashboardStatus() {
  try {
    const result = yield call(ordersApi.getDashboardStatus);

    yield put({
      type: ordersActionsTypes.GET_DASHBOARD_STATUS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_DASHBOARD_STATUS_FAILURE
    });
  }
}

function* getMobileStatus() {
  try {
    const result = yield call(ordersApi.getMobileStatus);

    yield put({
      type: ordersActionsTypes.GET_MOBILE_STATUS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_MOBILE_STATUS_FAILURE
    });
  }
}

function* setOrderStatus(action) {
  try {
    const result = yield call(ordersApi.setOrderStatus, action.payload);
    yield put({
      type: ordersActionsTypes.SET_ORDER_STATUS_SUCCESS,
      payload: result.data.data.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.SET_ORDER_STATUS_SUCCESS
    });
  }
}

function* getCancelTime() {
  try {
    const result = yield call(ordersApi.getCancelTime);

    yield put({
      type: ordersActionsTypes.GET_CANCEL_TIME_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_CANCEL_TIME_FAILURE
    });
  }
}

function* setCancelTime(action) {
  try {
    const result = yield call(ordersApi.setCancelTime, action.payload);
    yield put({
      type: ordersActionsTypes.SET_CANCEL_TIME_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.SET_CANCEL_TIME_FAILURE
    });
  }
}

function* getDeliveryFee() {
  try {
    const result = yield call(ordersApi.getDeliveryFee);

    yield put({
      type: ordersActionsTypes.GET_DELIVERY_FEE_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_DELIVERY_FEE_FAILURE
    });
  }
}

function* setDeliveryFee(action) {
  try {
    const result = yield call(
      ordersApi.setDeliveryFee,
      action.payload.deliveryFee,
      action.payload.deliveryFixedFee,
      action.payload.minOrderPrice,
      action.payload.minimumAmountForDelivery
    );
    yield put({
      type: ordersActionsTypes.SET_DELIVERY_FEE_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.SET_DELIVERY_FEE_FAILURE
    });
  }
}

function* getAllOrders(action) {
  try {
    const result = yield call(ordersApi.getAllOrders, action.payload);
    yield put({
      type: ordersActionsTypes.GET_ALL_ORDERS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_ALL_ORDERS_FAILURE
    });
  }
}

function* getReceiptExpiration() {
  try {
    const result = yield call(ordersApi.getReceiptExpiration);
    yield put({
      type: ordersActionsTypes.GET_RECEIPT_EXPIRATION_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_RECEIPT_EXPIRATION_FAILURE
    });
  }
}

function* setReceiptExpiration(action) {
  try {
    const result = yield call(ordersApi.setReceiptExpiration, action.payload);
    yield put({
      type: ordersActionsTypes.SET_RECEIPT_EXPIRATION_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.SET_RECEIPT_EXPIRATION_FAILURE
    });
  }
}

function* getAllOrderRefunds(action) {
  try {
    const result = yield call(ordersApi.getAllOrderRefunds, action.payload);
    yield put({
      type: ordersActionsTypes.GET_ORDER_REFUNDS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.GET_ORDER_REFUNDS_FAILURE
    });
  }
}
function* setOrderRefund(action) {
  try {
    const result = yield call(ordersApi.setOrderRefund, action.payload);
    yield put({
      type: ordersActionsTypes.SET_ORDER_REFUND_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: ordersActionsTypes.SET_ORDER_REFUND_FAILURE,
      payload:
        error.data.message || "An error occurred while processing the refund."
    });
  }
}

function* ordersSaga() {
  yield takeLatest([ordersActionsTypes.GET_ORDERS], getOrders);
  yield takeLatest([ordersActionsTypes.GET_SPECIFIC_ORDER], getSpecificOrder);

  yield takeLatest(
    [ordersActionsTypes.GET_DASHBOARD_STATUS],
    getDashboardStatus
  );
  yield takeLatest([ordersActionsTypes.GET_MOBILE_STATUS], getMobileStatus);
  yield takeLatest([ordersActionsTypes.SET_ORDER_STATUS], setOrderStatus);
  yield takeLatest([ordersActionsTypes.GET_CANCEL_TIME], getCancelTime);
  yield takeLatest([ordersActionsTypes.SET_CANCEL_TIME], setCancelTime);
  yield takeLatest([ordersActionsTypes.GET_DELIVERY_FEE], getDeliveryFee);
  yield takeLatest([ordersActionsTypes.SET_DELIVERY_FEE], setDeliveryFee);
  yield takeLatest([ordersActionsTypes.GET_ALL_ORDERS], getAllOrders);
  yield takeLatest(
    [ordersActionsTypes.GET_RECEIPT_EXPIRATION],
    getReceiptExpiration
  );
  yield takeLatest(
    [ordersActionsTypes.SET_RECEIPT_EXPIRATION],
    setReceiptExpiration
  );
  // yield takeLatest([ordersActionsTypes.GET_ORDER_REFUNDS], getAllOrderRefunds);
  yield takeLatest([ordersActionsTypes.SET_ORDER_REFUND], setOrderRefund);
}

export default ordersSaga;

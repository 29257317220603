import { call, put, takeLatest, select } from "redux-saga/effects";
import { branchesApi } from "../../services/branches.service";
import { branchesActionsTypes } from "../actions/branches.actions";
import * as BranchesActions from "../actions/branches.actions";

const getCountryId = state => ({
  countryId: state.branches.countryId,
  cityId: state.branches.cityId,
  availableForDelivery: state.branches.availableForDelivery,
  showInMobileApp: state.branches.showInMobileApp
});

function* getCountries() {
  try {
    const result = yield call(branchesApi.getCountries);
    yield put({
      type: branchesActionsTypes.GET_COUNTRIES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.GET_COUNTRIES_FAILURE
    });
  }
}

function* getCountriesList() {
  const data = yield select(getCountryId);
  try {
    const result = yield call(
      branchesApi.getCountriesList,
      data.countryId,
      data.cityId,
      data.availableForDelivery,
      data.showInMobileApp
    );
    console.log("Result:: ", result);
    yield put({
      type: branchesActionsTypes.GET_COUNTRIES_LIST_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.GET_COUNTRIES_LIST_FAILURE
    });
  }
}

function* getCities(action) {
  try {
    const result = yield call(branchesApi.getCities, action.payload);
    yield put({
      type: branchesActionsTypes.GET_CITIES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.GET_CITIES_FAILURE
    });
  }
}

function* setAvailableForDelivery(action) {
  try {
    const result = yield call(
      branchesApi.setAvailableForDelivery,
      action.payload.branchId,
      action.payload.availableForDelivery
    );
    yield put({
      type: branchesActionsTypes.SET_AVAILABLE_FOR_DELIVERY_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.SET_AVAILABLE_FOR_DELIVERY_SUCCESS_FAILURE
    });
  }
}

function* getBranches(action) {
  try {
    const result = yield call(
      branchesApi.getBranches,
      action.payload.pagination,
      action.payload.cityId,
      action.payload.countryId
    );

    yield put({
      type: branchesActionsTypes.GET_BRANCHES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.GET_BRANCHES_FAILURE
    });
  }
}

function* updateBranch(action) {
  try {
    const result = yield call(
      branchesApi.updateBranch,
      action.payload.branchId,
      action.payload.branch
    );
    yield put({
      type: branchesActionsTypes.UPDATE_BRANCH_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.UPDATE_BRANCH_FAILURE
    });
  }
}

function* synchronizeBranches() {
  try {
    yield call(branchesApi.synchronizeBranches);

    yield put({
      type: branchesActionsTypes.SYNCHRONIZE_BRANCHES_SUCCESS
    });

    yield put(BranchesActions.getCountries());
  } catch (error) {
    yield put({
      type: branchesActionsTypes.SYNCHRONIZE_BRANCHES_FAILURE
    });
  }
}

function* bulkBranchesUpdate(action) {
  try {
    const result = yield call(branchesApi.bulkBranchesUpdate, action.payload);
    yield put({
      type: branchesActionsTypes.BULK_BRANCHES_UPDATE_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: branchesActionsTypes.BULK_BRANCHES_UPDATE_FAILURE
    });
  }
}

function* branchesSaga() {
  yield takeLatest(
    [
      branchesActionsTypes.GET_COUNTRIES_LIST,
      branchesActionsTypes.UPDATE_BRANCH_SUCCESS
    ],
    getCountriesList
  );
  yield takeLatest([branchesActionsTypes.GET_CITIES], getCities);
  yield takeLatest([branchesActionsTypes.GET_COUNTRIES], getCountries);
  yield takeLatest([branchesActionsTypes.GET_BRANCHES], getBranches);

  yield takeLatest([branchesActionsTypes.UPDATE_BRANCH], updateBranch);
  yield takeLatest(
    [branchesActionsTypes.SET_AVAILABLE_FOR_DELIVERY],
    setAvailableForDelivery
  );
  yield takeLatest(
    [branchesActionsTypes.SYNCHRONIZE_BRANCHES],
    synchronizeBranches
  );

  yield takeLatest(
    [branchesActionsTypes.BULK_BRANCHES_UPDATE],
    bulkBranchesUpdate
  );
}

export default branchesSaga;

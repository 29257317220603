import { API_URL } from "../constants";
import axiosInstance from "../services/interceptor";
import { objectToFormData } from "object-to-formdata";

export const pagesContentApi = {
  getContactUs() {
    return axiosInstance.get(`${API_URL}/content-pages/contact-us`);
  },

  setContactUs(contactUs) {
    return axiosInstance.patch(`${API_URL}/content-pages/contact-us`, {
      ...contactUs
    });
  },

  getAboutUs(lang) {
    return axiosInstance.get(
      `${API_URL}/content-pages/about-us?language=${lang}`
    );
  },

  setAboutUs({ lang, ...data }) {
    return axiosInstance.patch(
      `${API_URL}/content-pages/about-us?language=${lang}`,
      objectToFormData(data)
    );
  },

  getPrivacy(lang) {
    return axiosInstance.get(
      `${API_URL}/content-pages/privacy-and-policy?language=${lang}`
    );
  },

  setPrivacy(privacy) {
    return axiosInstance.patch(
      `${API_URL}/content-pages/privacy-and-policy?language=${privacy.lang}`,
      {
        privacyAndPolicy: privacy.privacyAndPolicy
      }
    );
  },

  getAdvertisement() {
    return axiosInstance.get(`${API_URL}/content-pages/advertisement`);
  },

  setAdvertisement({ formData, product }) {
    const params = {};
    if (product) {
      params.productId = product.id;
    }
    return axiosInstance.post(
      `${API_URL}/content-pages/advertisement`,
      formData,
      { params }
    );
  },

  deleteAdvertisement(id) {
    return axiosInstance.delete(`${API_URL}/content-pages/advertisement/${id}`);
  }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getProducts } from "../../../store/actions/products.actions";
import {
  setFixedUpsell,
  resetError
} from "../../../store/actions/upsell.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

export default function ModalFixedProduct({ modal, toggle }) {
  const dispatch = useDispatch();

  const products = useSelector(state => state.products.products);

  const formik = useFormik({
    initialValues: {
      product: "",
      dateFrom: "",
      dateTo: "",
      timeFrom: "",
      timeTo: ""
    },
    validationSchema: Yup.object().shape({
      product: Yup.string().required("Required"),
      dateFrom: Yup.string().required("Required"),
      dateTo: Yup.string().required("Required"),
      timeFrom: Yup.string().required("Required"),
      timeTo: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(setFixedUpsell(values));
      formik.resetForm();
      toggle();
      setTimeout(() => {
        dispatch(resetError());
      }, 500);
    }
  });

  useEffect(() => {
    dispatch(getProducts({ pagination: { limit: "", page: "" } }));
  }, [dispatch]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Fixed Product
      </ModalHeader>
      <form onSubmit={formik.handleSubmit} className="modal-body p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="product" className="control-label">
                Select Product
              </label>
              <select
                className="form-control"
                id="product"
                name="product"
                onChange={formik.handleChange}
                value={formik.values.product}
              >
                <option>Choose Product</option>
                {products.map(
                  productItem =>
                    productItem.selected && (
                      <option key={productItem.id} value={productItem.id}>
                        {productItem.name.en}
                      </option>
                    )
                )}
              </select>
              {formik.errors.product && formik.touched.product ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.product}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="dateFrom" className="control-label">
                Expiration Date
              </label>
              <div className="d-flex justify-content-between">
                <div className="w-100">
                  <input
                    type="date"
                    className="form-control"
                    id="dateFrom"
                    name="dateFrom"
                    placeholder="Date"
                    onChange={formik.handleChange}
                    value={formik.values.dateFrom}
                    onClick={() => {
                      formik.setFieldTouched("dateFrom", true);
                    }}
                  />
                  {formik.errors.dateFrom && formik.touched.dateFrom ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {formik.errors.dateFrom}
                      </li>
                    </ul>
                  ) : null}
                </div>
                <div className="w-100">
                  <input
                    type="date"
                    className="form-control"
                    id="dateTo"
                    name="dateTo"
                    placeholder="Date"
                    onChange={formik.handleChange}
                    value={formik.values.dateTo}
                    onClick={() => {
                      formik.setFieldTouched("dateTo", true);
                    }}
                  />
                  {formik.errors.dateTo && formik.touched.dateTo ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {formik.errors.dateTo}
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="timeFrom" className="control-label">
                Time
              </label>
              <div className="d-flex justify-content-between">
                <div className="w-100">
                  <input
                    type="time"
                    step="1"
                    className="form-control"
                    id="timeFrom"
                    name="timeFrom"
                    placeholder="Time"
                    onChange={formik.handleChange}
                    value={formik.values.timeFrom}
                    onClick={() => {
                      formik.setFieldTouched("timeFrom", true);
                    }}
                  />
                  {formik.errors.timeFrom && formik.touched.timeFrom ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {formik.errors.timeFrom}
                      </li>
                    </ul>
                  ) : null}
                </div>
                <div className="w-100">
                  <input
                    type="time"
                    step="1"
                    className="form-control"
                    id="timeTo"
                    name="timeTo"
                    placeholder="Time"
                    onChange={formik.handleChange}
                    value={formik.values.timeTo}
                    onClick={() => {
                      formik.setFieldTouched("timeTo", true);
                    }}
                  />
                  {formik.errors.timeTo && formik.touched.timeTo ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {formik.errors.timeTo}
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import {
  deleteFixedUpsell,
  deleteRelatedUpsell
} from "../../../store/actions/upsell.actions";
import { deleteSupervisors } from "../../../store/actions/authorization.actions";
import { deleteAdvertisement } from "../../../store/actions/pagesContent.action";
import {
  deleteReward,
  deleteOffer
} from "../../../store/actions/points.actions";
import { Button, Modal, ModalHeader } from "reactstrap";

export default function ModalDelete({ modal, toggle, id, name }) {
  const dispatch = useDispatch();

  return (
    <Modal
      className="modal-dialog-centered modal-sm"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        Are you sure you want to delete?{" "}
      </ModalHeader>
      <div className="modal-body d-flex justify-content-center">
        <div className="d-flex justify-content-between w-50">
          <Button
            color="success"
            type="submit"
            onClick={() => {
              if (name === "FixedUpsell") {
                dispatch(deleteFixedUpsell(id));
              } else if (name === "RelatedUpsell") {
                dispatch(deleteRelatedUpsell(id));
              } else if (name === "Advertisement") {
                dispatch(deleteAdvertisement(id));
              } else if (name === "Supervisor") {
                dispatch(deleteSupervisors(id));
              } else if (name === "Reward") {
                dispatch(deleteReward(id));
              } else if (name === "Offer") {
                dispatch(deleteOffer(id));
              }
              toggle();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              toggle();
            }}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
}

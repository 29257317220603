import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getProducts } from "../../store/actions/products.actions";
import { getCategories } from "../../store/actions/categories.actions";
import {
  getFoodTruckMenu,
  updateFoodTruckMenu
} from "../../store/actions/foodTruck.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import LoadingButton from "../../components/loadingButton/loadingButton";
import QuantityList from "./quantityList";
import ProductList from "./productList";

import "react-toastify/dist/ReactToastify.css";

const ManageFoodTruck = ({ history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const menuData = useSelector(state => state.foodTruck.menuData);
  const { products, isLoadingProducts } = useSelector(state => state.products);
  const isLoading = useSelector(state => state.foodTruck.isLoadingMenu);
  const isLoadingBtn = useSelector(state => state.foodTruck.isLoadingMenuBtn);

  const addNewProduct = e => {
    formik.setFieldValue("availableProducts", [
      ...formik.values.availableProducts,
      {
        id: ""
      }
    ]);
  };

  const addNewQuantity = e => {
    formik.setFieldValue("quantityPrice", [
      ...formik.values.quantityPrice,
      {
        persons: "",
        price: ""
      }
    ]);
  };

  const deleteProduct = record => {
    formik.setFieldValue(
      "availableProducts",
      formik.values.availableProducts.filter(r => r !== record)
    );
  };

  const deleteQuantity = record => {
    formik.setFieldValue(
      "quantityPrice",
      formik.values.quantityPrice.filter(r => r !== record)
    );
  };

  const closeSurvey = () => {
    history.push("/food-truck");
  };

  const formik = useFormik({
    initialValues: {
      ...menuData
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      additionalQuantityPrice: Yup.object().shape({
        persons: Yup.number().required(),
        price: Yup.number().required()
      })
    }),
    onSubmit: values => {
      dispatch(
        updateFoodTruckMenu({
          quantityPrice: values.quantityPrice,
          additionalQuantityPrice: values.additionalQuantityPrice,
          availableProducts: values.availableProducts.map(item => item.id)
        })
      );
      setTimeout(() => {
        !isLoadingBtn && closeSurvey();
      }, 800);
    }
  });

  useEffect(() => {
    dispatch(getFoodTruckMenu());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getCategories({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: { limit: 100, page: 1 }
      })
    );
  }, [dispatch]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Manage Food Truck</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2"></div>
                    <div className="col-md-12">
                      <div className="modal-content">
                        <div className="modal-body p-4">
                          {isLoading || isLoadingProducts ? (
                            <div className="d-flex justify-content-center">
                              <div
                                className="spinner-border avatar-lg"
                                role="status"
                              ></div>
                            </div>
                          ) : (
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="additionalQuantityPrice.persons">
                                      Additional Persons
                                    </label>

                                    <input
                                      type="number"
                                      id="additionalQuantityPrice.persons"
                                      name="additionalQuantityPrice.persons"
                                      className="form-control"
                                      placeholder="Persons"
                                      onChange={formik.handleChange}
                                      value={
                                        formik.values.additionalQuantityPrice
                                          ? formik.values
                                              .additionalQuantityPrice.persons
                                          : ""
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="additionalQuantityPrice.price"
                                      className="control-label"
                                    >
                                      Additional Price
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="additionalQuantityPrice.price"
                                      name="additionalQuantityPrice.price"
                                      placeholder="Price"
                                      value={
                                        formik.values.additionalQuantityPrice
                                          ? formik.values
                                              .additionalQuantityPrice.price
                                          : ""
                                      }
                                      onChange={formik.handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>

                              <table className="table">
                                <thead>
                                  <tr className="d-flex">
                                    <th className="col-md-5 pl-0 text-left">
                                      Persons
                                    </th>
                                    <th className="col-md-5 pl-0 text-left">
                                      Price
                                    </th>
                                    <th className="col-md-2 pl-0"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.quantityPrice && (
                                    <QuantityList
                                      add={addNewQuantity}
                                      delete={deleteQuantity}
                                      taskList={formik.values.quantityPrice}
                                      formik={formik}
                                    />
                                  )}
                                </tbody>
                              </table>

                              <table className="table">
                                <thead>
                                  <tr className="d-flex">
                                    <th className="col-md-12 pl-0 text-left">
                                      Select Product
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.availableProducts && (
                                    <ProductList
                                      add={addNewProduct}
                                      delete={deleteProduct}
                                      taskList={formik.values.availableProducts}
                                      formik={formik}
                                      products={products}
                                    />
                                  )}
                                </tbody>
                              </table>

                              <div className="modal-footer mt-3">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-dismiss="modal"
                                  onClick={closeSurvey}
                                >
                                  Close
                                </button>
                                <LoadingButton
                                  isLoadingBtn={isLoadingBtn}
                                  btnContent="Save"
                                />
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageFoodTruck;

import { API_URL } from "../constants";
import axiosInstance from "./interceptor";
import { objectToFormData } from "object-to-formdata";

export const pointsApi = {
  getPointsRiyal() {
    return axiosInstance.get(`${API_URL}/orders/points-riyal-value`);
  },
  updatePointsRiyal(points) {
    return axiosInstance.post(`${API_URL}/orders/points-riyal-value`, points);
  },
  getRewards(params) {
    const { page, limit } = params;
    return axiosInstance.get(`${API_URL}/awards`, {
      params: {
        page,
        limit
      }
    });
  },

  setReward(reward) {
    return axiosInstance.post(`${API_URL}/awards`, reward);
  },

  updateReward(id, data) {
    return axiosInstance.patch(
      `${API_URL}/awards/${id}`,
      objectToFormData(data)
    );
  },

  deleteReward(id) {
    return axiosInstance.delete(`${API_URL}/awards/${id}`);
  },

  getOffers(params) {
    const { page, limit } = params;
    return axiosInstance.get(`${API_URL}/offers`, {
      params: {
        page,
        limit
      }
    });
  },

  setOffer(offer) {
    return axiosInstance.post(`${API_URL}/offers`, offer);
  },

  deleteOffer(id) {
    return axiosInstance.delete(`${API_URL}/offers/${id}`);
  },

  getPoints(type, params) {
    const { page, limit } = params;

    return axiosInstance.get(`${API_URL}/points`, {
      params: {
        type,
        page,
        limit
      }
    });
  },

  setPoint(point) {
    return axiosInstance.post(`${API_URL}/points`, point);
  },

  updatePoint(point) {
    if (point.expiration) {
      return axiosInstance.patch(`${API_URL}/points/${point.pointId}`, {
        expiration: point.expiration
      });
    } else {
      return axiosInstance.patch(`${API_URL}/points/${point.pointId}`, {
        pointCondition: point.pointCondition
      });
    }
  },

  getPointHistory(id) {
    return axiosInstance.get(`${API_URL}/points/points-history`, {
      params: {
        id
      }
    });
  },

  assignPoint(point) {
    return axiosInstance.post(
      `${API_URL}/points/${point.id}/assign-points`,
      point
    );
  },

  getPointsReport(params) {
    if (params.createFile) {
      axiosInstance
        .get(`${API_URL}/points/points-report`, {
          responseType: "blob",
          params
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `points.csv`);
          document.body.appendChild(link);
          link.click();
        });
      return { data: null };
    } else {
      return axiosInstance.get(`${API_URL}/points/points-report`, {
        params
      });
    }
  },

  getAwardHistory(params) {
    return axiosInstance.get(`${API_URL}/awards/awards-history`, {
      params
    });
  }
};

export const StorageService = {
  setToken(token) {
    return localStorage.setItem("token", token);
  },

  getToken() {
    return localStorage.getItem("token");
  },

  removeToken() {
    return localStorage.removeItem("token");
  },
  setUser(user) {
    return localStorage.setItem("user", JSON.stringify(user));
  },

  getUser() {
    return localStorage.getItem("user");
  }
};

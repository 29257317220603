import React, { useState } from "react";
import classNames from "classnames";
import Modifier from "../modifier";
import ModifierOption from "./modifierOption";
import "./index.scss";

const ModifierPage = ({ modifier }) => {
  const [activeTab, setActiveTab] = useState(true);
  return (
    <div className="card-box">
      <h4 className="header-title mb-4">{modifier.name.en}</h4>
      <div className="row">
        <div className="col-sm-3">
          <div className="nav flex-column nav-pills nav-pills-tab">
            <span
              className={classNames(
                "nav-link  show mb-2 d-flex justify-content-between",
                activeTab && "active"
              )}
              onClick={() => setActiveTab(!activeTab)}
            >
              Basic Information <i className="mdi mdi-arrow-right"></i>
            </span>
            <span
              className={classNames(
                "nav-link  show mb-2 d-flex justify-content-between",
                !activeTab && "active"
              )}
              onClick={() => setActiveTab(!activeTab)}
            >
              Options <i className="mdi mdi-arrow-right"></i>
            </span>
          </div>
        </div>
        <div className="col-sm-9">
          <div className="tab-content pt-0">
            <div
              className={classNames(
                "",
                activeTab ? "showContent" : "hideContent"
              )}
            >
              <div className="table-responsive">
                <table className="table table-centered table-borderless table-hover mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th className="w-25">Name | En</th>
                      <th className="w-25">Name | Ar</th>
                      <th className="w-25">IsDouble</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Modifier modifier={modifier} />
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={classNames(
                "",
                !activeTab ? "showContent" : "hideContent"
              )}
            >
              <div className="table-responsive">
                <table className="table table-centered table-borderless table-hover mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Name | En</th>
                      <th>Name | Ar</th>
                      <th>Price</th>
                      <th>Calories</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modifier.modifierOptions.map(modifierOption => (
                      <ModifierOption
                        key={modifierOption.id}
                        {...modifierOption}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierPage;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./sideBar.scss";
import DropDownChild from "./dropDownChild";

const SideBarDropDown = ({ link, match }) => {
  const openStatus = useSelector(state => state.authorization.menuStatus);
  const [menuStatus, setMenuStatus] = useState(openStatus);

  useEffect(() => {
    if (!openStatus) {
      setMenuStatus(false);
    }
  }, [openStatus]);

  return (
    <li id="link">
      <a
        role="button"
        className={classNames(
          "",
          link.children.some(({ url }) => match.url === url) && "active",

          link.children.map(
            child =>
              child.children &&
              child.children.some(({ url }) => match.url === url) &&
              "active"
          )
        )}
        onClick={() => {
          if (openStatus) {
            setMenuStatus(!menuStatus);
          }
        }}
        href="# "
      >
        <i className={link.icon}></i>
        <span>{link.name}</span>
        <span
          className={classNames("", menuStatus ? "arrow-rotate" : "menu-arrow")}
        ></span>
      </a>
      <ul
        className={classNames("nav-second-level collapse", menuStatus && "in")}
      >
        {link.children.map((child, index) => (
          <DropDownChild key={index} child={child} match={match} />
        ))}
      </ul>
    </li>
  );
};

export default SideBarDropDown;

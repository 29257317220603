import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import { getTransactionsID } from "../../store/actions/transactions.actions";
import {
  getContactUs,
  setContactUs,
} from "../../store/actions/pagesContent.action";
import { useFormik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import "./creditPage.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { creditFormInputs, creditType, dateString } from "./constants";
import DatePicker from "react-datepicker";
import { addWalletCredit } from "../../store/actions/customers.actions";
import { displayTransactionType } from "./utils";

const AddCreditCustomerPage = ({ match }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector((state) => state.authorization.menuStatus)
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { isLoadingTransactions, customerTransactions } = useSelector(
    (state) => state.transactions
  );
  const { isLoadingAddWalletCredit, errorAddWalletCredit } = useSelector(
    (state) => state.customers
  );

  useEffect(() => {
    dispatch(getTransactionsID({ customer_id: match.params.id }));
  }, [match.params.id]);

  const isLoadingBtn = useSelector(
    (state) => state.pagesContent.isloadingContactUsBtn
  );

  const creditFormInputsObj = _.chain(creditFormInputs)
    .keyBy("key")
    .mapValues("value")
    .value();

  const getError = (name) =>
    formik.errors[name] && formik.touched[name] ? (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{formik.errors[name]}</li>
      </ul>
    ) : null;

  const formik = useFormik({
    initialValues: creditFormInputsObj,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
      expiration_date: Yup.string(),
      reason: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setFormSubmitted(true);
      dispatch(
        addWalletCredit({
          ...values,
          amount: +values.amount,
          type: +values.type,
          customer_id: match.params.id,
        })
      );

      setTimeout(() => {
        dispatch(getTransactionsID({ customer_id: match.params.id }));
      }, 4000);
    },
  });

  useEffect(() => {
    dispatch(getContactUs());
  }, []);

  useEffect(() => {
    setFormSubmitted(false);
  }, [match]);

  useEffect(() => {
    // TOTO: to resolve this issue of toast, it's not working properly now
    if (!isLoadingBtn && !isLoadingAddWalletCredit && formSubmitted) {
      toast.success("Credit added successfully", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setFormSubmitted(false);
    }

    if (errorAddWalletCredit && !isLoadingBtn && formSubmitted) {
      toast.error(`Error adding credit: ${errorAddWalletCredit}`, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setFormSubmitted(false);
    }
  }, [
    isLoadingBtn,
    isLoadingAddWalletCredit,
    errorAddWalletCredit,
    formSubmitted,
  ]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Add Credit for customers</h4>
              </div>
            </div>
            <div className="row">
              {isLoadingAddWalletCredit ? (
                <div className="d-flex justify-content-center w-100">
                  <div className="spinner-border avatar-lg" role="status"></div>
                </div>
              ) : (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <form
                          className="col-12 p-4"
                          onSubmit={formik.handleSubmit}
                        >
                          {creditFormInputs.map((items) => (
                            <div className="form-group" key={items.id}>
                              <label
                                htmlFor={items.key}
                                className="control-label"
                              >
                                {items.key}:
                              </label>
                              {(() => {
                                switch (items.field_type) {
                                  case "textarea":
                                    return (
                                      <>
                                        <textarea
                                          rows="5"
                                          id={items.key}
                                          name={items.key}
                                          className="form-control"
                                          placeholder={items.key}
                                          onChange={formik.handleChange}
                                          value={formik.values[items.key] || ""}
                                        ></textarea>
                                        {getError(items.key)}
                                      </>
                                    );
                                  case "input":
                                    return (
                                      <>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={items.key}
                                          name={items.key}
                                          placeholder={items.key}
                                          onChange={formik.handleChange}
                                          value={formik.values[items.key] || ""}
                                        />
                                        {getError(items.key)}
                                      </>
                                    );
                                  case "dropdown":
                                    return (
                                      <>
                                        <select
                                          id={items.key}
                                          name={items.key}
                                          className="custom-select custom-select-sm"
                                          onChange={formik.handleChange}
                                          value={formik.values[items.key] || ""}
                                        >
                                          <option value="">
                                            Please select type
                                          </option>
                                          {creditType.map((option) => (
                                            <option
                                              value={option.id}
                                              key={option.id}
                                            >
                                              {option.value}
                                            </option>
                                          ))}
                                        </select>
                                        {getError(items.key)}
                                      </>
                                    );
                                  case "datePicker":
                                    return (
                                      <>
                                        <DatePicker
                                          dateFormat={dateString}
                                          selected={formik.values[items.key]}
                                          onChange={(e) => {
                                            formik.setValues({
                                              ...formik.values,
                                              expiration_date: e,
                                            });
                                          }}
                                          className="datepicker"
                                        />
                                        {getError(items.key)}
                                      </>
                                    );
                                  default:
                                    return <div>Unknown input type found!</div>;
                                }
                              })()}
                            </div>
                          ))}
                          <div className="modal-footer">
                            <LoadingButton
                              isLoadingBtn={isLoadingBtn}
                              btnContent="Save"
                            />
                          </div>
                          <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <h2>Customer Transactions History</h2>
            <div className="row">
              {customerTransactions
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((tr, index) => (
                  <div className="col-4" key={index}>
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Transaction ID: {tr.id}</h5>
                        <div className="card-text">
                          <strong>Type:</strong>{" "}
                          {displayTransactionType(tr.type)}
                        </div>
                        <div className="card-text">
                          <strong>Amount:</strong> {tr.amount}
                        </div>
                        <div className="card-text">
                          <strong>New Balance:</strong> {tr.new_balance}
                        </div>
                        <div className="card-text">
                          <strong>Reason:</strong> {tr.reason}
                        </div>
                        <div className="card-text">
                          <strong>Created By:</strong> {tr.created_by}
                        </div>
                        <div className="card-text">
                          <strong>Created At:</strong>{" "}
                          {new Date(tr.createdAt).toLocaleString()}
                        </div>
                        <div className="card-text">
                          <strong>Updated At:</strong>{" "}
                          {new Date(tr.updatedAt).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCreditCustomerPage;

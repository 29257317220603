import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../store/actions/orders.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";

const OrderHistoryPage = ({ match }) => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const orders = useSelector(state => state.orders.orders);
  const orderError = useSelector(state => state.orders.orderError);

  const loadingOrders = useSelector(state => state.orders.loadingOrders);

  const dispatch = useDispatch();

  const tableHead = [
    {
      name: "Order ID",
      key: "id"
    },
    {
      name: "Order Date",
      field: "orderDate",
      key: "date"
    },
    {
      name: "Order Status",
      key: "status",
      orderStatus: {
        1: "Pending",

        2: "Active",

        3: "Void",

        4: "Done",

        5: "Payment Failed",

        6: "Foodics Failed"
      }
    },
    {
      name: "Order Type",
      key: "type",
      orderType: {
        1: "Dine in",

        2: "Take Away",

        3: "Pickup",

        4: "Delivery",

        5: "Drive Thru"
      }
    },
    {
      name: "Is Refunded",
      key: "isRefunded"
    },
    {
      name: "Order Details",
      key: "orderDetailsIcon",
      customerHid: match.params.id
    }
  ];

  useEffect(() => {
    dispatch(getOrders(match.params.id));
  }, [match.params.id]);
  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Order History</h4>
              </div>
            </div>
            <div className="row">
              {loadingOrders ? (
                <div className="d-flex justify-content-center  w-100">
                  <div className="spinner-border avatar-lg" role="status"></div>
                </div>
              ) : (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-md-6"></div>
                      </div>
                      {orderError ? (
                        <div className="d-flex justify-content-center">
                          {orderError}
                        </div>
                      ) : (
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={orders}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryPage;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import { getSupervisors } from "../../store/actions/authorization.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModalSupervisors from "../../components/modal/modalSupervisors/modalSupervisors";
import ModalAddSupervisors from "../../components/modal/modalAddSupervisors/modalAddSupervisors";
import ModalDelete from "../../components/modal/modalDelete/modalDelete";
import TableComponent from "../../components/tableComponent";
import Pagination from "../../components/pagination/pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBranches } from "../../store/actions/branches.actions";

const SupervisorsPrivileges = ({ history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const supervisors = useSelector(
    state => state.authorization.supervisors || []
  );
  const isLoading = useSelector(
    state => state.authorization.isLoadingSupervisors
  );
  const countSupervisors = useSelector(
    state => state.authorization.countSupervisors
  );
  const supervisorError = useSelector(
    state => state.authorization.supervisorError
  );

  const [supervisorId, setSupervisorId] = useState("");
  const [oneSupervisor, setOneSupervisor] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [newSupervisor, setNewSupervisor] = useState(false);
  const addNewSupervisor = () => setNewSupervisor(!newSupervisor);

  const [supervisor, setSupervisor] = useState(false);
  const updateSupervisor = () => setSupervisor(!supervisor);

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  const allBranches = useSelector(state => state.branches.branches || []);

  const tableHead = [
    {
      name: "Name",
      key: "supervisorName",
      value: "name"
    },
    {
      name: "Email",
      key: "email"
    },
    {
      name: "Privileges",
      key: "editWithCallback",
      onClick: body => {
        setOneSupervisor(body);
        updateSupervisor();
      }
    },
    {
      name: "Delete",
      key: "delete",
      return: "id",
      onClick: id => {
        toggle();
        setSupervisorId(id);
      }
    }
  ];

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getBranches({
        pagination: { page: "", limit: "" }
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getSupervisors({ page: currentPage || 1, limit: rowsPerPage || 10 })
    );
  }, [currentPage, history, rowsPerPage]);

  useEffect(() => {
    if (supervisorError) {
      toast.error(supervisorError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }, [supervisorError]);
  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Supervisors Privileges</h4>
                </div>
              </div>
            </div>
            <div className="card-box">
              <div className="row mb-2">
                <div className="col-md-12">
                  <div className="text-md-right">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                      onClick={addNewSupervisor}
                    >
                      <i className="mdi mdi-plus mr-1"></i> New Supervisor
                    </button>
                  </div>
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border avatar-lg"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <div className="col-md-12">
                      <TableComponent
                        tableHead={tableHead}
                        tableBody={supervisors}
                      />
                      <Pagination
                        totalCount={countSupervisors}
                        rows={rowsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setPageQuery}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {oneSupervisor && (
            <ModalSupervisors
              extraData={[
                { id: "2", name: { en: "All", ar: "كل" } },
                ...allBranches
              ]}
              modal={supervisor}
              supervisor={oneSupervisor}
              onClose={() => {
                updateSupervisor();
                setOneSupervisor(undefined);
              }}
            />
          )}
          <ModalAddSupervisors
            extraData={[
              { id: "2", name: { en: "All", ar: "كل" } },
              ...allBranches
            ]}
            modal={newSupervisor}
            toggle={addNewSupervisor}
          />
          <ModalDelete
            modal={modal}
            toggle={toggle}
            id={supervisorId}
            name="Supervisor"
          />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
};

export default SupervisorsPrivileges;

import React, { Fragment } from "react";
const ProductList = props => {
  const taskList = props.taskList.length ? props.taskList : [{}];
  return taskList.map((val, idx) => (
    <Fragment key={val + idx}>
      <tr className="d-flex">
        <td className="col-md-8 pr-0 pl-0 border-0">
          <select
            className="form-control"
            name={`availableProducts[${idx}].id`}
            onChange={props.formik.handleChange}
            value={
              props.formik.values.availableProducts[idx] &&
              props.formik.values.availableProducts[idx].id
            }
            required
          >
            <option value="">--</option>
            {props.products.map(product => (
              <option key={product.id} value={product.id}>
                {product.name.en}
              </option>
            ))}
          </select>
        </td>

        <td className="col-md-3 border-0">
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              Add product
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-danger text-center"
              onClick={() => props.delete(val)}
            >
              X
            </button>
          )}
        </td>
      </tr>
      <tr>
        <td className="col-md-12 pl-0 pr-0">
          <input
            type="text"
            className="form-control"
            name={`availableProducts[${idx}].id`}
            value={props.products
              .filter(
                product =>
                  props.formik.values.availableProducts[idx] &&
                  product.id === props.formik.values.availableProducts[idx].id
              )
              .map(item => item.name.en)}
            onChange={props.formik.handleChange}
            placeholder="Product"
            disabled
            required
          />
        </td>
      </tr>
    </Fragment>
  ));
};
export default ProductList;

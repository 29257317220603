import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import {
  getCountriesList,
  getBranches,
  bulkBranchesUpdate
} from "../../store/actions/branches.actions";
import Filter from "../../components/filter/filter";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import "./index.scss";
import WorkingHours from "../../components/workingHours/workingHours";
import { StorageService } from "../../services/storage.service";
import ForbiddenPage from "../forbiddenPage";

const BranchesStatusBulkEdit = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const user = JSON.parse(StorageService.getUser()) || {};

  const {
    branches: allBranches,
    countries,
    bulkBranchesUpdateLoading,
    bulkBranchesUpdateErr,
    loadingBranches
  } = useSelector(state => state.branches);
  const noContent =
    countries[0] &&
    countries[0].cities &&
    !countries[0].cities.some(city => city.branches.length);

  const [selectedBranches, setSelectedBranches] = useState([]);
  const [availableBranches, setAvailableBranches] = useState(allBranches);
  const [workingHrs, setWorkingHrs] = useState([]);

  useEffect(() => {
    dispatch(
      getBranches({
        pagination: { page: "", limit: "" }
      })
    );
    if (allBranches.length > 0) {
      setWorkingHrs(allBranches[0].workingHours);
    }
  }, [dispatch]);

  const [allSelected, setAllSelected] = useState(
    allBranches.every(branch => branch.isSelected)
  );

  const handleSelect = (branchId, isSelected) => {
    // Find the index of the branch to update
    const branchIndex = availableBranches.findIndex(
      branch => branch.id === branchId
    );

    // Check if branch exists
    if (branchIndex !== -1) {
      const currentSelectedBranch = availableBranches[branchIndex];
      currentSelectedBranch.isSelected = true;

      if (isSelected) {
        setSelectedBranches([...selectedBranches, currentSelectedBranch]);
      } else {
        setSelectedBranches(
          selectedBranches.filter(obj => obj.id !== currentSelectedBranch.id)
        );
      }

      if (
        isSelected &&
        availableBranches.length >= 1 &&
        availableBranches.length === selectedBranches.length + 1
      ) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    }
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedBranches(
        availableBranches.map(b => ({ ...b, isSelected: isSelected }))
      );
    } else {
      setSelectedBranches([]);
    }
    setAllSelected(isSelected);
  };

  const handleUpdateWorkingHrs = val => {
    setWorkingHrs(val);
  };

  const handleUpdate = () => {
    const updatedBranchesWithWorkingHrs = selectedBranches.map(br => ({
      ...br,
      workingHours: workingHrs
    }));

    dispatch(
      bulkBranchesUpdate({
        option: "working_hrs",
        branches: updatedBranchesWithWorkingHrs,
        value: workingHrs
      })
    );
  };
  const { role, privileges } = user;

  if (role === "supervisor" && (!privileges || !privileges.bulkBranchesEdit)) {
    return <ForbiddenPage />;
  }
  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Branches Working HRs Bulk Edit</h4>
                </div>
              </div>

              <div className="col-12">
                <div className="card-box d-flex justify-content-center">
                  <Filter action={getCountriesList} isAvailable={true} />
                </div>
              </div>
            </div>

            {!loadingBranches && noContent && (
              <div className="notFound">Not Found</div>
            )}

            {bulkBranchesUpdateLoading || loadingBranches ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row  p-2 bg-white rounded mr-auto">
                <table className="col-md-4 cars table table-bordered toggle-circle mb-0 mt-2 text-left">
                  <thead>
                    <tr>
                      <th>
                        <label>
                          <input
                            type="checkbox"
                            className="mx-2 "
                            checked={allSelected}
                            onChange={e => handleSelectAll(e.target.checked)}
                          />
                          Select All
                        </label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableBranches.map(branch => (
                      <tr key={branch.id} style={{ margin: "10px" }}>
                        <td>
                          <label>
                            <input
                              className="mx-2 "
                              type="checkbox"
                              checked={selectedBranches.some(
                                chosenItem =>
                                  (chosenItem.id === branch.id) &
                                  chosenItem.isSelected
                              )}
                              onChange={e =>
                                handleSelect(branch.id, e.target.checked)
                              }
                            />
                            {branch.name.en}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {allBranches.length > 0 && (
                  <div className="col-md-8 ">
                    <WorkingHours
                      workHours={data => handleUpdateWorkingHrs(data)}
                      data={workingHrs}
                      disable={false}
                    />
                  </div>
                )}
                {selectedBranches.length > 0 && (
                  <div className="mx-auto w-25 ">
                    <button
                      type="button"
                      className=" btn-block my-4 btn btn-success"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchesStatusBulkEdit;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useQueryParam, NumberParam } from "use-query-params";
import { getCustomerFeedback } from "../../store/actions/feedback.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";
import Pagination from "../../components/pagination/pagination";
import "./index.scss";

const customerFeedback = ({ match }) => {
  const dispatch = useDispatch();

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const customerFeedback = useSelector(
    state => state.feedback.customerFeedback || []
  );
  const countCustomerFeedback = useSelector(
    state => state.feedback.countCustomerFeedback
  );
  const isLoading = useSelector(
    state => state.feedback.isLoadingCustomerFeedback
  );

  const tableHead = [
    {
      name: "Feedback Type",
      key: "customerFeedbackType",
      value: customerFeedback.feedbackType
    },
    {
      name: "Question Type",
      key: "customerQuestionType"
    },
    {
      name: "Question",
      key: "questionTitle"
    },
    {
      name: "Answer",
      key: "answer"
    },
    {
      name: "Customer Name",
      key: "customerName"
    },
    {
      name: "Customer Phone Number",
      key: "customerPhone"
    },
    {
      name: "Customer Email",
      key: "customerEmail"
    }
  ];

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(getCustomerFeedback(match.params.id));
  }, [match.params.id]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Customer Feedback</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <div className="General selected">
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-lg"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        {customerFeedback.surveysAnswers && (
                          <TableComponent
                            tableHead={tableHead}
                            tableBody={customerFeedback.surveysAnswers}
                          />
                        )}
                        <Pagination
                          totalCount={countCustomerFeedback}
                          rows={rowsPerPage}
                          currentPage={currentPage}
                          setCurrentPage={setPageQuery}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default customerFeedback;

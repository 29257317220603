export const tableHead1 = [
  {
    name: "New balance",
    key: "new_balance",
  },
  {
    name: "Amount",
    key: "amount",
  },
  {
    name: "Type",
    key: "type",
    orderType: {
      1: "Online Top Up",

      2: "Order Purchase (Detection)",

      3: "Refund",

      4: "CashBack",

      5: "Compensations",

      6: "Expiration Detection (Detection)",
    },
  },
  {
    name: "Created at",
    key: "createdAt",
  },
  {
    name: "Expiration date",
    key: "expirationDate",
  },
  {
    name: "Customer Details",
    key: "details",
    path: "transactions",
    param: "id",
  },
];

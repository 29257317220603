import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateBranch } from "../../../store/actions/branches.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import ToggleButton from "../../toggleButton/toggleButton";
import WorkingHours from "../../workingHours/workingHours";

export default function ModalBranch({ allBranches, branch, modal, toggle }) {
  const dispatch = useDispatch();

  const [workingHours, setWorkingHours] = useState(branch.workingHours);
  const handleToggleActive = () => {
    formik.values.selected
      ? formik.setFieldValue("selected", false)
      : formik.setFieldValue("selected", true);
  };
  const handleToggleAvailable = () => {
    formik.values.availableForDelivery
      ? formik.setFieldValue("availableForDelivery", false)
      : formik.setFieldValue("availableForDelivery", true);
  };
  const handleToggleFreeDelivery = () => {
    formik.values.freeDelivery
      ? formik.setFieldValue("freeDelivery", false)
      : formik.setFieldValue("freeDelivery", true);
  };

  const formik = useFormik({
    initialValues: {
      branchNameAr: branch.name.ar,
      branchNameEn: branch.name.en,
      selected: branch.selected,
      availableForDelivery: branch.availableForDelivery,
      freeDelivery: branch.freeDelivery,
      backupBranchId: branch.backupBranchId
    },
    validationSchema: Yup.object({
      branchNameAr: Yup.string()
        // .max(5, "Must be 5 characters or less")
        .required("Required"),
      branchNameEn: Yup.string()
        // .max(5, "Must be 5 characters or less")
        .required("Required")
      // backupBranchId: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(
        updateBranch({
          branchId: branch.id,
          branch: {
            name: {
              en: values.branchNameEn,
              ar: values.branchNameAr
            },
            workingHours: workingHours || branch.workingHours,
            selected: values.selected,
            availableForDelivery: values.availableForDelivery,
            freeDelivery: values.freeDelivery,
            backupBranchId: values.backupBranchId
          }
        })
      );
      formik.resetForm();
      toggle();
    }
  });

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        Edit Branch
      </ModalHeader>
      <div className="modal-body p-4">
        <form onSubmit={formik.handleSubmit}>
          <label className="control-label" htmlFor="branchNameEn">
            Branch Name - English
          </label>
          <input
            className="form-control"
            name="branchNameEn"
            id="branchNameEn"
            type="text"
            placeholder="Branch Name - English"
            onChange={formik.handleChange}
            onClick={() => {
              formik.setFieldTouched("branchNameEn", true);
            }}
            value={formik.values.branchNameEn}
          />
          {formik.errors.branchNameEn && formik.touched.branchNameEn ? (
            <ul className="parsley-errors-list filled">
              <li className="parsley-required">{formik.errors.branchNameEn}</li>
            </ul>
          ) : null}
          <label className="control-label" htmlFor="branchNameEn">
            Branch Name - Arabic
          </label>
          <input
            className="form-control"
            name="branchNameAr"
            id="branchNameAr"
            type="text"
            placeholder="Branch Name - Arabic"
            onChange={formik.handleChange}
            onClick={() => {
              formik.setFieldTouched("branchNameAr", true);
            }}
            value={formik.values.branchNameAr}
          />
          {formik.errors.branchNameAr && formik.touched.branchNameAr ? (
            <ul className="parsley-errors-list filled">
              <li className="parsley-required">{formik.errors.branchNameAr}</li>
            </ul>
          ) : null}

          <label className="control-label" htmlFor="branchNameEn">
            Backup Branch
          </label>
          <select
            className="form-control"
            name="backupBranchId"
            onChange={formik.handleChange}
            value={formik.values.backupBranchId || ""}
          >
            <option value="">--</option>
            {allBranches.map(
              item =>
                item.id !== branch.id && (
                  <option key={item.id} value={item.id}>
                    {item.name.en}
                  </option>
                )
            )}
          </select>
          {formik.errors.backupBranchId && formik.touched.backupBranchId ? (
            <ul className="parsley-errors-list filled">
              <li className="parsley-required">
                {formik.errors.backupBranchId}
              </li>
            </ul>
          ) : null}

          <div className="workingHours mt-1">
            <WorkingHours
              workHours={data => setWorkingHours(data)}
              data={workingHours}
              disable={false}
            />
          </div>

          <div className="toggle-block">
            <div>Available for Delivery</div>
            <div onClick={handleToggleAvailable}>
              <ToggleButton
                id="availableForDelivery"
                name="availableForDelivery"
                value={formik.values.availableForDelivery}
              />
            </div>
          </div>

          <div className="toggle-block">
            <div>Free Delivery</div>
            <div onClick={handleToggleFreeDelivery}>
              <ToggleButton
                id="freeDelivery"
                name="freeDelivery"
                value={formik.values.freeDelivery}
              />
            </div>
          </div>

          <div className="toggle-block">
            <div>Show in Mobile App</div>
            <div onClick={handleToggleActive}>
              <ToggleButton
                id="selected"
                name="selected"
                value={formik.values.selected}
              />
            </div>
          </div>
          <ModalFooter className="mt-3">
            <Button
              color="secondary"
              onClick={() => {
                toggle();
                formik.resetForm();
              }}
            >
              Close
            </Button>
            <Button color="success" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  );
}

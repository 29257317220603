import React from "react";

export const reportTypes = {
  TOTAL_SALES: "Total Sales",
  TOTAL_DELIVERY_SALES: "Total Delivery Sales",
  TOTAL_PICK_UP_SALES: "Total Pickup Sales",
  TOTAL_DINE_IN_SALES: "Total Dinein Sales",
  TOTAL_iOS_SALES: "Total iOS Sales",
  TOTAL_ANDROID_SALES: "Total Android Sales",
  CUSTOMERS_WITHOUT_ORDERS: "Customers without any Orders",
  DAY_OVER_DAY: "Day Over Day",
  WEEK_OVER_WEEK: "Week over week",
  MONTH_OVER_MONTH: "Month over month",
  AVERAGE_CHECK: "Average Check",
  CUSTOMERS_BY_PHONE_NUMBER: "Specific Customer Orders",
  CUSTOMERS_OVER: "Customers with > 1 Orders",
  CUSTOMERS_WITH: "Customers with # of Orders",
  PROFIT_ALL_PRODUCTS: "All Products Profit",
  PROFIT_PRODUCT: "Specific Product Profit",
  ONLINE_SALES_TARGET: "Online Sales Target"
};

export const reportTypesUpper = {
  TOTAL_SALES: "TOTAL_SALES",
  TOTAL_DELIVERY_SALES: "TOTAL_DELIVERY_SALES",
  TOTAL_PICK_UP_SALES: "TOTAL_PICK_UP_SALES",
  TOTAL_DINE_IN_SALES: "TOTAL_DINE_IN_SALES",
  TOTAL_iOS_SALES: "TOTAL_iOS_SALES",
  TOTAL_ANDROID_SALES: "TOTAL_ANDROID_SALES",
  CUSTOMERS_WITHOUT_ORDERS: "CUSTOMERS_WITHOUT_ORDERS",
  DAY_OVER_DAY: "DAY_OVER_DAY",
  WEEK_OVER_WEEK: "WEEK_OVER_WEEK",
  MONTH_OVER_MONTH: "MONTH_OVER_MONTH",
  AVERAGE_CHECK: "AVERAGE_CHECK",
  CUSTOMERS_BY_PHONE_NUMBER: "CUSTOMERS_BY_PHONE_NUMBER",
  CUSTOMERS_OVER: "CUSTOMERS_OVER",
  CUSTOMERS_WITH: "CUSTOMERS_WITH",
  COMPARE_DATES: "COMPARE_DATES",
  CUSTOMERS_BY_ORDERS: "CUSTOMERS_BY_ORDERS",
  PROFIT_ALL_PRODUCTS: "PROFIT_ALL_PRODUCTS",
  PROFIT_PRODUCT: "PROFIT_PRODUCT",
  ONLINE_SALES_TARGET: "ONLINE_SALES_TARGET"
};

export const orderStatus = ["All", "Pending", "Active", "Done", "Void"];

export const xaxis = {
  ios: "IOS",
  android: "Android",
  male: "Male",
  female: "Female",
  totalOrders: "Total Orders",
  totalPrice: "Total Price",
  averageCheck: "Average Check",
  targetSales: "Target Sales",
  totalSales: "Total Sales"
};

export const tableHead1 = [
  {
    name: "First Name",
    key: "firstName",
    lastName: ""
  },
  {
    name: "Last Name",
    key: "lastName"
  },
  {
    name: "Mobile Number",
    key: "phone"
  },
  {
    name: "Email",
    key: "email"
  },
  {
    name: "Foodics Id",
    key: "foodicsId"
  },
  {
    name: "Number Of Orders",
    key: "orders"
  },
  {
    name: "Customer Details",
    key: "details",
    path: "customer",
    param: "id"
  },
  {
    name: "Orders History",
    key: "details",
    path: "customers",
    param: "foodicsId"
  }
];

export const tableHead2 = [
  {
    name: "First Name",
    key: "first_name",
    lastName: ""
  },
  {
    name: "Last Name",
    key: "last_name"
  },
  {
    name: "Mobile Number",
    key: "phone"
  },
  {
    name: "Email",
    key: "email"
  },
  {
    name: "Foodics Id",
    key: "foodics_id"
  },
  {
    name: "Customer Details",
    key: "details",
    path: "customer",
    param: "id"
  }
];

export const tableHead3 = [
  {
    name: "Number of Orders",
    key: "orders"
  },
  {
    name: "Total Purchases",
    key: "totalPrice"
  },
  {
    name: "Average Check",
    key: "count"
  }
];

const createNamingCell = (index, body, head) => (
  <td key={index}>{body[head.key] && body[head.key][head.lang]}</td>
);

export const tableHead4 = [
  {
    name: "Product Name | Ar",
    key: "productname",
    lang: "ar",
    render: createNamingCell
  },
  {
    name: "Product Name | En",
    key: "productname",
    lang: "en",
    render: createNamingCell
  },
  {
    name: "Product Size Name | Ar",
    key: "productsizename",
    lang: "ar",
    render: createNamingCell
  },
  {
    name: "Product Size Name | En",
    key: "productsizename",
    lang: "en",
    render: createNamingCell
  },
  {
    name: "Profit",
    key: "profit"
  },
  {
    name: "Details",
    key: "details",
    path: "products",
    param: "productid"
  }
];

export const tableHead5 = [
  {
    name: "Product Price",
    key: "price"
  },
  {
    name: "Product Cost",
    key: "cost"
  },
  {
    name: "Product Profit",
    key: "profit"
  },
  {
    name: "Details",
    key: "details",
    path: "products",
    param: "data",
    keyDetails: "productId",
    getCustomPath: (body, head) => {
      if (
        head.keyDetails &&
        body[head.param] &&
        body[head.param][head.keyDetails]
      ) {
        return body[head.param][head.keyDetails];
      }
    }
  }
];

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import {
  getCountriesList,
  getBranches,
  bulkBranchesUpdate
} from "../../store/actions/branches.actions";
import Filter from "../../components/filter/filter";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import "./index.scss";
import { StorageService } from "../../services/storage.service";
import ForbiddenPage from "../forbiddenPage";

const BranchesStatusBulkEdit = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const user = JSON.parse(StorageService.getUser()) || {};

  // only l
  const [activateBranches, setActivateBranches] = useState(true);

  const allBranches = useSelector(state => state.branches.branches || []);
  const countries = useSelector(state => state.branches.countries || []);
  const {
    bulkBranchesUpdateLoading,
    isBulkBranchesUpdateSucceeded,
    bulkBranchesUpdateErr
  } = useSelector(state => state.branches);
  const noContent =
    countries[0] &&
    countries[0].cities &&
    !countries[0].cities.some(city => city.branches.length);
  const loadingBranches = useSelector(state => state.branches.loadingBranches);

  const [selectedBranches, setSelectedBranches] = useState([]);
  const [availableBranches, setAvailableBranches] = useState(allBranches);

  const toggleActiveStatusForAllBranches = value => {
    const toggledBranches = availableBranches.map(br => {
      return { ...br, selected: value };
    });

    // Toggle available(all) branches, this is used to all ow the user to activate/disable all branches statuses before selecting the branch
    setAvailableBranches(toggledBranches);

    // Toggle selected branches
    setSelectedBranches(
      selectedBranches.map(br => ({ ...br, selected: (br.selected = value) }))
    );
  };
  useEffect(() => {
    dispatch(
      getBranches({
        pagination: { page: "", limit: "" }
      })
    );
    toggleActiveStatusForAllBranches(true); // set all branched to true
  }, [dispatch]);

  const [allSelected, setAllSelected] = useState(
    allBranches.every(branch => branch.isSelected)
  );

  const handleSelect = (branchId, isSelected) => {
    // Find the index of the branch to update
    const branchIndex = availableBranches.findIndex(
      branch => branch.id === branchId
    );

    // Check if branch exists
    if (branchIndex !== -1) {
      const currentSelectedBranch = availableBranches[branchIndex];
      currentSelectedBranch.isSelected = true;

      if (isSelected) {
        setSelectedBranches([...selectedBranches, currentSelectedBranch]);
      } else {
        setSelectedBranches(
          selectedBranches.filter(obj => obj.id !== currentSelectedBranch.id)
        );
      }

      if (
        isSelected &&
        availableBranches.length >= 1 &&
        availableBranches.length === selectedBranches.length + 1
      ) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    }
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedBranches(
        availableBranches.map(b => ({ ...b, isSelected: isSelected }))
      );
    } else {
      setSelectedBranches([]);
    }
    setAllSelected(isSelected);
  };

  const handleUpdate = () => {
    dispatch(
      bulkBranchesUpdate({
        option: "show_in_mobile",
        branches: selectedBranches
      })
    );
  };

  const handleShowHideInMobile = val => {
    toggleActiveStatusForAllBranches(val);
    setActivateBranches(val);
  };

  const { role, privileges } = user;

  if (role === "supervisor" && (!privileges || !privileges.bulkBranchesEdit)) {
    return <ForbiddenPage />;
  }

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Branches Status Bulk Edit</h4>
                </div>
              </div>

              <div className="col-12">
                <div className="card-box d-flex justify-content-center">
                  <Filter action={getCountriesList} isAvailable={true} />
                </div>
              </div>
            </div>

            {!loadingBranches && noContent && (
              <div className="notFound">Not Found</div>
            )}

            {isBulkBranchesUpdateSucceeded && (
              <h3 className="d-flex  mx-auto w-25 badge badge-success justify-content-center p-2">
                Successfully updated
              </h3>
            )}
            {bulkBranchesUpdateErr && (
              <h3 className="d-flex mx-auto w-25  badge badge-danger justify-content-center p-2">
                {bulkBranchesUpdateErr}
              </h3>
            )}

            {bulkBranchesUpdateLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered toggle-circle mb-0">
                  <thead>
                    <tr>
                      <th>
                        <label>
                          <input
                            type="checkbox"
                            className="mx-2 "
                            checked={allSelected}
                            onChange={e => handleSelectAll(e.target.checked)}
                          />
                          Select All
                        </label>
                      </th>
                      <th>
                        <input
                          checked={activateBranches}
                          type="checkbox"
                          onChange={e =>
                            handleShowHideInMobile(e.target.checked)
                          }
                          id=""
                          className="mr-2 my-auto"
                        />
                        Show All selected branches in Mobile App
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableBranches.map(branch => (
                      <tr key={branch.id} style={{ margin: "10px" }}>
                        <td>
                          <label>
                            <input
                              className="mx-2 "
                              type="checkbox"
                              checked={selectedBranches.some(
                                chosenItem =>
                                  (chosenItem.id === branch.id) &
                                  chosenItem.isSelected
                              )}
                              onChange={e =>
                                handleSelect(branch.id, e.target.checked)
                              }
                            />
                            {branch.name.en}
                          </label>
                        </td>
                        <td>
                          <label>
                            {activateBranches ? (
                              <div className="badge bg-soft-success text-success mb-1 pt-1 pb-1">
                                Active
                              </div>
                            ) : (
                              <div className="badge bg-soft-danger text-danger mb-1 pt-1 pb-1">
                                Closed
                              </div>
                            )}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {selectedBranches.length > 0 && (
                  <div className="mx-auto w-25">
                    <button
                      type="button"
                      className=" btn-block my-4 btn btn-success"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchesStatusBulkEdit;

import React, { useState, useEffect } from "react";
import moment from "moment";
import classes from "./workingHours.scss";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

const WorkingHours = ({ workHours, data, disable }) => {
  const [workingHours, setWorkingHours] = useState([
    { day: 0, openTime: "", closeTime: "" },
    { day: 1, openTime: "", closeTime: "" },
    { day: 2, openTime: "", closeTime: "" },
    { day: 3, openTime: "", closeTime: "" },
    { day: 4, openTime: "", closeTime: "" },
    { day: 5, openTime: "", closeTime: "" },
    { day: 6, openTime: "", closeTime: "" }
  ]);
  const daysOfWeek = {
    0: `Sunday`,
    1: `Monday`,
    2: `Tuesday`,
    3: `Wednesday`,
    4: `Thursday`,
    5: `Friday`,
    6: `Saturday`
  };

  useEffect(() => {
    if (data.length > 0) {
      setWorkingHours(data);
    }
  }, [data]);

  return (
    <>
      <ul>
        <li className="workHours">
          <div className="w-25 mr-5">Store Times</div>
          <div className="w-75 d-flex">
            <div className="w-50">Branch</div>
            <div>Delivery</div>
          </div>
        </li>
        {workingHours.map((item, index) => (
          <li key={index} className="workHours border-bottom">
            <div className="w-25 mr-5 d-flex align-items-center">
              {daysOfWeek[item.dayOfWeek]}
            </div>
            <div className="w-75">
              <div className="d-flex">
                <DatePicker
                  disabled={disable}
                  id="morningFrom"
                  placeholderText={moment(item.morningFrom, "HH:mm:ss").format(
                    "HH:mm"
                  )}
                  onChange={e => {
                    item.morningFrom = `${new Date(e).getHours()}:${
                      new Date(e).getMinutes() === 0
                        ? "00"
                        : new Date(e).getMinutes()
                    }`;
                    const arr = [...workingHours];
                    workHours(arr);
                  }}
                  className={classes.time + " w-50"}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                />
                <DatePicker
                  disabled={disable}
                  id="eveningFrom"
                  placeholderText={moment(item.eveningFrom, "HH:mm:ss").format(
                    "HH:mm"
                  )}
                  onChange={e => {
                    item.eveningFrom = `${new Date(e).getHours()}:${
                      new Date(e).getMinutes() === 0
                        ? "00"
                        : new Date(e).getMinutes()
                    }`;
                    const arr = [...workingHours];
                    workHours(arr);
                  }}
                  className={classes.time + " w-50"}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                />
              </div>

              <div className="d-flex">
                <DatePicker
                  disabled={disable}
                  id="morningTill"
                  placeholderText={moment(item.morningTill, "HH:mm:ss").format(
                    "HH:mm"
                  )}
                  onChange={e => {
                    item.morningTill = `${new Date(e).getHours()}:${
                      new Date(e).getMinutes() === 0
                        ? "00"
                        : new Date(e).getMinutes()
                    }`;
                    const arr = [...workingHours];
                    workHours(arr);
                  }}
                  className={classes.time + " w-50"}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                />
                <DatePicker
                  disabled={disable}
                  id="eveningTill"
                  placeholderText={moment(item.eveningTill, "HH:mm:ss").format(
                    "HH:mm"
                  )}
                  onChange={e => {
                    item.eveningTill = `${new Date(e).getHours()}:${
                      new Date(e).getMinutes() === 0
                        ? "00"
                        : new Date(e).getMinutes()
                    }`;
                    const arr = [...workingHours];
                    workHours(arr);
                  }}
                  className={classes.time + " w-50"}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
export default WorkingHours;

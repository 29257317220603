import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import {
  getCancelTime,
  setCancelTime
} from "../../store/actions/orders.actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ToggleButton from "../../components/toggleButton/toggleButton";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrderCancelTime = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const value = useSelector(state => state.orders.value);
  const loadingCancelTime = useSelector(
    state => state.orders.loadingCancelTime
  );
  const isLoadingBtn = useSelector(
    state => state.orders.isloadingCancelTimeBtn
  );
  const [isActive, setIsActive] = useState(false);

  const form = useFormik({
    initialValues: {
      orderCancelTime: ""
    },
    validationSchema: Yup.object({
      orderCancelTime: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(setCancelTime(values.orderCancelTime));
    }
  });

  useEffect(() => {
    dispatch(getCancelTime());
  }, []);

  useEffect(() => {
    form.setValues({
      orderCancelTime: isActive ? "00:00:00" : value
    });
  }, [isActive, value]);

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Order Cancel Time</h4>
                </div>
              </div>
            </div>
            {loadingCancelTime ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="card-box">
                <form onSubmit={form.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="control-label">
                          Order Cancel Time:
                        </label>

                        <Flatpickr
                          className="form-control"
                          name="orderCancelTime"
                          placeholder="Period Time"
                          onClick={() => {
                            form.setFieldTouched("orderCancelTime", true);
                          }}
                          data-enable-time="true"
                          value={form.values.orderCancelTime}
                          onChange={data => {
                            form.setFieldValue(
                              "orderCancelTime",
                              moment(data, "ddd MMM D YYYY HH:mm:ss").format(
                                "HH:mm:ss"
                              )
                            );
                          }}
                          options={{
                            allowInput: true,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i:S",
                            time_24hr: true,
                            enableSeconds: true
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group d-flex">
                        <label className="control-label">No Cancel Time:</label>
                        <code className="ml-1" style={{ paddingTop: "3px" }}>
                          (The customer will not be able to cancel the order).{" "}
                        </code>
                        <div
                          className="ml-1"
                          style={{ paddingTop: "3px" }}
                          onClick={() => setIsActive(!isActive)}
                        >
                          <ToggleButton value={isActive} size={"small"} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <LoadingButton
                      isLoadingBtn={isLoadingBtn}
                      btnContent="Save"
                    />
                  </div>

                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                  />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCancelTime;

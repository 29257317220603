import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setPoint, resetError } from "../../store/actions/points.actions";
import { getProducts } from "../../store/actions/products.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import PointsList from "./pointList";
import "react-toastify/dist/ReactToastify.css";

const orderType = 1;

const CreatePoint = ({ history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const products = useSelector(state => state.products.products);
  const isLoadingBtn = useSelector(state => state.points.isLoadingPointsBtn);
  const pointError = useSelector(state => state.points.pointError);

  const addNewRow = e => {
    formik.setFieldValue("pointCondition", [
      ...formik.values.pointCondition,
      {
        numberOfItems: "",
        numberOfPoints: "",
        pointProduct: ""
      }
    ]);
  };

  const clickOnDelete = record => {
    formik.setFieldValue(
      "pointCondition",
      formik.values.pointCondition.filter(r => r !== record)
    );
  };

  const closeSurvey = () => {
    history.push("/points");
  };

  const formik = useFormik({
    initialValues: {
      pointType: 1,
      expiration: "",
      pointCondition: [
        {
          numberOfItems: "",
          numberOfPoints: "",
          pointProduct: ""
        }
      ],
      year: "00",
      month: "00",
      day: "00",
      hour: "00",
      minute: "00"
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      pointType: Yup.string().required("Required")
    }),
    onSubmit: ({ pointType, pointCondition, expiration }) => {
      if (pointType === orderType) {
        dispatch(
          setPoint({
            pointType: Number(pointType),
            pointCondition: pointCondition.map(i => ({
              numberOfOrders: i.numberOfItems,
              numberOfPoints: i.numberOfPoints,
              pointProduct: i.pointProduct
            })),
            expiration
          })
        );
      } else {
        dispatch(
          setPoint({
            pointType: Number(pointType),
            pointCondition: pointCondition.map(i => ({
              numberOfProducts: i.numberOfItems,
              numberOfPoints: i.numberOfPoints,
              productId: i.pointProduct
            })),
            expiration
          })
        );
      }
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetError());
      }, 500);
    }
  });

  const handleDuration = (value, id) => {
    let minuteValue = document.getElementById(id).value;
    if (minuteValue.length < 2) {
      document.getElementById(id).value = "0" + minuteValue;
      formik.setFieldValue(id, "0" + value);
    } else {
      formik.setFieldValue(id, value);
    }
  };

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    formik.setFieldValue(
      "expiration",
      ...[
        formik.values.year +
          ":" +
          formik.values.month +
          ":" +
          formik.values.day +
          ":" +
          formik.values.hour +
          ":" +
          formik.values.minute
      ]
    );
  }, [
    formik.values.year,
    formik.values.month,
    formik.values.day,
    formik.values.hour,
    formik.values.minute
  ]);

  useEffect(() => {
    if (pointError) {
      toast.error(pointError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }, [pointError]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Create Point</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2"></div>
                    <div className="col-md-12">
                      <div className="modal-content">
                        <div className="modal-body p-4">
                          <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="pointType">
                                    Point Type: &nbsp; &nbsp;{" "}
                                  </label>

                                  <select
                                    id="pointType"
                                    name="pointType"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.pointType}
                                  >
                                    <option value={1}>Order</option>
                                    <option value={2}>Product</option>
                                  </select>
                                  {formik.errors.pointType &&
                                  formik.touched.pointType ? (
                                    <ul className="parsley-errors-list filled">
                                      <li className="parsley-required">
                                        {formik.errors.pointType}
                                      </li>
                                    </ul>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="expiration"
                                    className="control-label mr-2"
                                  >
                                    Point Duration:
                                  </label>
                                  <div id="duration" className="duration">
                                    <input
                                      id="year"
                                      type="number"
                                      min="0"
                                      max="99"
                                      placeholder="00"
                                      className={classNames(
                                        "form-control custom-select-sm"
                                      )}
                                      value={formik.values.year}
                                      onChange={e =>
                                        handleDuration(e.target.value, "year")
                                      }
                                      required
                                    />{" "}
                                    <input
                                      id="month"
                                      type="number"
                                      min="0"
                                      max="99"
                                      placeholder="00"
                                      className={classNames(
                                        "form-control custom-select-sm"
                                      )}
                                      value={formik.values.month}
                                      onChange={e =>
                                        handleDuration(e.target.value, "month")
                                      }
                                      required
                                    />
                                    <input
                                      id="day"
                                      type="number"
                                      min="0"
                                      max="99"
                                      placeholder="00"
                                      className={classNames(
                                        "form-control custom-select-sm"
                                      )}
                                      value={formik.values.day}
                                      onChange={e =>
                                        handleDuration(e.target.value, "day")
                                      }
                                      required
                                    />
                                    <input
                                      id="hour"
                                      type="number"
                                      min="0"
                                      max="99"
                                      placeholder="00"
                                      className={classNames(
                                        "form-control custom-select-sm"
                                      )}
                                      value={formik.values.hour}
                                      onChange={e =>
                                        handleDuration(e.target.value, "hour")
                                      }
                                      required
                                    />
                                    <input
                                      id="minute"
                                      type="number"
                                      min="0"
                                      max="99"
                                      placeholder="00"
                                      className={classNames(
                                        "form-control custom-select-sm"
                                      )}
                                      value={formik.values.minute}
                                      onChange={e =>
                                        handleDuration(e.target.value, "minute")
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <table className="table">
                              <thead>
                                <tr className="d-flex">
                                  <th className="col-md-5 pl-0 text-left">
                                    {Number(formik.values.pointType) === 1
                                      ? "Number of Orders"
                                      : "Number of Products"}
                                  </th>
                                  <th className="col-md-5 pl-0 text-left">
                                    Number of Points
                                  </th>
                                  <th className="col-md-2 pl-0"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <PointsList
                                  add={addNewRow}
                                  delete={clickOnDelete}
                                  taskList={formik.values.pointCondition}
                                  formik={formik}
                                  products={products}
                                />
                              </tbody>
                            </table>

                            <div className="modal-footer mt-3">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-dismiss="modal"
                                onClick={closeSurvey}
                              >
                                Close
                              </button>
                              <LoadingButton
                                isLoadingBtn={isLoadingBtn}
                                btnContent="Save"
                              />
                            </div>
                            <ToastContainer
                              position="top-right"
                              autoClose={3000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnVisibilityChange
                              draggable
                              pauseOnHover
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePoint;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getReports } from "../../store/actions/feedback.actions";
import { getCategories } from "../../store/actions/categories.actions";
import { getProducts } from "../../store/actions/products.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import DatePicker from "react-datepicker";
import "./index.scss";
import { useFormik } from "formik";
import Chart from "components/Chart";
import TableComponent from "../../components/tableComponent";
import {
  reportTypes,
  reportTypesUpper,
  xaxis,
  tableHead1,
  tableHead2,
  tableHead3,
  tableHead4,
  tableHead5,
  orderStatus
} from "./constants";

const onlyMonth = "MMMM";
const dateString = "dd-MM-yy";
const chartReportTypes = [
  reportTypesUpper.MONTH_OVER_MONTH,
  reportTypesUpper.WEEK_OVER_WEEK,
  reportTypesUpper.DAY_OVER_DAY,
  reportTypesUpper.ONLINE_SALES_TARGET
];

const Reports = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const [
    filteredCategorySelectValue,
    setfilteredCategorySelectValue
  ] = useState("");

  const { isLoadingReports, reports } = useSelector(state => state.feedback);
  const categories = useSelector(state => state.categories.categories || []);
  const products = useSelector(state => state.products.products);

  const [productSizes, setProductSizes] = useState([]);
  const [pickerParams, setPickerParams] = useState({});
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  const [tableBody, setTableBody] = useState([]);
  const [tableHead, setTableHead] = useState([]);

  const formik = useFormik({
    initialValues: {
      dateTo: "",
      dateFrom: "",
      fileType: "pdf",
      reportType: reportTypesUpper.TOTAL_SALES,
      numberOfOrders: "",
      orderStatus: 0,
      phoneNumber: "",
      targetSales: 0
    }
  });

  const { valuesByFirstDate, valuesBySecondDate } = reports;

  useEffect(() => {
    const params = {};
    const {
      reportType,
      dateFrom,
      dateTo,
      numberOfOrders,
      orderStatus,
      phoneNumber
    } = formik.values;
    if (dateFrom) {
      params.timeFrom = new Date(dateFrom).toISOString();
    }
    if (dateTo) {
      params.timeTo = new Date(dateTo).toISOString();
    }
    if (reportType) {
      if (
        reportType === reportTypesUpper.DAY_OVER_DAY ||
        reportType === reportTypesUpper.WEEK_OVER_WEEK ||
        reportType === reportTypesUpper.MONTH_OVER_MONTH
      ) {
        params.reportType = reportTypesUpper.COMPARE_DATES;
        params.dateType = reportType.split("_")[0];
      } else if (reportType === reportTypesUpper.CUSTOMERS_OVER) {
        params.reportType = reportTypesUpper.CUSTOMERS_BY_ORDERS;
      } else if (reportType === reportTypesUpper.CUSTOMERS_WITH) {
        params.reportType = reportTypesUpper.CUSTOMERS_BY_ORDERS;
        params.numberOfOrders = numberOfOrders;
      } else if (reportType === reportTypesUpper.AVERAGE_CHECK) {
        params.reportType = reportTypesUpper.AVERAGE_CHECK;
        params.orderStatusType = orderStatus;
      } else if (reportType === reportTypesUpper.CUSTOMERS_BY_PHONE_NUMBER) {
        if (phoneNumber.length === 13) {
          params.reportType = reportType;
          params.phoneNumber = phoneNumber;
        } else return;
      } else if (reportType === reportTypesUpper.PROFIT_PRODUCT) {
        if (filteredCategorySelectValue && selectedProduct && selectedSize) {
          params.reportType = reportType;
          params.productId = selectedProduct;
          params.productSizeId = selectedSize;
        }
      } else if (reportType === reportTypesUpper.ONLINE_SALES_TARGET) {
        params.reportType = reportTypesUpper.TOTAL_SALES;
      } else {
        params.reportType = formik.values.reportType;
      }
    }
    dispatch(getReports(params));
  }, [
    dispatch,
    formik.values.dateTo,
    formik.values.dateFrom,
    formik.values.reportType,
    formik.values.numberOfOrders,
    formik.values.orderStatus,
    formik.values.phoneNumber,
    selectedSize
  ]);

  useEffect(() => {
    const { reportType } = formik.values;
    if (reportType === reportTypesUpper.MONTH_OVER_MONTH) {
      setPickerParams({
        showMonthYearPicker: true,
        dateFormat: onlyMonth,
        placeholderText: "Month"
      });
    } else if (reportType === reportTypesUpper.DAY_OVER_DAY) {
      setPickerParams({
        placeholderText: "Day"
      });
    } else if (reportType === reportTypesUpper.CUSTOMERS_WITHOUT_ORDERS) {
      setTableHead(tableHead2);
    } else if (
      reportType === reportTypesUpper.CUSTOMERS_WITH ||
      reportType === reportTypesUpper.CUSTOMERS_OVER
    ) {
      setTableHead(tableHead1);
    } else if (reportType === reportTypesUpper.AVERAGE_CHECK) {
      setTableHead(tableHead3);
    } else if (reportType === reportTypesUpper.PROFIT_ALL_PRODUCTS) {
      setTableHead(tableHead4);
    } else if (reportType === reportTypesUpper.PROFIT_PRODUCT) {
      setTableHead(tableHead5);
    } else {
      setPickerParams({});
      setTableHead(tableHead1);
    }
  }, [formik.values.reportType]);

  const getDatePickers = () => (
    <>
      <div className="col-md-2">
        <DatePicker
          dateFormat={dateString}
          selected={formik.values.dateFrom}
          onChange={e => {
            formik.setValues({
              ...formik.values,
              dateFrom: e
            });
          }}
          className="datepicker"
          {...pickerParams}
          placeholderText={
            pickerParams.placeholderText
              ? pickerParams.placeholderText + "#1"
              : "DateFrom"
          }
        />
      </div>
      <div className="col-md-2">
        <DatePicker
          dateFormat={dateString}
          selected={formik.values.dateTo}
          onChange={e => {
            formik.setValues({
              ...formik.values,
              dateTo: e
            });
          }}
          className="datepicker"
          {...pickerParams}
          placeholderText={
            pickerParams.placeholderText
              ? pickerParams.placeholderText + "#2"
              : "DateFrom"
          }
        />
      </div>
    </>
  );

  const handleClick = () => {
    const params = {};
    if (formik.values.dateFrom) {
      params.timeFrom = new Date(formik.values.dateFrom).toISOString();
    }
    if (formik.values.dateTo) {
      params.timeTo = new Date(formik.values.dateTo).toISOString();
    }
    if (formik.values.fileType) {
      params.fileType = formik.values.fileType;
    }
    if (formik.values.reportType) {
      params.reportType = formik.values.reportType;
    }
    dispatch(getReports(params));
  };

  useEffect(() => {
    if (reports && reports.topCustomerByOrders) {
      setTableBody([reports.topCustomerByOrders]);
    } else if (reports && reports.customers && reports.customers.length) {
      setTableBody(reports.customers);
    } else if (
      reports &&
      formik.values.reportType === reportTypesUpper.PROFIT_ALL_PRODUCTS
    ) {
      setTableBody(reports.data);
    } else {
      setTableBody([reports]);
    }
  }, [reports]);

  useEffect(() => {
    dispatch(getCategories({ pagination: { limit: null, page: null } }));
  }, []);

  useEffect(() => {
    if (filteredCategorySelectValue) {
      dispatch(
        getProducts({
          pagination: { page: null, limit: null },
          search: null,
          filteredCategory: filteredCategorySelectValue
        })
      );
    }
  }, [filteredCategorySelectValue]);

  useEffect(() => {
    if (products.length && selectedProduct) {
      const product = products.find(product => product.id === selectedProduct);
      if (products && product.sizes) {
        setProductSizes(product && product.sizes);
      }
    }
  }, [products, selectedProduct]);

  const loader = () => (
    <div className="d-flex justify-content-center mt-5">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  );

  const filters = () => (
    <div className="row">
      <div className="col-md-12 d-flex align-items-center">
        <div className="form-group mr-2">
          <label htmlFor="category-select" className="mr-2">
            Category:
          </label>
          <select
            className="custom-select"
            id="category-select"
            value={filteredCategorySelectValue}
            onChange={e => {
              setProductSizes([]);
              setfilteredCategorySelectValue(e.target.value);
              // setCategoryQuery(e.target.value);
            }}
          >
            {filteredCategorySelectValue ? null : (
              <option value="">Select a category:</option>
            )}
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name.en}{" "}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mr-2">
          <label htmlFor="products-select" className="mr-2">
            Product:
          </label>
          <select
            className="custom-select"
            value={selectedProduct}
            onChange={e => {
              setProductSizes([]);
              setSelectedProduct(e.target.value);
            }}
            id="products-select"
          >
            {<option value="">Select a product:</option>}
            {products.map(product => (
              <option key={product.id} value={product.id}>
                {product.name.en}{" "}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mr-2">
          <label htmlFor="size-select" className="mr-2">
            Product Sizes:
          </label>
          <select
            className="custom-select"
            value={selectedSize}
            onChange={e => {
              setSelectedSize(e.target.value);
            }}
            id="size-select"
          >
            <option value="">Select a Size:</option>
            {productSizes.map(size => (
              <option key={size.id} value={size.id}>
                {size.name.en}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  const header = () => (
    <div className="row">
      <div className="col-sm-12">
        <div className="card-box paddingNone">
          <div className="row mb-2">
            <div className="col-md-12 d-flex align-items-center flex-wrap">
              <div className="text-sm-center form-inline">
                <label htmlFor="example-select">
                  File Type : &nbsp; &nbsp;{" "}
                </label>
                <div className="form-group mr-2">
                  <select
                    id="demo-foo-filter-status"
                    className="custom-select custom-select-sm"
                    name="fileType"
                    onChange={formik.handleChange}
                    value={formik.values.fileType}
                  >
                    <option value={"pdf"}>PDF</option>
                    <option value={"word"}>Word</option>
                    <option value={"excel"}>Excel</option>
                  </select>
                </div>
              </div>

              <div className="text-sm-center form-inline">
                <label htmlFor="example-select">
                  Report Type : &nbsp; &nbsp;{" "}
                </label>
                <div className="form-group mr-3">
                  <select
                    id="demo-foo-filter-status"
                    className="custom-select custom-select-sm"
                    name="reportType"
                    onChange={formik.handleChange}
                    value={formik.values.reportType}
                  >
                    {Object.entries(reportTypes).map(i => (
                      <option key={i[0]} value={i[0]}>
                        {i[1]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {formik.values.reportType === reportTypesUpper.CUSTOMERS_WITH && (
                <div className="text-sm-center form-inline">
                  <div className="form-group mr-2">
                    <input
                      min="1"
                      type="number"
                      name="numberOfOrders"
                      id="numberOfOrders"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.numberOfOrders}
                      placeholder="Number of orders"
                    />
                  </div>
                </div>
              )}
              {formik.values.reportType === reportTypesUpper.AVERAGE_CHECK && (
                <div className="text-sm-center form-inline">
                  <label htmlFor="example-select">
                    Order status : &nbsp; &nbsp;{" "}
                  </label>
                  <div className="form-group mr-3">
                    <select
                      id="demo-foo-filter-status"
                      className="custom-select custom-select-sm"
                      name="orderStatus"
                      onChange={formik.handleChange}
                      value={formik.values.orderStatus}
                    >
                      {orderStatus.map((item, index) => (
                        <option key={index} value={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {formik.values.reportType ===
                reportTypesUpper.CUSTOMERS_BY_PHONE_NUMBER && (
                <div className="text-sm-center form-inline">
                  <div className="form-group mr-2">
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      placeholder="+966555555555"
                    />
                  </div>
                </div>
              )}

              {formik.values.reportType ===
                reportTypesUpper.ONLINE_SALES_TARGET && (
                <div className="text-sm-center form-inline">
                  <label htmlFor="targetSales">
                    Target Sales: &nbsp; &nbsp;{" "}
                  </label>
                  <div className="form-group mr-2">
                    <input
                      type="number"
                      name="targetSales"
                      id="targetSales"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.targetSales}
                    />
                  </div>
                </div>
              )}

              {formik.values.reportType !== reportTypesUpper.WEEK_OVER_WEEK &&
                formik.values.reportType !== reportTypesUpper.CUSTOMERS_WITH &&
                formik.values.reportType !== reportTypesUpper.CUSTOMERS_OVER &&
                formik.values.reportType !==
                  reportTypesUpper.CUSTOMERS_BY_PHONE_NUMBER &&
                formik.values.reportType !== reportTypesUpper.PROFIT_PRODUCT &&
                getDatePickers()}

              <div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light  custom-select-sm"
                  onClick={handleClick}
                >
                  <i className="mr-1">
                    <span style={{ color: "#fff" }}>Save File</span>
                  </i>
                </button>
              </div>
            </div>
          </div>
          {formik.values.reportType === reportTypesUpper.PROFIT_PRODUCT &&
            filters()}
        </div>
      </div>
    </div>
  );

  const chart = (dataA = {}, dataB = {}) => {
    let firstSerie = dataA;
    let secondSerie = dataB;
    let endingShape = "rounded";
    let series = [Object.values(firstSerie), Object.values(secondSerie)];

    if (formik.values.reportType === reportTypesUpper.ONLINE_SALES_TARGET) {
      firstSerie = {
        targetSales: formik.values.targetSales || 0,
        totalSales: reports.totalPrice || 0
      };
      series = [Object.values(firstSerie)];
      endingShape = "flat";
    }
    return (
      <div className="card-box">
        <Chart
          series={series}
          xaxis={Object.keys(firstSerie).map(i => xaxis[i])}
          endingShape={endingShape}
        />
      </div>
    );
  };

  const table = () => (
    <div className="row card-box">
      {formik.values.reportType !== reportTypesUpper.CUSTOMERS_WITHOUT_ORDERS &&
        formik.values.reportType !== reportTypesUpper.CUSTOMERS_OVER && (
          // formik.values.reportType !== reportTypesUpper.AVERAGE_CHECK &&
          <>
            <div className="d-flex mb-2">
              {String(reports.orders) ? (
                <div className="mx-2">Number of Orders: {reports.orders}</div>
              ) : null}
              {reports && reports.totalPrice ? (
                <div className="mx-2">Total Sales: {reports.totalPrice}</div>
              ) : null}
              {reports && reports.count ? (
                <div className="mx-2">Average Check: {reports.count}</div>
              ) : null}
            </div>

            {formik.values.reportType !==
              reportTypesUpper.CUSTOMERS_BY_PHONE_NUMBER && (
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Top Customer by Orders</h4>
                </div>
              </div>
            )}
          </>
        )}
      <TableComponent tableHead={tableHead} tableBody={tableBody} />
    </div>
  );

  return (
    <div id="wrapper" className="reports">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          {isLoadingReports ? (
            loader()
          ) : (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title">Reports</h4>
                  </div>
                </div>
              </div>

              {header()}
              {chartReportTypes.includes(formik.values.reportType)
                ? chart(valuesByFirstDate, valuesBySecondDate)
                : table()}
              {/* {valuesByFirstDate && valuesBySecondDate  ? chart(valuesByFirstDate, valuesBySecondDate ) : table()} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;

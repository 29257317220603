import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { getRelatedUpsell } from "../../store/actions/upsell.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModalRelatedProduct from "../../components/modal/modalRelatedProduct/modalRelatedProduct";
import TableComponent from "../../components/tableComponent";
import ModalDelete from "../../components/modal/modalDelete/modalDelete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RelatedProductUpsell = () => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const dispatch = useDispatch();
  const relatedUpsells = useSelector(state => state.upsell.relatedUpsells);
  const loadingRelatedUpsell = useSelector(
    state => state.upsell.loadingRelatedUpsell
  );
  const errorMessage = useSelector(state => state.upsell.relatedMSG53);

  const tableHead = [
    {
      name: "Product",
      key: "productToRelated",
      value: "products"
    },
    {
      name: "Related Product",
      key: "relatedProduct",
      value: "products"
    },

    {
      name: "Delete",
      key: "delete",
      return: "id",
      onClick: id => {
        toggle();
        setUpsellId(id);
      }
    }
  ];

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [upsellId, setUpsellId] = useState("");

  const [relatedProduct, setRelatedProduct] = useState(false);
  const addNewRelatedProduct = () => setRelatedProduct(!relatedProduct);

  useEffect(() => {
    dispatch(getRelatedUpsell());
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage === "MSG53") {
      toast.error("Upsell is already exists", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }, [errorMessage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Related Products Upsell</h4>
                </div>
              </div>
            </div>
            {loadingRelatedUpsell ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="card-box">
                <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="text-md-right">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                        onClick={addNewRelatedProduct}
                      >
                        <i className="mdi mdi-plus mr-1"></i> Add
                      </button>
                    </div>
                    <TableComponent
                      tableHead={tableHead}
                      tableBody={relatedUpsells}
                    />
                  </div>
                </div>
              </div>
            )}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
          <ModalDelete
            modal={modal}
            toggle={toggle}
            id={upsellId}
            name="RelatedUpsell"
          />

          <ModalRelatedProduct
            modal={relatedProduct}
            toggle={addNewRelatedProduct}
          />
        </div>
      </div>
    </div>
  );
};

export default RelatedProductUpsell;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { useLocation } from "react-router-dom";

import moment from "moment";
import {
  getAllOrders,
  getReceiptExpiration,
  setReceiptExpiration,
  addOrder
} from "../../store/actions/orders.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import { useFormik } from "formik";
import socketIOClient from "socket.io-client";
import { SOCKET_URL } from "../../constants";
import Pagination from "../../components/pagination/pagination";
import { displayPaymentMethod, paymentType } from "./utils";

export const orderStatus = {
  1: "Pending",

  2: "Active",

  3: "Void",

  4: "Done",

  5: "Payment Failed",

  6: "Foodics Failed"
};

const orderType = {
  1: "Dine in",

  2: "Take Away",

  3: "Pickup",

  4: "Delivery",

  5: "Drive Thru"
};

const Orders = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const { orders, loadingOrders, receiptExpirationTime } = useSelector(
    state => state.orders
  );

  const countOrders = useSelector(state => state.orders.countOrders);

  const [searchQueryTag, setSearchQueryTag] = useQueryParam(
    "foodicsId",
    StringParam
  );
  const [phoneQueryNumber, setPhoneQueryNumber] = useQueryParam(
    "phoneNumber",
    StringParam
  );
  const [statusQueryParam, setStatusQueryParam] = useQueryParam(
    "orderStatus",
    NumberParam
  );
  const [orderIdQuery, setOrderIdQuery] = useQueryParam("orderId", StringParam);

  const [dateFromParam, setDateFromParam] = useQueryParam(
    "dateFrom",
    StringParam
  );
  const [dateToParam, setDateToParam] = useQueryParam("dateTo", StringParam);

  const [, setStatus] = useState(statusQueryParam || 0);
  const [, setDateFrom] = useState(dateFromParam || "");
  const [, setDateTo] = useState(dateToParam || "");
  const [searchTag, setSearchTag] = useState(searchQueryTag || "");
  const [orderIdTag, setOrderIdTag] = useState(orderIdQuery || "");
  const [searchNumber, setSearchNumber] = useState(phoneQueryNumber || "");

  //

  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);

  const [search, setSearch] = useState(searchQuery || "");
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setSearch(searchQuery || "");
  });

  //

  const formik = useFormik({
    initialValues: {
      orderStatus: "",
      dateTo: "",
      dateFrom: "",
      foodicsId: "",
      phoneNumber: "",
      receiptExpirationTime: "",
      orderId: ""
    }
  });

  useEffect(() => {
    setStatus(statusQueryParam || 1);
  });

  useEffect(() => {
    setDateFrom(dateFromParam || 1);
  });

  useEffect(() => {
    setDateTo(dateToParam || 1);
  });

  useEffect(() => {
    setSearchTag(searchQueryTag || "");
  });

  useEffect(() => {
    setSearchNumber(phoneQueryNumber || "");
  });

  useEffect(() => {
    setOrderIdTag(orderIdQuery || "");
  });

  useEffect(() => {
    const params = {
      foodicsId: searchTag,
      phoneNumber: searchNumber,
      orderId: orderIdTag
    };
    if (formik.values.orderStatus) {
      params.orderStatus = formik.values.orderStatus;
    }
    if (formik.values.dateFrom) {
      params.dateFrom = new Date(formik.values.dateFrom).toISOString();
    }
    if (formik.values.dateTo) {
      params.dateTo = new Date(formik.values.dateTo).toISOString();
    }
    dispatch(
      getAllOrders({
        ...params,
        pagination: { page: currentPage, limit: rowsPerPage }
      })
    );
  }, [
    dispatch,
    searchTag,
    searchNumber,
    formik.values.orderStatus,
    formik.values.dateTo,
    formik.values.dateFrom,
    currentPage,
    rowsPerPage,
    orderIdTag,
    location
  ]);

  // useEffect(() => {
  //   dispatch(getReceiptExpiration());
  // }, [dispatch]);

  useEffect(() => {
    formik.setFieldValue("receiptExpirationTime", receiptExpirationTime);
  }, [receiptExpirationTime]);

  useEffect(() => {
    const socket = socketIOClient(SOCKET_URL, {
      path: "/orders",
      transports: ["websocket"]
    });
    socket.on("connect", () => {
      socket.emit("joinRoom", { orderId: "orderToDashboard" });
    });
    socket.on("created", data => dispatch(addOrder(data)));
  }, []);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Orders</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row mb-2">
                  <div className="col-md-12"></div>
                </div>
                {loadingOrders ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border avatar-lg"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <div>
                    <div className="card">
                      <div className="card-body">
                        <div className="col-md-12">
                          <form
                            action=""
                            className="form-inline  d-flex justify-content-between align-items-end "
                          >
                            <div className="form-group d-flex flex-column align-items-start">
                              <label
                                htmlFor="receiptExpirationTime"
                                className="mb-2"
                              >
                                Receipt Expiration Time
                              </label>
                              <DebounceInput
                                id="receiptExpirationTime"
                                type="text"
                                minLength={1}
                                className="form-control pt-0 pb-0"
                                debounceTimeout={800}
                                value={formik.values.receiptExpirationTime}
                                onChange={formik.handleChange}
                                name="receiptExpirationTime"
                                placeholder="DD:HH:MM"
                              />
                            </div>
                            <button
                              className="btn btn-default bg-success"
                              onClick={() => {
                                if (
                                  formik.values.receiptExpirationTime !==
                                  receiptExpirationTime
                                ) {
                                  dispatch(
                                    setReceiptExpiration(
                                      formik.values.receiptExpirationTime
                                    )
                                  );
                                }
                              }}
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <div className="col-md-12 d-flex pr-0">
                          <div className="col-md-4">
                            <div className="form-group mb-2">
                              <DebounceInput
                                type="text"
                                minLength={1}
                                className="form-control pt-0 pb-0"
                                debounceTimeout={2000}
                                value={searchQueryTag}
                                onChange={e => {
                                  setCurrentPage(1);
                                  setPageQuery(1);
                                  setSearchQueryTag(e.target.value);
                                }}
                                placeholder="Foodics id"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-2">
                              <DebounceInput
                                type="text"
                                minLength={1}
                                className="form-control pt-0 pb-0"
                                debounceTimeout={2000}
                                value={phoneQueryNumber}
                                onChange={e => {
                                  setCurrentPage(1);
                                  setPageQuery(1);
                                  setPhoneQueryNumber(e.target.value);
                                }}
                                placeholder="Customer phone number"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-2">
                              <DebounceInput
                                type="text"
                                minLength={1}
                                className="form-control pt-0 pb-0"
                                debounceTimeout={2000}
                                value={orderIdQuery}
                                onChange={e => {
                                  setCurrentPage(1);
                                  setPageQuery(1);
                                  setOrderIdQuery(e.target.value);
                                }}
                                placeholder="Order id"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex pl-4 mb-2">
                        <div className="col-md-4">
                          <div className="form-group mb-2">
                            <DatePicker
                              dateFormat="dd-MM-yy"
                              placeholderText="Date From"
                              selected={formik.values.dateFrom}
                              onChange={e => {
                                setDateFromParam(e);
                                formik.setValues({
                                  ...formik.values,
                                  dateFrom: e
                                });
                              }}
                              className="datepicker"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mb-2">
                            <DatePicker
                              dateFormat="dd-MM-yy"
                              placeholderText="Date To"
                              selected={formik.values.dateTo}
                              onChange={e => {
                                setDateToParam(e);
                                formik.setValues({
                                  ...formik.values,
                                  dateTo: e
                                });
                              }}
                              className="datepicker"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="form-group mb-2">
                            <select
                              name="orderStatus"
                              className="custom-select"
                              onChange={e => {
                                setStatusQueryParam(e.target.value);
                                return formik.handleChange(e);
                              }}
                              value={formik.values.orderStatus}
                            >
                              <option value="">Status</option>
                              {orderStatus.length > 0 &&
                                Object.entries(orderStatus).map(i => (
                                  <option key={i[0]} value={i[0]}>
                                    {i[1]}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {orders.length > 0 && (
                      <>
                        {orders.map(item => (
                          <div className="card" key={item.id}>
                            <div className="card-body">
                              <table className="table table-centered table-borderless table-hover mb-1">
                                <thead className="thead-light">
                                  <tr>
                                    {item.newOrder && (
                                      <th>
                                        <span className="badge bg-soft-success text-success">
                                          New Order
                                        </span>
                                      </th>
                                    )}
                                    <th>{item.id}</th>

                                    <th>
                                      {item.status !== 5 &&
                                      item.status !== 6 ? (
                                        <a
                                          href={`https://console.foodics.com/orders/${item.foodicsId}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.foodicsId}
                                        </a>
                                      ) : item.error.message.error ? (
                                        item.error.message.error
                                      ) : (
                                        item.error.message
                                      )}
                                    </th>
                                    <th>
                                      {moment
                                        .utc(item.orderDate)
                                        .local()
                                        .format("DD/MM/YY hh:mm")}
                                    </th>
                                    <th>{item.finalPrice} SR</th>
                                    <th>
                                      {item.isRefunded
                                        ? "Refunded"
                                        : "Not Refunded"}
                                    </th>
                                    <th></th>
                                    <th></th>
                                    {!item.newOrder && <th></th>}
                                    {/* <th></th> */}
                                  </tr>
                                </thead>
                                <thead className="thead-dark">
                                  <tr>
                                    {/* <td>Location</td> */}
                                    <td>Name</td>
                                    <td>Mobile Number</td>
                                    <td>Source: </td>
                                    <td>Payment Type</td>
                                    <td>Order Type</td>
                                    <td>Branch</td>
                                    <td>Status</td>
                                    <td>Details</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {/* <td>Customer Location: </td> */}
                                    <td>
                                      {item.customer && item.customer.firstName}
                                      {item.customer && item.customer.lastName}
                                    </td>
                                    <td>
                                      {item.customer && item.customer.phone}
                                    </td>
                                    <td>
                                      {item.tags &&
                                        item.tags.length > 0 &&
                                        item.tags.map(i => (
                                          <div key={i}>{i}</div>
                                        ))}
                                    </td>
                                    <td>
                                      {`
                                      
                                          ${
                                            !item.payments.length
                                              ? "Cash on Delivery"
                                              : item.payments.map(payment =>
                                                  paymentType(
                                                    payment.paymentMethodHid
                                                  )
                                                )
                                          }
                                            `}
                                    </td>
                                    <td>{orderType[item.type]}</td>
                                    <td>
                                      {item.branches && item.branches.name.en}
                                    </td>
                                    <td>{orderStatus[item.status]}</td>
                                    <td>
                                      <Link
                                        to={`/customers/${item.customer &&
                                          item.customer.foodicsId}/${item.id}`}
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}

                        <div className="row">
                          <div className="col-12">
                            <Pagination
                              totalCount={countOrders}
                              rows={rowsPerPage}
                              currentPage={currentPage}
                              setCurrentPage={setPageQuery}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "../../helpers/usePrevious";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import {
  getProducts,
  updateProductsOrder
} from "../../store/actions/products.actions";
import { getCategories } from "../../store/actions/categories.actions";
import Pagination from "../../components/pagination/pagination";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import avatar from "../../assets/images/small/defaultCategory.png";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import classNames from "classnames";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ProductsArrangement = () => {
  const [items, setItems] = useState([]);
  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [categoryQuery, setCategoryQuery] = useQueryParam(
    "category",
    StringParam
  );

  const [filteredCategory, setFilteredCategory] = useState(categoryQuery || "");
  const [
    filteredCategorySelectValue,
    setfilteredCategorySelectValue
  ] = useState(categoryQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(100);
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const dispatch = useDispatch();
  const products = useSelector(state => state.products.products);
  const categories = useSelector(state => state.categories.categories || []);
  const countProduct = useSelector(state => state.products.countProduct);
  const isLoadingProducts = useSelector(
    state => state.products.isLoadingProducts
  );

  const previousCurrentPageValue = usePrevious(currentPage);
  const previousFilteredCategoryValue = usePrevious(filteredCategory);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setFilteredCategory(categoryQuery || "");
  });

  useEffect(() => {
    if (
      previousFilteredCategoryValue !== undefined &&
      filteredCategory !== undefined &&
      previousFilteredCategoryValue !== filteredCategory
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }
    dispatch(
      getProducts({
        pagination: { page: "", limit: "" },
        filteredCategory
      })
    );
  }, [dispatch, currentPage, rowsPerPage, filteredCategory]);

  useEffect(() => {
    dispatch(getCategories({ pagination: { limit: "", page: "" } }));
  }, []);

  useEffect(() => {
    setItems(products.sort((a, b) => a.order - b.order));
  }, [products]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const item = reorder(items, result.source.index, result.destination.index);
    setItems(item);
    dispatch(
      updateProductsOrder(item.map((i, index) => ({ id: i.id, order: index })))
    );
  };

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Products Arrangement</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-inline">
                        <div className="form-group mx-sm-3">
                          <label htmlFor="status-select" className="mr-2">
                            Category
                          </label>
                          <select
                            className="custom-select"
                            id="status-select"
                            value={filteredCategorySelectValue}
                            onChange={e => {
                              setfilteredCategorySelectValue(e.target.value);
                              setCategoryQuery(e.target.value);
                            }}
                          >
                            <option value="">All </option>
                            {categories.map(category => (
                              <option key={category.id} value={category.id}>
                                {category.name.en}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      {isLoadingProducts ? (
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border avatar-lg"
                            role="status"
                          ></div>
                        </div>
                      ) : products.length ? (
                        <div className="table-responsive">
                          <DragDropContext onDragEnd={onDragEnd}>
                            <table className="table table-centered table-borderless table-hover mb-0">
                              <thead className="thead-light">
                                <tr>
                                  <th>Image</th>
                                  <th>Name English</th>
                                  <th>Name Arabic</th>
                                </tr>
                              </thead>

                              <Droppable droppableId="droppable">
                                {provided => {
                                  return (
                                    <tbody ref={provided.innerRef}>
                                      {items.map((item, index) => (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {provided => (
                                            <tr
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              id={item.id}
                                              {...provided.dragHandleProps}
                                            >
                                              <td className="table-user">
                                                <img
                                                  alt="table-user"
                                                  className="mr-2 rounded-circle"
                                                  src={item.imagePath || avatar}
                                                />
                                              </td>
                                              <td>{item.name.en}</td>
                                              <td>{item.name.ar}</td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </tbody>
                                  );
                                }}
                              </Droppable>
                            </table>
                          </DragDropContext>
                        </div>
                      ) : (
                        <div className="notFound">Not Found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Pagination
                  totalCount={countProduct}
                  rows={rowsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setPageQuery}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsArrangement;

import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updatePoint } from "../../../store/actions/points.actions";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

export default function ModalPoint({
  products,
  point,
  pointType,
  modal,
  onClose
}) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      pointProduct: point.productId || "--",
      numberOfItems: point.numberOfOrders || point.numberOfProducts,
      numberOfPoints: point.numberOfPoints,
      pointId: point.pointId,
      id: point.id
    },
    validationSchema: Yup.object().shape({
      pointProduct: Yup.string().required("Required"),
      numberOfItems: Yup.string().required("Required"),
      numberOfPoints: Yup.string().required("Required")
    }),
    onSubmit: values => {
      if (Number(pointType) === 1) {
        dispatch(
          updatePoint({
            pointId: values.pointId,
            pointCondition: [
              {
                id: values.id,
                numberOfOrders: values.numberOfItems,
                numberOfPoints: values.numberOfPoints
              }
            ]
          })
        );
      } else {
        dispatch(
          updatePoint({
            pointId: values.pointId,
            pointCondition: [
              {
                id: values.id,
                numberOfProducts: values.numberOfItems,
                numberOfPoints: values.numberOfPoints,
                productId: values.pointProduct
              }
            ]
          })
        );
      }
      onClose();
    }
  });

  return (
    <Modal isOpen={modal} toggle={onClose}>
      <ModalHeader toggle={onClose}>Edit Point</ModalHeader>
      <form className="modal-body p-4" onSubmit={formik.handleSubmit}>
        {Number(pointType) === 2 && (
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="pointProduct" className="control-label">
                  Select Product
                </label>
                <select
                  className="custom-select custom-select-sm"
                  value={formik.values.pointProduct}
                  name="pointProduct"
                  onChange={formik.handleChange}
                >
                  <option value="">--</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name.en}
                    </option>
                  ))}
                </select>
                {formik.errors.pointProduct && formik.touched.pointProduct ? (
                  <ul className="parsley-errors-list filled">
                    <li className="parsley-required">
                      {formik.errors.pointProduct}
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="numberOfItems" className="control-label">
                {Number(pointType) === 1
                  ? "Number of Orders"
                  : "Number of Products"}
              </label>
              <input
                type="number"
                className="form-control"
                name="numberOfItems"
                placeholder={
                  Number(pointType) === 1
                    ? "Number of Orders"
                    : "Number of Products"
                }
                value={formik.values.numberOfItems}
                onChange={formik.handleChange}
                onClick={() => {
                  formik.setFieldTouched("numberOfItems", true);
                }}
              />
              {formik.errors.numberOfItems && formik.touched.numberOfItems ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.numberOfItems}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="numberOfPoints" className="control-label">
                Number of Points
              </label>
              <input
                type="number"
                className="form-control"
                name="numberOfPoints"
                placeholder="Number of Points"
                value={formik.values.numberOfPoints}
                onChange={formik.handleChange}
                onClick={() => {
                  formik.setFieldTouched("numberOfPoints", true);
                }}
              />
              {formik.errors.numberOfPoints && formik.touched.numberOfPoints ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.numberOfPoints}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              onClose();
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button color="success" type="submit">
            Save Changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import axios from "axios";
import { API_URL } from "../constants";
import { StorageService } from "../services/storage.service";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const requestHandler = (request) => {
  const token = StorageService.getToken();

  if (token) {
    request.headers.Authorization = `${token}`;
  }
  request.headers["Access-Control-Allow-Origin"] = "*";
  return request;
};

const errorHandler = async (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location = "/login";
    return;
  }
  if (error.response.status === 403) {
    window.location = "/403";
    return;
  }

  if (error.response.status === 404) {
    window.location = "/404";
    return;
  }

  if (error.response.status >= 400) {
    await Promise.reject(error.response);
  }
};

const successHandler = (response) => {
  return response;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;

export const upsellActionsTypes = {
  GET_FIXED_UPSELL: "GET_FIXED_UPSELL",
  GET_FIXED_UPSELL_SUCCESS: "GET_FIXED_UPSELL_SUCCESS",
  GET_FIXED_UPSELL_FAILURE: "GET_FIXED_UPSELL_FAILURE",

  SET_FIXED_UPSELL: "SET_FIXED_UPSELL",
  SET_FIXED_UPSELL_SUCCESS: "SET_FIXED_UPSELL_SUCCESS",
  SET_FIXED_UPSELL_FAILURE: "SET_FIXED_UPSELL_FAILURE",

  DELETE_FIXED_UPSELL: "DELETE_FIXED_UPSELL",
  DELETE_FIXED_UPSELL_SUCCESS: "DELETE_FIXED_UPSELL_SUCCESS",
  DELETE_FIXED_UPSELL_FAILURE: "DELETE_FIXED_UPSELL_FAILURE",

  GET_RELATED_UPSELL: "GET_RELATED_UPSELL",
  GET_RELATED_UPSELL_SUCCESS: "GET_RELATED_UPSELL_SUCCESS",
  GET_RELATED_UPSELL_FAILURE: "GET_RELATED_UPSELL_FAILURE",

  SET_RELATED_UPSELL: "SET_RELATED_UPSELL",
  SET_RELATED_UPSELL_SUCCESS: "SET_RELATED_UPSELL_SUCCESS",
  SET_RELATED_UPSELL_FAILURE: "SET_RELATED_UPSELL_FAILURE",

  DELETE_RELATED_UPSELL: "DELETE_RELATED_UPSELL",
  DELETE_RELATED_UPSELL_SUCCESS: "DELETE_RELATED_UPSELL_SUCCESS",
  DELETE_RELATED_UPSELL_FAILURE: "DELETE_RELATED_UPSELL_FAILURE",

  RESET_ERROR: "RESET_ERROR"
};

export const getFixedUpsell = data => {
  return {
    type: upsellActionsTypes.GET_FIXED_UPSELL,
    payload: data
  };
};

export const setFixedUpsell = data => {
  return {
    type: upsellActionsTypes.SET_FIXED_UPSELL,
    payload: data
  };
};

export const deleteFixedUpsell = data => {
  return {
    type: upsellActionsTypes.DELETE_FIXED_UPSELL,
    payload: data
  };
};

export const getRelatedUpsell = data => {
  return {
    type: upsellActionsTypes.GET_RELATED_UPSELL,
    payload: data
  };
};

export const setRelatedUpsell = data => {
  return {
    type: upsellActionsTypes.SET_RELATED_UPSELL,
    payload: data
  };
};

export const deleteRelatedUpsell = data => {
  return {
    type: upsellActionsTypes.DELETE_RELATED_UPSELL,
    payload: data
  };
};

export const resetError = () => {
  return {
    type: upsellActionsTypes.RESET_ERROR
  };
};

import React, { useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { updateModifierImage } from "store/actions/products.actions";
import "./index.scss";
import { fileSize5 } from "utils/validators";

const ModifierOption = ({
  id,
  name: { en, ar },
  price,
  calories,
  imagePath
}) => {
  const dispatch = useDispatch();
  const [headerImage, setHeaderImage] = useState();
  const [error, setError] = useState("");

  const handleChange = e => {
    let fr = new FileReader();
    fr.onload = function() {
      setHeaderImage(fr.result);
    };
    const error = fileSize5(e.target.files[0].size);
    if (error) setError(error);
    else {
      setError("");
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      dispatch(updateModifierImage(id, formData));
      fr.readAsDataURL(e.target.files[0]);
    }
  };
  const getError = () =>
    error && (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{error}</li>
      </ul>
    );

  return (
    <tr key={id}>
      <td>{en}</td>
      <td>{ar}</td>
      <td>{price}</td>
      <td>{calories}</td>
      <td>
        <label htmlFor={`selectedFile${id}`}>
          <input
            type="file"
            accept=".png"
            hidden
            id={`selectedFile${id}`}
            name="img"
            style={{ zIndex: "1" }}
            onChange={handleChange}
          />
          <div className={classNames("dropify-upload modifier-img")}>
            <code className="mb-2">Image size {"<="} 5 MB</code>
            {imagePath || headerImage ? (
              <img
                className="mod-image"
                src={headerImage ? headerImage : imagePath}
                alt="alt"
              />
            ) : (
              <i className="dripicons-cloud-upload"></i>
            )}
          </div>
        </label>
        {getError()}
      </td>
    </tr>
  );
};

export default ModifierOption;

export const API_URL = "https://burgerizzr.digitalizzr.com/v2";
// export const API_URL = "https://dev-burgerizzr.digitalizzr.com/v2";
// export const API_URL = "http://localhost:8080/v2";

export const GOOGLE_MAP_API_KEY = "AIzaSyCl7bu7Zninz_LcMD8cuQO5TutrqxNj0z0";

export const GOOGLE_ANALYTICS_API =
  "https://www.googleapis.com/analytics/v3/data/ga";

export const SOCKET_URL = "http://dev-burgerizzr.digitalizzr.com:3031/";

export const DATE_FORMAT = "YYYY-MM-DD HH:mm";

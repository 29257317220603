export const categoriesActionsTypes = {
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",

  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

  UPDATE_CATEGORY_IMAGE: "UPDATE_CATEGORY_IMAGE",
  UPDATE_CATEGORY_IMAGE_SUCCESS: "UPDATE_CATEGORY_IMAGE_SUCCESS",

  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_SELECTED_CATEGORY_SUCCESS: "SET_SELECTED_CATEGORY_SUCCESS",
  SET_SELECTED_CATEGORY_FAILURE: "SET_SELECTED_CATEGORY_FAILURE",

  SYNCHRONIZE_MENU: "SYNCHRONIZE_MENU",
  SYNCHRONIZE_MENU_SUCCESS: "SYNCHRONIZE_MENU_SUCCESS",
  SYNCHRONIZE_MENU_FAILURE: "SYNCHRONIZE_MENU_FAILURE",

  UPDATE_CATEGORIES_ORDER: "UPDATE_CATEGORIES_ORDER",
  UPDATE_CATEGORIES_ORDER_SUCCESS: "UPDATE_CATEGORIES_ORDER_SUCCESS",
  UPDATE_CATEGORIES_ORDER_FAILURE: "UPDATE_CATEGORIES_ORDER_FAILURE"
};

export const updateCategoriesOrder = data => {
  return {
    type: categoriesActionsTypes.UPDATE_CATEGORIES_ORDER,
    payload: data
  };
};

export const getCategories = (
  data = { pagination: { page: 1, limit: 10 }, search: "" }
) => {
  return {
    type: categoriesActionsTypes.GET_CATEGORIES,
    payload: data
  };
};

export const updateCategory = data => {
  return {
    type: categoriesActionsTypes.UPDATE_CATEGORY,
    payload: data
  };
};

export const updateCategoryImage = data => {
  return {
    type: categoriesActionsTypes.UPDATE_CATEGORY_IMAGE,
    payload: data
  };
};

export const setSelectedCategory = data => {
  return {
    type: categoriesActionsTypes.SET_SELECTED_CATEGORY,
    payload: data
  };
};

export const synchronizeMenu = () => {
  return {
    type: categoriesActionsTypes.SYNCHRONIZE_MENU
  };
};

export const ordersActionsTypes = {
  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",

  GET_ALL_ORDERS: "GET_ALL_ORDERS",
  GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
  GET_ALL_ORDERS_FAILURE: "GET_ALL_ORDERS_FAILURE",

  GET_SPECIFIC_ORDER: "GET_SPECIFIC_ORDER",
  GET_SPECIFIC_ORDER_SUCCESS: "GET_SPECIFIC_ORDER_SUCCESS",
  GET_SPECIFIC_ORDER_FAILURE: "GET_SPECIFIC_ORDER_FAILURE",

  GET_DASHBOARD_STATUS: "GET_DASHBOARD_STATUS",
  GET_DASHBOARD_STATUS_SUCCESS: "GET_DASHBOARD_STATUS_SUCCESS",
  GET_DASHBOARD_STATUS_FAILURE: "GET_DASHBOARD_STATUS_FAILURE",

  GET_MOBILE_STATUS: "GET_MOBILE_STATUS",
  GET_MOBILE_STATUS_SUCCESS: "GET_MOBILE_STATUS_SUCCESS",
  GET_MOBILE_STATUS_FAILURE: "GET_MOBILE_STATUS_FAILURE",

  SET_ORDER_STATUS: "SET_ORDER_STATUS",
  SET_ORDER_STATUS_SUCCESS: "SET_ORDER_STATUS_SUCCESS",
  SET_ORDER_STATUS_FAILURE: "SET_ORDER_STATUS_FAILURE",

  GET_CANCEL_TIME: "GET_CANCEL_TIME",
  GET_CANCEL_TIME_SUCCESS: "GET_CANCEL_TIME_SUCCESS",
  GET_CANCEL_TIME_FAILURE: "GET_CANCEL_TIME_FAILURE",

  SET_CANCEL_TIME: "SET_CANCEL_TIME",
  SET_CANCEL_TIME_SUCCESS: "SET_CANCEL_TIME_SUCCESS",
  SET_CANCEL_TIME_FAILURE: "SET_CANCEL_TIME_FAILURE",

  GET_DELIVERY_FEE: "GET_DELIVERY_FEE",
  GET_DELIVERY_FEE_SUCCESS: "GET_DELIVERY_FEE_SUCCESS",
  GET_DELIVERY_FEE_FAILURE: "GET_DELIVERY_FEE_FAILURE",

  SET_DELIVERY_FEE: "SET_DELIVERY_FEE",
  SET_DELIVERY_FEE_SUCCESS: "SET_DELIVERY_FEE_SUCCESS",
  SET_DELIVERY_FEE_FAILURE: "SET_DELIVERY_FEE_FAILURE",

  GET_RECEIPT_EXPIRATION: "GET_RECEIPT_EXPIRATION",
  GET_RECEIPT_EXPIRATION_SUCCESS: "GET_RECEIPT_EXPIRATION_SUCCESS",
  GET_RECEIPT_EXPIRATION_FAILURE: "GET_RECEIPT_EXPIRATION_FAILURE",

  SET_RECEIPT_EXPIRATION: "SET_RECEIPT_EXPIRATION",
  SET_RECEIPT_EXPIRATION_SUCCESS: "SET_RECEIPT_EXPIRATION_SUCCESS",
  SET_RECEIPT_EXPIRATION_FAILURE: "SET_RECEIPT_EXPIRATION_FAILURE",

  ADD_ORDER: "ADD_ORDER",

  SET_ORDER_REFUND: "SET_ORDER_REFUND",
  SET_ORDER_REFUND_SUCCESS: "SET_ORDER_REFUND_SUCCESS",
  SET_ORDER_REFUND_FAILURE: "SET_ORDER_REFUND_FAILURE",

  GET_ORDER_REFUNDS: "GET_ORDER_REFUNDS",
  GET_ORDER_REFUNDS_SUCCESS: "GET_ORDER_REFUNDS_SUCCESS",
  GET_ORDER_REFUNDS_FAILURE: "GET_ORDER_REFUNDS_FAILURE",
};

export const getOrders = (data) => {
  return {
    type: ordersActionsTypes.GET_ORDERS,
    payload: data,
  };
};

export const getAllOrders = (data) => {
  return {
    type: ordersActionsTypes.GET_ALL_ORDERS,
    payload: data,
  };
};

export const getSpecificOrder = (data) => {
  return {
    type: ordersActionsTypes.GET_SPECIFIC_ORDER,
    payload: data,
  };
};

export const getDashboardStatus = () => {
  return {
    type: ordersActionsTypes.GET_DASHBOARD_STATUS,
  };
};

export const getMobileStatus = () => {
  return {
    type: ordersActionsTypes.GET_MOBILE_STATUS,
  };
};

export const setOrderStatus = (data) => {
  return {
    type: ordersActionsTypes.SET_ORDER_STATUS,
    payload: data,
  };
};

export const getCancelTime = () => {
  return {
    type: ordersActionsTypes.GET_CANCEL_TIME,
  };
};

export const setCancelTime = (data) => {
  return {
    type: ordersActionsTypes.SET_CANCEL_TIME,
    payload: data,
  };
};

export const getDeliveryFee = () => {
  return {
    type: ordersActionsTypes.GET_DELIVERY_FEE,
  };
};

export const setDeliveryFee = (data) => {
  return {
    type: ordersActionsTypes.SET_DELIVERY_FEE,
    payload: data,
  };
};

export const getReceiptExpiration = () => {
  return {
    type: ordersActionsTypes.GET_RECEIPT_EXPIRATION,
  };
};

export const setReceiptExpiration = (data) => {
  return {
    type: ordersActionsTypes.SET_RECEIPT_EXPIRATION,
    payload: data,
  };
};

export const addOrder = (data) => {
  return {
    type: ordersActionsTypes.ADD_ORDER,
    payload: data,
  };
};

export const getOrderRefunds = (id) => {
  return {
    type: ordersActionsTypes.GET_ORDER_REFUNDS,
    payload: id,
  };
};

export const setOrderRefund = (data) => {
  return {
    type: ordersActionsTypes.SET_ORDER_REFUND,
    payload: data,
  };
};

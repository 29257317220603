import React from "react";

const LoadingButton = ({ isLoadingBtn, btnContent, onClick }) => {
  return (
    <button
      type="submit"
      className="btn btn-success waves-effect waves-light"
      onClick={onClick}
      disabled={isLoadingBtn}
    >
      {isLoadingBtn && (
        <span
          className="spinner-border spinner-border-sm"
          style={{ margin: "0 5px 3px 0" }}
        ></span>
      )}
      {isLoadingBtn ? "Loading..." : btnContent}
    </button>
  );
};

export default LoadingButton;

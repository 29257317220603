import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPointHistory } from "../../store/actions/points.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModalPointHistory from "../../components/modal/ModalPointHistory/ModalPointHistory";
import TableComponent from "../../components/tableComponent";
import { StorageService } from "../../services/storage.service";

const PointHistoryPage = ({ match }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const points = useSelector(state => state.points.pointHistory);
  const pointsHistory = useSelector(
    state => state.points.pointHistory.pointsHistory
  );
  const { role, privileges } = JSON.parse(StorageService.getUser()) || {};
  const isLoading = useSelector(state => state.points.isLoadingPointHistory);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const tableHead = [
    {
      name: "Type",
      key: "pointType"
    },
    {
      name: "Points",
      key: "numberOfHistoryPoints"
    },
    {
      name: "Expiration Date",
      key: "expirationDate"
    },
    {
      name: "Used Date",
      key: "currentDate"
    }
  ];

  useEffect(() => {
    dispatch(getPointHistory(match.params.id));
  }, [match.params.id]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Point History</h4>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card-body">
                    <div className="col-lg-12">
                      {role === "admin" || privileges.managePoints ? (
                        <div className="text-md-right">
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                            onClick={toggle}
                          >
                            <i className="mr-1"></i> Assign Points
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="card-box">
                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-1">Total Points</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Total Points"
                            value={points.totalPoints}
                            required
                          />
                        </div>
                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-1">
                            Available Points
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Available Points"
                            value={points.availablePoints}
                            required
                          />
                        </div>
                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-1">Expired Points</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Expired Points"
                            value={points.expiredPoints}
                            required
                          />
                        </div>
                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-1">Used Points</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Used Points"
                            value={points.usedPoints}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="product-meta-1">Points History</label>
                          <TableComponent
                            tableHead={tableHead}
                            tableBody={pointsHistory}
                          />
                        </div>
                        <ModalPointHistory
                          modal={modal}
                          toggle={toggle}
                          id={match.params.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointHistoryPage;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import "./aboutUsDropify.scss";

const AboutUsDropify = ({ aboutUsImage, height, action }) => {
  const [background, setBackground] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [imageName] = useState(aboutUsImage);

  const isLoadingProductImage = useSelector(
    state => state.products.isLoadingProductImage
  );

  const fileSelectedHandler = event => {
    action(event.target.files[0]);
  };

  return (
    <div>
      {isLoadingProductImage ? (
        <div className=" h-75 d-flex justify-content-center align-items-center">
          <div className="spinner-border avatar-lg" role="status"></div>
        </div>
      ) : (
        <>
          <code>Image Type: PNG</code>
          <div
            style={{ height: height }}
            className={classNames(
              "dropify aboutUsDropify",
              background && (headerImage || aboutUsImage) && "hoverImage"
            )}
            onMouseEnter={() => setBackground(true)}
            onMouseLeave={() => setBackground(false)}
          >
            <input
              type="file"
              accept=".png,"
              hidden
              id="selectedFile"
              name="selectedFile"
              onChange={e => {
                fileSelectedHandler(e);
                let fr = new FileReader();
                fr.onload = function() {
                  setHeaderImage(fr.result);
                };
                fr.readAsDataURL(e.target.files[0]);
              }}
            />
            <div
              className={classNames(
                "dropify-upload",
                (aboutUsImage || headerImage) && "opacityZero"
              )}
            >
              <i className="dripicons-cloud-upload"></i>
              <h5>Drop files here or click to upload.</h5>
            </div>

            {background && (headerImage || aboutUsImage) && (
              <div className="dropify-replace text-center">
                <p className="dropify-filename">
                  <span className="dropify-filename-inner">
                    {(imageName && imageName.slice(67)) ||
                      (aboutUsImage && aboutUsImage.slice(67))}
                  </span>
                </p>
                <p className="dropify-infos-message">
                  Drag and drop or click to replace
                </p>
              </div>
            )}
            <img
              src={headerImage || aboutUsImage}
              alt="Card  cap"
              className={classNames(
                "",
                !headerImage && !aboutUsImage && "opacityZero"
              )}
              onClick={e => {
                document.getElementById("uploadHeader").click();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AboutUsDropify;

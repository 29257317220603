import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { updateModifierProduct } from "../../store/actions/products.actions";
import "./modifiersForProduct.scss";

const ModifiersForProducts = ({ product, modifier }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(modifier.selectedForProduct);

  const handleClick = () => {
    modifier.selectedForProduct = !modifier.selectedForProduct;
    dispatch(
      updateModifierProduct({
        modifierIds: modifier.id,
        id: product.id,
        selected: modifier.selectedForProduct
      })
    );
    setSelected(!selected);
  };

  return (
    <span
      key={modifier.id}
      className={classNames(
        "btn btn-xs btn-light waves-effect waves-light",
        modifier.selectedForProduct
          ? "modifier-selected"
          : "modifier-notselected"
      )}
      onClick={handleClick}
    >
      {modifier.name && modifier.name.en}
    </span>
  );
};

export default ModifiersForProducts;

import { StorageService } from "../../services/storage.service";

class Auth {
  async checkAuth(protectedRouteProps) {
    const user = await JSON.parse(StorageService.getUser());
    if (
      user.role === "admin" ||
      user.privileges[protectedRouteProps.fieldName] === true
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export default new Auth();

import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import DoubleDropify from "../../doubleDropify/doubleDropify";
import ToggleButton from "../../toggleButton/toggleButton";
import DatePicker from "react-datepicker";

export default function ModalOffer({
  modal,
  toggle,
  categories,
  products,
  formik
}) {
  const handleToggleAvailable = () => {
    formik.values.availableForDelivery
      ? formik.setFieldValue("availableForDelivery", false)
      : formik.setFieldValue("availableForDelivery", true);
  };
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Offer
      </ModalHeader>
      <form
        className="modal-body pt-2 pb-2 pr-4 pl-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="category" className="control-label">
                Category
              </label>
              <select
                className="form-control"
                id="category"
                name="category"
                onChange={formik.handleChange}
                value={formik.values.category}
              >
                <option value="">All</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name.en}
                  </option>
                ))}
              </select>
              {formik.errors.category && formik.touched.category ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.category}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="product" className="control-label">
                Select product
              </label>
              <select
                className="form-control"
                id="product"
                name="product"
                onChange={formik.handleChange}
                value={formik.values.product}
              >
                <option value="">Select Product</option>
                {products.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.name.en}
                  </option>
                ))}
              </select>
              {formik.errors.product && formik.touched.product ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.product}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="nameAr" className="control-label">
                Name|Ar
              </label>
              <input
                type="text"
                className="form-control"
                id="nameAr"
                name="nameAr"
                placeholder="Name|Ar"
                value={formik.values.nameAr}
                onClick={() => {
                  formik.setFieldTouched("nameAr", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.nameAr && formik.touched.nameAr ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.nameAr}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="offerName" className="control-label">
                Offer Name
              </label>
              <input
                type="text"
                className="form-control"
                id="offerName"
                name="offerName"
                placeholder="Offer Name"
                value={formik.values.offerName}
                onClick={() => {
                  formik.setFieldTouched("offerName", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.offerName && formik.touched.offerName ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.offerName}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        {/* 
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="expirationDate" className="control-label">
                Expiration Date
              </label>
              <input
                type="date"
                className="form-control"
                id="expirationDate"
                name="expirationDate"
                placeholder="Expiration Date"
                value={formik.values.expirationDate}
                onClick={() => {
                  formik.setFieldTouched("expirationDate", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.expirationDate && formik.touched.expirationDate ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.expirationDate}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="relatedPoints" className="control-label">
                Related Points
              </label>
              <input
                type="number"
                className="form-control"
                id="relatedPoints"
                name="relatedPoints"
                placeholder="Related Points"
                value={formik.values.relatedPoints}
                onClick={() => {
                  formik.setFieldTouched("relatedPoints", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.relatedPoints && formik.touched.relatedPoints ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.relatedPoints}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="dateFrom" className="control-label">
                Date From
              </label>
              <DatePicker
                id="dateFrom"
                dateFormat="dd-MM-yy"
                placeholderText="Date From"
                selected={formik.values.dateFrom}
                onChange={e => {
                  formik.setValues({
                    ...formik.values,
                    dateFrom: e
                  });
                }}
                onClick={() => {
                  formik.setFieldTouched("dateFrom", true);
                }}
                className="datepicker"
              />
              {formik.errors.dateFrom && formik.touched.dateFrom ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.dateFrom}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="dateTo" className="control-label">
                Date To
              </label>
              <DatePicker
                id="dateTo"
                dateFormat="dd-MM-yy"
                placeholderText="Date To"
                selected={formik.values.dateTo}
                onChange={e => {
                  formik.setValues({
                    ...formik.values,
                    dateTo: e
                  });
                }}
                onClick={() => {
                  formik.setFieldTouched("dateTo", true);
                }}
                className="datepicker"
              />
              {formik.errors.dateTo && formik.touched.dateTo ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.dateTo}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="timeFrom" className="control-label">
                Time From
              </label>
              <DatePicker
                id="timeFrom"
                placeholderText="Time From"
                selected={formik.values.timeFrom}
                onChange={e => {
                  formik.setValues({
                    ...formik.values,
                    timeFrom: e
                  });
                }}
                onClick={() => {
                  formik.setFieldTouched("timeFrom", true);
                }}
                className="datepicker"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time From"
                dateFormat="h:mm aa"
              />
              {formik.errors.timeFrom && formik.touched.timeFrom ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.timeFrom}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="timeTo" className="control-label">
                Time To
              </label>
              <DatePicker
                id="timeTo"
                placeholderText="Time To"
                selected={formik.values.timeTo}
                onChange={e => {
                  formik.setValues({
                    ...formik.values,
                    timeTo: e
                  });
                }}
                onClick={() => {
                  formik.setFieldTouched("timeTo", true);
                }}
                className="datepicker"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time From"
                dateFormat="h:mm aa"
              />
              {formik.errors.timeTo && formik.touched.timeTo ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.timeTo}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="toggle-block border-0 p-0 m-0">
                <div>Available for Delivery</div>
                <div onClick={handleToggleAvailable}>
                  <ToggleButton
                    id="availableForDelivery"
                    name="availableForDelivery"
                    value={formik.values.availableForDelivery}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="field-3" className="control-label">
                Offer Image
              </label>
              <DoubleDropify
                height="20vh"
                formik={formik}
                type="selectedFile"
              />
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              formik.resetForm();
              toggle();
            }}
          >
            Close
          </Button>{" "}
          <Button type="submit" color="success">
            Save Changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

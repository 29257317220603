import { discountsActionsTypes } from "../actions/discounts.actions";

const initialState = {
  discounts: [],
  discount: [],
  coupons: [],
  coupon: [],
  isLoadingDiscounts: false,
  isLoadingCoupons: false,
  isLoadingDiscount: false,
  isLoadingDiscountBtn: false,
  isLoadingCoupon: false,
  isLoadingCouponBtn: false
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case discountsActionsTypes.GET_DISCOUNTS: {
      return {
        ...state,
        isLoadingDiscounts: true
      };
    }

    case discountsActionsTypes.GET_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        discounts: action.payload.allDiscounts,
        isLoadingDiscounts: false,
        discountCount: action.payload.meta.totalCount
      };
    }

    case discountsActionsTypes.GET_DISCOUNT: {
      return {
        ...state,
        isLoadingDiscount: true
      };
    }

    case discountsActionsTypes.GET_DISCOUNT_SUCCESS: {
      return {
        ...state,
        discount: action.payload,
        isLoadingDiscount: false
      };
    }

    case discountsActionsTypes.UPDATE_DISCOUNT: {
      return {
        ...state,
        isLoadingDiscountBtn: true
      };
    }

    case discountsActionsTypes.UPDATE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isLoadingDiscountBtn: false
      };
    }

    case discountsActionsTypes.GET_COUPONS: {
      return {
        ...state,
        isLoadingCoupons: true
      };
    }

    case discountsActionsTypes.GET_COUPONS_SUCCESS: {
      return {
        ...state,
        coupons: action.payload.allCoupons,
        isLoadingCoupons: false,
        couponCount: action.payload.meta.totalCount
      };
    }

    case discountsActionsTypes.GET_COUPON: {
      return {
        ...state,
        isLoadingCoupon: true
      };
    }

    case discountsActionsTypes.GET_COUPON_SUCCESS: {
      return {
        ...state,
        coupon: action.payload,
        isLoadingCoupon: false
      };
    }

    case discountsActionsTypes.UPDATE_COUPON: {
      return {
        ...state,
        isLoadingCouponBtn: true
      };
    }

    case discountsActionsTypes.UPDATE_COUPON_SUCCESS: {
      return {
        ...state,
        isLoadingCouponBtn: false
      };
    }

    default:
      return state;
  }
}

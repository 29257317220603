import { authorizationActionsTypes } from "../actions/authorization.actions";

const initialState = {
  credentials: {},
  isLoadingAuth: false,
  isLoadingSupervisors: false,
  authError: null,
  pagination: null,
  token: "",
  menuStatus: false,
  sideBarStatus: false,
  foodicsToken: "",
  supervisors: [],
  countSupervisors: null,
  supervisorError: "",
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case authorizationActionsTypes.SET_STATUS_MENU: {
      state.menuStatus = !state.menuStatus;
      return {
        ...state,
        authError: null,
      };
    }
    case authorizationActionsTypes.SET_STATUS_MENU_FALSE: {
      state.menuStatus = false;
      return {
        ...state,
        authError: null,
      };
    }

    case authorizationActionsTypes.LOGIN: {
      return {
        ...state,
        credentials: action.payload,
        authError: false,
        isLoadingAuth: true,
      };
    }

    case authorizationActionsTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        authError: null,
        isLoadingAuth: false,
      };
    }

    case authorizationActionsTypes.LOGIN_FAILURE: {
      return {
        ...state,
        authError: action.payload,
        isLoadingAuth: false,
      };
    }

    case authorizationActionsTypes.LOGOUT: {
      return {
        ...state,
        token: "",
      };
    }

    case authorizationActionsTypes.GET_SUPERVISORS: {
      return {
        ...state,
        isLoadingSupervisors: true,
      };
    }

    case authorizationActionsTypes.SET_SUPERVISORS: {
      return {
        ...state,
        isLoadingSupervisors: true,
      };
    }

    case authorizationActionsTypes.GET_SUPERVISORS_SUCCESS: {
      return {
        ...state,
        supervisors: action.payload.supervisors,
        countSupervisors: action.payload.meta.totalCount,
        isLoadingSupervisors: false,
      };
    }

    case authorizationActionsTypes.GET_SUPERVISORS_FAILURE: {
      return {
        ...state,
        isLoadingSupervisors: false,
      };
    }

    case authorizationActionsTypes.SET_SUPERVISORS_SUCCESS: {
      const newSupervisor = [...state.supervisors, action.payload];
      return {
        ...state,
        supervisors: newSupervisor,
        isLoadingSupervisors: false,
      };
    }

    case authorizationActionsTypes.SET_SUPERVISORS_FAILURE: {
      return {
        ...state,
        supervisorError: action.payload,
        isLoadingSupervisors: false,
      };
    }

    case authorizationActionsTypes.UPDATE_SUPERVISORS_SUCCESS: {
      return {
        ...state,
        supervisors: state.supervisors.map((supervisor) => {
          if (supervisor.id === action.payload.id) {
            supervisor.privileges = action.payload.privileges;
          }
          return supervisor;
        }),
      };
    }

    case authorizationActionsTypes.DELETE_SUPERVISORS: {
      return {
        ...state,
        deletedSupervisor: action.payload,
        isLoadingSupervisors: true,
      };
    }

    case authorizationActionsTypes.DELETE_SUPERVISORS_SUCCESS: {
      const deletedArr = state.supervisors.filter(
        (supervisor) => supervisor.id !== action.payload
      );
      return {
        ...state,
        supervisors: deletedArr,
        isLoadingSupervisors: false,
      };
    }

    case authorizationActionsTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        userData: action.payload.privileges,
      };
    }

    default:
      return state;
  }
}

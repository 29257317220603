import { transactionsActionsTypes } from "../actions/transactions.actions";

const initialState = {
  transactions: [],
  customerTransactions: [],
  customerTransactionsError: null,
  isLoadingTransactions: false,
};

export default function transactions(state = initialState, action) {
  switch (action.type) {
    case transactionsActionsTypes.GET_TRANSACTIONS: {
      return {
        ...state,
        isLoadingTransactions: true,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions: action.payload,
        isLoadingTransactions: false,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        isLoadingTransactions: false,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_ID: {
      return {
        ...state,
        customerTransactionsError: null,
        isLoadingTransactions: true,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_ID_SUCCESS: {
      return {
        ...state,
        customerTransactions: action.payload,
        isLoadingTransactions: false,
        customerTransactionsError: null,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_ID_FAILURE: {
      return {
        ...state,
        isLoadingTransactions: false,
        customerTransactionsError: action.payload,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_EXPORT: {
      return {
        ...state,
        isLoadingTransactions: true,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_EXPORT_SUCCESS: {
      return {
        ...state,
        isLoadingTransactions: false,
      };
    }

    case transactionsActionsTypes.GET_TRANSACTIONS_EXPORT_FAILURE: {
      return {
        ...state,
        isLoadingTransactions: false,
      };
    }

    default:
      return state;
  }
}

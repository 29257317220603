export const discountsActionsTypes = {
  GET_DISCOUNTS: "GET_DISCOUNTS",
  GET_DISCOUNTS_SUCCESS: "GET_DISCOUNTS_SUCCESS",
  GET_DISCOUNTS_FAILURE: "GET_DISCOUNTS_FAILURE",

  GET_DISCOUNT: "GET_DISCOUNT",
  GET_DISCOUNT_SUCCESS: "GET_DISCOUNT_SUCCESS",
  GET_DISCOUNT_FAILURE: "GET_DISCOUNT_FAILURE",

  UPDATE_DISCOUNT: "UPDATE_DISCOUNT",
  UPDATE_DISCOUNT_SUCCESS: "UPDATE_DISCOUNT_SUCCESS",
  UPDATE_DISCOUNT_FAILURE: "UPDATE_DISCOUNT_FAILURE",

  GET_COUPONS: "GET_COUPONS",
  GET_COUPONS_SUCCESS: "GET_COUPONS_SUCCESS",
  GET_COUPONS_FAILURE: "GET_COUPONS_FAILURE",

  GET_COUPON: "GET_COUPON",
  GET_COUPON_SUCCESS: "GET_COUPON_SUCCESS",
  GET_COUPON_FAILURE: "GET_COUPON_FAILURE",

  UPDATE_COUPON: "UPDATE_COUPON",
  UPDATE_COUPON_SUCCESS: "UPDATE_COUPON_SUCCESS",
  UPDATE_COUPON_FAILURE: "UPDATE_COUPON_FAILURE"
};

export const getDiscounts = data => {
  return {
    type: discountsActionsTypes.GET_DISCOUNTS,
    payload: data
  };
};

export const getDiscount = id => {
  return {
    type: discountsActionsTypes.GET_DISCOUNT,
    payload: id
  };
};

export const updateDiscount = data => {
  return {
    type: discountsActionsTypes.UPDATE_DISCOUNT,
    payload: data
  };
};

export const getCoupons = data => {
  return {
    type: discountsActionsTypes.GET_COUPONS,
    payload: data
  };
};

export const getCoupon = id => {
  return {
    type: discountsActionsTypes.GET_COUPON,
    payload: id
  };
};

export const updateCoupon = data => {
  return {
    type: discountsActionsTypes.UPDATE_COUPON,
    payload: data
  };
};

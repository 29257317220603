import React, { Fragment } from "react";
const QuantityList = props => {
  const taskList = props.taskList.length ? props.taskList : [{}];
  return taskList.map((val, idx) => (
    <Fragment key={val + idx}>
      <tr className="d-flex">
        <td className="col-md-4 pl-0 pr-0 border-0">
          <input
            type="number"
            className="form-control"
            id={`quantityPrice[${idx}].persons`}
            name={`quantityPrice[${idx}].persons`}
            value={
              props.formik.values.quantityPrice[idx] &&
              props.formik.values.quantityPrice[idx].persons
            }
            onChange={props.formik.handleChange}
            placeholder="Persons"
            required
          />
        </td>

        <td className="col-md-4 pr-0 pl-0 border-0">
          <input
            type="text"
            className="form-control"
            id={`quantityPrice[${idx}].price`}
            name={`quantityPrice[${idx}].price`}
            value={
              props.formik.values.quantityPrice[idx] &&
              props.formik.values.quantityPrice[idx].price
            }
            onChange={props.formik.handleChange}
            placeholder="Price"
            required
          />
        </td>
        <td className="col-md-3 border-0">
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              Add Quantity
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-danger text-center"
              onClick={() => props.delete(val)}
            >
              X
            </button>
          )}
        </td>
      </tr>
    </Fragment>
  ));
};
export default QuantityList;

export default [
  {
    path: "/categories",
    fieldName: "menu",
    exact: true,
    component: () => require("../../pages/categories").default
  },
  {
    path: "/products",
    fieldName: "menu",
    exact: true,
    component: () => require("../../pages/products").default
  },
  {
    path: "/products/:id",
    fieldName: "menu",
    exact: true,
    component: () => require("../../pages/productDetails").default
  },
  {
    path: "/modifiers",
    fieldName: "menu",
    exact: true,
    component: () => require("../../pages/modifiers").default
  },
  {
    path: "/branches",
    fieldName: "branchesList",
    exact: true,
    component: () => require("../../pages/branches").default
  },
  {
    path: "/branches/bulk/status",
    fieldName: "bulkBranchesEdit",
    exact: true,
    component: () => require("../../pages/branchesStatusBulkEdit").default
  },
  {
    path: "/branches/bulk/working-hrs",
    fieldName: "bulkBranchesEdit",
    exact: true,
    component: () => require("../../pages/branchesWorkingHrsBulkEdit").default
  },
  {
    path: "/orderStatus",
    fieldName: "order",
    exact: true,
    component: () => require("../../pages/orderStatus").default
  },
  {
    path: "/orderCancelTime",
    fieldName: "order",
    exact: true,
    component: () => require("../../pages/orderCancelTime").default
  },
  {
    path: "/deliveryFees",
    fieldName: "order",
    exact: true,
    component: () => require("../../pages/deliveryFees").default
  },
  {
    path: "/relatedProductUpsell",
    fieldName: "order",
    exact: true,
    component: () => require("../../pages/relatedProductUpsell").default
  },
  {
    path: "/fixedProductUpsell",
    fieldName: "order",
    exact: true,
    component: () => require("../../pages/fixedProductUpsell").default
  },
  {
    path: "/coupons",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/coupons").default
  },
  {
    path: "/coupons/:id",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/couponDetails").default
  },
  {
    path: "/discounts",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/discounts").default
  },
  {
    path: "/discounts/:id",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/discountDetails").default
  },
  {
    path: "/points",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/points").default
  },
  {
    path: "/points-report",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/pointsReport").default
  },
  {
    path: "/pointHistory/:id",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/pointHistory").default
  },
  {
    path: "/createPoint",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/createPoint").default
  },
  {
    path: "/updatePoint/:pointType",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/updatePoint").default
  },
  {
    path: "/rewards",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/rewards").default
  },
  {
    path: "/offers",
    fieldName: "discountsAndCoupons",
    exact: true,
    component: () => require("../../pages/offers").default
  },
  {
    path: "/customer/:id",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/customerDetails").default
  },
  {
    path: "/customers",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/customers").default
  },
  {
    path: "/customers/:id",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/orderHistoryPage").default
  },
  {
    path: "/customerTransactions/:id",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/customerTransactionsPage").default
  },
  {
    path: "/customerAddCredit/:id",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/customerAddCredit").default
  },
  {
    path: "/customers/:Hid/:id",
    fieldName: "order",
    exact: true,
    component: () => require("../../pages/orderDetailsPage").default
  },
  {
    path: "/feedbackSurveys",
    fieldName: "feedback",
    exact: true,
    component: () => require("../../pages/feedbackSurveys").default
  },
  {
    path: "/createSurvey",
    fieldName: "feedback",
    exact: true,
    component: () =>
      require("../../components/createSurvey/createSurvey").default
  },
  {
    path: "/updateSurvey/:id",
    fieldName: "feedback",
    exact: true,
    component: () =>
      require("../../components/updateServey/updateSurvey").default
  },
  {
    path: "/feedbackSurveys/:id",
    fieldName: "feedback",
    exact: true,
    component: () => require("../../pages/customerFeedback").default
  },
  {
    path: "/feedbackQuestions/:id",
    fieldName: "feedback",
    exact: true,
    component: () => require("../../pages/feedbackQuestion").default
  },

  {
    path: "/contentPages",
    fieldName: "contentPages",
    exact: true,
    component: () => require("../../pages/pagesContent").default
  },
  {
    path: "/supervisors",
    fieldName: "supervisors",
    exact: true,
    component: () => require("../../pages/supervisorsPrivileges").default
  },
  {
    path: "/food-truck",
    fieldName: "foodTruck",
    exact: true,
    component: () => require("../../pages/foodTruck").default
  },

  {
    path: "/food-truck/booked",
    fieldName: "foodTruck",
    exact: true,
    component: () => require("../../pages/boookedFoodTruck").default
  },
  {
    path: "/food-truck/manage",
    fieldName: "foodTruck",
    exact: true,
    component: () => require("../../pages/manageFoodTruck").default
  },
  {
    path: "/advertisement",
    fieldName: "advertisement",
    exact: true,
    component: () => require("../../pages/advertisement").default
  },
  {
    path: "/orders",
    fieldName: "orders",
    exact: true,
    component: () => require("../../pages/orders").default
  },
  {
    path: "/arrangement/products",
    fieldName: "arrangement",
    exact: true,
    component: () => require("../../pages/productsArrangement").default
  },
  {
    path: "/arrangement/categories",
    fieldName: "arrangement",
    exact: true,
    component: () => require("../../pages/categoriesArrangement").default
  },
  {
    path: "/awardHistory/:id",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/awardsHistory").default
  },
  {
    path: "/notifications/general",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/generalNotifications").default
  },
  {
    path: "/notifications/specific",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/specificNotifications").default
  },
  {
    path: "/notifications/offer",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/scheduleNotifications").default
  },
  {
    path: "/notifications/birthday",
    fieldName: "customers",
    exact: true,
    component: () => require("../../pages/birthdayNotifications").default
  },
  {
    path: "/transactions",
    fieldName: "transactions",
    exact: true,
    component: () => require("../../pages/transactions").default
  },
  {
    path: "/transactions/:id",
    fieldName: "transactions",
    exact: true,
    component: () => require("../../pages/transactionsDetails").default
  }
];

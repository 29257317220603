import { customersActionsTypes } from "../actions/customers.actions";

const initialState = {
  customers: [],
  isLoadingCustomer: false,
  countCustomers: {},
  isSynchronized: true,
  isLoadingAddWalletCredit: false,
  errorAddWalletCredit: null
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case customersActionsTypes.GET_CUSTOMERS: {
      return {
        ...state,
        isLoadingCustomer: true
      };
    }

    case customersActionsTypes.GET_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        customers: action.payload.customers,
        countCustomers: action.payload.meta.count,
        isLoadingCustomer: false
      };
    }

    case customersActionsTypes.SYNCHRONIZE_BLACKLIST: {
      return { ...state, isSynchronized: false };
    }

    case customersActionsTypes.SYNCHRONIZE_BLACKLIST_SUCCESS: {
      return { ...state, isSynchronized: true };
    }

    case customersActionsTypes.SYNCHRONIZE_BLACKLIST_FAILURE: {
      return { ...state, isSynchronized: true };
    }

    case customersActionsTypes.ADD_WALLET_CREDIT: {
      return {
        ...state,
        isLoadingAddWalletCredit: true,
        errorAddWalletCredit: null
      };
    }

    case customersActionsTypes.ADD_WALLET_CREDIT_SUCCESS: {
      return {
        ...state,
        isLoadingAddWalletCredit: false,
        errorAddWalletCredit: null
      };
    }
    case customersActionsTypes.ADD_WALLET_CREDIT_FAILURE: {
      return {
        ...state,
        isLoadingAddWalletCredit: false,
        errorAddWalletCredit: action.payload
      };
    }

    default:
      return state;
  }
}

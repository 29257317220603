import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Pagination from "../pagination/pagination";
import Filter from "../filter/filter";
import { getBranches } from "../../store/actions/branches.actions";

const BranchTable = ({ formik }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const branches = useSelector(state => state.branches.branches);
  const countBranch = useSelector(state => state.branches.countBranch);
  const [selectedArr, setSelectedArr] = useState([]);
  const [notAvailable, setNotAvailable] = useState([]);

  useEffect(() => {
    const selected =
      formik.values.branches && formik.values.branches.map(i => i.id);
    formik.setFieldValue("selectedBranches", new Set(selected));
    setSelectedArr(selected);
  }, [formik.values.branches]);

  useEffect(() => {
    if (formik.values.notAvailableBranches) {
      const seleted = branches.map(item =>
        formik.values.notAvailableBranches.has(item.id) ? item.id : null
      );
      formik.setFieldValue("selectedBranches", new Set(seleted));
      setSelectedArr(seleted);
    }
  }, [formik.values.notAvailableBranches, branches]);

  useEffect(() => {
    if (formik.values.notAvailableBranches) {
      setNotAvailable(formik.values.notAvailableBranches);
    } else {
      const notAvailable = new Set(
        formik.values.branches && formik.values.branches.map(i => i.id)
      );
      branches.map(branch => {
        if (!notAvailable.has(branch.id)) {
          notAvailable.add(branch.id);
        } else {
          notAvailable.delete(branch.id);
        }
        formik.setFieldValue("notAvailableBranches", notAvailable);
        setNotAvailable(notAvailable);
        return null;
      });
    }
  }, [branches, formik.values.branches]);
  return (
    <div className="row">
      <div className="col-sm-12">
        <label htmlFor="example-select">Branch:</label>
        <div className="row">
          <div className="col-md-12">
            <Filter
              action={getBranches}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              isAvailable={false}
            />

            <div className="table-responsive">
              <table className="table table-bordered toggle-circle mb-0">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Name</th>
                    {/* <th>Country</th> */}
                    {/* <th>City</th> */}
                  </tr>
                </thead>
                <tbody>
                  {branches.map(branch => (
                    <tr key={branch.id}>
                      <td>
                        {React.createElement("input", {
                          type: "checkbox",
                          checked:
                            selectedArr && selectedArr.includes(branch.id),
                          onChange: () => {
                            if (!notAvailable.has(branch.id)) {
                              notAvailable.add(branch.id);
                            } else {
                              notAvailable.delete(branch.id);
                            }
                            formik.setFieldValue(
                              "notAvailableBranches",
                              new Set(notAvailable)
                            );
                          }
                        })}
                      </td>
                      <td>{branch.name.en}</td>
                      {/* <td>{branch.name.en}</td> */}
                      {/* <td>{branch.name.en}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalCount={countBranch}
              rows={rowsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchTable;

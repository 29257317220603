import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import classNames from "classnames";
import { getCoupon, updateCoupon } from "../../store/actions/discounts.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ToggleButton from "../../components/toggleButton/toggleButton";
import BranchTable from "../../components/branchTable";
import ProductTable from "../../components/ProductTable";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CouponDetails = ({ history, match }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const objOrderType = {
    pickup: false,
    dinein: false,
    delivery: false
  };

  const objPlatform = {
    android: false,
    ios: false,
    web: false
  };

  const { coupon: oneCoupon = {} } = useSelector(state => state.discounts);
  const isLoading = useSelector(state => state.discounts.isLoadingCoupon);
  const isLoadingBtn = useSelector(state => state.discounts.isLoadingCouponBtn);

  const handleToggleActive = () => {
    formik.values.isActive
      ? formik.setFieldValue("isActive", false)
      : formik.setFieldValue("isActive", true);
  };

  const handleToggleUsed = () => {
    formik.values.isActive
      ? formik.setFieldValue("isUsed", false)
      : formik.setFieldValue("isUsed", true);
  };

  const formik = useFormik({
    initialValues: {
      ...oneCoupon,
      selectedBranches: new Set(),
      selectedProducts: new Set()
    },
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(
        updateCoupon({
          id: values.id,
          validFrom: values.validFrom,
          validTo: values.validTo,
          orderType: Object.keys({
            Pickup: values.pickup,
            Dinein: values.dinein,
            Delivery: values.delivery
          }).filter(key => values[key.toLocaleLowerCase()]),

          platform: Object.keys({
            Android: values.dndroid,
            iOS: values.ios,
            web: values.web
          }).filter(key => values[key.toLocaleLowerCase()]),
          isActive: values.isActive,
          isUsed: values.isUsed,
          branches: [...values.selectedBranches.keys()],
          products: [...values.selectedProducts.keys()]
        })
      );
    }
  });

  const closeCoupon = () => {
    history.push("/coupons");
  };

  useEffect(() => {
    if (formik.values.orderType) {
      objOrderType.pickup =
        formik.values && formik.values.orderType.includes("Pickup") === true
          ? true
          : false;
      objOrderType.dinein =
        formik.values && formik.values.orderType.includes("Dinein") === true
          ? true
          : false;
      objOrderType.delivery =
        formik.values && formik.values.orderType.includes("Delivery") === true
          ? true
          : false;
    }
    formik.setFieldValue("pickup", objOrderType.pickup);
    formik.setFieldValue("dinein", objOrderType.dinein);
    formik.setFieldValue("delivery", objOrderType.delivery);
  }, [formik.values.orderType]);

  useEffect(() => {
    if (formik.values.platform) {
      objPlatform.android =
        formik.values && formik.values.platform.includes("Android") === true
          ? true
          : false;
      objPlatform.ios =
        formik.values && formik.values.platform.includes("iOS") === true
          ? true
          : false;
      objPlatform.web =
        formik.values && formik.values.platform.includes("web") === true
          ? true
          : false;
    }
    formik.setFieldValue("android", objPlatform.android);
    formik.setFieldValue("ios", objPlatform.ios);
    formik.setFieldValue("web", objPlatform.web);
  }, [formik.values.orderType]);

  useEffect(() => {
    dispatch(getCoupon(match.params.id));
  }, [match.params.id]);

  useEffect(() => {
    isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Edit Coupon</h4>
                </div>
              </div>
            </div>

            {_.isEmpty(oneCoupon) || isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2"></div>
                      <div className="col-md-12">
                        <div className="modal-content">
                          <div className="modal-body p-4">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="nameEn"
                                      className="control-label"
                                    >
                                      Name | En:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="nameEn"
                                      name="nameEn"
                                      placeholder="Name | Ar"
                                      disabled
                                      value={formik.values.nameEn || ""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="nameAr"
                                      className="control-label"
                                    >
                                      Name | Ar:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="nameAr"
                                      name="nameAr"
                                      placeholder="Name | En"
                                      disabled
                                      value={formik.values.nameAr || ""}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className="form-group mb-3">
                                      <label htmlFor="example-select">
                                        Coupon Type:
                                      </label>
                                      <select
                                        className="form-control"
                                        id="amountOrPescentage"
                                        disabled
                                        value={
                                          formik.values.isPercentage || false
                                        }
                                      >
                                        {formik.values.isPercentage ? (
                                          <option>Percentage</option>
                                        ) : (
                                          <option>Amount</option>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="amount">Value:</label>
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="amount"
                                        name="amount"
                                        placeholder="Value"
                                        value={formik.values.amount || 0}
                                        disabled
                                      />
                                      {formik.values.isPercentage && (
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            %
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="code"
                                      className="control-label"
                                    >
                                      Coupon Code:
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="code"
                                      name="code"
                                      placeholder="Coupon Code"
                                      onChange={formik.handleChange}
                                      value={formik.values.code || 0}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="validFrom"
                                      className="control-label"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="validFrom"
                                      name="validFrom"
                                      placeholder="validFrom"
                                      onChange={formik.handleChange}
                                      value={moment(
                                        formik.values.validFrom
                                      ).format("YYYY-MM-DD")}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="validTo"
                                      className="control-label"
                                    >
                                      To:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="validTo"
                                      name="validTo"
                                      placeholder="validTo"
                                      onChange={formik.handleChange}
                                      value={moment(
                                        formik.values.validTo
                                      ).format("YYYY-MM-DD")}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="orderType"
                                      className="control-label"
                                    >
                                      Order Type:
                                    </label>
                                    <div className="row col-md-12 form-group">
                                      <div className="col-md-3 custom-control custom-checkbox checkbox-dark">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="pickup"
                                          name="pickup"
                                          onChange={formik.handleChange}
                                          checked={
                                            formik.values.pickup || false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="pickup"
                                        >
                                          Pickup
                                        </label>
                                      </div>
                                      <div className="col-md-3 custom-control custom-checkbox checkbox-dark">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="dinein"
                                          name="dinein"
                                          onChange={formik.handleChange}
                                          checked={
                                            formik.values.dinein || false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="dinein"
                                        >
                                          Dine-In
                                        </label>
                                      </div>
                                      <div className="col-md-3 custom-control custom-checkbox checkbox-dark">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="delivery"
                                          name="delivery"
                                          onChange={formik.handleChange}
                                          checked={
                                            formik.values.delivery || false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="delivery"
                                        >
                                          Delivery
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="Platform"
                                      className="control-label"
                                    >
                                      Platform:
                                    </label>
                                    <div className="row col-md-12 form-group">
                                      <div className="col-md-3 custom-control custom-checkbox checkbox-dark">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="android"
                                          name="android"
                                          onChange={formik.handleChange}
                                          checked={
                                            formik.values.android || false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="android"
                                        >
                                          Android
                                        </label>
                                      </div>
                                      <div className="col-md-3 custom-control custom-checkbox checkbox-dark">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="ios"
                                          name="ios"
                                          onChange={formik.handleChange}
                                          checked={formik.values.ios || false}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="ios"
                                        >
                                          iOS
                                        </label>
                                      </div>
                                      <div className="col-md-3 custom-control custom-checkbox checkbox-dark">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="web"
                                          name="web"
                                          onChange={formik.handleChange}
                                          checked={formik.values.web || false}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="web"
                                        >
                                          Web
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />

                              <div className="d-flex justify-content-between">
                                <div className="row w-50">
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <label
                                        htmlFor="Activated"
                                        className="control-label"
                                      >
                                        Activated:
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div onClick={handleToggleActive}>
                                      <ToggleButton
                                        id="isActive"
                                        name="isActive"
                                        value={formik.values.isActive || false}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row w-50">
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <label
                                        htmlFor="Used"
                                        className="control-label"
                                      >
                                        Used:
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div onClick={handleToggleUsed}>
                                      <ToggleButton
                                        id="isUsed"
                                        name="isUsed"
                                        value={formik.values.isUsed || false}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <ProductTable formik={formik} />
                              <BranchTable formik={formik} />

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  data-dismiss="modal"
                                  onClick={closeCoupon}
                                >
                                  Close
                                </button>
                                <LoadingButton
                                  isLoadingBtn={isLoadingBtn}
                                  btnContent="Save"
                                />
                              </div>
                              <ToastContainer
                                position="top-right"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponDetails;

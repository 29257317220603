import { API_URL } from "../constants";
import axiosInstance from "../services/interceptor";

export const branchesApi = {
  getCountriesList(
    countryId,
    cityId,
    availableForDelivery,
    showInMobileApp = null
  ) {
    let params = [];
    if (countryId && countryId !== "All") params.push(`countryId=${countryId}`);
    if (cityId && cityId !== "All") params.push(`cityId=${cityId}`);
    if (availableForDelivery && availableForDelivery !== "All")
      params.push(`availableForDelivery=${availableForDelivery}`);
    if (showInMobileApp && showInMobileApp !== "All")
      params.push(`showInMobileApp=${showInMobileApp}`);
    return axiosInstance.get(`${API_URL}/countries?${params.join("&")}`);
  },

  getCountries() {
    return axiosInstance.get(`${API_URL}/countries`);
  },

  getCities(id) {
    return axiosInstance.get(`${API_URL}/cities?countryId=${id}`);
  },

  setAvailableForDelivery(branchId, availableForDelivery) {
    return axiosInstance.patch(
      `${API_URL}/branches/${branchId}/available-for-delivery`,
      { availableForDelivery }
    );
  },

  getBranches(params, cityId, countryId) {
    if (params) {
      const { page, limit } = params;
      return axiosInstance.get(`${API_URL}/branches`, {
        params: {
          page,
          limit,
          cityId,
          countryId
        }
      });
    } else {
      return axiosInstance.get(`${API_URL}/branches`);
    }
  },

  selectBranches(branchId, branchSelected) {
    return axiosInstance.patch(`${API_URL}/branches/${branchId}/selection`, {
      selected: branchSelected
    });
  },

  updateBranch(branchId, branchUpdate) {
    return axiosInstance.patch(`${API_URL}/branches/${branchId}`, branchUpdate);
  },

  synchronizeBranches() {
    return axiosInstance.post(`${API_URL}/foodics/sync-branches-menu`);
  },

  bulkBranchesUpdate(data) {
    const { branches, option } = data;
    return axiosInstance.put(
      `${API_URL}/branches/bulk?option=${option}`,
      branches
    );
  }
};

import { API_URL } from "../constants";
import axiosInstance from "./interceptor";
const extensions = {
  pdf: "pdf",
  excel: "xlsx",
  word: "doc"
};

export const feedbackApi = {
  getCustomerFeedback(surveyId) {
    return axiosInstance.get(`${API_URL}/surveys/submitted-surveys`, {
      params: { surveyId }
    });
  },

  getFeedbackQuestions(feedbackType, params, id) {
    const { page, limit } = params;
    return axiosInstance.get(`${API_URL}/surveys`, {
      params: {
        feedbackType,
        page,
        limit,
        id
      }
    });
  },

  setFeedbackQuestions(values) {
    const { feedbackType, isActive, questions, specialTypeFilters } = values;
    const allQuestions = questions.map(item => {
      item.questionType = Number(item.questionType);
      if (item.questionType !== 3) {
        item.choices = undefined;
      }
      return item;
    });

    return axiosInstance.post(`${API_URL}/surveys`, {
      feedbackType: Number(feedbackType),
      isActive,
      questions: allQuestions,
      specialTypeFilters
    });
  },

  updateFeedbackQuestions(values) {
    return axiosInstance.patch(`${API_URL}/surveys/${values.id}`, values);
  },

  deleteFeedbackQuestion(id) {
    return axiosInstance.delete(`${API_URL}/surveys/${id}`);
  },

  getReports(params) {
    if (params.fileType) {
      axiosInstance
        .get(`${API_URL}/reports`, {
          responseType: "blob",
          params: {
            fileType: params.fileType,
            reportType: params.reportType,
            dateType: params.dateType
          }
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${extensions[params.fileType]}`);
          document.body.appendChild(link);
          link.click();
        });
    } else {
      return axiosInstance.get(`${API_URL}/reports`, { params });
    }
  }
};

import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import avatar from "../../assets/images/small/defaultCategory.png";
import ToggleButton from "../toggleButton/toggleButton";

const feedbackType = {
  1: "General",
  2: "Order",
  3: "Specific"
};

const questionType = {
  1: "Rate",
  2: "Text",
  3: "MultipleChoice"
};

const pointHistoryType = {
  1: "Earned",
  2: "Used",
  3: "Expired"
};

const defaultFormat = "DD/MM/YYYY";
const tableComponent = ({ tableBody, tableHead }) => {
  return (
    <div className="table-responsive">
      {_.isEmpty(tableBody) || _.isEmpty(tableBody[0]) ? (
        <div className="notFound">Not Found</div>
      ) : (
        <table className="table table-centered table-borderless table-hover mb-0">
          <thead className="thead-light">
            <tr>
              {tableHead.map(
                header => header && <th key={header.name}>{header.name}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((body, i) => (
              <tr key={i}>
                {tableHead.map((head, index) => {
                  const firstType = body.type === 1;
                  const secondType = body.type === 2;
                  const thirdType = body.type === 3;
                  const questionWithChoices = body.questionType === 3;
                  const pointWithProduct = head.value === 2;
                  switch (head.key) {
                    case "date":
                      return (
                        <td key={index}>
                          {moment
                            .utc(body[head.field])
                            .local()
                            .format(head.format || defaultFormat)}
                        </td>
                      );

                    case "coordinates":
                      return (
                        <td key={index}>
                          <a
                            href={`https://www.google.com/maps/search/${body["lat"]},${body["long"]}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {body["lat"]}, {body["long"]}
                          </a>
                        </td>
                      );

                    case "ProductName":
                      return (
                        <td key={index}>
                          {body.product && body.product.name.en}
                        </td>
                      );

                    case "productHid":
                      return (
                        <td key={index}>
                          {body.product && body.product.foodicsId}
                        </td>
                      );

                    case "foodicsId":
                      return (
                        <td key={index}>{body[head.key] || body.foodics_id}</td>
                      );

                    case "name":
                      return <td key={index}>{body[head.key][head.lang]}</td>;

                    case "supervisorName":
                      return <td key={index}>{body[head.value]}</td>;

                    case "imageIcon":
                      return (
                        <td key={index} className="table-user">
                          <img
                            alt="table-user"
                            className="mr-2 rounded-circle"
                            src={body[head.field] || avatar}
                          />
                        </td>
                      );

                    case "products":
                      return (
                        <td key={index}>{body[head.key].length} Products</td>
                      );

                    case "selected":
                      return (
                        <td key={index}>
                          {body[head.key] ? (
                            <span className="badge bg-soft-success text-success">
                              {head.name}
                            </span>
                          ) : (
                            <span className="badge bg-soft-danger text-danger">
                              {head.name}
                            </span>
                          )}
                        </td>
                      );

                    case "blacklist":
                      return (
                        <td key={index}>
                          {!body[head.key] ? (
                            <span className="badge bg-soft-success text-success">
                              false
                            </span>
                          ) : (
                            <span className="badge bg-soft-danger text-danger">
                              true
                            </span>
                          )}
                        </td>
                      );

                    case "checkCustomer":
                      return (
                        <td key={index}>
                          <input
                            type="checkbox"
                            value={body.id}
                            defaultChecked={head.formik.values.customers.has(
                              body.id
                            )}
                            onChange={head.onClick}
                          />
                        </td>
                      );

                    case "editWithCallback":
                      return (
                        <td key={index}>
                          <i
                            className="mdi mdi-pencil action-icon"
                            onClick={() =>
                              head.onClick(body[head.return] || body)
                            }
                          ></i>
                        </td>
                      );

                    case "editWithRedirect":
                      return (
                        <td key={index}>
                          <Link to={`/${head.path}/${body[head.param]}`}>
                            <i className="mdi mdi-pencil action-icon"></i>
                          </Link>
                        </td>
                      );

                    case "delete":
                      return (
                        <td key={index}>
                          <span className="action-icon">
                            <i
                              className="mdi mdi-delete"
                              onClick={() => head.onClick(body[head.return])}
                            ></i>
                          </span>
                        </td>
                      );

                    case "details":
                      const param = head.getCustomPath
                        ? head.getCustomPath(body, head)
                        : body[head.param];
                      return (
                        <td key={index}>
                          <Link to={`${head.path}/${param}`}>
                            <i className="mdi mdi-eye"></i>
                          </Link>
                        </td>
                      );

                    case "addCredit":
                      const paramForAdd = head.getCustomPath
                        ? head.getCustomPath(body, head)
                        : body[head.param];
                      return (
                        <td key={index}>
                          <Link to={`${head.path}/${paramForAdd}`}>
                            <i className="mdi mdi-plus-circle"></i>
                          </Link>
                        </td>
                      );

                    case "show":
                      return (
                        <td key={index}>
                          <div
                            onClick={() => head.onClick(body)}
                            className="d-flex justify-content-center"
                          >
                            <ToggleButton value={body.show} />
                          </div>
                        </td>
                      );

                    case "showLocation":
                      return (
                        <td key={index}>
                          <i
                            className="fe-map-pin action-icon"
                            onClick={() => head.onClick(body)}
                          ></i>
                        </td>
                      );

                    case "viewChoices":
                      return (
                        <td key={index}>
                          {questionWithChoices ? (
                            <i
                              className="mdi mdi-eye"
                              onClick={() => head.onClick(body)}
                            ></i>
                          ) : (
                            "null"
                          )}
                        </td>
                      );

                    case "firstName":
                      return (
                        <td key={index}>
                          {body[head.key] || body.first_name} &nbsp;
                          {body[head.lastName]}
                        </td>
                      );

                    case "lastName":
                      return (
                        <td key={index}>{body[head.key] || body.last_name}</td>
                      );
                    case "value":
                      return <td key={index}>{body[head.value]}</td>;

                    case "product":
                      return (
                        <td key={index}>
                          {body[head.value][0] && body[head.value][0].name.en}
                        </td>
                      );

                    case "productToRelated":
                      return (
                        <td key={index}>
                          {body.product && body.product.name.en}
                        </td>
                      );

                    case "relatedProduct":
                      return (
                        <td key={index}>
                          {_.isEmpty(body.product)
                            ? ""
                            : body.product.productRelatedUpsell &&
                              body.product.productRelatedUpsell.name.en}
                        </td>
                      );

                    case "categoryName":
                      return <td key={index}>{body.categories[0].name.en}</td>;

                    case "checkbox":
                      return (
                        <td key={index}>
                          <input type="checkbox" name="1" />
                          {body[head.text]}
                        </td>
                      );

                    case "dateRange":
                      return (
                        <td key={index}>
                          {moment(body[head.dateFrom]).format(defaultFormat) +
                            " - " +
                            moment(body[head.dateTo]).format(defaultFormat)}
                        </td>
                      );

                    case "timeRange":
                      return (
                        <td key={index}>
                          {body[head.timeFrom] + " - " + body[head.timeTo]}
                        </td>
                      );

                    case "status":
                      return (
                        <td key={index}>{head.orderStatus[body.status]}</td>
                      );

                    case "type":
                      return <td key={index}>{head.orderType[body.type]}</td>;

                    case "isRefunded":
                      return (
                        <td key={index}>
                          {body[head.key] ||
                          (body.refunds && body.refunds.length > 0)
                            ? "YES"
                            : "NO"}
                        </td>
                      );

                    case "orderDetailsIcon":
                      return (
                        <td key={index}>
                          <Link
                            to={`/customers/${head.customerHid}/${body.id}`}
                          >
                            <i className="fe-file-text"></i>
                          </Link>
                        </td>
                      );

                    case "isPercentage":
                      return (
                        <td key={index}>
                          {body[head.key] ? "Percentage" : "Amount"}
                        </td>
                      );

                    case "amount":
                      return <td key={index}>{body[head.key]} SAR</td>;

                    case "new_balance":
                      return <td key={index}>{body[head.key]} SAR</td>;

                    case "feedbackType":
                      return (
                        <td key={index}>
                          {head.value
                            ? feedbackType[head.value[0].feedbackType]
                            : "Question was deleted"}
                        </td>
                      );

                    case "customerFeedbackType":
                      return (
                        <td key={index}>
                          {head.value
                            ? feedbackType[head.value]
                            : "Question was deleted"}
                        </td>
                      );

                    case "feedbackTypeSurvey":
                      return (
                        <td key={index}>
                          {body.feedbackType
                            ? feedbackType[body.feedbackType]
                            : "Question was deleted"}
                        </td>
                      );

                    case "questionType":
                      return (
                        <td key={index}>{questionType[body.questionType]}</td>
                      );

                    case "customerQuestionType":
                      return (
                        <td key={index}>
                          {questionType[body.questions[0].questionType]}
                        </td>
                      );

                    case "questionTitle":
                      return (
                        <td key={index}>
                          {body.questions
                            ? body.questions[0][head.key]
                            : "Question was deleted"}
                        </td>
                      );

                    case "answer":
                      return <td key={index}>{body.questions[0][head.key]}</td>;

                    case "customerName":
                      return (
                        <td key={index}>
                          {body.customer.firstName} {body.customer.lastName}
                        </td>
                      );

                    case "customerPhone":
                      return <td key={index}> {body.customer.phone}</td>;

                    case "customerEmail":
                      return <td key={index}> {body.customer.email}</td>;

                    case "pointName":
                      return (
                        <td key={index}>
                          {pointWithProduct &&
                            head.products.map(
                              product =>
                                product.id === body.productId && product.name.en
                            )}
                        </td>
                      );

                    case "numberOfOrders":
                      return (
                        <td key={index}>{body[head.key] || body.count}</td>
                      );

                    case "expirationDate":
                      return (
                        <td key={index}>
                          {secondType
                            ? "null"
                            : moment(body[head.key]).format(defaultFormat)}
                        </td>
                      );

                    case "createdAt":
                      return (
                        <td key={index}>
                          {secondType
                            ? "null"
                            : moment(body[head.key]).format(defaultFormat)}
                        </td>
                      );

                    case "currentDate":
                      return (
                        <td key={index}>
                          {firstType || thirdType
                            ? ""
                            : moment(body[head.key]).format(defaultFormat)}
                        </td>
                      );

                    case "pointType":
                      return <td key={index}>{pointHistoryType[body.type]}</td>;

                    case "numberOfHistoryPoints":
                      if (firstType) {
                        return (
                          <td key={index} style={{ color: "green" }}>
                            + {body.numberOfPoints}
                          </td>
                        );
                      } else if (secondType) {
                        return (
                          <td key={index} style={{ color: "red" }}>
                            - {body.numberOfPoints}
                          </td>
                        );
                      } else {
                        return (
                          <td key={index} style={{ color: "#F6B84B" }}>
                            {body.numberOfPoints}
                          </td>
                        );
                      }

                    case "productName":
                      return (
                        <td key={index}>
                          {head.value.map(
                            item => item.id === body.id && item.name.en
                          )}
                        </td>
                      );

                    case "#":
                      return <td key={index}>{i + 1}</td>;

                    case "bookedProductId":
                      return <td key={index}>{body.products[0].id}</td>;

                    case "offerName":
                      return <td key={index}>{body.name}</td>;

                    case "offerProduct":
                      return <td key={index}>{body.product.name.en}</td>;

                    case "offerPrice":
                      return <td key={index}>{body.product.sizes[0].price}</td>;

                    case "availableForDelivery":
                      return (
                        <td key={index}>{body[head.key] ? "Yes" : "No"}</td>
                      );

                    case "isActive":
                      return (
                        <td key={index}>
                          {body[head.key] ? (
                            <span className="badge bg-soft-success text-success">
                              {head.name}
                            </span>
                          ) : (
                            <span className="badge bg-soft-danger text-danger">
                              {head.name}
                            </span>
                          )}
                        </td>
                      );

                    case "notificationMonth":
                      return (
                        <td key={index}>
                          {moment(new Date().setMonth(body.date - 1)).format(
                            "MMMM"
                          )}
                        </td>
                      );

                    case "notificationStatus":
                      return (
                        <td key={index}>
                          <div
                            onClick={() => head.onClick(body)}
                            className="d-flex justify-content-center"
                          >
                            <ToggleButton value={+body.status === 1} />
                          </div>
                        </td>
                      );

                    case "orders":
                      return (
                        <td key={index}>{body[head.key] || body.count}</td>
                      );

                    default:
                      return head.render ? (
                        head.render(index, body, head)
                      ) : (
                        <td key={index}>{String(body[head.key])}</td>
                      );
                  }
                  // return null;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default tableComponent;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { changeStatusMenuFalse } from "../../store/actions/authorization.actions";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./sideBar.scss";

const ThirdLevel = ({ link, match }) => {
  const dispatch = useDispatch();
  const openStatus = useSelector(state => state.authorization.menuStatus);
  const [menuStatus, setMenuStatus] = useState(openStatus);
  const [upsellStatus, setUpsellStatus] = useState(false);

  useEffect(() => {
    if (!openStatus) {
      setMenuStatus(false);
      setUpsellStatus(false);
    }
  }, [openStatus]);

  return (
    <li id="link">
      <a
        role="button"
        className={classNames(
          link.children.some(({ url }) => match.url === url) && "active",

          link.children.map(
            child =>
              child.children &&
              child.children.some(({ url }) => match.url === url) &&
              "active"
          )
        )}
        onClick={() => {
          setMenuStatus(!menuStatus);
        }}
        href="# "
      >
        <i className={link.icon}></i>
        <span>{link.name}</span>
        <span
          className={classNames(menuStatus ? "arrow-rotate" : "menu-arrow")}
        ></span>
      </a>
      <ul
        className={classNames("nav-third-level collapse", menuStatus && "in")}
      >
        {link.children.map(child => (
          <div key={child.name}>
            {child.children ? (
              <span id="link" className="childItem">
                <a
                  id="link"
                  className={classNames(
                    child.children.some(({ url }) => match.url === url) &&
                      "active"
                  )}
                  onClick={() => setUpsellStatus(!upsellStatus)}
                  href="# "
                >
                  <i className={child.icon}></i>
                  <span>{child.name}</span>
                  <span
                    className={classNames(
                      openStatus &&
                        (upsellStatus ? "arrow-rotate" : "menu-arrow")
                    )}
                  ></span>
                </a>
                <span
                  className={classNames(
                    "nav-third-level",
                    upsellStatus ? "open" : "hide"
                  )}
                >
                  {child.children.map((childItem, index) => (
                    <li key={child.name + index} id="menu-item">
                      <NavLink
                        to={childItem.url}
                        onClick={() => dispatch(changeStatusMenuFalse())}
                        className="has-dropdown"
                        activeClassName="activeLink"
                      >
                        <i className={childItem.icon}></i>
                        <span>{childItem.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </span>
              </span>
            ) : (
              <li key={child.name}>
                <NavLink
                  to={child.url}
                  onClick={() => dispatch(changeStatusMenuFalse())}
                  className="has-dropdown"
                  activeClassName="activeLink"
                >
                  <i className={child.icon}></i>
                  <span>{child.name}</span>
                </NavLink>
              </li>
            )}
          </div>
        ))}
      </ul>
    </li>
  );
};

export default ThirdLevel;

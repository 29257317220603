import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setFeedbackQuestions } from "../../../store/actions/feedback.actions";
import ToggleButton from "../../toggleButton/toggleButton";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

export default function ModalQuestion({ modal, toggle }) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      feedbackType: 1,
      questionType: 1,
      questionTitle: "",
      choices: ["", "", "", "", ""],
      isActive: false
    },
    validationSchema: Yup.object().shape({
      feedbackType: Yup.string().required("Required"),
      questionType: Yup.string().required("Required"),
      questionTitle: Yup.string().required("Required")
    }),
    onSubmit: values => {
      if (values.questionType === "3") {
        dispatch(
          setFeedbackQuestions({
            feedbackType: +values.feedbackType,
            questions: [
              {
                questionType: +values.questionType,
                questionTitle: values.questionTitle,
                choices: values.choices
              }
            ],
            isActive: values.isActive
          })
        );
      } else {
        dispatch(
          setFeedbackQuestions({
            feedbackType: +values.feedbackType,
            questions: [
              {
                questionType: +values.questionType,
                questionTitle: values.questionTitle
              }
            ],
            isActive: true
          })
        );
      }
      formik.resetForm();
      toggle();
    }
  });

  const handleToggle = () => {
    formik.values.isActive
      ? formik.setFieldValue("isActive", false)
      : formik.setFieldValue("isActive", true);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        New Question
      </ModalHeader>
      <form onSubmit={formik.handleSubmit} className="modal-body p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="feedbackType">
                Feedback Type: &nbsp; &nbsp;{" "}
              </label>

              <select
                id="feedbackType"
                name="feedbackType"
                className="custom-select custom-select-sm"
                onChange={formik.handleChange}
                value={formik.values.feedbackType}
              >
                <option value={1}>General</option>
                <option value={2}>Order</option>
              </select>
              {formik.errors.feedbackType && formik.touched.feedbackType ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.feedbackType}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="questionType">
                Question Type: &nbsp; &nbsp;{" "}
              </label>

              <select
                id="questionType"
                name="questionType"
                className="custom-select custom-select-sm"
                onChange={formik.handleChange}
                value={formik.values.questionType}
              >
                <option value={1}>Rate</option>
                <option value={2}>Text</option>
                <option value={3}>Multiple Choices</option>
              </select>
              {formik.errors.questionType && formik.touched.questionType ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.questionType}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="form-group">
              <label htmlFor="isActive" className="control-label">
                Activated:
              </label>
            </div>
          </div>
          <div className="col-md-3">
            <div onClick={handleToggle}>
              <ToggleButton value={formik.values.isActive || false} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="questionTitle" className="control-label">
                Question
              </label>
              <input
                type="text"
                className="form-control"
                id="questionTitle"
                name="questionTitle"
                onChange={formik.handleChange}
                value={formik.values.questionTitle}
                placeholder="Question"
                onClick={() => {
                  formik.setFieldTouched("questionTitle", true);
                }}
              />
              {formik.errors.questionTitle && formik.touched.questionTitle ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.questionTitle}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        {formik.values.questionType === "3" && (
          <div className="row">
            {formik.values.choices.map((choice, index) => (
              <div key={`choice + ${index}`} className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor={`choices[${index + 1}]`}
                    className="control-label"
                  >
                    {`Choice#${index + 1}`}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id={`choices[${index}]`}
                    name={`choices[${index}]`}
                    onChange={formik.handleChange}
                    value={choice}
                    placeholder={`Choice#${index + 1}`}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              formik.resetForm();
              toggle();
            }}
          >
            Close
          </Button>

          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap";
import logo from "../assets/images/logo-sm.png";

const ProfileDropdown = ({ menuItems, username }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="notification-list"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        <img src={logo} className="rounded-circle" alt="user" />
        <span className="pro-user-name ml-1">
          {username} <i className="mdi mdi-chevron-down"></i>{" "}
        </span>
      </DropdownToggle>
      <DropdownMenu right className="topbar-dropdown-menu profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">Welcome !</h6>
          </div>
          {menuItems.map((item, i) => {
            return (
              <React.Fragment key={i + "-profile-menu"}>
                {item.hasDivider ? <DropdownItem divider /> : null}
                <Link
                  to={item.redirectTo}
                  className="dropdown-item notify-item"
                >
                  <i className={`${item.icon} mr-1`}></i>
                  <span>{item.label}</span>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";
import {
  getTransactionsExport,
  getTransactionsID
} from "../../store/actions/transactions.actions";
import { useFormik } from "formik";

const CustomerTransactionsPage = ({ match }) => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const customers = useSelector(
    state => state.transactions.customerTransactions
  );
  const customerTransactionsError = useSelector(
    state => state.transactions.customerTransactionsError
  );

  const isLoadingTransactions = useSelector(
    state => state.transactions.isLoadingTransactions
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fileType: "excel"
    }
  });

  const handleClick = () => {
    dispatch(
      getTransactionsExport({
        fileType: formik.values.fileType,
        customer_id: match.params.id
      })
    );
  };

  const tableHead = [
    {
      name: "New balance",
      key: "new_balance"
    },
    {
      name: "Amount",
      key: "amount"
    },
    {
      name: "Type",
      key: "type",
      orderType: {
        1: "Online Top Up",

        2: "Order Purchase (Detection)",

        3: "Refund",

        4: "CashBack",

        5: "Compensations",

        6: "Expiration Detection (Detection)"
      }
    },
    {
      name: "Created at",
      key: "createdAt"
    },
    {
      name: "Expiration date",
      key: "expirationDate"
    }
  ];

  useEffect(() => {
    dispatch(getTransactionsID({ customer_id: match.params.id }));
  }, [match.params.id]);

  const header = () => (
    <div className="row">
      <div className="col-sm-12">
        <div className="card-box p-0">
          <div className="row mb-2">
            <div className="col-md-12 d-flex align-items-center flex-wrap">
              <div className="text-sm-center form-inline">
                <label htmlFor="example-select">
                  File Type : &nbsp; &nbsp;{" "}
                </label>
                <div className="form-group mr-2">
                  <select
                    id="demo-foo-filter-status"
                    className="custom-select custom-select-sm"
                    name="fileType"
                    onChange={formik.handleChange}
                    value={formik.values.fileType}
                  >
                    <option value={"excel"}>Excel</option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light  custom-select-sm"
                  onClick={handleClick}
                >
                  <i className="mr-1">
                    <span style={{ color: "#fff" }}>Save File</span>
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Customer Transactions History</h4>
              </div>
            </div>
            <div className="row">
              {isLoadingTransactions ? (
                <div className="d-flex justify-content-center  w-100">
                  <div className="spinner-border avatar-lg" role="status"></div>
                </div>
              ) : (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-md-6"></div>
                      </div>
                      {customerTransactionsError ? (
                        <div className="d-flex justify-content-center">
                          {customerTransactionsError}
                        </div>
                      ) : (
                        <>
                          {header()}
                          <TableComponent
                            tableHead={tableHead}
                            tableBody={customers}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTransactionsPage;

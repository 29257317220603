import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getDeliveryFee,
  setDeliveryFee
} from "../../store/actions/orders.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ToggleButton from "../../components/toggleButton/toggleButton";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeliveryFees = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const [isActive, setIsActive] = useState(null);

  const loadingDeliveryFee = useSelector(
    state => state.orders.loadingDeliveryFee
  );
  const isLoadingBtn = useSelector(
    state => state.orders.isloadingDeliveryFeeBtn
  );
  const minimumFreeDeliveryOrderPrice = useSelector(
    state => state.orders.minimumFreeDeliveryOrderPrice
  );
  const deliveryFixedFee = useSelector(state => state.orders.fixedFee);
  const deliveryState = useSelector(state => state.orders.deliveryState);
  const minimumAmountForDelivery = useSelector(
    state => state.orders.minimumAmountForDelivery
  );

  useEffect(() => {
    dispatch(getDeliveryFee());
  }, []);

  useEffect(() => {
    setIsActive(deliveryState);
  }, [deliveryState]);

  useEffect(() => {
    form.setValues({
      deliveryFixedFee: deliveryFixedFee,
      freeDelivery: minimumFreeDeliveryOrderPrice,
      minimumAmountForDelivery
    });
  }, [
    deliveryFixedFee,
    minimumFreeDeliveryOrderPrice,
    minimumAmountForDelivery
  ]);

  const form = useFormik({
    initialValues: {
      deliveryFixedFee: "",
      freeDelivery: "",
      minimumAmountForDelivery: ""
    },
    validationSchema: Yup.object({
      deliveryFixedFee: Yup.string()
        .max(5, "Must be 5 characters or less")
        .required("Required"),
      freeDelivery: Yup.string()
        .max(5, "Must be 5 characters or less")
        .required("Required"),
      minimumAmountForDelivery: Yup.string().required("Required")
    }),
    onSubmit: values => {
      dispatch(
        setDeliveryFee({
          deliveryFixedFee: Number(values.deliveryFixedFee),
          minOrderPrice: Number(values.freeDelivery),
          minimumAmountForDelivery: Number(values.minimumAmountForDelivery),
          deliveryFee: isActive
        })
      );
    }
  });

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", { mL: open, "ml-0": !open })}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Delivery Fees</h4>
                </div>
              </div>
            </div>

            {loadingDeliveryFee ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="card-box">
                <form onSubmit={form.handleSubmit}>
                  <label className="control-label">Delivery Fixed Fee:</label>
                  <input
                    className="form-control"
                    label="Delivery Fixed Fee:"
                    name="deliveryFixedFee"
                    type="number"
                    placeholder="Delivery Fixed Fee"
                    onClick={() => {
                      form.setFieldTouched("deliveryFixedFee", true);
                    }}
                    onChange={e =>
                      form.setFieldValue("deliveryFixedFee", e.target.value)
                    }
                    value={form.values.deliveryFixedFee}
                  />
                  {form.errors.deliveryFixedFee &&
                  form.touched.deliveryFixedFee ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {form.errors.deliveryFixedFee}
                      </li>
                    </ul>
                  ) : null}

                  <label className="control-label mt-2">
                    Minimum Amount For Delivery:
                  </label>
                  <input
                    className="form-control"
                    label="Minimum Amount For Delivery:"
                    name="minimumAmountForDelivery"
                    type="number"
                    placeholder="Minimum Amount For Delivery"
                    onClick={() => {
                      form.setFieldTouched("minimumAmountForDelivery", true);
                    }}
                    onChange={e =>
                      form.setFieldValue(
                        "minimumAmountForDelivery",
                        e.target.value
                      )
                    }
                    value={form.values.minimumAmountForDelivery || ""}
                  />
                  {form.errors.minimumAmountForDelivery &&
                  form.touched.minimumAmountForDelivery ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {form.errors.minimumAmountForDelivery}
                      </li>
                    </ul>
                  ) : null}

                  <div className="d-flex mt-2 mb-1">
                    <label className="control-label">Free Delivery:</label>

                    <div
                      className="ml-2"
                      onClick={() => {
                        setIsActive(!isActive);
                      }}
                    >
                      <ToggleButton value={isActive} size={"small"} />
                    </div>
                  </div>

                  <code style={{ paddingTop: "3px" }}>
                    (Minimum Order Price: Minimum order price for free delivery)
                  </code>

                  <input
                    className="form-control"
                    label="Free Delivery:"
                    name="freeDelivery"
                    type="number"
                    value={form.values.freeDelivery}
                    onClick={() => {
                      form.setFieldTouched("freeDelivery", true);
                    }}
                    onChange={e =>
                      form.setFieldValue("freeDelivery", e.target.value)
                    }
                    placeholder="Free Delivery"
                  />

                  {form.errors.freeDelivery && form.touched.freeDelivery ? (
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">
                        {form.errors.freeDelivery}
                      </li>
                    </ul>
                  ) : null}

                  <div className="modal-footer">
                    <LoadingButton
                      isLoadingBtn={isLoadingBtn}
                      btnContent="Save"
                    />
                  </div>

                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                  />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryFees;

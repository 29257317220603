import { upsellActionsTypes } from "../actions/upsell.actions";

const initialState = {
  fixedUpsells: [],
  relatedUpsells: [],
  countFixedUpsell: {},
  countRelatedUpsell: {},
  fixedMSG53: null,
  relatedMSG53: null,
  loadingFixedUpsell: false,
  loadingRelatedUpsell: false
};

export default function upsell(state = initialState, action) {
  switch (action.type) {
    case upsellActionsTypes.GET_FIXED_UPSELL: {
      return {
        ...state,
        loadingFixedUpsell: true
      };
    }

    case upsellActionsTypes.GET_FIXED_UPSELL_SUCCESS: {
      return {
        ...state,
        fixedUpsells: action.payload.upsells,
        loadingFixedUpsell: false
      };
    }

    case upsellActionsTypes.SET_FIXED_UPSELL: {
      return {
        ...state,
        fixedMSG53: null
      };
    }

    case upsellActionsTypes.SET_FIXED_UPSELL_SUCCESS: {
      const newFixedUpsells = [...state.fixedUpsells, action.payload.data];
      return {
        ...state,
        fixedUpsells: newFixedUpsells
      };
    }

    case upsellActionsTypes.SET_FIXED_UPSELL_FAILURE: {
      return {
        ...state,
        fixedMSG53: action.payload
      };
    }

    case upsellActionsTypes.DELETE_FIXED_UPSELL: {
      const deletedArr = state.fixedUpsells.filter(
        upsell => upsell.id !== action.payload
      );
      return {
        ...state,
        fixedUpsells: deletedArr
      };
    }

    case upsellActionsTypes.GET_RELATED_UPSELL: {
      return {
        ...state,
        loadingRelatedUpsell: true,
        relatedMSG53: null
      };
    }

    case upsellActionsTypes.GET_RELATED_UPSELL_SUCCESS: {
      return {
        ...state,
        relatedUpsells: action.payload.upsells,
        loadingRelatedUpsell: false
      };
    }

    case upsellActionsTypes.SET_RELATED_UPSELL: {
      return {
        ...state,
        relatedMSG53: null
      };
    }

    case upsellActionsTypes.SET_RELATED_UPSELL_SUCCESS: {
      const newRelatedUpsells = [...state.relatedUpsells, action.payload.data];

      return {
        ...state,
        relatedUpsells: newRelatedUpsells
      };
    }

    case upsellActionsTypes.SET_RELATED_UPSELL_FAILURE: {
      return {
        ...state,
        relatedMSG53: action.payload
      };
    }

    case upsellActionsTypes.DELETE_RELATED_UPSELL: {
      const deletedArr = state.relatedUpsells.filter(
        upsell => upsell.id !== action.payload
      );

      return {
        ...state,
        relatedUpsells: deletedArr
      };
    }

    case upsellActionsTypes.RESET_ERROR: {
      return {
        ...state,
        relatedMSG53: null,
        fixedMSG53: null
      };
    }

    default:
      return state;
  }
}

import React from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const OrderStatusField = ({ mobile, dashboard }) => {
  return (
    <>
      <div className="col-md-3">
        <div className="form-group">
          <label className="control-label">Name:</label>
          <input
            type="text"
            className="form-control"
            value={dashboard.name}
            disabled
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label className="control-label">NameEn:</label>
          <input
            type="text"
            className="form-control"
            defaultValue={mobile.nameEn}
            disabled={mobile.nameEn === "None"}
            onChange={e => {
              mobile.nameEn = e.target.value;
            }}
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label className="control-label">NameAr:</label>
          <input
            type="text"
            className="form-control"
            defaultValue={mobile.nameAr}
            disabled={mobile.nameAr === "None"}
            onChange={e => {
              mobile.nameAr = e.target.value;
            }}
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <label className="control-label">Period Time:</label>
          <Flatpickr
            className="form-control"
            placeholder={mobile.periodTime === null ? "-----" : "Period Time"}
            data-enable-time="true"
            defaultValue={mobile.periodTime}
            onChange={data => {
              mobile.periodTime = moment(
                data,
                "ddd MMM D YYYY HH:mm:ss"
              ).format("HH:mm:ss");
            }}
            options={{
              allowInput: true,
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i:S",
              time_24hr: true,
              enableSeconds: true
            }}
            disabled={mobile.periodTime === null ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default OrderStatusField;

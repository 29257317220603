export const notificationActionsTypes = {
  SEND_ALL_NOTIFICATIONS: "SEND_ALL_NOTIFICATIONS",
  SEND_ALL_NOTIFICATIONS_SUCCESS: "SEND_ALL_NOTIFICATIONS_SUCCESS",
  SEND_ALL_NOTIFICATIONS_FAILURE: "SEND_ALL_NOTIFICATIONS_FAILURE",

  SEND_SPECIFIC_NOTIFICATIONS: "SEND_SPECIFIC_NOTIFICATIONS",
  SEND_SPECIFIC_NOTIFICATIONS_SUCCESS: "SEND_SPECIFIC_NOTIFICATIONS_SUCCESS",
  SEND_SPECIFIC_NOTIFICATIONS_FAILURE: "SEND_SPECIFIC_NOTIFICATIONS_FAILURE",

  CREATE_SCHEDULE_NOTIFICATION: "CREATE_SCHEDULE_NOTIFICATION",
  CREATE_SCHEDULE_NOTIFICATION_SUCCESS: "CREATE_SCHEDULE_NOTIFICATION_SUCCESS",
  CREATE_SCHEDULE_NOTIFICATION_FAILURE: "CREATE_SCHEDULE_NOTIFICATION_FAILURE",

  GET_SCHEDULE_NOTIFICATION: "GET_SCHEDULE_NOTIFICATION",
  GET_SCHEDULE_NOTIFICATION_SUCCESS: "GET_SCHEDULE_NOTIFICATION_SUCCESS",
  GET_SCHEDULE_NOTIFICATION_FAILURE: "GET_SCHEDULE_NOTIFICATION_FAILURE",

  EDIT_SCHEDULE_NOTIFICATION_STATUS: "EDIT_SCHEDULE_NOTIFICATION_STATUS",
  EDIT_SCHEDULE_NOTIFICATION_STATUS_SUCCESS:
    "EDIT_SCHEDULE_NOTIFICATION_STATUS_SUCCESS",
  EDIT_SCHEDULE_NOTIFICATION_STATUS_FAILURE:
    "EDIT_SCHEDULE_NOTIFICATION_STATUS_FAILURE",

  HIDE_SUCCESS_MESSAGE: "HIDE_SUCCESS_MESSAGE"
};

export const sendAllNotifications = data => {
  return {
    type: notificationActionsTypes.SEND_ALL_NOTIFICATIONS,
    payload: data
  };
};

export const sendSpecificNotification = data => {
  return {
    type: notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS,
    payload: data
  };
};

export const createScheduleNotification = data => {
  return {
    type: notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION,
    payload: data
  };
};

export const getScheduleNotification = data => {
  return {
    type: notificationActionsTypes.GET_SCHEDULE_NOTIFICATION,
    payload: data
  };
};

export const editScheduleNotificationStatus = (id, data) => {
  return {
    type: notificationActionsTypes.EDIT_SCHEDULE_NOTIFICATION_STATUS,
    payload: { id, data }
  };
};

export const hideSuccessMessage = () => {
  return {
    type: notificationActionsTypes.HIDE_SUCCESS_MESSAGE
  };
};

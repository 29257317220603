import React from "react";
import { useDispatch } from "react-redux";
import { setDoubledModifiers } from "../../store/actions/products.actions";
import ToggleButton from "../toggleButton/toggleButton";

const Modifier = ({ modifier }) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <td>{modifier.name && modifier.name.en}</td>
      <td>{modifier.name && modifier.name.ar}</td>
      <td>
        <div
          className="d-flex justify-content-center"
          onClick={() => {
            dispatch(
              setDoubledModifiers({
                modifierId: modifier.id,
                modifierIsDoubled: !modifier.isDouble
              })
            );
          }}
        >
          <ToggleButton value={modifier.isDouble} />
        </div>
      </td>
    </tr>
  );
};

export default Modifier;

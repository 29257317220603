import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import "./dropify.scss";
import { fileSize5 } from "utils/validators";

const Dropify = ({ product, height, action, modal = false, formik }) => {
  const dispatch = useDispatch();
  const [background, setBackground] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [imageName, setImageName] = useState(product.imagePath);

  const isLoadingProductImage = useSelector(
    state => state.products.isLoadingProductImage
  );

  const fileSelectedHandler = event => {
    formik.setFieldValue("selectedFile", event.target.files[0]);
    setImageName(event.target.files[0] && event.target.files[0].name);
  };

  useEffect(() => {
    if (formik.values.selectedFile === imageName) {
      return;
    } else {
      if (formik.values.selectedFile && !formik.errors.selectedFile) {
        if (action) {
          const formData = new FormData();
          formData.append("image", formik.values.selectedFile);
          dispatch(action({ formData, productId: product.id }));
        }
      }
    }
  }, [formik.values.selectedFile, action, modal]);

  const getError = name =>
    formik.errors[name] && formik.touched[name] ? (
      <ul className="parsley-errors-list filled">
        <li className="parsley-required">{formik.errors[name]}</li>
      </ul>
    ) : null;

  const handleChange = e => {
    let fr = new FileReader();
    fr.onload = function() {
      setHeaderImage(fr.result);
    };
    const error = fileSize5(e.target.files[0].size);
    if (error) formik.setFieldError("selectedFile", error);
    else {
      fileSelectedHandler(e);
      fr.readAsDataURL(e.target.files[0]);
    }
  };

  const handleClick = () => {
    formik.setFieldTouched("selectedFile", true);
  };

  return (
    <div>
      {isLoadingProductImage ? (
        <div className=" h-75 d-flex justify-content-center align-items-center">
          <div className="spinner-border avatar-lg" role="status"></div>
        </div>
      ) : (
        <>
          <code>Image Type: PNG or JPG, Image size {"<="} 5 MB</code>
          <div
            style={{ height: height }}
            className={classNames(
              "dropify",
              background && (headerImage || product.imagePath) && "hoverImage"
            )}
            onMouseEnter={() => setBackground(true)}
            onMouseLeave={() => setBackground(false)}
          >
            <input
              type="file"
              accept=".png, .jpg"
              hidden
              id="selectedFile"
              name="selectedFile"
              style={{ zIndex: "100" }}
              onClick={handleClick}
              onChange={handleChange}
            />
            <div
              className={classNames(
                "dropify-upload",
                (product.imagePath || headerImage) && "opacityZero"
              )}
            >
              <i className="dripicons-cloud-upload"></i>
              <h5>Drop files here or click to upload.</h5>
            </div>

            {background && (headerImage || product.imagePath) && (
              <div className="dropify-replace text-center">
                <p className="dropify-filename">
                  <span className="dropify-filename-inner">
                    {(imageName && imageName.slice(67)) ||
                      (product.imagePath && product.imagePath.slice(67))}
                  </span>
                </p>
                <p className="dropify-infos-message">
                  Drag and drop or click to replace
                </p>
              </div>
            )}
            <img
              src={headerImage || product.imagePath}
              alt="Card  cap"
              className={classNames(
                "",
                !headerImage && !product.imagePath && "opacityZero"
              )}
              onClick={e => {
                document.getElementById("selectedFile").click();
              }}
            />
          </div>
          {getError("selectedFile")}
        </>
      )}
    </div>
  );
};

export default Dropify;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Advertisement from "../../components/contentPage/advertisement/advertisement";

const pagesContent = () => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Advertisement</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <Advertisement />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pagesContent;

import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import image from "../../assets/images/small/defaultProduct.png";
import "./product.scss";

const Product = ({ product }) => {
  return (
    <>
      <div className="col-md-6 col-xl-4 mb-3">
        <div className="card-box product-box h-100">
          <div className="product-action">
            <Link
              className="btn btn-primary btn-background btn-xs waves-effect waves-light"
              to={`products/${product.id}`}
            >
              <i className="fe-eye mr-0"></i>
            </Link>
          </div>

          <div className="productImage">
            <img
              src={product.imagePath || image}
              className="img-fluid"
              alt="#"
            />
          </div>

          <div className="product-info">
            <div className="row align-items-center">
              <div className="col">
                {product.selected ? (
                  <h5 className="font-16 mt-0 sp-line-1">
                    {" "}
                    <span className="badge bg-soft-success text-success">
                      Active
                    </span>
                  </h5>
                ) : (
                  <h5 className="font-16 mt-0 sp-line-1">
                    {" "}
                    <span className="badge bg-soft-danger text-danger">
                      Active
                    </span>
                  </h5>
                )}
                <h5 className="font-16 mt-0 sp-line-1">
                  <span className="text-dark">{product.name.en}</span>{" "}
                </h5>
                <h5 className="font-16 mt-0 sp-line-1">
                  <span className="text-dark">{product.name.ar}</span>{" "}
                </h5>
                <h6 className="m-0">
                  {" "}
                  <span className="text-muted">
                    {" "}
                    {_.isEmpty(product.categories)
                      ? "Category name"
                      : product.categories[0].name.en}
                  </span>
                </h6>
              </div>
              <div className="col-auto">
                <div className="product-price-tag">{product.price} SR</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;

import { combineReducers } from "redux";
import categories from "./categories.reducers";
import authorization from "./authorization.reducers";
import branches from "./branches.reducers";
import products from "./products.reducers";
import customers from "./customers.reducers";
import orders from "./orders.reducers";
import pagesContent from "./pagesContent.reducers";
import discounts from "./discounts.reducers";
import upsell from "./upsell.reducers";
import feedback from "./feedback.reducers";
import points from "./points.reducers";
import foodTruck from "./foodTruck.reducers";
import notification from "./notification.reducers";
import transactions from "./transactions.reducers";

const allReducers = combineReducers({
  categories,
  authorization,
  products,
  branches,
  customers,
  orders,
  pagesContent,
  discounts,
  upsell,
  feedback,
  points,
  foodTruck,
  notification,
  transactions,
});

const rootReducers = (state, action) => {
  return allReducers(state, action);
};

export default rootReducers;

import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { objectToFormData } from "object-to-formdata";
import {
  updateCategoryImage,
  updateCategory
} from "../../../store/actions/categories.actions";
import ToggleButton from "../../toggleButton/toggleButton";
import DoubleDropify from "../../doubleDropify/doubleDropify";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import "./modalCategory.scss";

export default function ModalCategory({ category, modal, onClose }) {
  const dispatch = useDispatch();

  const handleToggleActive = () => {
    form.values.selected
      ? form.setFieldValue("selected", false)
      : form.setFieldValue("selected", true);
  };

  const form = useFormik({
    initialValues: {
      categoryNameEn: category.name.en,
      categoryNameAr: category.name.ar,
      selectedRedFile: category.redIconPath,
      selectedGreyFile: category.greyIconPath,
      selected: category.selected
    },
    validationSchema: Yup.object({
      categoryNameEn: Yup.string().required("Required"),
      categoryNameAr: Yup.string().required("Required"),
      selectedRedFile: Yup.mixed().required("Required"),
      selectedGreyFile: Yup.mixed().required("Required")
    }),
    validate: values => {
      const errors = {};
      if (
        values.selectedRedFile === category.redIconPath &&
        values.selectedGreyFile === category.greyIconPath
      ) {
      } else {
        if (typeof values.selectedRedFile === "string") {
          errors.selectedRedFile = "Required";
        }
        if (typeof values.selectedGreyFile === "string") {
          errors.selectedGreyFile = "Required";
        }
        return errors;
      }
    },
    onSubmit: values => {
      dispatch(
        updateCategory({
          name: {
            en: values.categoryNameEn,
            ar: values.categoryNameAr
          },
          id: category.id,
          selected: values.selected
        })
      );

      if (
        values.selectedRedFile !== category.redIconPath &&
        values.selectedGreyFile !== category.greyIconPath
      ) {
        dispatch(
          updateCategoryImage({
            formdata: objectToFormData({
              greyIcon: values.selectedGreyFile,
              redIcon: values.selectedRedFile
            }),
            categoryId: category.id
          })
        );
      }

      onClose();
    }
  });

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        onClose();
        form.resetForm();
      }}
    >
      <form onSubmit={form.handleSubmit}>
        <ModalHeader
          toggle={() => {
            onClose();
            form.resetForm();
          }}
        >
          Edit Category
        </ModalHeader>
        <div className="modal-body p-4">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="categoryNameEn" className="control-label">
                  Category Name - English
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="categoryNameEn"
                  name="categoryNameEn"
                  placeholder="Name"
                  onClick={() => {
                    form.setFieldTouched("categoryNameEn", true);
                  }}
                  onChange={e =>
                    form.setFieldValue("categoryNameEn", e.target.value)
                  }
                  value={form.values.categoryNameEn}
                />
                {form.errors.categoryNameEn && form.touched.categoryNameEn ? (
                  <ul className="parsley-errors-list filled">
                    <li className="parsley-required">
                      {form.errors.categoryNameEn}
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="categoryNameAr" className="control-label">
                  Category Name - Arabic
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="categoryNameAr"
                  name="categoryNameAr"
                  placeholder="Name"
                  onClick={() => {
                    form.setFieldTouched("categoryNameAr", true);
                  }}
                  onChange={e =>
                    form.setFieldValue("categoryNameAr", e.target.value)
                  }
                  value={form.values.categoryNameAr}
                />
                {form.errors.categoryNameAr && form.touched.categoryNameAr ? (
                  <ul className="parsley-errors-list filled">
                    <li className="parsley-required">
                      {form.errors.categoryNameAr}
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="toggleButton">
                <div>Show in Mobile App</div>
                <div onClick={handleToggleActive}>
                  <ToggleButton
                    id="selected"
                    name="selected"
                    value={form.values.selected}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-2">
            <div className="col-md-12">
              <div className="control-label mb-1">Grey Icon </div>
              <DoubleDropify
                height="25vh"
                formik={form}
                type="selectedGreyFile"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="control-label mb-1">Red Icon </div>
              <DoubleDropify
                height="25vh"
                formik={form}
                type="selectedRedFile"
              />
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              form.resetForm();
              onClose();
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

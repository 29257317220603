import { call, put, takeLatest } from "redux-saga/effects";
import { pointsApi } from "../../services/points.service";
import { pointsActionsTypes } from "../actions/points.actions";

function* getRewards(action) {
  try {
    const result = yield call(pointsApi.getRewards, action.payload);
    yield put({
      type: pointsActionsTypes.GET_REWARDS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.GET_REWARDS_FAILURE
    });
  }
}

function* setReward(action) {
  try {
    const result = yield call(pointsApi.setReward, action.payload);

    yield put({
      type: pointsActionsTypes.SET_REWARD_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.SET_REWARD_FAILURE
    });
  }
}

function* deleteReward(action) {
  try {
    yield call(pointsApi.deleteReward, action.payload);
    yield put({
      type: pointsActionsTypes.DELETE_REWARD_SUCCESS,
      payload: action.payload
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.DELETE_REWARD_FAILURE,
      payload: error.data.message
    });
  }
}

function* getOffers(action) {
  try {
    const result = yield call(pointsApi.getOffers, action.payload);
    yield put({
      type: pointsActionsTypes.GET_OFFERS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.GET_OFFERS_FAILURE
    });
  }
}

function* setOffer(action) {
  try {
    const result = yield call(pointsApi.setOffer, action.payload);

    yield put({
      type: pointsActionsTypes.SET_OFFER_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.SET_OFFER_FAILURE,
      payload: error.data.message
    });
  }
}

function* deleteOffer(action) {
  try {
    const result = yield call(pointsApi.deleteOffer, action.payload);

    yield put({
      type: pointsActionsTypes.DELETE_OFFER_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.DELETE_OFFER_FAILURE
    });
  }
}

function* getPoints(action) {
  try {
    const result = yield call(
      pointsApi.getPoints,
      action.payload.type,
      action.payload.pagination
    );
    yield put({
      type: pointsActionsTypes.GET_POINTS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.GET_POINTS_FAILURE
    });
  }
}

function* setPoint(action) {
  try {
    const result = yield call(pointsApi.setPoint, action.payload);

    yield put({
      type: pointsActionsTypes.SET_POINT_SUCCESS,
      payload: result.data
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.SET_POINT_FAILURE,
      payload: e.data.message
    });
  }
}

function* updatePoint(action) {
  try {
    const result = yield call(pointsApi.updatePoint, action.payload);

    yield put({
      type: pointsActionsTypes.UPDATE_POINT_SUCCESS,
      payload: result.data
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.UPDATE_POINT_FAILURE
    });
  }
}

function* getPointHistory(action) {
  try {
    const result = yield call(pointsApi.getPointHistory, action.payload);
    yield put({
      type: pointsActionsTypes.GET_POINT_HISTORY_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: pointsActionsTypes.GET_POINT_HISTORY_FAILURE
    });
  }
}

function* assignPoint(action) {
  try {
    const result = yield call(pointsApi.assignPoint, action.payload);
    yield put({
      type: pointsActionsTypes.ASSIGN_POINT_SUCCESS,
      payload: result.data
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.ASSIGN_POINT_FAILURE
    });
  }
}

function* getPointsRiyal() {
  try {
    const result = yield call(pointsApi.getPointsRiyal);
    yield put({
      type: pointsActionsTypes.GET_POINTS_RIYAL_SUCCESS,
      payload: result.data
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.GET_POINTS_RIYAL_FAILURE,
      payload: e.data.message
    });
  }
}

function* updatePointsRiyal(action) {
  try {
    yield call(pointsApi.updatePointsRiyal, action.payload);
  } catch (e) {
    yield put({
      type: pointsActionsTypes.UPDATE_POINTS_RIYAL_FAILURE,
      payload: e.data.message
    });
  }
}

function* updateReward(action) {
  try {
    yield call(pointsApi.updateReward, action.payload.id, action.payload.data);
    yield put({
      type: pointsActionsTypes.UPDATE_REWARD_SUCCESS,
      payload: { id: action.payload.id, data: action.payload.data }
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.UPDATE_REWARD_FAILURE,
      payload: e.data.message
    });
  }
}

function* getPointsReport(action) {
  try {
    const res = yield call(pointsApi.getPointsReport, action.payload);
    yield put({
      type: pointsActionsTypes.GET_POINTS_REPORT_SUCCESS,
      payload: res.data
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.GET_POINTS_REPORT_FAILURE,
      payload: e.data.message
    });
  }
}

function* getAwardHistory(action) {
  try {
    const res = yield call(pointsApi.getAwardHistory, action.payload);
    yield put({
      type: pointsActionsTypes.GET_AWARD_HISTORY_SUCCESS,
      payload: res.data
    });
  } catch (e) {
    yield put({
      type: pointsActionsTypes.GET_AWARD_HISTORY_FAILURE,
      payload: e.data.message
    });
  }
}

function* pointsSaga() {
  yield takeLatest([pointsActionsTypes.GET_REWARDS], getRewards);
  yield takeLatest([pointsActionsTypes.SET_REWARD], setReward);
  yield takeLatest([pointsActionsTypes.DELETE_REWARD], deleteReward);
  yield takeLatest([pointsActionsTypes.GET_OFFERS], getOffers);
  yield takeLatest([pointsActionsTypes.SET_OFFER], setOffer);
  yield takeLatest([pointsActionsTypes.DELETE_OFFER], deleteOffer);
  yield takeLatest([pointsActionsTypes.GET_POINTS], getPoints);
  yield takeLatest([pointsActionsTypes.SET_POINT], setPoint);
  yield takeLatest([pointsActionsTypes.UPDATE_POINT], updatePoint);
  yield takeLatest([pointsActionsTypes.GET_POINT_HISTORY], getPointHistory);
  yield takeLatest([pointsActionsTypes.ASSIGN_POINT], assignPoint);
  yield takeLatest([pointsActionsTypes.GET_POINTS_RIYAL], getPointsRiyal);
  yield takeLatest([pointsActionsTypes.UPDATE_POINTS_RIYAL], updatePointsRiyal);
  yield takeLatest([pointsActionsTypes.UPDATE_REWARD], updateReward);
  yield takeLatest([pointsActionsTypes.GET_POINTS_REPORT], getPointsReport);
  yield takeLatest([pointsActionsTypes.GET_AWARD_HISTORY], getAwardHistory);
}

export default pointsSaga;

import { call, put, takeLatest, select } from "redux-saga/effects";
import { productsApi } from "../../services/products.service";
import { productsActionsTypes } from "../actions/products.actions";

const getCategoriesData = state => ({
  productsForUpdate: state.products.productsForUpdate,
  modifiersForUpdate: state.products.modifiersForUpdate,
  modifiersForChange: state.products.modifiersForChange,
  modifiersForUpdateOptions: state.products.modifiersForUpdateOptions,
  modifiersForSetDouble: state.products.modifiersForSetDouble,
  modifiersForChangeOptions: state.products.modifiersForChangeOptions
});

function* getProducts(action) {
  try {
    const result = yield call(
      productsApi.getProducts,
      action.payload.pagination,
      action.payload.search,
      action.payload.filteredCategory
    );

    yield put({
      type: productsActionsTypes.GET_PRODUCTS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.GET_PRODUCTS_FAILURE
    });
  }
}

function* getProduct(action) {
  try {
    const result = yield call(productsApi.getProduct, action.payload);

    yield put({
      type: productsActionsTypes.GET_PRODUCT_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.GET_PRODUCT_FAILURE
    });
  }
}

function* getModifiers(action) {
  try {
    const result = yield call(
      productsApi.getModifiers,
      action.payload.pagination
    );
    yield put({
      type: productsActionsTypes.GET_MODIFIERS_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.GET_MODIFIERS_FAILURE
    });
  }
}

function* setDoubleModifiers() {
  const data = yield select(getCategoriesData);

  try {
    const result = yield call(
      productsApi.setDoubledModifiers,
      data.modifiersForSetDouble.modifierId,
      data.modifiersForSetDouble.modifierIsDoubled
    );
    yield put({
      type: productsActionsTypes.SET_DOUBLE_MODIFIERS_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.SET_DOUBLE_MODIFIERS_FAILURE
    });
  }
}

function* addProduct(action) {
  try {
    const result = yield call(
      productsApi.addProduct,
      action.payload.productId,
      action.payload
    );
    yield put({
      type: productsActionsTypes.ADD_PRODUCT_SUCCESS,
      payload: result.data.data.result
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.ADD_PRODUCT_FAILURE
    });
  }
}

function* updateProductImage(action) {
  try {
    const result = yield call(
      productsApi.updateProductImage,
      action.payload.formData,
      action.payload.productId
    );

    yield put({
      type: productsActionsTypes.UPDATE_PRODUCT_IMAGE_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.UPDATE_PRODUCT_IMAGE_FAILURE
    });
  }
}

function* updateProduct(action) {
  const { id, ...obj } = action.payload;
  try {
    const result = yield call(productsApi.updateProduct, id, obj);
    yield put({
      type: productsActionsTypes.UPDATE_PRODUCT_SUCCESS,
      payload: {
        result: result.data.data.result
      }
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.UPDATE_PRODUCT_FAILURE
    });
  }
}

function* updateModifierProduct(action) {
  try {
    const result = yield call(
      productsApi.updateModifierProduct,
      action.payload.id,
      action.payload.modifierIds,
      action.payload.selected
    );
    yield put({
      type: productsActionsTypes.UPDATE_MODIFIER_PRODUCT_SUCCESS,
      payload: {
        result: result.data.data.result
      }
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.UPDATE_MODIFIER_PRODUCT_FAILURE
    });
  }
}

function* updateModifierImage(action) {
  try {
    yield call(
      productsApi.updateModifierImage,
      action.payload.id,
      action.payload.formData
    );
  } catch (error) {
    yield put({
      type: productsActionsTypes.UPDATE_MODIFIER_IMAGE_FAILURE
    });
  }
}

function* updateProductsOrder(action) {
  try {
    yield call(productsApi.updateProductsOrder, action.payload);
    yield put({
      type: productsActionsTypes.UPDATE_PRODUCTS_ORDER_SUCCESS
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.UPDATE_PRODUCTS_ORDER_FAILURE
    });
  }
}

function* toggleSelection(action) {
  try {
    yield call(productsApi.toggleSelection, action.payload);
    yield put({
      type: productsActionsTypes.TOGGLE_SELECTION_SUCCESS
    });
  } catch (error) {
    yield put({
      type: productsActionsTypes.TOGGLE_SELECTION_FAILURE
    });
  }
}

function* productsSaga() {
  yield takeLatest([productsActionsTypes.GET_PRODUCTS], getProducts);
  yield takeLatest([productsActionsTypes.GET_PRODUCT], getProduct);

  yield takeLatest([productsActionsTypes.GET_MODIFIERS], getModifiers);
  yield takeLatest([productsActionsTypes.ADD_PRODUCT], addProduct);
  yield takeLatest(
    [productsActionsTypes.UPDATE_PRODUCT_IMAGE],
    updateProductImage
  );
  yield takeLatest([productsActionsTypes.UPDATE_PRODUCT], updateProduct);
  yield takeLatest(
    [productsActionsTypes.UPDATE_MODIFIER_PRODUCT],
    updateModifierProduct
  );
  yield takeLatest(
    [productsActionsTypes.SET_DOUBLE_MODIFIERS],
    setDoubleModifiers
  );
  yield takeLatest(
    [productsActionsTypes.UPDATE_MODIFIER_IMAGE],
    updateModifierImage
  );
  yield takeLatest(
    [productsActionsTypes.UPDATE_PRODUCTS_ORDER],
    updateProductsOrder
  );
  yield takeLatest([productsActionsTypes.TOGGLE_SELECTION], toggleSelection);
}

export default productsSaga;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import classNames from "classnames";
import { getFoodTruckBooked } from "../../store/actions/foodTruck.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";
import ModalBookedFoofTruck from "../../components/modal/modalBookedFoodTruck/modalBookedFoodTruck";
import Pagination from "../../components/pagination/pagination";

const BookedFoodTruck = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const bookedData = useSelector(state => state.foodTruck.bookedData);
  const isLoading = useSelector(state => state.foodTruck.isLoadingBooked);
  const countBookedData = useSelector(state => state.foodTruck.countBookedData);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [location, setLocation] = useState({});

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  const tableHead = [
    {
      name: "Customer Name",
      key: "customerName"
    },
    {
      name: "Customer Phone",
      key: "customerPhone"
    },
    {
      name: "Customer Email",
      key: "customerEmail"
    },
    {
      name: "Customer Location",
      key: "showLocation",
      onClick: body => {
        toggle();
        setLocation({
          lat: body.address.lat,
          lng: body.address.long
        });
      }
    },
    {
      name: "Total Quantity",
      key: "totalQuantity"
    },
    {
      name: "Total Price",
      key: "totalPrice"
    },
    {
      name: "Submission Date",
      field: "submissionDate",
      key: "date"
    },
    {
      name: "Booked Date",
      field: "bookedDate",
      key: "date"
    },
    {
      name: "Product ID",
      key: "bookedProductId"
    }
  ];

  useEffect(() => {
    dispatch(
      getFoodTruckBooked({
        pagination: { page: currentPage || 1, limit: rowsPerPage || 10 }
      })
    );
  }, [dispatch, currentPage, rowsPerPage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Booked Food Truck</h4>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-box">
                      <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                        Boooked
                      </h5>
                      <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={bookedData}
                        />

                        <ModalBookedFoofTruck
                          modal={modal}
                          toggle={toggle}
                          location={location}
                        />
                      </div>
                    </div>
                    <Pagination
                      totalCount={countBookedData}
                      rows={rowsPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setPageQuery}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookedFoodTruck;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { getPointsReport } from "../../store/actions/points.actions";
import { useFormik } from "formik";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";

const reportTypes = {
  aboutToExpire: "About to Expire",
  allEarnedPointsInSys: "Earned Points",
  allExpiredPointsInSys: "Expired Points",
  allUsedPointsInSys: "Used Points"
};

const Points = ({ history }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const { pointsReport, userList } = useSelector(state => state.points);

  const isLoading = useSelector(state => state.points.isLoadingPoints);

  const formik = useFormik({
    initialValues: {
      gender: "",
      device: "",
      customer: ""
    },
    enableReinitialize: true
  });

  const tableHead = [
    { name: "First Name", key: "firstName" },
    { name: "Last Name", key: "lastName" },
    { name: "Mobile Number", key: "phone" },
    { name: "email", key: "email" },
    { name: "Foodics Id", key: "foodicsId" },
    { name: "Spent Points", key: "spentPoints" },
    { name: "Points History", key: "details", path: "customer", param: "id" }
  ];

  useEffect(() => {
    dispatch(getPointsReport(formik.values));
  }, [formik.values]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Points Report</h4>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-box">
                    <div className="d-flex justify-content-between">
                      <div className="page-title-box">
                        <h4 className="page-title">General Report</h4>
                      </div>
                      <div className="text-md-right">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                          onClick={() => {
                            dispatch(
                              getPointsReport({
                                ...formik.values,
                                createFile: true
                              })
                            );
                          }}
                        >
                          Export as CSV
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      {pointsReport &&
                        Object.entries(pointsReport).length &&
                        Object.entries(pointsReport).map((item, index) => (
                          <div key={item[0]} className="col-3 p-2">
                            <div
                              className="rounded p-2 d-flex justify-content-between"
                              style={{ border: "2px solid" }}
                            >
                              <h3>#</h3>
                              <div className="text-right">
                                <h2> {item[1]}</h2>
                                <p>{reportTypes[item[0]]}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="card-box">
                    <div className="d-flex justify-content-between">
                      <div className="page-title-box">
                        <h4 className="page-title">Specific Report</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 text-sm-center form-inline">
                        <div className="form-group mr-2">
                          <label htmlFor="Filter By">
                            Customer: &nbsp; &nbsp;
                          </label>
                          <select
                            id="customer"
                            name="customer"
                            className="custom-select custom-select-sm"
                            onChange={formik.handleChange}
                            value={formik.values.customer}
                          >
                            <option value="">--</option>
                            <option value="userList">All</option>
                            <option value="theMostEarnedPointsUser">
                              The Most Earned Points Customer
                            </option>
                            <option value="theMostUsedPointsUser">
                              The Most Used Points Customer
                            </option>
                          </select>
                        </div>
                        <div className="form-group mr-2">
                          <label htmlFor="Filter By">
                            Gender: &nbsp; &nbsp;
                          </label>
                          <select
                            id="gender"
                            name="gender"
                            className="custom-select custom-select-sm"
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                          >
                            <option value="">--</option>
                            <option value="unknown">Unknown</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                        <div className="form-group mr-2">
                          <label htmlFor="Filter By">
                            Device: &nbsp; &nbsp;
                          </label>
                          <select
                            id="device"
                            name="device"
                            className="custom-select custom-select-sm"
                            onChange={formik.handleChange}
                            value={formik.values.device}
                          >
                            <option value="">--</option>
                            <option value="both">Both</option>
                            <option value="IOS">iOS</option>
                            <option value="Android">Android</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={userList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Points;

import { call, put, takeLatest } from "redux-saga/effects";
import { discountsApi } from "../../services/discounts.service";
import { discountsActionsTypes } from "../actions/discounts.actions";

function* getDiscounts(action) {
  try {
    const result = yield call(
      discountsApi.getDiscounts,
      action.payload.pagination,
      action.payload.discountId
    );

    yield put({
      type: discountsActionsTypes.GET_DISCOUNTS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: discountsActionsTypes.GET_DISCOUNTS_FAILURE
    });
  }
}

function* getDiscount(action) {
  try {
    const result = yield call(discountsApi.getDiscount, action.payload);

    yield put({
      type: discountsActionsTypes.GET_DISCOUNT_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: discountsActionsTypes.GET_DISCOUNT_FAILURE
    });
  }
}

function* updateDiscount(action) {
  try {
    const result = yield call(discountsApi.updateDiscount, action.payload);

    yield put({
      type: discountsActionsTypes.UPDATE_DISCOUNT_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: discountsActionsTypes.UPDATE_DISCOUNT_FAILURE
    });
  }
}

function* getCoupons(action) {
  try {
    const { pagination, data } = action.payload;
    const result = yield call(discountsApi.getCoupons, pagination, data);

    yield put({
      type: discountsActionsTypes.GET_COUPONS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: discountsActionsTypes.GET_COUPONS_FAILURE
    });
  }
}

function* getCoupon(action) {
  try {
    const result = yield call(discountsApi.getCoupon, action.payload);

    yield put({
      type: discountsActionsTypes.GET_COUPON_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: discountsActionsTypes.GET_COUPON_FAILURE
    });
  }
}

function* updateCoupon(action) {
  try {
    const result = yield call(discountsApi.updateCoupon, action.payload);

    yield put({
      type: discountsActionsTypes.UPDATE_COUPON_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: discountsActionsTypes.UPDATE_COUPON_FAILURE
    });
  }
}

function* discountsSaga() {
  yield takeLatest([discountsActionsTypes.GET_DISCOUNTS], getDiscounts);
  yield takeLatest([discountsActionsTypes.GET_COUPONS], getCoupons);
  yield takeLatest([discountsActionsTypes.GET_DISCOUNT], getDiscount);
  yield takeLatest([discountsActionsTypes.GET_COUPON], getCoupon);
  yield takeLatest([discountsActionsTypes.UPDATE_DISCOUNT], updateDiscount);
  yield takeLatest([discountsActionsTypes.UPDATE_COUPON], updateCoupon);
}

export default discountsSaga;

import { API_URL } from "../constants";
import axiosInstance from "../services/interceptor";

export const ordersApi = {
  getOrders(customerHid) {
    return axiosInstance.get(`${API_URL}/orders`, {
      params: {
        customerHid
      }
    });
  },

  getAllOrders(data) {
    const { page, limit } = data.pagination;
    return axiosInstance.get(`${API_URL}/orders/all`, {
      params: {
        ...data,
        page,
        limit
      }
    });
  },

  getSpecificOrder(id) {
    return axiosInstance.get(`${API_URL}/orders/specific-order-statuses`, {
      params: {
        id
      }
    });
  },

  getDashboardStatus() {
    return axiosInstance.get(`${API_URL}/orders/dashboard-statuses`);
  },

  getMobileStatus() {
    return axiosInstance.get(`${API_URL}/orders/mobile-statuses`);
  },

  setOrderStatus(orderStatus) {
    const orderArr = [];
    orderStatus.map(order => {
      orderArr.push({
        mobileStatusId: order.id,
        periodTime: order.periodTime,
        nameEn: order.nameEn,
        nameAr: order.nameAr
      });
      return orderArr;
    });

    return axiosInstance.post(
      `${API_URL}/orders/manage-order-status-mobile`,
      orderArr
    );
  },

  getCancelTime() {
    return axiosInstance.get(`${API_URL}/orders/cancel-time`);
  },

  setCancelTime(value) {
    return axiosInstance.post(`${API_URL}/orders/cancel-time`, {
      value
    });
  },

  getDeliveryFee() {
    return axiosInstance.get(`${API_URL}/delivery-fee`);
  },

  setDeliveryFee(
    deliveryFee,
    fixedFee,
    minimumFreeDeliveryOrderPrice,
    minimumAmountForDelivery
  ) {
    return axiosInstance.patch(`${API_URL}/delivery-fee`, {
      deliveryFee,
      fixedFee,
      minimumFreeDeliveryOrderPrice,
      minimumAmountForDelivery
    });
  },

  getReceiptExpiration() {
    return axiosInstance.get(`${API_URL}/admin/receipt-expiration`);
  },

  setReceiptExpiration(receiptExpirationTime) {
    return axiosInstance.post(`${API_URL}/admin/receipt-expiration`, {
      receiptExpirationTime
    });
  },

  getAllOrderRefunds(id) {
    return axiosInstance.get(`${API_URL}/orders/refunds/${id}`, {});
  },

  setOrderRefund(data) {
    return axiosInstance.post(`${API_URL}/orders/refunds`, data);
  }
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import {
  getDashboardStatus,
  getMobileStatus,
  setOrderStatus
} from "../../store/actions/orders.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import OrderStatusField from "../../components/orderStatusField/orderStatusField";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

const OrderStatus = () => {
  const dispatch = useDispatch();
  const tabStatus = [
    { name: "Pending", foodicsStatusCode: 1 },
    { name: "Active", foodicsStatusCode: 2 },
    { name: "Done", foodicsStatusCode: 4 },
    { name: "Void", foodicsStatusCode: 3 }
  ];
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const [activeTab, setActiveTab] = useState("Pending");
  const dashboardStatus = useSelector(state => state.orders.dashboardStatus);
  const mobileStatus = useSelector(state => state.orders.mobileStatus);
  const loadingOrderStatus = useSelector(
    state => state.orders.loadingOrderStatus
  );
  const isLoadingBtn = useSelector(state => state.orders.loadingOrderStatusBtn);

  const saveOrderStatus = () => {
    dispatch(setOrderStatus(mobileStatus));
  };

  useEffect(() => {
    dispatch(getDashboardStatus());
    dispatch(getMobileStatus());
  }, []);

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Order Status</h4>
                </div>
              </div>
            </div>
            {loadingOrderStatus ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="nav flex-column nav-pills nav-pills-tab">
                          <span
                            className={classNames(
                              "nav-link  show mb-2 d-flex justify-content-between",
                              activeTab === "Pending" && "active"
                            )}
                            onClick={() => setActiveTab("Pending")}
                          >
                            Pending <i className="mdi mdi-arrow-right"></i>
                          </span>
                          <span
                            className={classNames(
                              "nav-link  show mb-2 d-flex justify-content-between",
                              activeTab === "Active" && "active"
                            )}
                            onClick={() => setActiveTab("Active")}
                          >
                            Active
                            <i className="mdi mdi-arrow-right"></i>
                          </span>
                          <span
                            className={classNames(
                              "nav-link  show mb-2 d-flex justify-content-between",
                              activeTab === "Done" && "active"
                            )}
                            onClick={() => setActiveTab("Done")}
                          >
                            Done
                            <i className="mdi mdi-arrow-right"></i>
                          </span>
                          <span
                            className={classNames(
                              "nav-link  show mb-2 d-flex justify-content-between",
                              activeTab === "Void" && "active"
                            )}
                            onClick={() => setActiveTab("Void")}
                          >
                            Void
                            <i className="mdi mdi-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="tab-content pt-0">
                          {tabStatus.map(status => (
                            <div
                              key={status.name}
                              className={classNames("show", {
                                showContent: activeTab === status.name,
                                hideContent: activeTab !== status.name
                              })}
                            >
                              <div className="col-md-12 dashboard-status">
                                <div className="card-header">
                                  <ul className="nav nav-tabs card-header-tabs">
                                    <li className="nav-item col-lg-4 pl-0">
                                      <span className="nav-link active text-center">
                                        Dashboard Order Status{" "}
                                      </span>
                                    </li>

                                    <li className="nav-item col-lg-4 pl-0">
                                      <span className="nav-link active text-center">
                                        Mobile Order Status
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row col-md-12 pl-4">
                                {dashboardStatus.map(dashboard => {
                                  if (
                                    dashboard.id === 14 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return (
                                      <OrderStatusField
                                        key={dashboard.id}
                                        mobile={{
                                          nameEn: "None",
                                          nameAr: "None",
                                          periodTime: null
                                        }}
                                        dashboard={dashboard}
                                      />
                                    );
                                  } else if (
                                    dashboard.id === 1 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 2) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 3 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 4) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 5 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 5) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 7 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 8) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 9 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 10) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 11 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 11) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 12 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 12) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 13 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return mobileStatus.map(mobile => {
                                      if (mobile.id === 13) {
                                        return (
                                          <OrderStatusField
                                            key={mobile.id}
                                            mobile={mobile}
                                            dashboard={dashboard}
                                          />
                                        );
                                      }
                                      return null;
                                    });
                                  } else if (
                                    dashboard.id === 6 &&
                                    dashboard.foodicsStatusCode ===
                                      status.foodicsStatusCode
                                  ) {
                                    return (
                                      <OrderStatusField
                                        key={dashboard.id}
                                        mobile={{
                                          nameEn: "None",
                                          nameAr: "None",
                                          periodTime: null
                                        }}
                                        dashboard={dashboard}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <LoadingButton
                        isLoadingBtn={isLoadingBtn}
                        btnContent="Save"
                        onClick={saveOrderStatus}
                      />
                    </div>
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnVisibilityChange
                      draggable
                      pauseOnHover
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;

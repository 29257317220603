import { call, put, takeLatest } from "redux-saga/effects";
import { transactionsApi } from "../../services/transactions.service";
import { transactionsActionsTypes } from "../actions/transactions.actions";

function* getTransactions(action) {
  try {
    const result = yield call(transactionsApi.getTransactions, action.payload);
    console.log("result: ", result);
    yield put({
      type: transactionsActionsTypes.GET_TRANSACTIONS_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: transactionsActionsTypes.GET_TRANSACTIONS_FAILURE
    });
  }
}

function* getTransactionsID(action) {
  try {
    console.log("Calling....");
    const result = yield call(
      transactionsApi.getTransactionsID,
      action.payload
    );
    console.log("Result: ", result);
    yield put({
      type: transactionsActionsTypes.GET_TRANSACTIONS_ID_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    console.log("Eroorrr:", error);
    yield put({
      type: transactionsActionsTypes.GET_TRANSACTIONS_ID_FAILURE
    });
  }
}

function* getTransactionsExport(action) {
  try {
    yield call(transactionsApi.getTransactionsExport, action.payload);
    yield put({
      type: transactionsActionsTypes.GET_TRANSACTIONS_EXPORT_SUCCESS
    });
  } catch (error) {
    yield put({
      type: transactionsActionsTypes.GET_TRANSACTIONS_EXPORT_FAILURE
    });
  }
}

function* transactionsSaga() {
  yield takeLatest(
    [transactionsActionsTypes.GET_TRANSACTIONS],
    getTransactions
  );
  yield takeLatest(
    [transactionsActionsTypes.GET_TRANSACTIONS_ID],
    getTransactionsID
  );
  yield takeLatest(
    [transactionsActionsTypes.GET_TRANSACTIONS_EXPORT],
    getTransactionsExport
  );
}

export default transactionsSaga;

export const customersActionsTypes = {
  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",

  SYNCHRONIZE_BLACKLIST: "SYNCHRONIZE_BLACKLIST",
  SYNCHRONIZE_BLACKLIST_SUCCESS: "SYNCHRONIZE_BLACKLIST_SUCCESS",
  SYNCHRONIZE_BLACKLIST_FAILURE: "SYNCHRONIZE_BLACKLIST_FAILURE",

  ADD_WALLET_CREDIT: "ADD_WALLET_CREDIT",
  ADD_WALLET_CREDIT_SUCCESS: "ADD_WALLET_CREDIT_SUCCESS",
  ADD_WALLET_CREDIT_FAILURE: "ADD_WALLET_CREDIT_FAILURE"
};

export const getCustomers = data => {
  return {
    type: customersActionsTypes.GET_CUSTOMERS,
    payload: data
  };
};

export const synchronizeBlacklist = () => {
  return {
    type: customersActionsTypes.SYNCHRONIZE_BLACKLIST
  };
};

export const addWalletCredit = data => {
  return {
    type: customersActionsTypes.ADD_WALLET_CREDIT,
    payload: data
  };
};

import React, { Fragment } from "react";
const PointList = props => {
  return props.taskList.map((val, idx) => (
    <Fragment key={val + idx}>
      <tr className="d-flex">
        <td className="col-md-4 pl-0 pr-0 border-0">
          <input
            type="number"
            className="form-control"
            id={`pointCondition[${idx}].numberOfItems`}
            name={`pointCondition[${idx}].numberOfItems`}
            value={props.formik.values.pointCondition[idx].numberOfItems}
            onChange={props.formik.handleChange}
            placeholder={
              Number(props.formik.values.pointType) === 1
                ? "Number of Orders"
                : "Number of Products"
            }
            onClick={() => {
              props.formik.setFieldTouched(
                `pointCondition[${idx}].numberOfItems`,
                true
              );
            }}
            required
          />
        </td>

        <td className="col-md-4 pr-0 pl-0 border-0">
          <input
            type="number"
            className="form-control"
            id={`pointCondition[${idx}].numberOfPoints`}
            name={`pointCondition[${idx}].numberOfPoints`}
            value={props.formik.values.pointCondition[idx].numberOfPoints}
            onChange={props.formik.handleChange}
            placeholder="Number of Points"
            onClick={() => {
              props.formik.setFieldTouched(
                `pointCondition[${idx}].numberOfPoints`,
                true
              );
            }}
            required
          />
        </td>
        <td className="col-md-3 border-0">
          {idx === 0 ? (
            <button
              onClick={() => props.add()}
              type="button"
              className="btn btn-primary text-center"
            >
              Add Condition
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-danger text-center"
              onClick={() => props.delete(val)}
            >
              X
            </button>
          )}
        </td>
      </tr>
      {Number(props.formik.values.pointType) === 2 && (
        <tr className="row">
          <td className="col-md-12 text-left">
            <div className="form-group">
              <label
                htmlFor={`pointCondition[${idx}].pointProduct`}
                className="control-label"
              >
                Select Product
              </label>
              <select
                className="custom-select custom-select-sm"
                value={props.formik.values.pointCondition[idx].pointProduct}
                id={`pointCondition[${idx}].pointProduct`}
                name={`pointCondition[${idx}].pointProduct`}
                onChange={props.formik.handleChange}
                required
              >
                <option value="">--</option>
                {props.products.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.name.en}
                  </option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      )}
    </Fragment>
  ));
};
export default PointList;

import { call, put, takeLatest } from "redux-saga/effects";
import { foodTruckApi } from "../../services/foodTruck.service";
import { foodTruckActionsTypes } from "../actions/foodTruck.actions";

function* getFoodTruckMenu() {
  try {
    const result = yield call(foodTruckApi.getFoodTruckMenu);
    yield put({
      type: foodTruckActionsTypes.GET_FOODTRUCK_MENU_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: foodTruckActionsTypes.GET_FOODTRUCK_MENU_FAILURE
    });
  }
}

function* updateFoodTruckMenu(action) {
  try {
    const result = yield call(foodTruckApi.updateFoodTruckMenu, action.payload);
    yield put({
      type: foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU_FAILURE
    });
  }
}

function* getFoodTruckBooked(action) {
  try {
    const result = yield call(
      foodTruckApi.getFoodTruckBooked,
      action.payload.pagination
    );

    yield put({
      type: foodTruckActionsTypes.GET_FOODTRUCK_BOOKED_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: foodTruckActionsTypes.GET_FOODTRUCK_BOOKED_FAILURE
    });
  }
}

function* foodTruckSaga() {
  yield takeLatest(
    [foodTruckActionsTypes.GET_FOODTRUCK_MENU],
    getFoodTruckMenu
  );
  yield takeLatest(
    [foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU],
    updateFoodTruckMenu
  );
  yield takeLatest(
    [foodTruckActionsTypes.GET_FOODTRUCK_BOOKED],
    getFoodTruckBooked
  );
}

export default foodTruckSaga;

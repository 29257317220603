export const foodTruckActionsTypes = {
  GET_FOODTRUCK_MENU: "GET_FOODTRUCK_MENU",
  GET_FOODTRUCK_MENU_SUCCESS: "GET_FOODTRUCK_MENU_SUCCESS",
  GET_FOODTRUCK_MENU_FAILURE: "GET_FOODTRUCK_MENU_FAILURE",

  UPDATE_FOODTRUCK_MENU: "UPDATE_FOODTRUCK_MENU",
  UPDATE_FOODTRUCK_MENU_SUCCESS: "UPDATE_FOODTRUCK_MENU_SUCCESS",
  UPDATE_FOODTRUCK_MENU_FAILURE: "UPDATE_FOODTRUCK_MENU_FAILURE",

  GET_FOODTRUCK_BOOKED: "GET_FOODTRUCK_BOOKED",
  GET_FOODTRUCK_BOOKED_SUCCESS: "GET_FOODTRUCK_BOOKED_SUCCESS",
  GET_FOODTRUCK_BOOKED_FAILURE: "GET_FOODTRUCK_BOOKED_FAILURE"
};

export const getFoodTruckMenu = () => {
  return {
    type: foodTruckActionsTypes.GET_FOODTRUCK_MENU
  };
};

export const updateFoodTruckMenu = data => {
  return {
    type: foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU,
    payload: data
  };
};

export const getFoodTruckBooked = data => {
  return {
    type: foodTruckActionsTypes.GET_FOODTRUCK_BOOKED,
    payload: data
  };
};

import { notificationActionsTypes } from "../actions/notification.actions";

const initialState = {
  loadingNotifications: false,
  success: false,
  notifications: []
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case notificationActionsTypes.SEND_ALL_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: true,
        success: false
      };

    case notificationActionsTypes.SEND_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        success: true
      };

    case notificationActionsTypes.SEND_ALL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
        success: false
      };

    case notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: true,
        success: false
      };

    case notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        success: true
      };

    case notificationActionsTypes.SEND_SPECIFIC_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
        success: false
      };

    case notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION:
      return {
        ...state,
        loadingNotifications: true,
        success: false
      };

    case notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        success: true,
        notifications: [
          ...state.notifications,
          {
            ...action.payload,
            repeat_times: action.payload.repeatTimes,
            additional_data: action.payload.additionalData
          }
        ]
      };

    case notificationActionsTypes.CREATE_SCHEDULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
        success: false
      };

    case notificationActionsTypes.GET_SCHEDULE_NOTIFICATION:
      return {
        ...state,
        loadingNotifications: true
      };

    case notificationActionsTypes.GET_SCHEDULE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        notifications: action.payload
      };

    case notificationActionsTypes.GET_SCHEDULE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingNotifications: false
      };

    case notificationActionsTypes.HIDE_SUCCESS_MESSAGE:
      return {
        ...state,
        success: false
      };

    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";
import {
  getScheduleNotification,
  editScheduleNotificationStatus,
  hideSuccessMessage
} from "../../store/actions/notification.actions";
import { ToastContainer, toast } from "react-toastify";
import ModalBirthdayNotifications from "components/modal/modalBirthdayNotifications";

const BirthdayNotifications = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const { loadingNotifications, success, notifications } = useSelector(
    state => state.notification
  );

  const [tableBody, setTableBody] = useState(notifications || []);

  const tableHead = [
    {
      name: "Title",
      key: "title"
    },
    {
      name: "Message",
      key: "text"
    },
    {
      name: "Month",
      key: "notificationMonth"
    },
    {
      name: "Status",
      key: "notificationStatus",
      onClick: ({ id, status: prevStatus }) => {
        const status = +prevStatus === 1 ? 2 : 1;
        setTableBody(tableBody.map(i => (i.id === id ? { ...i, status } : i)));
        dispatch(editScheduleNotificationStatus(id, { status }));
      }
    },
    {
      name: "Repeated Times",
      key: "repeat_times"
    },
    {
      name: "Promo Code",
      key: "additional_data"
    }
  ];

  useEffect(() => {
    if (success) {
      toast.success("Sent", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      dispatch(hideSuccessMessage());
    }
  }, [success]);

  useEffect(() => {
    dispatch(getScheduleNotification({ type: 2 }));
  }, [dispatch]);

  useEffect(() => {
    setTableBody(notifications);
  }, [notifications]);

  const loadingIndicator = () => (
    <div className="d-flex justify-content-center">
      <div className="spinner-border avatar-lg" role="status"></div>
    </div>
  );

  const getContent = () => (
    <div className="row">
      <div className="col-sm-12">
        <div className="card-box">
          <div className="text-md-right">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light mb-2 mr-2"
              onClick={toggleModal}
            >
              <i className="mdi mdi-plus mr-1"></i> Add New
            </button>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <TableComponent tableHead={tableHead} tableBody={tableBody} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Birthday Notifications</h4>
                </div>
              </div>
            </div>
            {loadingNotifications ? loadingIndicator() : getContent()}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <ModalBirthdayNotifications
              modal={openModal}
              toggle={toggleModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthdayNotifications;

export const transactionsActionsTypes = {
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",

  GET_TRANSACTIONS_ID: "GET_TRANSACTIONS_ID",
  GET_TRANSACTIONS_ID_SUCCESS: "GET_TRANSACTIONS_ID_SUCCESS",
  GET_TRANSACTIONS_ID_FAILURE: "GET_TRANSACTIONS_ID_FAILURE",

  GET_TRANSACTIONS_EXPORT: "GET_TRANSACTIONS_EXPORT",
  GET_TRANSACTIONS_EXPORT_SUCCESS: "GET_TRANSACTIONS_EXPORT_SUCCESS",
  GET_TRANSACTIONS_EXPORT_FAILURE: "GET_TRANSACTIONS_EXPORT_FAILURE",
};

export const getTransactions = () => {
  return {
    type: transactionsActionsTypes.GET_TRANSACTIONS,
  };
};

export const getTransactionsID = (data) => {
  return {
    type: transactionsActionsTypes.GET_TRANSACTIONS_ID,
    payload: data,
  };
};

export const getTransactionsExport = (data) => {
  return {
    type: transactionsActionsTypes.GET_TRANSACTIONS_EXPORT,
    payload: data,
  };
};

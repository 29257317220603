import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeStatusMenu } from "../store/actions/authorization.actions";
import ProfileDropdown from "./ProfileDropdown";
import logo from "../assets/images/logo-sm.png";
import { StorageService } from "../services/storage.service";
import { useDispatch, useSelector } from "react-redux";

const ProfileMenus = [
  {
    label: "Logout",
    icon: "fe-log-out",
    redirectTo: "/logout",
    hasDivider: true
  }
];

const Topbar = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [countMenuOpen, setCountMenuOpen] = useState(0);
  const user = JSON.parse(StorageService.getUser());
  const userName = user && user.role;
  const menuStatus = useSelector(state => state.authorization.menuStatus);

  useEffect(() => {
    document.body.classList.remove("no-transition");
    document.body.classList.remove("sidebar-enable");
    document.body.classList.remove("enlarged");
  }, []);

  useEffect(() => {
    setOpen(menuStatus);

    if (!menuStatus) {
      document.body.classList.add("sidebar-enable");
      document.body.classList.add("enlarged");
      if (countMenuOpen === 0) {
        document.body.classList.add("no-transition");
      }
    } else {
      document.body.classList.remove("sidebar-enable");
      document.body.classList.remove("enlarged");
      document.body.classList.remove("no-transition");
    }
    setCountMenuOpen(countMenuOpen + 1);
  }, [menuStatus]);

  useEffect(() => {
    document.body.classList.remove("sidebar-enable");
    document.body.classList.remove("enlarged");
    document.body.classList.remove("no-transition");
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      document.body.classList.add("sidebar-enable");
      if (countMenuOpen === 0) {
        document.body.classList.add("no-transition");
      }
      document.body.classList.add("enlarged");
    } else {
      document.body.classList.remove("sidebar-enable");
      document.body.classList.remove("no-transition");
      document.body.classList.remove("enlarged");
    }
  }, []);

  return (
    <>
      <div className="navbar-custom">
        <ul className="list-unstyled topnav-menu float-right mb-0">
          <li>
            <ProfileDropdown menuItems={ProfileMenus} username={userName} />
          </li>
        </ul>

        <div className="logo-box">
          <Link to="/" className="logo text-center">
            <span className="logo-lg">
              <img src={logo} alt="" height="24" />
              Burgerizzr
            </span>
            <span className="logo-sm">
              <img src={logo} alt="" height="24" />
            </span>
          </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button
              type="button"
              className="button-menu-mobile waves-effect waves-light"
              onClick={() => dispatch(changeStatusMenu())}
            >
              <i className="fe-menu"></i>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Topbar;

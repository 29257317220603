import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import { getFeedbackQuestions } from "../../store/actions/feedback.actions";
import classNames from "classnames";
import TableComponent from "../../components/tableComponent";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ModalQuestion from "../../components/modal/modalQuestion/modalQuestion";
import Pagination from "../../components/pagination/pagination";

const feedbackSurveys = ({ history }) => {
  const dispatch = useDispatch();
  const [feedbackType, setFeedbackType] = useState(undefined);

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  const [feedbackQuestion, setFeedbackQuestion] = useState(false);
  const addNewFeedbackQuestion = () => setFeedbackQuestion(!feedbackQuestion);

  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const feedbackQuestions = useSelector(
    state => state.feedback.feedbackQuestions || []
  );
  const countFeedbackQuestions = useSelector(
    state => state.feedback.countFeedbackQuestions
  );
  const isLoading = useSelector(
    state => state.feedback.isLoadingFeedbackQuestions
  );

  const tableHead = [
    {
      name: "Feedback Type",
      key: "feedbackTypeSurvey"
    },
    {
      name: "Active",
      key: "isActive"
    },
    {
      name: "Edit",
      key: "editWithRedirect",
      path: "feedbackQuestions",
      param: "id"
    },

    {
      name: "Customers Answers",
      key: "details",
      path: "feedbackSurveys",
      param: "id"
    }
  ];

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    feedbackType === "--"
      ? dispatch(
          getFeedbackQuestions({
            pagination: {
              page: currentPage || 1,
              limit: rowsPerPage || 10
            }
          })
        )
      : dispatch(
          getFeedbackQuestions({
            feedbackType,
            pagination: {
              page: currentPage || 1,
              limit: rowsPerPage || 10
            }
          })
        );
  }, [feedbackType, currentPage, rowsPerPage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Feedback Surveys</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box paddingNone">
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="text-md-right">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mb-2 mr-2 mt-2"
                          onClick={() => {
                            history.push("createSurvey");
                          }}
                        >
                          <i className="mdi mdi-plus mr-1">
                            <span style={{ color: "#fff" }}>New Survey</span>
                          </i>
                        </button>
                      </div>
                      <div className="col-12 text-sm-center form-inline">
                        <label htmlFor="example-select">
                          Feedback Type: &nbsp; &nbsp;{" "}
                        </label>
                        <div className="form-group mr-2">
                          <select
                            id="demo-foo-filter-status"
                            className="custom-select custom-select-sm"
                            onChange={e => {
                              setPageQuery(1);
                              setFeedbackType(e.target.value);
                            }}
                            value={feedbackType}
                          >
                            <option value={"--"}>All</option>
                            <option value={1}>General</option>
                            <option value={2}>Order</option>
                            <option value={3}>Specific</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="MultipleChoices selected">
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-lg"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <TableComponent
                          tableHead={tableHead}
                          tableBody={feedbackQuestions}
                        />
                        <Pagination
                          totalCount={countFeedbackQuestions}
                          rows={rowsPerPage}
                          currentPage={currentPage}
                          setCurrentPage={setPageQuery}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalQuestion modal={feedbackQuestion} toggle={addNewFeedbackQuestion} />
    </div>
  );
};

export default feedbackSurveys;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePrevious from "../../helpers/usePrevious";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";
import {
  getCategories,
  synchronizeMenu
} from "../../store/actions/categories.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import TableComponent from "../../components/tableComponent";
import ModalCategory from "../../components/modal/modalCategory/modalCategory";
import LoadingButton from "../../components/loadingButton/loadingButton";

const Categories = () => {
  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [pageQuery, setPageQuery] = useQueryParam("catPage", NumberParam);

  const [search, setSearch] = useState(searchQuery || "");
  const [searchInputValue, setSearchInputValue] = useState(searchQuery || "");
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });
  useEffect(() => {
    setSearch(searchQuery || "");
  });
  const categories = useSelector(state => state.categories.categories);
  const isLoadingCategories = useSelector(state => state.categories.isLoading);
  const isLoadingBtn = useSelector(
    state => state.categories.isSynchronizedMenu
  );
  const countCategory = useSelector(state => state.categories.countCategory);

  const [category, setCategory] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const tableHead = [
    { name: "Red Icon", field: "redIconPath", key: "imageIcon" },
    { name: "Grey Icon", field: "greyIconPath", key: "imageIcon" },

    {
      name: "Name English",
      key: "name",
      lang: "en"
    },
    {
      name: "Name Arabic",
      key: "name",
      lang: "ar"
    },
    { name: "Products", key: "products" },
    {
      name: "Created Date",
      field: "createdAt",
      key: "date"
    },
    { name: "Active", key: "selected" },
    {
      name: "Edit",
      key: "editWithCallback",
      onClick: body => {
        setCategory(body);
        toggle();
      }
    }
  ];

  const previousSearchValue = usePrevious(search);
  const previousCurrentPageValue = usePrevious(currentPage);
  const myRef = React.createRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      previousSearchValue !== undefined &&
      search !== undefined &&
      previousSearchValue !== search
    ) {
      if (previousCurrentPageValue !== 1) {
        return setPageQuery(1);
      }
    }

    dispatch(
      getCategories({
        pagination: {
          page: currentPage || 1,
          limit: rowsPerPage || 10
        },
        search
      })
    );
  }, [dispatch, currentPage, rowsPerPage, search]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content" ref={myRef}>
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Categories</h4>
              </div>
            </div>
            <div className="col-12">
              <div className="card-box d-flex">
                <h5 className="mr-3">
                  Do you want to synchronize Menu with Foodics ?
                </h5>
                <LoadingButton
                  isLoadingBtn={isLoadingBtn}
                  btnContent="Synchronize"
                  onClick={() => dispatch(synchronizeMenu())}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="form-inline">
                        <div className="form-group mb-2">
                          <label htmlFor="inputPassword2" className="sr-only">
                            Search
                          </label>
                          <DebounceInput
                            type="search"
                            minLength={1}
                            className="form-control"
                            debounceTimeout={800}
                            value={searchInputValue}
                            onChange={e => {
                              setSearchInputValue(e.target.value);
                              setSearchQuery(e.target.value);
                            }}
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoadingCategories ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border avatar-lg"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <TableComponent
                      tableHead={tableHead}
                      tableBody={categories}
                    />
                  )}
                  <Pagination
                    totalCount={countCategory}
                    rows={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setPageQuery}
                  />
                  {category && (
                    <ModalCategory
                      category={category}
                      modal={modal}
                      onClose={() => {
                        toggle();
                        setCategory(undefined);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Categories;

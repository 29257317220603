import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { getCustomers } from "../../store/actions/customers.actions";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import TableComponent from "../../components/tableComponent";

const Customers = () => {
  const { id } = useParams();
  const customers = useSelector((state) => state.customers.customers) || [];
  const [customer, setCustomer] = useState({});
  const dispatch = useDispatch();

  const [open, setOpen] = useState(
    useSelector((state) => state.authorization.menuStatus)
  );
  const isLoadingCustomers = useSelector(
    (state) => state.customers.isLoadingCustomer
  );
  useEffect(() => {
    dispatch(
      getCustomers({
        pagination: { page: 1, limit: 1000 },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    const index = customers.findIndex((i) => i.id === id);
    if (index > -1) setCustomer(customers[index]);
  }, [customers]);

  const tableHead = [
    {
      name: "Address Name",
      key: "addressName",
    },
    {
      name: "Address Hid",
      key: "id",
    },
    {
      name: "Delivery zone Hid",
      key: "customerHid",
    },
    {
      name: "Main",
      key: "isMain",
    },
    {
      name: "Building Number",
      key: "buildingNumber",
    },
    {
      name: "Coordinates",
      key: "coordinates",
    },
  ];

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Customer Details</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">First name:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.firstName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Last name:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.lastName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Phone:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.phone}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Email:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.email}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Created at:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={moment
                              .utc(customer.createdAt)
                              .local()
                              .format("dddd, MMMM D, YYYY")}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Birthdate:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={moment
                              .utc(customer.birthdate)
                              .local()
                              .format("dddd, MMMM D, YYYY")}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Blacklist:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={!!customer.blacklist}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Gender:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.gender}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Foodics Id:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.foodicsId}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Spent points:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.spentPoints}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group w-100">
                          <label className="control-label">Orders:</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={customer.orders}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {isLoadingCustomers ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border avatar-lg"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <TableComponent
                        tableHead={tableHead}
                        tableBody={customer.address}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;

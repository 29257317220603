export const creditFormInputs = [
  {
    id: "1",
    key: "type",
    value: "",
    field_type: "dropdown"
  },
  {
    id: "2",
    key: "amount",
    value: "",
    field_type: "input"
  },
  {
    id: "3",
    key: "expiration_date",
    value: "",
    field_type: "datePicker"
  },
  {
    id: "4",
    key: "reason",
    value: "",
    field_type: "textarea"
  }
];

export const creditType = [
  {
    id: 1,
    value: "Online Top Up (By Admin)"
  },

  { id: 2, value: "Order Purchase (Detection)" },

  { id: 3, value: "Refund" },

  { id: 4, value: "CashBack" },

  { id: 5, value: "Compensations" },

  { id: 6, value: "Expiration Detection (Detection)" }
];

export const dateString = "dd-MM-yy";

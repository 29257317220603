import { foodTruckActionsTypes } from "../actions/foodTruck.actions";

const initialState = {
  menuData: [],
  bookedData: [],
  isLoadingMenu: false,
  isLoadingMenuBtn: false,
  countBookedData: 0,
  isLoadingBooked: false
};

export default function foodTruck(state = initialState, action) {
  switch (action.type) {
    case foodTruckActionsTypes.GET_FOODTRUCK_MENU: {
      return {
        ...state,
        isLoadingMenu: true
      };
    }

    case foodTruckActionsTypes.GET_FOODTRUCK_MENU_SUCCESS: {
      return {
        ...state,
        menuData: action.payload,
        isLoadingMenu: false
      };
    }

    case foodTruckActionsTypes.GET_FOODTRUCK_MENU_FAILURE: {
      return {
        ...state,
        isLoadingMenu: false
      };
    }

    case foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU: {
      return {
        ...state,
        isLoadingMenuBtn: true
      };
    }

    case foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU_SUCCESS: {
      return {
        ...state,
        isLoadingMenuBtn: false
      };
    }

    case foodTruckActionsTypes.UPDATE_FOODTRUCK_MENU_FAILURE: {
      return {
        ...state,
        isLoadingMenuBtn: false
      };
    }

    case foodTruckActionsTypes.GET_FOODTRUCK_BOOKED: {
      return {
        ...state,
        isLoadingBooked: true
      };
    }
    case foodTruckActionsTypes.GET_FOODTRUCK_BOOKED_SUCCESS: {
      return {
        ...state,
        bookedData: action.payload.bookedTrucks,
        countBookedData: action.payload.meta.totalCount,
        isLoadingBooked: false
      };
    }
    case foodTruckActionsTypes.GET_FOODTRUCK_BOOKED_FAILURE: {
      return {
        ...state,
        isLoadingBooked: false
      };
    }

    default:
      return state;
  }
}

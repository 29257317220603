import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { createBrowserHistory } from "history";
import { Redirect } from "react-router";
import Reports from "./pages/reports";
import Login from "./pages/login";
import Logout from "./pages/logout";
import NotFound from "./pages/notFound";
import ForbiddenPage from "./pages/forbiddenPage";
import ProtectedRoute from "./components/protectedRoute/protectedRoute";
import Routes from "./components/protectedRoute/config";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import "./App.scss";
import "./assets/scss/DefaultTheme.scss";

function App() {
  const customHistory = createBrowserHistory();

  return (
    <BrowserRouter>
      <ScrollToTop />
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch history={customHistory}>
          <Route
            exact
            path="/"
            component={() => {
              // return <Redirect to="/reports" />;
              return <Redirect to="/branches" />;
            }}
          />

          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/reports" component={Reports} />
          {Routes.map(route => (
            <ProtectedRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              fieldName={route.fieldName}
              component={route.component()}
            />
          ))}
          <Route path="/403" component={ForbiddenPage} />
          <Route path="*" component={NotFound} />
          {!localStorage.getItem("token") && <Redirect to="/login" />}
        </Switch>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;

export function displayPaymentMethod(payments) {
  const { paidOnline, paidWithPoints, paidWithWallet } = payments;

  let paymentMethods = [];

  if (paidOnline > 0) paymentMethods.push("Online");
  if (paidWithPoints > 0) paymentMethods.push("Points");
  if (paidWithWallet > 0) paymentMethods.push("Wallet");
  if (paymentMethods.length === 0) paymentMethods.push("Cash");

  return paymentMethods.join(", ");
}

export const paymentType = id => {
  switch (id) {
    case "_671g2647":
      return "Card";
    case "_74gag247":
      return "Apple Pay";
    case "9ae2bf9c-1cee-460c-a983-30a03d147949":
      return "Wallet";
    case "_741968g7": // this payment method was missing
      return "Points";
    default:
      return "UNKNOWN";
  }
};

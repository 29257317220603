import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

export default function ModalEditQuestion({ modal, toggle, feedbackQuestion }) {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Survey</ModalHeader>
      <form className="modal-body p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="questionTitle" className="control-label">
                Questions
              </label>

              {feedbackQuestion.choices &&
                feedbackQuestion.choices.map((choice, index) => (
                  <div>
                    <label htmlFor="choice" className="control-label mt-2">
                      Choice#{index + 1}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="choice"
                      name="choice"
                      placeholder="Choice"
                      value={choice}
                      readOnly
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

import { call, put, takeLatest } from "redux-saga/effects";
import { upsellApi } from "../../services/upsell.service";
import { upsellActionsTypes } from "../actions/upsell.actions";

function* getFixedUpsell() {
  try {
    const result = yield call(upsellApi.getFixedUpsell);

    yield put({
      type: upsellActionsTypes.GET_FIXED_UPSELL_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: upsellActionsTypes.GET_FIXED_UPSELL_FAILURE
    });
  }
}

function* setFixedUpsell(action) {
  try {
    const result = yield call(upsellApi.setFixedUpsell, action.payload);

    yield put({
      type: upsellActionsTypes.SET_FIXED_UPSELL_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: upsellActionsTypes.SET_FIXED_UPSELL_FAILURE,
      payload: error.data.message
    });
  }
}

function* deleteFixedUpsell(action) {
  try {
    const result = yield call(upsellApi.deleteFixedUpsell, action.payload);

    yield put({
      type: upsellActionsTypes.DELETE_FIXED_UPSELL_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: upsellActionsTypes.DELETE_FIXED_UPSELL_FAILURE
    });
  }
}

function* getRelatedUpsell() {
  try {
    const result = yield call(upsellApi.getRelatedUpsell);

    yield put({
      type: upsellActionsTypes.GET_RELATED_UPSELL_SUCCESS,
      payload: result.data.data
    });
  } catch (error) {
    yield put({
      type: upsellActionsTypes.GET_RELATED_UPSELL_FAILURE
    });
  }
}

function* setRelatedUpsell(action) {
  try {
    const result = yield call(upsellApi.setRelatedUpsell, action.payload);

    yield put({
      type: upsellActionsTypes.SET_RELATED_UPSELL_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: upsellActionsTypes.SET_RELATED_UPSELL_FAILURE,
      payload: error.data.message || error.data.error
    });
  }
}

function* deleteRelatedUpsell(action) {
  try {
    const result = yield call(upsellApi.deleteRelatedUpsell, action.payload);

    yield put({
      type: upsellActionsTypes.DELETE_RELATED_UPSELL_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    yield put({
      type: upsellActionsTypes.DELETE_RELATED_UPSELL_FAILURE
    });
  }
}

function* upsellSaga() {
  yield takeLatest([upsellActionsTypes.GET_FIXED_UPSELL], getFixedUpsell);
  yield takeLatest([upsellActionsTypes.SET_FIXED_UPSELL], setFixedUpsell);
  yield takeLatest([upsellActionsTypes.DELETE_FIXED_UPSELL], deleteFixedUpsell);
  yield takeLatest([upsellActionsTypes.GET_RELATED_UPSELL], getRelatedUpsell);
  yield takeLatest([upsellActionsTypes.SET_RELATED_UPSELL], setRelatedUpsell);
  yield takeLatest(
    [upsellActionsTypes.DELETE_RELATED_UPSELL],
    deleteRelatedUpsell
  );
}

export default upsellSaga;

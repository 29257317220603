export const branchesActionsTypes = {
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_FAILURE",

  GET_COUNTRIES_LIST: "GET_COUNTRIES_LIST",
  GET_COUNTRIES_LIST_SUCCESS: "GET_COUNTRIES_LIST_SUCCESS",
  GET_COUNTRIES_LIST_FAILURE: "GET_COUNTRIES_LIST_FAILURE",

  SET_AVAILABLE_FOR_DELIVERY: "SET_AVAILABLE_FOR_DELIVERY",
  SET_AVAILABLE_FOR_DELIVERY_SUCCESS: "SET_AVAILABLE_FOR_DELIVERY_SUCCESS",
  SET_AVAILABLE_FOR_DELIVERY_SUCCESS_FAILURE:
    "SET_AVAILABLE_FOR_DELIVERY_SUCCESS_FAILURE",

  GET_BRANCHES: "GET_BRANCHES",
  GET_BRANCHES_SUCCESS: "GET_BRANCHES_SUCCESS",
  GET_BRANCHES_FAILURE: "GET_BRANCHES_FAILURE",

  UPDATE_BRANCH: "UPDATE_BRANCH",
  UPDATE_BRANCH_SUCCESS: "UPDATE_BRANCH_SUCCESS",
  UPDATE_BRANCH_FAILURE: "UPDATE_BRANCH_FAILURE",

  GET_CITIES: "GET_CITIES",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_FAILURE: "GET_CITIES_FAILURE",

  SYNCHRONIZE_BRANCHES: "SYNCHRONIZE_BRANCHES",
  SYNCHRONIZE_BRANCHES_SUCCESS: "SYNCHRONIZE_BRANCHES_SUCCESS",
  SYNCHRONIZE_BRANCHES_FAILURE: "SYNCHRONIZE_BRANCHES_FAILURE",

  BULK_BRANCHES_UPDATE: "BULK_BRANCHES_UPDATE",
  BULK_BRANCHES_UPDATE_SUCCESS: "BULK_BRANCHES_UPDATE_SUCCESS",
  BULK_BRANCHES_UPDATE_FAILURE: "BULK_BRANCHES_UPDATE_FAILURE"
};

export const getCountriesList = data => {
  return {
    type: branchesActionsTypes.GET_COUNTRIES_LIST,
    payload: data
  };
};

export const getCountries = () => {
  return {
    type: branchesActionsTypes.GET_COUNTRIES
  };
};

export const setAvailableForDelivery = data => {
  return {
    type: branchesActionsTypes.SET_AVAILABLE_FOR_DELIVERY,
    payload: data
  };
};

export const getBranches = data => {
  return {
    type: branchesActionsTypes.GET_BRANCHES,
    payload: data
  };
};

export const selectBranch = data => {
  return {
    type: branchesActionsTypes.SELECT_BRANCH,
    payload: data
  };
};

export const updateBranch = data => {
  return {
    type: branchesActionsTypes.UPDATE_BRANCH,
    payload: data
  };
};

export const getCities = data => {
  return {
    type: branchesActionsTypes.GET_CITIES,
    payload: data
  };
};

export const synchronizeBranches = () => {
  return {
    type: branchesActionsTypes.SYNCHRONIZE_BRANCHES
  };
};

export const bulkBranchesUpdate = data => {
  return {
    type: branchesActionsTypes.BULK_BRANCHES_UPDATE,
    payload: data
  };
};

// import { API_URL } from "../constants";
import { API_URL } from "../constants";
import axiosInstance from "./interceptor";
const extensions = {
  pdf: "pdf",
  excel: "xls",
  word: "doc"
};

const API_URL_TEMP = "https://2a8c-182-70-121-2.ngrok-free.app";

const downloadFile = (fileData, params) => {
  const url = window.URL.createObjectURL(new Blob([fileData.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `file.${extensions[params.fileType]}`);
  document.body.appendChild(link);
  link.click();
};

export const transactionsApi = {
  getTransactions() {
    return axiosInstance.post(`${API_URL}/wallet/transaction-list-dashboard`);
  },

  getTransactionsID(params) {
    console.log("Param:", params);
    return axiosInstance.post(
      `${API_URL}/wallet/transaction-list-dashboard`,
      params
    );
  },

  getTransactionsExport(params) {
    if (params.customer_id) {
      return axiosInstance
        .post(`${API_URL}/wallet/export-transaction`, {
          customer_id: params.customer_id
        })
        .then(response => {
          downloadFile(response, params);
        });
    } else {
      return axiosInstance
        .post(`${API_URL}/wallet/export-transaction`)
        .then(response => {
          downloadFile(response, params);
        });
    }
  }
};

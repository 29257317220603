import React, { useState, useEffect } from "react";

import ModalBranch from "../modal/modalBranch/modalBranch";
import WorkingHours from "../workingHours/workingHours";
import Location from "../location/location";
import "./branch.scss";

const Branch = ({ allBranches, branch, city, user }) => {
  const location = {
    lat: branch.latitude,
    lng: branch.longitude
  };
  const [modal, setModal] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const toggle = () => setModal(!modal);
  const [workingHours, setWorkingHours] = useState(branch.workingHours);

  useEffect(() => {
    const item = allBranches.find(i => i.id === branch.id);
    if (item && item.deliveryZones) {
      setCoordinates(item.deliveryZones.coordinates);
    }
  }, [branch, allBranches]);

  const { role, privileges } = user;
  return (
    <div className="col-xl-6">
      <div className="card-box project-box">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <h4 className="mt-0 branchName">{branch.name.en}</h4>
          {(role === "admin" ||
            (role === "supervisor" &&
              privileges &&
              privileges.branchesEdit)) && (
            <i
              style={{
                cursor: "pointer"
              }}
              className="font-18 cursor text-muted  mdi mdi-pencil"
              onClick={toggle}
            ></i>
          )}
        </div>
        <h4 className="mt-0 branchName">{branch.name.ar}</h4>
        <p className="text-muted text-uppercase mb-1">
          <small>{city.name.en}</small>
        </p>
        {branch.selected ? (
          <div className="badge bg-soft-success text-success mb-1 pt-1 pb-1">
            Active
          </div>
        ) : (
          <div className="badge bg-soft-danger text-danger mb-1 pt-1 pb-1">
            Closed
          </div>
        )}
        <div className="deliveryPhone">
          <i className="mdi mdi-phone-classic"></i>
          <p className="text-muted  mb-0  sp-line-2">{branch.phone}</p>
        </div>
        <div className="deliveryTime">
          <p className="font-weight-bold mb-0">Pickup Promising Time</p>
          <p className="font-weight-bold mb-0">
            {branch.pickupPromisingTime} min
          </p>
        </div>
        <div className="deliveryTime">
          <p className="font-weight-bold mb-0">Delivery Promising Time</p>
          <p className="font-weight-bold mb-0">
            {branch.deliveryPromisingTime} min
          </p>
        </div>
        <div className="workingHours">
          <WorkingHours
            workHours={data => setWorkingHours(data)}
            data={workingHours}
            disable={true}
          />
        </div>
        {/* <div className="map">
          <Location location={location} coordinates={coordinates} />
        </div> */}
        {branch && (
          <ModalBranch
            allBranches={allBranches}
            branch={branch}
            modal={modal}
            toggle={toggle}
          />
        )}
      </div>
    </div>
  );
};

export default Branch;

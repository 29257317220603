import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import DoubleDropify from "../../doubleDropify/doubleDropify";
import { updateReward } from "../../../store/actions/points.actions";
import { getCategories } from "../../../store/actions/categories.actions";
import { getProducts } from "../../../store/actions/products.actions";

export default function ModalEditAward({ modal, toggle, award }) {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.categories);
  const products = useSelector(state => state.products.products);

  const formik = useFormik({
    initialValues: award,
    enableReinitialize: true,
    validationSchema: Yup.object({
      productId: Yup.string().required("Required"),
      numberOfPoints: Yup.number().required("Required"),
      awardName: Yup.string().required("Required"),
      imagePath: Yup.mixed().required("Required")
    }),
    onSubmit: values => {
      const obj = {
        numberOfPoints: values.numberOfPoints,
        awardName: values.awardName,
        awardNameAr: values.awardNameAr,
        productId: values.productId
      };
      if (award.imagePath !== values.imagePath) {
        obj.image = values.imagePath;
        const fr = new FileReader();
        fr.onload = () => {
          obj.imagePath = fr.result;
        };
        fr.readAsDataURL(values.imagePath);
      }
      dispatch(updateReward(values.id, obj));
      toggle();
    }
  });

  useEffect(() => {
    dispatch(
      getCategories({
        pagination: { limit: "", page: "" }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProducts({
        pagination: {
          page: "",
          limit: ""
        },
        filteredCategory: formik.values.category
      })
    );
  }, [dispatch, formik.values.category]);
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
        formik.resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          formik.resetForm();
        }}
      >
        Edit Award
      </ModalHeader>
      <form className="modal-body p-4" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="category" className="control-label">
                Category
              </label>
              <select
                className="form-control"
                id="category"
                name="category"
                onChange={formik.handleChange}
                defaultValue={award.product && award.product.categoryId}
                value={formik.values.category}
              >
                <option value="">All</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name.en}
                  </option>
                ))}
              </select>
              {formik.errors.category && formik.touched.category ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{formik.errors.category}</li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="product" className="control-label">
                Select product
              </label>
              <select
                className="form-control"
                id="productId"
                name="productId"
                onChange={formik.handleChange}
                value={formik.values.productId}
              >
                <option value="">Select Product</option>
                {products.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.name.en}
                  </option>
                ))}
              </select>
              {formik.errors.productId && formik.touched.productId ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.productId}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="numberOfPoints" className="control-label">
                Number of Points
              </label>
              <input
                type="number"
                className="form-control"
                id="numberOfPoints"
                placeholder="Number of Points"
                value={formik.values.numberOfPoints || ""}
                onClick={() => {
                  formik.setFieldTouched("numberOfPoints", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.numberOfPoints && formik.touched.numberOfPoints ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.numberOfPoints}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="awardName" className="control-label">
                Award Name
              </label>
              <input
                type="text"
                className="form-control"
                id="awardName"
                placeholder="Award Name"
                value={formik.values.awardName || ""}
                onClick={() => {
                  formik.setFieldTouched("awardName", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.awardName && formik.touched.awardName ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.awardName}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="awardNameAr" className="control-label">
                Name|Ar
              </label>
              <input
                type="text"
                className="form-control"
                id="awardNameAr"
                placeholder="Name|Ar"
                value={formik.values.awardNameAr || ""}
                onClick={() => {
                  formik.setFieldTouched("awardNameAr", true);
                }}
                onChange={formik.handleChange}
              />
              {formik.errors.awardNameAr && formik.touched.awardNameAr ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">
                    {formik.errors.awardNameAr}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="field-3" className="control-label">
                Award Image
              </label>
              <DoubleDropify height="25vh" formik={formik} type="imagePath" />
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              formik.resetForm();
              toggle();
            }}
          >
            Close
          </Button>{" "}
          <Button type="submit" color="success">
            Save Changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

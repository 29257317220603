import { productsActionsTypes } from "../actions/products.actions";

const initialState = {
  countProduct: 0,
  countModifiers: 0,
  products: [],
  isLoadingProduct: false,
  isLoadingProducts: false,
  isLoadingModifiers: false,
  product: {},
  modifiers: [],
  prevModifiers: [],
  productDetails: {},
  formData: {},
  oldRelatedProductId: null,
  pagination: {},
  isLoadingProductBtn: false
};

export default function products(state = initialState, action) {
  switch (action.type) {
    case productsActionsTypes.GET_PRODUCTS: {
      return {
        ...state,
        isLoadingProducts: true
      };
    }

    case productsActionsTypes.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        countProduct: action.payload.meta.totalCount,
        products: action.payload.data,
        isLoadingProducts: false
      };
    }

    case productsActionsTypes.GET_PRODUCTS_FAILURE: {
      return {
        ...state,
        isLoadingProducts: false
      };
    }

    case productsActionsTypes.GET_PRODUCT: {
      return {
        ...state,
        isLoadingProduct: true
      };
    }

    case productsActionsTypes.GET_PRODUCT_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        history: action.payload.history,
        isLoadingProduct: false
      };
    }

    case productsActionsTypes.GET_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoadingProduct: false
      };
    }

    case productsActionsTypes.GET_MODIFIERS: {
      return {
        ...state,
        isLoadingModifiers: true
      };
    }

    case productsActionsTypes.GET_MODIFIERS_SUCCESS: {
      return {
        ...state,
        modifiers: action.payload.modifiers,
        isLoadingModifiers: false,
        countModifiers: action.payload.meta.totalCount
      };
    }

    case productsActionsTypes.GET_MODIFIERS_FAILURE: {
      return {
        ...state,
        isLoadingModifiers: false
      };
    }

    case productsActionsTypes.SET_DOUBLE_MODIFIERS: {
      const modifiers = state.modifiers.map(item =>
        item.id === action.payload.modifierId
          ? { ...item, isDouble: !item.isDouble }
          : item
      );
      return {
        ...state,
        prevModifiers: state.modifiers,
        modifiers,
        modifiersForSetDouble: action.payload
      };
    }

    case productsActionsTypes.SET_DOUBLE_MODIFIERS_SUCCESS: {
      return {
        ...state,
        prevModifiers: []
      };
    }

    case productsActionsTypes.SET_DOUBLE_MODIFIERS_FAILURE: {
      return {
        ...state,
        modifiers: state.prevModifiers,
        prevModifiers: []
      };
    }

    case productsActionsTypes.ADD_PRODUCT: {
      return {
        ...state,
        productId: action.payload.productId,
        relatedProductId: action.payload.relatedProductId,
        oldRelatedProductId: action.payload.oldRelatedProductId
      };
    }

    case productsActionsTypes.ADD_PRODUCT_SUCCESS: {
      let { categories, ...rest } = state;
      categories = categories.map(category => {
        category.products = category.products.map(product => {
          if (action.payload.id === product.id) {
            product = action.payload;
          }
          if (action.payload.relatedProductId === product.id) {
            product.relatedProductId = product.id;
            product.relatedProduct = action.payload;
          }
          if (
            state.oldRelatedProductId &&
            state.oldRelatedProductId === product.id
          ) {
            product.relatedProductId = null;
            product.relatedProduct = null;
          }
          return product;
        });
        return category;
      });
      return {
        ...rest,
        categories,
        oldRelatedProductId: null
      };
    }

    case productsActionsTypes.UPDATE_PRODUCT_IMAGE: {
      return {
        ...state,
        isLoadingProductImage: true
      };
    }

    case productsActionsTypes.UPDATE_PRODUCT_IMAGE_SUCCESS: {
      return {
        ...state,
        products: state.products.map(product => {
          if (product.id === action.payload.id) {
            product.imagePath = action.payload.imagePath;
          }
          return product;
        }),
        isLoadingProductImage: false
      };
    }

    case productsActionsTypes.UPDATE_PRODUCT: {
      return {
        ...state,
        isLoadingProductBtn: true
      };
    }

    case productsActionsTypes.UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        isLoadingProductBtn: false,
        products: state.products.map(product => {
          if (product.id === action.payload.id) {
            product.name = action.payload.name;
            product.selected = action.payload.selected;
          }
          return product;
        })
      };
    }

    case productsActionsTypes.UPDATE_PRODUCTS_ORDER:
      return { ...state, isLoadingProducts: true };

    case productsActionsTypes.UPDATE_PRODUCTS_ORDER_SUCCESS:
      return { ...state, isLoadingProducts: false };

    case productsActionsTypes.UPDATE_PRODUCTS_ORDER_FAILURE:
      return { ...state, isLoadingProducts: false };

    default:
      return state;
  }
}

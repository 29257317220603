export const feedbackActionsTypes = {
  GET_CUSTOMER_FEEDBACK: "GET_CUSTOMER_FEEDBACK",
  GET_CUSTOMER_FEEDBACK_SUCCESS: "GET_CUSTOMER_FEEDBACK_SUCCESS",
  GET_CUSTOMER_FEEDBACK_FAILURE: "GET_CUSTOMER_FEEDBACK_FAILURE",

  GET_FEEDBACK_QUESTIONS: "GET_FEEDBACK_QUESTIONS",
  GET_FEEDBACK_QUESTIONS_SUCCESS: "GET_FEEDBACK_QUESTIONS_SUCCESS",
  GET_FEEDBACK_QUESTIONS_FAILURE: "GET_FEEDBACK_QUESTIONS_FAILURE",

  SET_FEEDBACK_QUESTIONS: "SET_FEEDBACK_QUESTIONS",
  SET_FEEDBACK_QUESTIONS_SUCCESS: "SET_FEEDBACK_QUESTIONS_SUCCESS",
  SET_FEEDBACK_QUESTIONS_FAILURE: "SET_FEEDBACK_QUESTIONS_FAILURE",

  UPDATE_FEEDBACK_QUESTIONS: "UPDATE_FEEDBACK_QUESTIONS",
  UPDATE_FEEDBACK_QUESTIONS_SUCCESS: "UPDATE_FEEDBACK_QUESTIONS_SUCCESS",
  UPDATE_FEEDBACK_QUESTIONS_FAILURE: "UPDATE_FEEDBACK_QUESTIONS_FAILURE",

  DELETE_FEEDBACK_QUESTIONS: "DELETE_FEEDBACK_QUESTIONS",
  DELETE_FEEDBACK_QUESTIONS_SUCCESS: "DELETE_FEEDBACK_QUESTIONS_SUCCESS",
  DELETE_FEEDBACK_QUESTIONS_FAILURE: "DELETE_FEEDBACK_QUESTIONS_FAILURE",

  GET_REPORTS: "GET_REPORTS",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILURE: "GET_REPORTS_FAILURE"
};

export const getCustomerFeedback = data => {
  return {
    type: feedbackActionsTypes.GET_CUSTOMER_FEEDBACK,
    payload: data
  };
};

export const getFeedbackQuestions = data => {
  return {
    type: feedbackActionsTypes.GET_FEEDBACK_QUESTIONS,
    payload: data
  };
};

export const setFeedbackQuestions = data => {
  return {
    type: feedbackActionsTypes.SET_FEEDBACK_QUESTIONS,
    payload: data
  };
};

export const updateFeedbackQuestions = data => {
  return {
    type: feedbackActionsTypes.UPDATE_FEEDBACK_QUESTIONS,
    payload: data
  };
};

export const deleteFeedbackQuestion = data => {
  return {
    type: feedbackActionsTypes.DELETE_FEEDBACK_QUESTIONS,
    payload: data
  };
};

export const getReports = data => {
  return {
    type: feedbackActionsTypes.GET_REPORTS,
    payload: data
  };
};

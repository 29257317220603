import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, NumberParam } from "use-query-params";
import { getDiscounts } from "../../store/actions/discounts.actions";
import classNames from "classnames";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import Pagination from "../../components/pagination/pagination";
import TableComponent from "../../components/tableComponent";

const Customers = () => {
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );

  const tableHead = [
    {
      name: "Name | En",
      key: "nameEn"
    },
    {
      name: "Name | Ar",
      key: "nameAr"
    },
    {
      name: "Code | Ar",
      key: "codeAr"
    },
    {
      name: "Code | En",
      key: "codeEn"
    },
    { name: "Value", key: "amount" },
    { name: "Valid From", field: "validFrom", key: "date" },
    { name: "Valid To", field: "validTo", key: "date" },
    {
      name: "Discount Type",
      key: "isPercentage"
    },
    { name: "Active", key: "isActive" },
    {
      name: "Edit",
      key: "editWithRedirect",
      path: "discounts",
      param: "id"
    }
  ];

  const [pageQuery, setPageQuery] = useQueryParam("page", NumberParam);
  const [currentPage, setCurrentPage] = useState(pageQuery || 1);
  const [rowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const discounts = useSelector(state => state.discounts.discounts);
  const countDiscounts = useSelector(state => state.discounts.discountCount);
  const isLoading = useSelector(state => state.discounts.isLoadingDiscounts);

  useEffect(() => {
    setCurrentPage(pageQuery || 1);
  });

  useEffect(() => {
    dispatch(
      getDiscounts({
        pagination: { page: currentPage || 1, limit: rowsPerPage || 10 }
      })
    );
  }, [dispatch, currentPage, rowsPerPage]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Discounts</h4>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border avatar-lg" role="status"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2"></div>

                      <TableComponent
                        tableHead={tableHead}
                        tableBody={discounts}
                      />

                      <Pagination
                        totalCount={countDiscounts}
                        rows={rowsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setPageQuery}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;

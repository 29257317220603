import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import BranchTable from "../../components/branchTable";
// import { getCategories } from "../../store/actions/categories.actions";
import {
  updateProduct,
  addProduct,
  getProduct,
  getProducts,
  updateProductImage,
  toggleSelections
} from "../../store/actions/products.actions";
import { Link } from "react-router-dom";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/sideBar/sideBar";
import ToggleButton from "../../components/toggleButton/toggleButton";
import ModifiersForProducts from "../../components/modifiersForProduct/modifiersForProduct";
import Dropify from "../../components/dropify/dropify";
import LoadingButton from "../../components/loadingButton/loadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

const ProductDetails = ({ match }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(
    useSelector(state => state.authorization.menuStatus)
  );
  const [openDropDown, setOpenDropDown] = useState(false);
  const { product, products } = useSelector(state => state.products);
  // const categories = useSelector(state => state.categories.categories);

  const isLoadingBtn = useSelector(state => state.products.isLoadingProductBtn);
  const [modal, setModal] = useState(true);

  const isLoadingProduct = useSelector(
    state => state.products.isLoadingProduct
  );

  const handleToggleActive = () => {
    form.values.selected
      ? form.setFieldValue("selected", false)
      : form.setFieldValue("selected", true);
  };

  const form = useFormik({
    initialValues: {
      productNameEn: "",
      productNameAr: "",
      productLabelEn: "",
      productLabelAr: "",
      assignedProductLabelEn: "",
      assignedProductLabelAr: "",
      selectedFile: "",
      assignedProduct: "",
      selected: false,
      selectedBranches: new Set()
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      productNameEn: Yup.string().required("Required"),
      productNameAr: Yup.string().required("Required"),
      selectedFile: Yup.mixed().required("Required")
    }),
    onSubmit: values => {
      setModal(false);
      dispatch(
        updateProduct({
          name: { en: values.productNameEn, ar: values.productNameAr },
          startTime: product.startTime,
          endTime: product.endTime,
          id: product.id,
          branches: [...values.selectedBranches.keys()].filter(i => !!i),
          labelEn: values.productLabelEn,
          labelAr: values.productLabelAr
        })
      );
      dispatch(
        toggleSelections({
          id: match.params.id,
          selected: values.selected
        })
      );
    }
  });

  const breakRelation = () => {
    setOpenDropDown(!openDropDown);
    form.setFieldValue("assignedProduct", "");
    dispatch(
      addProduct({
        productId: product.id,
        relatedProductId: "",
        productLabelEn: form.values.productLabelEn,
        productLabelAr: form.values.productLabelAr,
        assignedToLabelEn: form.values.assignedProductLabelEn || "",
        assignedToLabelAr: form.values.assignedProductLabelAr || ""
      })
    );
  };

  useEffect(() => {
    dispatch(getProduct(match.params.id));
    // dispatch(getCategories({ pagination: { limit: "", page: "" } }));
    if (!products.length) {
      dispatch(
        getProducts({
          pagination: { limit: 100, page: 1 }
        })
      );
    }
  }, [match.params.id]);

  useEffect(() => {
    if (product.name) {
      form.setFieldValue("productNameEn", product.name.en);
      form.setFieldValue("productNameAr", product.name.ar);
      form.setFieldValue("productLabelEn", product.labelEn || "");
      form.setFieldValue("productLabelAr", product.labelAr || "");
      form.setFieldValue("selected", product.selected);
      form.setFieldValue("selectedFile", product.imagePath);
    }
  }, [product]);

  useEffect(() => {
    if (product.relatedProduct) {
      form.setFieldValue("assignedProduct", product.relatedProduct.name.en);
      form.setFieldValue(
        "assignedProductLabelEn",
        product.relatedProduct.labelEn
      );
      form.setFieldValue(
        "assignedProductLabelAr",
        product.relatedProduct.labelAr
      );
    }
  }, [product.relatedProduct]);

  useEffect(() => {
    !isLoadingBtn &&
      toast.success("Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
  }, [isLoadingBtn]);

  useEffect(() => {
    if (product.notAvailableBranches) {
      const notAvailable = new Set(product.notAvailableBranches.map(i => i.id));
      form.setFieldValue("notAvailableBranches", notAvailable);
    }
  }, [product]);

  return (
    <div id="wrapper">
      <Topbar open={open} setOpen={setOpen} />
      <Sidebar isCondensed={open} />
      <div className={classNames("content-page", open ? "mL" : "ml-0")}>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title">Product Details</h4>
                </div>
              </div>
            </div>

            {isLoadingProduct ? (
              <div id="wrapper">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border avatar-lg" role="status"></div>
                </div>
              </div>
            ) : (
              <>
                <form onSubmit={form.handleSubmit} className="productDetails">
                  <div className="row" style={{ marginBottom: "25px" }}>
                    <div className="col-lg-6 h-50vh">
                      <div className="card-box h-100 ">
                        <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                          Product Image
                        </h5>
                        <Dropify
                          product={product}
                          height="36.8vh"
                          action={updateProductImage}
                          modal={modal}
                          formik={form}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 h-50vh">
                      <div className="card-box h-100">
                        <h5 className="text-uppercase mt-0 mb-1 bg-light p-2">
                          Product Information
                        </h5>

                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-1">
                            Product Name | En
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="product-meta-1"
                            placeholder="Product Name | En"
                            onClick={() => {
                              form.setFieldTouched("productNameEn", true);
                            }}
                            onChange={e =>
                              form.setFieldValue(
                                "productNameEn",
                                e.target.value
                              )
                            }
                            value={form.values.productNameEn || ""}
                            required
                          />
                          {form.errors.productNameEn &&
                          form.touched.productNameEn ? (
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">
                                {form.errors.productNameEn}
                              </li>
                            </ul>
                          ) : null}
                        </div>

                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-2">
                            Product Name | Ar
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="product-meta-2"
                            placeholder="Product Name | Ar"
                            onClick={() => {
                              form.setFieldTouched("productNameAr", true);
                            }}
                            onChange={e =>
                              form.setFieldValue(
                                "productNameAr",
                                e.target.value
                              )
                            }
                            value={form.values.productNameAr || ""}
                          />
                          {form.errors.productNameAr &&
                          form.touched.productNameAr ? (
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">
                                {form.errors.productNameAr}
                              </li>
                            </ul>
                          ) : null}
                        </div>

                        <div className="form-group mb-1">
                          <label htmlFor="product-meta-3">
                            Product Description | En
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="product-meta-3"
                            placeholder="Product Description | En"
                            disabled
                            value={
                              (product.description && product.description.en) ||
                              ""
                            }
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label htmlFor="product-meta-4">
                            Product Description | Ar
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="product-meta-4"
                            placeholder="Product Description | Ar"
                            disabled
                            value={
                              (product.description && product.description.ar) ||
                              ""
                            }
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label htmlFor="product-meta-4">Product Hid</label>
                          <input
                            type="text"
                            className="form-control"
                            id="product-meta-4"
                            placeholder="Product Description | Ar"
                            disabled
                            value={
                              (product.foodicsId && product.foodicsId) || ""
                            }
                          />
                        </div>

                        <div className="form-group mb-1">
                          <div className="toggleButton">
                            <div>Show in Mobile App</div>
                            <div onClick={handleToggleActive}>
                              <ToggleButton
                                id="selected"
                                name="selected"
                                value={form.values.selected}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box">
                        <h5 className="text-uppercase mt-0 mb-1 bg-light p-2">
                          Product Sizes
                        </h5>
                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                          {product.sizes &&
                            product.sizes.map(
                              (size, index) =>
                                (index === 0 || index === 1) && (
                                  <div key={size.id} className="w-50">
                                    <div className="form-group mb-1 pr-3 w-100">
                                      <label htmlFor="product-meta-7">
                                        Size Name | En
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control btn-light"
                                        id="product-meta-7"
                                        placeholder="Product Description | En"
                                        disabled
                                        value={
                                          (size.name && size.name.en) || ""
                                        }
                                      />
                                    </div>
                                    <div className="form-group mb-1 w-100 pr-3">
                                      <label htmlFor="product-meta-8">
                                        Size Name | Ar
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control btn-light"
                                        id="product-meta-8"
                                        placeholder="Product Description | En"
                                        disabled
                                        value={
                                          (size.name && size.name.ar) || ""
                                        }
                                      />
                                    </div>
                                    <div className="form-group mb-1 w-100 pr-3">
                                      <label htmlFor="product-meta-9">
                                        Calories
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control btn-light"
                                        id="product-meta-9"
                                        placeholder="Calories"
                                        disabled
                                        value={size.calories}
                                      />
                                    </div>
                                    <div className="form-group mb-1 w-100 pr-3">
                                      <label htmlFor="product-meta-10">
                                        Price
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control btn-light"
                                        id="product-meta-10"
                                        placeholder="Product Description | En"
                                        disabled
                                        value={size.price || ""}
                                      />
                                    </div>
                                    <div className="form-group mb-1 w-100 pr-3">
                                      <label htmlFor="product-meta-11">
                                        Cost
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control btn-light"
                                        id="product-meta-11"
                                        placeholder="Product Description | En"
                                        disabled
                                        value={size.cost || ""}
                                      />
                                    </div>
                                  </div>
                                )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box">
                        <h5 className="text-uppercase mt-0 mb-1 bg-light p-2">
                          Assign to
                        </h5>

                        <div className="form-group mb-2">
                          {!product.show && (
                            <h5 className="font-16 mt-0 sp-line-1">
                              <span className="badge bg-soft-warning text-warning">
                                Hidden
                              </span>
                            </h5>
                          )}
                          <div className="d-flex justify-content-between">
                            <div className="w-50 mr-1">
                              <label htmlFor="productLabelAr">
                                Product Label | AR
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="productLabelAr"
                                placeholder=" Product Label | AR"
                                onChange={e =>
                                  form.setFieldValue(
                                    "productLabelAr",
                                    e.target.value
                                  )
                                }
                                value={form.values.productLabelAr || ""}
                                // required
                              />
                            </div>
                            <div className="w-50 ml-1">
                              <label htmlFor="productLabelEn">
                                Product Label | EN
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="productLabelAr"
                                placeholder=" Product Label | EN"
                                onChange={e =>
                                  form.setFieldValue(
                                    "productLabelEn",
                                    e.target.value
                                  )
                                }
                                value={form.values.productLabelEn || ""}
                                // required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 d-flex ">
                          <input
                            style={{ width: "40%" }}
                            type="text"
                            className="form-control"
                            id="product-meta-12"
                            placeholder="Assigned Product"
                            disabled
                            value={form.values.assignedProduct || ""}
                          />

                          <div className="dropdown" style={{ width: "50%" }}>
                            <span
                              style={{ width: "60%" }}
                              className="btn btn-light dropdown-toggle"
                              id="dropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              onClick={() => setOpenDropDown(!openDropDown)}
                            >
                              Assign to another{" "}
                              <i className="mdi mdi-chevron-down"></i>
                            </span>
                            <div
                              className={classNames(
                                "dropdown-menu",
                                openDropDown && "show"
                              )}
                              aria-labelledby="dropdownMenuLink"
                              x-placement="bottom-start"
                            >
                              <span
                                aria-expanded="false"
                                className="dropdown-item"
                                onClick={breakRelation}
                              >
                                None
                              </span>
                              {products.map(relatedProduct => (
                                <span
                                  aria-expanded="false"
                                  className="dropdown-item"
                                  key={relatedProduct.id}
                                  onClick={() => {
                                    setOpenDropDown(!openDropDown);
                                    form.setFieldValue(
                                      "assignedProduct",
                                      relatedProduct.name.en
                                    );
                                    dispatch(
                                      addProduct({
                                        productId: product.id,
                                        relatedProductId: relatedProduct.id,
                                        productLabelEn:
                                          form.values.productLabelEn,
                                        productLabelAr:
                                          form.values.productLabelAr,
                                        assignedToLabelEn:
                                          form.values.assignedProductLabelEn ||
                                          "",
                                        assignedToLabelAr:
                                          form.values.assignedProductLabelAr ||
                                          ""
                                      })
                                    );
                                  }}
                                >
                                  {relatedProduct && relatedProduct.name.en}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-2 mt-2">
                          <div className="d-flex justify-content-between">
                            <div className="w-50 mr-1">
                              <label htmlFor="assignedProductLabelAr">
                                Assigned Product Label | AR
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="assignedProductLabelAr"
                                placeholder="Assigned Product Label | AR"
                                onChange={e =>
                                  form.setFieldValue(
                                    "assignedProductLabelAr",
                                    e.target.value
                                  )
                                }
                                value={form.values.assignedProductLabelAr || ""}
                                // required
                              />
                            </div>
                            <div className="w-50 ml-1">
                              <label htmlFor="assignedProductLabelEn">
                                Assigned Product Label | EN
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="assignedProductLabelEn"
                                placeholder="Assigned Product Label | EN"
                                onChange={e =>
                                  form.setFieldValue(
                                    "assignedProductLabelEn",
                                    e.target.value
                                  )
                                }
                                value={form.values.assignedProductLabelEn || ""}
                                // required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box">
                        <BranchTable formik={form} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box">
                        <h5 className="text-uppercase mt-0 mb-1 bg-light p-2">
                          Modifiers
                        </h5>
                        <div className="col-sm-12 mt-2">
                          <div className="text-center button-list">
                            {product.modifiers &&
                              product.modifiers.map(modifier => (
                                <ModifiersForProducts
                                  key={modifier.id}
                                  product={product}
                                  modifier={modifier}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="text-center mb-3">
                        <Link to={`/products`}>
                          <button
                            type="button"
                            className="btn btn-dark  w-sm btn-danger waves-effect waves-light mr-1"
                          >
                            Close
                          </button>
                        </Link>
                        <LoadingButton
                          isLoadingBtn={isLoadingBtn}
                          btnContent="Save"
                        />
                      </div>
                    </div>
                  </div>

                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                  />
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
